module App
{
  export class DatePickerDirective implements ng.IDirective {
    public static id = "kendoDatePicker";
    public restrict = 'AE';
    public scope = {
      kOptions: '=',
      kNgModel: '='
    }


    public link($scope: ng.IScope, element: JQuery, attributes: ng.IAttributes) {
      $scope["kOptions"] = $scope["kOptions"] || [];
      $scope.$watch("kNgModel", (value: string) => {
        $scope.$parent["stringValue"] = "";
        if (value) {
          var d = <Date>(new Date(value));
          $scope.$parent["stringValue"] = d.getDate()  + "-" + (d.getMonth()+1) + "-" + d.getFullYear() + " " +
          d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
        }
      });

      angular.extend($scope["kOptions"], {
        parseFormats: ["dd-MM-yyyy HH:mm:ss"],
        animation: {
          close: {
            effects: "fadeOut zoom:out",
            duration: 300
          },
          open: {
            effects: "fadeIn zoom:in",
            duration: 300
          }
        }
      })
    }
  }

  angular.module(Module)
    .directive(DatePickerDirective.id, () => new DatePickerDirective());
}
