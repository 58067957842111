module App {
    export class AnalysisListController extends BaseController {

        public newsFeedType: NewsFeedTypeEnum;
        public filterUsingPageEnum = FilterUsingPageEnum;
        public newsFeedTypeEnum = NewsFeedTypeEnum;
        public analysisList: AnalysisList[];
        public commonFilters: INewsFilter;

        constructor(
            private analysisService: AnalysisService,
            private eventManagerService: EventManagerService,
            private globals: any) {
            super();
        }

        $onInit = () => {
            this.analysisService.getAnalysisByUser(this.newsFeedType).then((response: AnalysisList[]) => {
                this.analysisList = response;
            });
            this.commonFilters = { project: null, subject: null, start_date: null, end_date: null };
        };

        public clearFilters = () => {
            for (let key in this.commonFilters) {
                this.commonFilters[key] = null;
            }
        };

        public generateChart = (analysis: AnalysisList) => {
            if (analysis.id == 11 && this.commonFilters.subject.length == 0) {
                Notifier.show("Նախ ընտրեք որևէ թեմա։", MessageType.warning);
            } else if (analysis.id == 11 && this.commonFilters.subject.length > 1) {
                Notifier.show("Վերլուծությունը նախատեսված է միայն մեկ թեմայի համար։", MessageType.warning);
            } else {
                analysis.filters = this.commonFilters;
                this.eventManagerService.publish(this.globals.events.generateAnalysisChart, analysis, this.newsFeedType);
            }
        }
    }

    export class AnalysisListComponent implements ng.IComponentOptions {
        public static id = "analysisList";
        templateUrl = "/components/analysis-components/analysis-list/analysis-list.html";
        controllerAs = "vm";
        bindings: any;
        constructor() {
            this.bindings = {
                newsFeedType: "<?",
            };
        }
        controller = [
            'analysisService',
            'eventManagerService',
            'globals',
            AnalysisListController
        ];
    }

    angular.module(Module)
        .component(AnalysisListComponent.id, new AnalysisListComponent());
}
