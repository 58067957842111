module App {

    export interface  ICustomerService{
        getCustomers(): ng.IPromise<ICustomer[]>;

		saveSubjectForMailing(subjectForMailing: any): ng.IPromise<boolean>;
		removeSubjectForMailing(id: number): ng.IPromise<boolean>;
    }

    export class CustomerService implements ICustomerService {
        static id = "customerService";

        /*@ngInject*/
        constructor(private $q: ng.IQService){

        }

        public getCustomers = (): ng.IPromise<ICustomer[]> => {
            return HttpRequester.post<ICustomer[]>("/request/get_customers", null)
                .then((response: ICustomer[]) => {
                    return response;
                });
        };

        public saveSubjectForMailing = (subjectForMailing: any): ng.IPromise<boolean> => {
			return HttpRequester.post<boolean>("/request/save_user_mailing_subject", {subjectForMailing: subjectForMailing})
				.then((response: boolean) => {
					return true;
				}).catch( (err) => {
					return false;
				});
        }

		public removeSubjectForMailing = (id: number): ng.IPromise<boolean> => {
			return HttpRequester.post<boolean>("/request/remove_subject_for_mailing", {id: id})
				.then((response: boolean) => {
					return true;
				}).catch( (err) => {
					return false;
				});
		}

		public getMassMediaSectionsByCustomerId = (customerId: number, massMediaId: number ): ng.IPromise<any> => {
            return HttpRequester.post<any>("request/get_mass_media_sections_by_customer_id", {customer_id: customerId, mass_media_id: massMediaId})
                .then((response: any) => {
                return response;
            }).catch( (err) => {
                return false;
            });
        }

        public saveMassMediaSectionToCustomer = (customerId: number, massMediaId: number, sections: string): ng.IPromise<boolean> => {
            return HttpRequester.post<boolean>("request/save_mass_media_section_to_customer",{customer_id: customerId, mass_media_id: massMediaId, sections: sections})
                .then((response: boolean) => {
                    return response;
                }).catch( (err) => {
                    return false;
                });
        }

        public removeMassMediaSections = (customerId: number, massMediaId: number): ng.IPromise<boolean> => {
            return HttpRequester.post<boolean>("request/remove_mass_media_sections_from_customer",{customer_id: customerId, mass_media_id: massMediaId})
                .then((response: boolean) => {
                    return response;
                }).catch( (err) => {
                    return false;
                });
        }
    }

    angular.module(App.Module)
        .service(CustomerService.id, CustomerService);
}
