module App {

    export interface  ISocialMediaService{
        saveSocialmediaAccount(social_account: ISocialMedia): ng.IPromise<boolean>;
        savePost(post: IPost): ng.IPromise<boolean>;
        getPostList(requestParameter: IRequestParameterModel): ng.IPromise<INewsFeedResponse>; 
        saveEmojies(post: IPost): ng.IPromise<boolean> ;
        changeReadState(posts:Array<ISocialMediaElement>, state: boolean): ng.IPromise<boolean>;
        removePosts(elementsList: any[], action: FeedElementActionsEnum, groupId?: any): ng.IPromise<boolean>;
        savePostFilter(filter: IPostFilter): ng.IPromise<boolean>;
    }

    export class SocialMediaService implements ISocialMediaService {
        static id = "socialMediaService";

        /*@ngInject*/
        constructor(private $q: ng.IQService,
                    private $http: ng.IHttpService,
                    private configurationService: IConfigurationService){

                     }

        
        public saveEmojies = (post: IPost): ng.IPromise<boolean> => {
            return HttpRequester.post("/request/save_emojies", post)
                .then(() => {
                    return true;
                }).catch( (err) => {
                    Notifier.show(err.message, MessageType.error);
                    return false;
                });
        };

        public getSubjectsBySelectedPosts(selectedElements:Array<ISocialMediaElement>): ng.IPromise<Dictionary[]>{
            return HttpRequester.post<Dictionary[]>("/request/get_post_common_subjects", {id: this.generateStringFromArray(selectedElements)})
                .then((res: Dictionary[]) => {
                    return res;
                })
        };

        public changeReadState = (postList:Array<ISocialMediaElement>, state: boolean): ng.IPromise<boolean> => {
            return HttpRequester.post("/request/change_post_read_state",  {state: state, posts: this.generateStringFromArray(postList)})
                .then(() => {
                    return true;
                }).catch( (err) => {
                    Notifier.show(err.message, MessageType.error);
                    return false;
                });
        };

        public getPostList = (requestParameter: IRequestParameterModel): ng.IPromise<INewsFeedResponse> => {
            return HttpRequester.post<INewsFeedResponse>("/request/get_social_media_feed",  requestParameter)
                .then((res: any) => {
                    return res
                });
        };         

        public saveSocialmediaAccount= (social_account: ISocialMedia): ng.IPromise<boolean> => {
            return HttpRequester.post("/request/save_social_media_account", social_account)
                .then(() => {
                    return true;
                }).catch( (err) => {
                    Notifier.show(err.message, MessageType.error);
                    return false;
                });
        };  

        public savePost = (post: IPost): ng.IPromise<boolean> => {
            return HttpRequester.post("/request/save_post", post)
                .then(() => {
                    return true;
                }).catch( (err) => {
                    Notifier.show(err.message, MessageType.error);
                    return false;
                });
        };   

        private generateStringFromArray=(arr: any[], property: string = "id"): string => {
            let result: number[] = [];
            arr.forEach(item => {
                result.push(item[property]);
            });
            return result.join();
        };

        public removePosts = (elementsList: any[], action: FeedElementActionsEnum, groupId?: any): ng.IPromise<boolean> => {
            let requestUrl = action ==  FeedElementActionsEnum.RemoveElementFromProject ? "/request/remove_post_from_project" : "/request/remove_post_from_subjects";
            let requestParameter = action ==  FeedElementActionsEnum.RemoveElementFromProject
                                   ? {ids: this.generateStringFromArray(elementsList), projectId: groupId}
                                   : {ids: this.generateStringFromArray(elementsList), subjectIds: groupId.join()};
            return this.$http.post<boolean>(requestUrl, requestParameter)
                .then(() => {
                    Notifier.show('ARTICLE_REMOVED', MessageType.success);
                    return true;
                }).catch( (err) => {
                    Notifier.show(err.message, MessageType.error);
                    return false;
                });
        };

        
        public savePostFilter = (filter: IPostFilter): ng.IPromise<boolean> => {
            let convertedFilter = angular.copy(filter);
            convertedFilter.subject = convertedFilter.subject && <any>convertedFilter.subject.join();
            convertedFilter.social_media_accounts = convertedFilter.social_media_accounts && <any>convertedFilter.social_media_accounts.join();
            return HttpRequester.post("/request/save_post_filter", convertedFilter)
                .then(() => {
                    return true;
                }).catch( (err) => {
                    Notifier.show(err.message, MessageType.error);
                    return false;
                });
        };

        public getPostFilterById = (id: number): ng.IPromise<IPostFilter> => {
            return HttpRequester.post("/request/get_post_filter_by_id", {id: id})
                .then((response: any) => {
                    var filter = new PostFilter();
                    filter.id = response.id;
                    filter.name = response.name;
                    filter.start_date = response.start_date;
                    filter.end_date = response.end_date;
                    filter.pattern = response.pattern;
                    filter.project = response.project;
                    filter.subject = response.subjects && response.subjects.split(",");
                    filter.social_media_account_type = response.social_media_type;
                    filter.social_media_accounts = response.social_medias && response.social_medias.split(",");
                    return filter;
                }).catch( (err) => {
                    Notifier.show(err.message, MessageType.error);
                    return null;
                });
        };

        public getSocialMediaPostBYId = (id:number): ng.IPromise<IPost> => {
            return  HttpRequester.post("/request/get_social_media_post_by_id", {id: id})
            .then((res: any) => {
                return res
            });
        }

    }

    angular.module(App.Module)
        .service(SocialMediaService.id, SocialMediaService);
}
