module App {
  export class PressEventViewController extends BaseController {
    public pressEventId: number;
    public changeToList () {
      this.viewMode = ViewMode.List;
    }
    constructor(private $mdDialog: any){
      super();

    }

    $onInit(){
        this.LoadPressEvent(this.pressEventId);
    }

    public LoadPressEvent(id: number) {
      this.pressEvent = new PressEvent(id);
      this.pressEvent.loadFromDB((err) => {
        if (err) {
          console.error("Cannot get press event", err);
        }
      });
    }

    public pressEvent: PressEvent = new PressEvent();

    public header = {
      title: "PRESS_EVENT",
      icon: "action:event",
      buttons: {
        btn_edit: {
          tooltip: "EDIT_PRESS_EVENT",
          permission_name: "edit_press_event",
          icon: "editor:mode_edit",
          fnc: ($event) => {
            var options: any;

            this.$mdDialog.show({
              template: '<press-event-edit options="options" press-event-id="pressEventId"></press-event-edit>',
              parent: angular.element(document.body),
              targetEvent: $event || false,
              //clickOutsideToClose: options.clickOutsideToClose || true,
              locals: {options: options},
              controller: ['$scope', 'options', ($scope, options) => {
                $scope.options = options;
                $scope.pressEventId = this.pressEventId} ]
            }).then(() => {
              this.pressEvent.loadFromDB();
            });

          },
        },
      }
    };

    public press_event_form_options = {
      fields: {
        name: {
          type: "text",
          readonly: "true",
          label: "NAME",
          get: (item) => item
        },
        event_date: {
          type: "text",
          readonly: "true",
          label: "EVENT_DATE",
          get: (item) => item,
          format: 'date'
        }
      },
      rows: [
        [{field: "name", col_class: "col-md-8"}, {field: "event_date", col_class: "col-md-4"}]
      ]
    };
  }

  export class PressEventViewComponent implements ng.IComponentOptions {
    public static id = "pressEventView";
    templateUrl = "/components/press-event-components/press-event-view-component/press-event-view.component.html";
    controllerAs = "vm";
    bindings: any;
    constructor(){
      this.bindings = {
          viewMode: "=",
          pressEventId: "<"
      };
    }
    controller = [
      '$mdDialog',
      PressEventViewController
    ];
  }

  angular.module(Module)
    .component(PressEventViewComponent.id, new PressEventViewComponent());

}
