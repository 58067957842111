module App {
    export class FbEmojiesComponentController  {

        public options: any;
        public viewMode: ViewMode;
        public Mode = ViewMode;
        
        constructor(private $mdDialog: any,
            private eventManagerService: EventManagerService,
            private socialMediaService: SocialMediaService,
            private globals: any) {
        }

        $onInit(){    }

        public save = () => {
            this.socialMediaService.saveEmojies(this.options).then((res) => {
                if (res) {
                    Notifier.show("Պահպանված է։", MessageType.success);
                    this.closeWindow();
                    this.eventManagerService.publish(this.globals.events.getPostList,1)
                }
            })
        }

        private closeWindow = (): void =>{
            this.$mdDialog.hide();
        }
    }

    export class FbEmojiesComponent implements ng.IComponentOptions {
        public static id = "fbEmojies";
        templateUrl = "/components/social-media-components/fb-emojies/fb-emojies.component.html";
        controllerAs = "vm";
        bindings: any;
        constructor() {
            this.bindings = {
                options: "<"
            };
        }
        controller = ['$mdDialog',  'eventManagerService', 'socialMediaService', 'globals',
            FbEmojiesComponentController
        ];
    }
    angular.module(Module)
        .component(FbEmojiesComponent.id, new FbEmojiesComponent());
}
