module App {
    export class NewsFeedCardComponentController {
        private cardElement: IFeedCardElement;
        public Languages = LanguageEnum;
        public NewsFeedSelectionModeEnum = NewsFeedSelectionModeEnum;
        public searchPattern: string;

        constructor(private $mdDialog: any,
                    private newsFeedService: INewsFeedService,
                    private eventManagerService: EventManagerService,
                    private globals: any,
                    public authenticationService: Authentication,
                    public configurationService: IConfigurationService) {
        }

        $onInit() {
            //this.refreshLanguage(this.cardElement.articles[0].language);
            this.refreshLanguage(_.sortBy(this.cardElement.articles, 'language')[0].language);
        }

        public getFbLink = (): string => {
            if (this.cardElement.selected_article.fb_link) {
                return this.cardElement.selected_article.fb_link;
            } else if (this.cardElement.articles.length > 1) {
                let a: Article = _.find(_.sortBy(this.cardElement.articles, 'language'), (item: Article) => {
                    return item.fb_link
                });
                return a && a.fb_link;
            } else {
                return null;
            }
        };

        public changeSelected = () => {
            this.eventManagerService.publish(this.globals.events.selectedElementsEdit, this.cardElement.id);
        };

        public refreshLanguage = (lang: number): void => {
            this.cardElement.selected_article = _.find(this.cardElement.articles, (item: Article) => (item.language == lang));
            this.cardElement.logo = this.findLogo();
        };

        public findLogo = (): string => {
            return "imgs/massMediaLogos/svg/" + this.cardElement.selected_article.mass_media.name + ".svg";
        };

        public showDialog = (action, $event): void => {
            let options: any;
            if (action == 1) {
                options = {
                    elementId: this.cardElement.id
                };
            } else {
                options = {
                    articleId: this.cardElement.selected_article.id
                };
            }
            this.$mdDialog.show({
                template: action == 1 ? '<news-feed-element-analyze options="options"></news-feed-element-analyze>' : '<add-fb-link options="options"></add-fb-link>',
                parent: angular.element(document.body),
                targetEvent: $event || false,
                locals: {options: options},
                controller: ['$scope', 'options', ($scope, options) => {
                    $scope.options = options;
                }
                ]
            }).then(() => {
                //this.eventManagerService.publish(this.globals.events.getNewsFeedList,1)
            });
        };


        public changeReadUnreadState = (state: boolean): void => {
            this.newsFeedService.changeReadState([this.cardElement], state).then((response: boolean) => {
                if (response) {
                    this.cardElement.read_state = state;
                }
            });
        }
    }

    export class NewsFeedCardComponent implements ng.IComponentOptions {
        public static id = "newsFeedCard";
        templateUrl = "/components/news-feed-components/news-feed-card/news-feed-card.component.html";
        controllerAs = "vm";
        bindings: any;

        constructor() {
            this.bindings = {
                cardElement: "<",
                searchPattern: "<?"
            };
        }

        controller = ['$mdDialog', 'newsFeedService', 'eventManagerService', 'globals', 'authenticationService', 'configurationService',
            NewsFeedCardComponentController
        ];
    }
    angular.module(Module)
        .component(NewsFeedCardComponent.id, new NewsFeedCardComponent());
}
