module App {

    export interface  INewsFeedService{
        getNewsFeedList(filter: any): ng.IPromise<INewsFeedResponse>;
        changeReadState(elementsList: IFeedCardElement[],state: boolean): ng.IPromise<boolean>;
        saveFilter(filter: INewsFilter): ng.IPromise<boolean>;
        saveArticle(article: IArticle): ng.IPromise<boolean>;
        groupAction(elementsList: any[], action: FeedElementActionsEnum, groupId?: number): ng.IHttpPromise<boolean>;
        getAnalyzeDetailsBySubject(elementId: number): ng.IPromise<IAnalyzeDetail[]>;
        getAnalyzeDetailsBySelectedArticle(elementsList: any[], feedType:NewsFeedTypeEnum): ng.IPromise<IAnalyzeDetail[]>;
        analyzeFeedElementBySubject(analyzeData: IAnalyzeDetail): ng.IPromise<boolean>;
        analyzeFeedElementBySelectedArticles(analyzeData: IAnalyzeDetail, analyzeList: any[], feedType: NewsFeedTypeEnum): ng.IPromise<boolean>;
        getFilterById(id: number): ng.IPromise<INewsFilter>;
        getAttitudeDatasourse(): ng.IPromise<Array<any>>;
        getCountriesDatasourse(): ng.IPromise<Array<any>>;
        saveFbLink(fbLinkData: any, articleId: number ): ng.IPromise<boolean>;
        getFbLinkData(elementId: number): any;
        getSubjectsBySelectedArticles(selectedElements: IFeedCardElement[]): ng.IPromise<Dictionary[]>;
    }

    export class NewsFeedService implements INewsFeedService {
        static id = "newsFeedService";

        /*@ngInject*/
        constructor(private $q: ng.IQService,
                    private $http: ng.IHttpService,
                    private configurationService: IConfigurationService){ }

/*        public getNewsFeedList = (requestParameter: IRequestParameterModel): ng.IPromise<IFeedCardElement[]> => {
            var defer = this.$q.defer<IFeedCardElement>();
            var ret: IFeedCardElement[] = this.dummyData;
            defer.resolve(<IFeedCardElement>ret);
            return defer.promise;
        }*/
        public getSubjectsBySelectedArticles(selectedElements: IFeedCardElement[]): ng.IPromise<Dictionary[]>{
            return HttpRequester.post<Dictionary[]>("/request/get_common_subjects", {id: this.generateStringFromArray(selectedElements)})
                .then((response: Dictionary[]) => {
                    return response;
                })
        }

        public getNewsFeedList = (requestParameter: IRequestParameterModel): ng.IPromise<INewsFeedResponse> => {
            let requestURL = this.configurationService.newsFeedSelectionMode == NewsFeedSelectionModeEnum.Sections  ?  "/request/get_news_feed_by_sections" : "/request/get_news_feed_for_customer";
            return HttpRequester.post<INewsFeedResponse>(requestURL, requestParameter)
                .then((response: any) => {
                    return this.generateFeedCardElements(response);
                });
        };

        private generateFeedCardElements = (response: any): INewsFeedResponse => {
            let generatedElements: INewsFeedResponse = new NewsFeedResponse();
            if (response) {
                generatedElements.total_count = response.total_count;
                let i: number;
                for (let article of response.articles) {
                    i = _.findIndex(response.feed_elements, (element: IFeedCardElement) => { return element.id == article.feed_element_id});
                    if (i != -1) {
                        if(!response.feed_elements[i].articles) {
                            response.feed_elements[i].articles = [];
                        }
                        response.feed_elements[i].articles.push(this.makeArticleModel(article));
                    }
                }
                generatedElements.feed_elements = response.feed_elements;
            }
            return generatedElements;
        };

        private makeArticleModel = (responseArticle: any): Article => {
            return new Article(responseArticle);
        };

        public changeReadState = (elementsList: IFeedCardElement[],state: boolean): ng.IPromise<boolean> => {
            return HttpRequester.post<INewsFeedResponse>("/request/change_feed_element_read_state", {state: state, elementsList: this.generateStringFromArray(elementsList)})
                .then(() => {
                    return true;
                }).catch( (err) => {
                    Notifier.show(err.message, MessageType.error);
                    return false;
                });
        };

        public saveFilter = (filter: INewsFilter): ng.IPromise<boolean> => {
            let convertedFilter = angular.copy(filter);
            convertedFilter.subject = convertedFilter.subject && <any>convertedFilter.subject.join();
            convertedFilter.mass_media = convertedFilter.mass_media && <any>convertedFilter.mass_media.join();
            return HttpRequester.post("/request/save_filter", convertedFilter)
                .then(() => {
                    return true;
                }).catch( (err) => {
                    Notifier.show(err.message, MessageType.error);
                    return false;
                });
        };

        public saveArticle = (article: IArticle): ng.IPromise<boolean> => {
            return HttpRequester.post("/request/save_article", article)
                .then(() => {
                    return true;
                }).catch( (err) => {
                    Notifier.show(err.message, MessageType.error);
                    return false;
                });
        };

        public saveFbLink = (fbLinkData: any ,articleId: number): ng.IPromise<boolean> => {
            return HttpRequester.post("/request/save_fb_link", {fbLinkData: fbLinkData, articleId: articleId})
                .then(() => {
                    return true;
                }).catch( (err) => {
                    Notifier.show(err.message, MessageType.error);
                    return false;
                });
        };

        public getFbLinkData = (articleId: number): any => {
            return HttpRequester.post("/request/get_article_fb_data", { articleId: articleId})
            .then((response: any) => {
                return response;
            })
        };

        public groupAction = (elementsList: any[], action: FeedElementActionsEnum, groupId?: any): ng.IHttpPromise<boolean> => {
            if (action ==  FeedElementActionsEnum.ArticleMerge) {
                return this.$http.post<boolean>('/request/merge_articles', {ids: this.generateStringFromArray(elementsList)})
                    .success((response) => {
                        Notifier.show('CHANGES_SAVED', MessageType.success);
                        return true
                    }).error((err) => {
                        Notifier.show(err.message || "ERROR_OCCURRED", MessageType.error);
                        return false;
                    });
            } else if (action ==  FeedElementActionsEnum.PressEventJoin) {
                return this.$http.post<boolean>('/request/join_in_press_event', {ids: this.generateStringFromArray(elementsList), pressEvent: groupId})
                    .success((response) => {
                        Notifier.show('CHANGES_SAVED', MessageType.success);
                        return true
                    }).error((err) => {
                        Notifier.show(err.message || "ERROR_OCCURRED", MessageType.error);
                        return false;
                    });
            } else if (action ==  FeedElementActionsEnum.RemoveElementFromProject) {
                return this.$http.post<boolean>('/request/remove_element_from_project', {ids: this.generateStringFromArray(elementsList), projectId: groupId})
                    .success((response) => {
                        Notifier.show('ARTICLE_REMOVED', MessageType.success);
                        return true
                    }).error((err) => {
                        Notifier.show(err.message || "ERROR_OCCURRED", MessageType.error);
                        return false;
                    });
            } else if (action ==  FeedElementActionsEnum.RemoveElementFromSubjects) {
                return this.$http.post<boolean>('/request/remove_element_from_subjects', {ids: this.generateStringFromArray(elementsList), subjectIds: groupId.join()})
                    .success((response) => {
                        Notifier.show('ARTICLE_REMOVED', MessageType.success);
                        return true
                    }).error((err) => {
                        Notifier.show(err.message || "ERROR_OCCURRED", MessageType.error);
                        return false;
                    });
            }
        };

        public getAnalyzeDetailsBySubject = (item: any): ng.IPromise<Array<IAnalyzeDetail>> => {
            let requestURL = item.post ?  "/request/get_post_analyze_details_by_subject": "/request/get_analyze_details_by_subject";
            return HttpRequester.post<Array<IAnalyzeDetail>>(requestURL, {id: item.elementId})
                .then((response: any) => {
                    return response;
                });
        };

        public getAnalyzeDetailsBySelectedArticle = (elementsList: any[], feedType: NewsFeedTypeEnum): ng.IPromise<Array<IAnalyzeDetail>> => {
            let requestURL = (feedType === NewsFeedTypeEnum.SocialMedia) ?  "/request/get_post_analyze_details_by_selected_articles": "/request/get_analyze_details_by_selected_articles";
            return HttpRequester.post<Array<IAnalyzeDetail>>(requestURL, {ids: this.generateStringFromArray(elementsList)})
                .then((response: any) => {
                    return response;
                });
        };
        // private generateAnalyzeDetails = (response: any): Array<IAnalyzeDetail> => {
        //     let generatedAnalyzeDetails: IAnalyzeDetail[] = [];
        //
        //     for (let detail of response.details) {
        //         detail.tags =_.map(_.filter(response.tags, (tag: any) => { return tag.subject_id == detail.subject_id}), (item) => {
        //              if(item.subject_id == detail.subject_id) {
        //                  return [item.tag]
        //             }
        //         });
        //         generatedAnalyzeDetails.push(detail);
        //     }
        //
        //     return generatedAnalyzeDetails;
        // };

        public analyzeFeedElementBySubject = (analyzeData: IAnalyzeDetail): ng.IPromise<boolean> => {
            let requestURL = analyzeData.post_id ?  "/request/save_post_analyze_details": "/request/save_analyze_details";
            return HttpRequester.post<boolean>(requestURL, {details: analyzeData})
                .then(() => {
                    return true;
                }).catch( (err) => {
                    Notifier.show(err.message, MessageType.error);
                    return false;
                });
        };

        public analyzeFeedElementBySelectedArticles = (analyzeData: IAnalyzeDetail, analyzeList: any[], feedType: NewsFeedTypeEnum): ng.IPromise<boolean> => {
            let requestURL = (feedType === NewsFeedTypeEnum.MassMedia) ?  "/request/save_analyze_details_by_articles": "/request/save_post_analyze_details_by_articles";
            return HttpRequester.post<boolean>(requestURL, {ids: this.generateStringFromArray(analyzeList), details: analyzeData})
                .then(() => {
                    return true;
                }).catch( (err) => {
                    Notifier.show(err.message, MessageType.error);
                    return false;
                });
        };

        private generateStringFromArray=(arr: any[], property: string = "id"): string => {
            let result: number[] = [];
            arr.forEach(item => {
                result.push(item[property]);
            });
            return result.join();
        };

        public getFilterById = (id: number): ng.IPromise<INewsFilter> => {
            return HttpRequester.post("/request/get_user_filter_by_id", {id: id})
                .then((response: any) => {
                    var filter = new NewsFilter();
                    filter.id = response.id;
                    filter.name = response.name;
                    filter.start_date = response.start_date;
                    filter.end_date = response.end_date;
                    filter.project = response.project;
                    filter.subject = response.subjects && response.subjects.split(",");
                    filter.mass_media_type = response.mass_media_type;
                    filter.mass_media = response.mass_medias && response.mass_medias.split(",");
                    filter.press_event = response.press_event;
                    filter.rated = response.rated;
                    filter.attitude = response.attitude;
                    filter.mass_media_rating = response.mass_media_rating;
                    filter.reference_type = response.reference_type;
                    return filter;
                }).catch( (err) => {
                    Notifier.show(err.message, MessageType.error);
                    return null;
                });
        };
        public getAttitudeDatasourse = (): ng.IPromise<Array<any>> => {
            return HttpRequester.post<Array<any>>("/request/get_attitudes", null)
                .then((response: any) => {
                    return response;
                });
        };

        public getCountriesDatasourse = (): ng.IPromise<Array<any>> => {
            return HttpRequester.post<Array<any>>("/request/get_countries_for_combo", null)
                .then((response: any) => {
                    return response;
                });
        };
    }

    angular.module(App.Module)
        .service(NewsFeedService.id, NewsFeedService);
}
