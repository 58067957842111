module App {

    export interface IChart {
        id?: number,
        uid?: string,
        name?: string,
        data?: any,
        additionalProperty?: string;
        filter?: INewsFilter
    }

    interface ICharts {
        analysis1: IChart[],
        analysis2: IChart[],
        analysis3: IChart[],
        analysis4: IChart[],
        analysis5: IChart[],
        analysis6: IChart[],
        analysis7: IChart[],
        analysis8: IChart[],
        analysis9: IChart[],
        analysis10: IChart[],
        analysis11: IChart[],
        analysis12: IChart[],
        analysis13: IChart[],
        analysis14: IChart[],
        analysis15: IChart[],
    }

    export class AnalysisViewController extends BaseController {
        public analysisId: number;
        public transferEvent = 'transfer';

        public charts: ICharts = {
            "analysis1": [],
            "analysis2": [],
            "analysis3": [],
            "analysis4": [],
            "analysis5": [],
            "analysis6": [],
            "analysis7": [],
            "analysis8": [],
            "analysis9": [],
            "analysis10": [],
            "analysis11": [],
            "analysis12": [],
            "analysis13": [],
            "analysis14": [],
            "analysis15": [],
        };

        public newsFeedType: NewsFeedTypeEnum;

        constructor(private eventManagerService: EventManagerService,
            private analysisService: IAnalysisService,
            private globals: any) {
            super();
            this.eventManagerService.subscribe(globals.events.removeChartFromList, this.removeChart);
        }

        $onInit() {
            this.eventManagerService.subscribe(this.globals.events.generateAnalysisChart, this.generateAnalysisChart);
        }

        public generateAnalysisChart = (analysis: AnalysisList, newsFeedType: NewsFeedTypeEnum): void => {
            let uid: string = "uid_" + Date.now().toString();
            this.newsFeedType = newsFeedType;
            switch (analysis.id) {
                case 1:
                    this.analysisService.analysisByDistributionOfMediaCoverage(analysis).then((response) => {
                        let c: IChart = {
                            id: analysis.id,
                            name: analysis.name,
                            data: response,
                            uid: uid,
                            filter: analysis.filters
                        };
                        this.charts.analysis1.unshift(c)
                    }).catch((err: Error) => {
                        Notifier.show("ERROR_OCCURRED", MessageType.error)
                    });
                    break;
                case 2:
                    this.analysisService.analysisByAttitudes(analysis).then((response) => {
                        let c: IChart = {
                            id: analysis.id,
                            name: analysis.name,
                            data: response,
                            additionalProperty: "attitude",
                            uid: uid,
                            filter: analysis.filters
                        };
                        this.charts.analysis2.unshift(c)
                    }).catch((err: Error) => {
                        Notifier.show("ERROR_OCCURRED", MessageType.error)
                    });
                    break;
                case 3:
                    this.analysisService.analysisByMassMediaRatings(analysis).then((response) => {
                        let c: IChart = {
                            id: analysis.id,
                            name: analysis.name,
                            data: response,
                            additionalProperty: "mass_media_rating",
                            uid: uid,
                            filter: analysis.filters
                        };
                        this.charts.analysis3.unshift(c)
                    }).catch((err: Error) => {
                        Notifier.show("ERROR_OCCURRED", MessageType.error)
                    });
                    break;
                case 4:
                    this.analysisService.analysisByReferenceTypes(analysis).then((response) => {
                        let c: IChart = {
                            id: analysis.id,
                            uid: uid,
                            name: analysis.name,
                            data: response,
                            additionalProperty: "reference_type",
                            filter: analysis.filters
                        };
                        this.charts.analysis4.unshift(c)
                    }).catch((err: Error) => {
                        console.log(err.message);
                    });
                    break;
                case 5:
                    this.analysisService.analysisByCategory(analysis).then((response) => {
                        let c: IChart = {
                            id: analysis.id,
                            uid: uid,
                            name: analysis.name,
                            data: response,
                            filter: analysis.filters
                        };
                        this.charts.analysis5.unshift(c)
                    }).catch((err: Error) => {
                        console.log(err.message);
                    });
                    break;
                case 6:
                    this.analysisService.analysisScore(analysis).then((response) => {
                        let c: IChart = {
                            id: analysis.id,
                            uid: uid,
                            name: analysis.name,
                            data: response,
                            filter: analysis.filters
                        };
                        this.charts.analysis6.unshift(c)
                    }).catch((err: Error) => {
                        console.log(err.message);
                    });
                    break;
                case 7:
                    this.analysisService.analysisBySphere(analysis).then((response) => {
                        let c: IChart = {
                            id: analysis.id,
                            uid: uid,
                            name: analysis.name,
                            data: response,
                            filter: analysis.filters
                        };
                        this.charts.analysis7.unshift(c)
                    }).catch((err: Error) => {
                        console.log(err.message);
                    });
                    break;
                case 8:
                    this.analysisService.analysisFB(analysis).then((response) => {
                        let c: IChart = {
                            id: analysis.id,
                            uid: uid,
                            name: analysis.name,
                            data: response,
                            filter: analysis.filters
                        };
                        this.charts.analysis8.unshift(c)
                    }).catch((err: Error) => {
                        console.log(err.message);
                    });
                    break;
                case 9:
                    this.analysisService.analysisByPressEvents(analysis).then((response) => {
                        let c: IChart = {
                            id: analysis.id,
                            name: analysis.name,
                            data: response,
                            uid: uid,
                            filter: analysis.filters
                        };
                        this.charts.analysis9.unshift(c)
                    }).catch((err: Error) => {
                        Notifier.show("ERROR_OCCURRED", MessageType.error)
                    });
                    break;
                case 10:
                    this.analysisService.analysisCompareForPeriod(analysis).then((response) => {
                        let c: IChart = {
                            id: analysis.id,
                            name: analysis.name,
                            data: response,
                            uid: uid,
                            filter: analysis.filters
                        };
                        this.charts.analysis10.unshift(c)
                    }).catch((err: Error) => {
                        Notifier.show("ERROR_OCCURRED", MessageType.error)
                    });
                    break;
                case 11:
                    this.analysisService.analysisWeeklyBySubject(analysis).then((response) => {
                        let c: IChart = {
                            id: analysis.id,
                            uid: uid,
                            name: analysis.name,
                            data: response,
                            filter: analysis.filters
                        };
                        this.charts.analysis11.unshift(c)
                    }).catch((err: Error) => {
                        console.log(err.message);
                    });
                    break;
                // case 11:
                //     this.analysisService.analysisByTags(analysis).then((response) => {
                //         let c: IChart = {
                //             id: analysis.id,
                //             uid: uid,
                //             name: analysis.name,
                //             data: response,
                //             filter: analysis.filters
                //         };
                //         this.charts.analysis10.unshift(c)
                //     }).catch((err: Error) => {
                //         console.log(err.message);
                //     });
                //     break;
                case 12:
                    this.analysisService.fbAnalysisByDistributionOfMediaCoverage(analysis).then((response) => {
                        let c: IChart = {
                            id: analysis.id,
                            uid: uid,
                            name: analysis.name,
                            data: response,
                            filter: analysis.filters
                        };
                        this.charts.analysis12.unshift(c)
                    }).catch((err: Error) => {
                        console.log(err.message);
                    });
                    break;
                case 13:
                    this.analysisService.fbAnalysisByAttitudes(analysis).then((response) => {
                        let c: IChart = {
                            id: analysis.id,
                            uid: uid,
                            name: analysis.name,
                            data: response,
                            filter: analysis.filters
                        };
                        this.charts.analysis13.unshift(c)
                    }).catch((err: Error) => {
                        console.log(err.message);
                    });
                    break;
                case 14:
                    this.analysisService.fbAnalysisByCategory(analysis).then((response) => {
                        let c: IChart = {
                            id: analysis.id,
                            uid: uid,
                            name: analysis.name,
                            data: response,
                            filter: analysis.filters
                        };
                        this.charts.analysis14.unshift(c)
                    }).catch((err: Error) => {
                        console.log(err.message);
                    });
                    break;
                case 15:
                    this.analysisService.fbAnalysisBySphere(analysis).then((response) => {
                        let c: IChart = {
                            id: analysis.id,
                            uid: uid,
                            name: analysis.name,
                            data: response,
                            filter: analysis.filters
                        };
                        this.charts.analysis15.unshift(c)
                    }).catch((err: Error) => {
                        console.log(err.message);
                    });
                    break;
            }
        };

        public removeChart = (id: number, uid: string): void => {
            let idx = _.findIndex(this.charts["analysis" + id.toString()], (item: IChart) => {
                return item.uid == uid
            });
            this.charts["analysis" + id.toString()].splice(idx, 1);
        };

        public isChartsExists = (): boolean => {
            for (let key in this.charts) {
                if (this.charts[key].length) {
                    return false;
                }
            }
            return true;
        }
    }

    export class AnalysisViewComponent implements ng.IComponentOptions {
        public static id = "analysisView";
        templateUrl = "/components/analysis-components/analysis-view/analysis-view.html";
        controllerAs = "vm";
        bindings: any;

        constructor() {
            this.bindings = {};
        }

        controller = [
            'eventManagerService',
            'analysisService',
            'globals',
            AnalysisViewController
        ];
    }

    angular.module(Module)
        .component(AnalysisViewComponent.id, new AnalysisViewComponent());

}
