module App
{
    export class SprootDateTimePickerDirective implements ng.IDirective {
        public static id = "sprootDateTimePicker";
        public template = `
            <md-input-container class="md-block">
                <label>{{label | translate}}</label>
                <input kendo-date-time-picker 
                        k-ng-model="value" 
                        ng-model="stringValue" 
                        k-format = "format || 'dd/MM/yy HH:mm'" 
                        k-options="options" 
                        name="{{fieldName}}"
                        ng-required="form && required"
                        aria-label="..."/>
                <div ng-messages="form[fieldName].$error">
                    <div ng-message="required">{{'THIS_IS_REQUIRED' | translate}}</div>
                </div>
            </md-input-container>`;
        public restrict = 'AE';
        public require = ['^?form'];
        public replace: boolean = false;
        public scope = {
            value: '=ngModel',
            label: "<?",
            format: "<?",
            name: "<?",
            required: "<?"
        }

        public link = ($scope: ng.IScope, element: JQuery, attributes: ng.IAttributes, ctrl: ng.IControllerService[] ) => {
            $scope["form"] = ctrl && ctrl[0];
            $scope["fieldName"] = $scope["name"] || $scope["label"];
            $scope["options"] = this.options;
            $scope.watcher = $scope.$watch("value", (newValue, oldValue) => {
                if(newValue !== undefined) {
                    if (newValue) {
                        $scope["stringValue"] = new Date($scope["value"]) 
                    }                  
                } else {
                    element.find("input").val("");
                }
                  
            });
        }

        public options: kendo.ui.DatePickerOptions = {
            animation: {
                close: {
                    effects: "fadeOut zoom:out",
                    duration: 300
                },
                open: {
                    effects: "fadeIn zoom:in",
                    duration: 300
                }
            }
        }

    }

    angular.module(Module)
        .directive(SprootDateTimePickerDirective.id, () => new SprootDateTimePickerDirective());
}
