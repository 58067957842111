module App {
  'use strict';

  export class AnalysisController extends BaseController {
    static id = "analysisController";

    public newsFeedTypeEnum = NewsFeedTypeEnum;

    /*@ngInject*/
    constructor() {
      super();
    }

  }

  angular.module(Module)
    .controller(AnalysisController.id, AnalysisController);
}
