
module App{
    'use strict';

    interface IFilters {
        description: string;
        firstValue: string;
        secondValue?: string;
        range?: boolean;
    }

    export class ReportViewController{
        static id = "reportViewController";
        public reportId: number;
        public reportDescription: string;
        public mainGridOptions: any;
        public isLoaded: boolean = false;
        public filters: any;
        public filter_form_options: any;
        public gridOptions: any;
        public reportGrid: kendo.ui.Grid;
        public languageCode: string;
        public filtersDescriptionForExcel: string;
        public dataSource :any;

        private initGridColumns() {
            $.ajax({
                type: "POST",
                url: 'request/get_report_columns',
                data: {reportId: this.reportId},
                async: false,
                dataType: 'json',
                success: (options) => {
                    this.gridOptions = options;
                    this.initFilters(options.filters, options.rows);
                    this.initGridOptions(options, this.generateFilterString());
                    this.dataSource = {id: this.reportId, filter: this.generateFilterString()}
                }
            }).fail((err) => {
                this.isLoaded = true;
                Notifier.show("ERROR_OCCURRED", MessageType.error);
            });
        }

        private initGridOptions(options: any, filters: string) {
            this.mainGridOptions = {
                toolbar: [
                    {
                        template: '<h4>{{vm.reportDescription}}</h4><span flex></span>' +
                        '<md-button class="md-icon-button custom-cell-button" ng-click="vm.exportToExcel($event)">' +
                        '<md-tooltip><span data-translate="EXPORT_TO_EXCEL"></span></md-tooltip>' +
                        '<md-icon md-svg-icon="custom:excel" style="color: white;" aria-label="..."></md-icon>' +
                        '</md-button>'
                    }
                ],
                excel:{
                    fileName:"report_" + this.reportDescription + ".xlsx",
                    allPages:true
                },
                excelExport: (e) => {
                    this.changeCreatedExcel(e.workbook)
                },
                dataSource: {
                    transport: {
                        read: (e) => {
                            this.isLoaded = false;
                            this.$http.post("/request/get_report_data/", this.dataSource)
                                .success((res)=>{
                                    if(res){
                                        e.success(res);
                                        this.isLoaded = true;
                                    }
                                })
                                .error((err) => {
                                    e.error(err);
                                    this.isLoaded = true;
                                    Notifier.show("ERROR_OCCURRED", MessageType.error)
                                })
                        }
                    },
                    schema: options.schema,
                    pageSize: 30,
                    aggregate: options.aggregate || null
                },
                sortable: true,
                pageable: true,
                selectable: "row",
                columnMenu: true,
                resizable: true,
                editable: false,
                groupable: true,
                columns: options.columns,
            };
        }

        private initFilters(fields: any, rows: any) {
            this.filters = {};

            for (var key in fields) {
                if (fields[key].hasOwnProperty("default_value")) {
                    this.filters[key] = this.getDefaultValues(fields[key].default_value);
                }
            }

            this.filter_form_options = {
                fields: fields,
                rows: rows ,
                label_small_size: true
            }
            this.filtersDescriptionForExcel = this.getFiltersDescription();
        }

        public exportToExcel($event) {
            this.reportGrid.saveAsExcel()
        }

        public clearFilters() {
            this.initFilters(this.gridOptions.filters,this.gridOptions.rows);
            this.getReportData()

        }

        private generateFilterString(): string {
            var filterStr: string = "";
            if (this.filters != {}) {
                for (var i in this.filters) {
                    if(this.filters[i] != "" && this.filters[i] != null) {
                        if (i.indexOf("date") != -1) {
                            var d:string = this.filters[i].getDate().toString();
                            var m:string = ((this.filters[i].getMonth()) + 1).toString();
                            var y:string = this.filters[i].getFullYear().toString();
                            filterStr += i + "#" + y + '-' + (m.length == 1 ? "0" + m : m) + '-' + (d.length == 1 ? "0" + d : d);
                        } else if (Array.isArray(this.filters[i])) {
                            filterStr += i + "#";
                            for (var j = 0; j < this.filters[i].length; ++j) {
                                filterStr += this.filters[i][j].toString() + (j != this.filters[i].length - 1 ? "," : "");
                            }
                        } else if (typeof this.filters[i] === 'object' && this.filters[i].id) {
                            filterStr += i + "#" + this.filters[i].id.toString();
                        } else {
                            filterStr += i + "#" + this.filters[i].toString();
                        }
                        filterStr += "^"
                    }
                }
                filterStr = filterStr.substr(0, filterStr.length - 1)
            }
            return filterStr;
        }

        public getReportData() {
            this.filtersDescriptionForExcel = this.getFiltersDescription();
            this.dataSource = {id: this.reportId, filter: this.generateFilterString()};
            this.reportGrid.dataSource.read();
            this.reportGrid.refresh();
        }

        private getDefaultValues(description: any): any {
            switch(description) {
                case "currentDate":
                    return new Date();
                case "firstDayOfCurrentMonth":
                    var date = new Date();
                    return new Date(date.getFullYear(), date.getMonth(), 1);
                default:
                    return description;
            }
        }

        private changeCreatedExcel(workbook) {
            var sheet = workbook.sheets[0];
            var alphabet: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

            sheet.freezePane.rowSplit = 0;
            for (var i: number = 0; i < 3; ++i) {
                sheet.rows.unshift({});
            }
            sheet.mergedCells = ["A1:" + alphabet.substr(sheet.columns.length - 1, 1) + "1", "A2:" + alphabet.substr(sheet.columns.length - 1, 1) + "2"];

            sheet.rows[0].cells = [{value: this.reportDescription, hAlign: "center"}];
            sheet.rows[1].cells = [{value: this.filtersDescriptionForExcel, hAlign: "center"}];
            sheet.columns[0].autoWidth = false;
            sheet.columns[0].width = 200;

            for (var i: number = 3; i < sheet.rows.length; ++i) {
                for (var j: number = 0; j < sheet.rows[i].cells.length; ++j) {
                    var v = sheet.rows[i].cells[j].value;
                    if (v && v.toString().indexOf("' | translate}}") != -1) {
                        var substringForReplase: string = v.substring(v.indexOf("{{'"), v.indexOf("' | translate}}") + 15)
                        var phraseForTranslate = v.substring(v.indexOf("{{'") + 3, v.indexOf("' | translate}}"));
                        sheet.rows[i].cells[j].value = v.replace(substringForReplase, this.$translate.instant(phraseForTranslate));
                    }
                }
            }
        }

        private getFiltersDescription(): string {
            var filtersDescriptions: string = "";
            var translatedLabel: string;
            var value: string = "";
            var arr: Array<IFilters> = [];
            var objIndex: number;

            for (var key in this.filters) {
                value = "";
                translatedLabel = this.$translate.instant(this.filter_form_options.fields[key].label) || this.$translate.instant(this.filter_form_options.fields[this.filter_form_options.fields[key].associate].label);

                if (this.filters[key] != null) {
                    if (Array.isArray(this.filters[key])) {
                        var s = this.filter_form_options.fields[key].source;
                        value = this.getDescriptionsFromArrayByIds(this.filters[key], s);
                    } else if (key.indexOf("date") != -1) {
                        var d = this.filters[key];
                        value = d.getDate().toString() + "/" + (d.getMonth() + 1).toString() + "/" + d.getFullYear().toString();
                    } else if (typeof this.filters[key] === 'object') {
                        value = this.filters[key].description;
                    } else {
                        value = this.filters[key].toString();
                    }
                }

                objIndex = this.getObjectIndexInArrayByProperty(translatedLabel, "description", arr)
                if(objIndex == -1) {
                    var obj: IFilters;
                    if (this.filter_form_options.fields[key].label != "") {
                        obj = {description: translatedLabel, firstValue: value};
                    } else {
                        obj = {description: translatedLabel, firstValue: "", secondValue: value};
                    }
                    if (this.filter_form_options.fields[key].range) {
                        obj.range = true;
                    }
                    arr.push(obj);
                } else {
                    if (this.filter_form_options.fields[key].label != "") {
                        arr[objIndex].firstValue = value;
                    } else {
                        arr[objIndex].secondValue = value;
                    }
                }
            }

            for (var i: number = 0; i < arr.length; ++i) {
                if (arr[i].firstValue && arr[i].secondValue) {
                    filtersDescriptions += arr[i].description + ": " + (arr[i].range ? "(>=)" : "") + arr[i].firstValue + " - " + (arr[i].range ? "(<=)" : "") + arr[i].secondValue + ", ";
                } else if (arr[i].firstValue) {
                    filtersDescriptions += arr[i].description + ": " + (arr[i].range ? "(>=)" : "") + arr[i].firstValue + ", ";
                } else if (arr[i].secondValue) {
                    filtersDescriptions += arr[i].description + ": " + (arr[i].range ? "(<=)" : "") +  arr[i].secondValue + ", ";
                }
            }

            return filtersDescriptions.substr(0, filtersDescriptions.length - 2);
        }

        private getDescriptionsFromArrayByIds(ids: Array<number>, source: Array<Object>): string {
            var descriptions: string = "";

            for (var i: number = 0; i < ids.length; ++i) {
                var result = $.grep(source, function(e){ return e["id"] == ids[i]; });
                descriptions += result[0]["description"] + (i == ids.length - 1 ? "" : ", ");
            }

            return descriptions;
        }

        private getObjectIndexInArrayByProperty(value: any, property: string, arr: Array<Object>): number {
            var index: number = -1;

            for (var i = 0; i < arr.length; ++i) {
                if (arr[i][property] == value) {
                    return i;
                }
            }

            return index;
        }

        /*@ngInject*/
        constructor(
            public $state: ng.ui.IStateService,
            private $http: ng.IHttpService,
            public $mdDialog: any,
            private authenticationService: Authentication,
            private $translate: ng.translate.ITranslateService,
            private $mdSidenav: any){

            this.reportId = $state.params["reportId"];
            this.reportDescription = $state.params["reportDescription"];
            this.filtersDescriptionForExcel = "";
            this.initGridColumns();
        }
    }

    angular.module(Module)
        .controller(ReportViewController.id, ReportViewController);
}
