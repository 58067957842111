module App {

    export class AnalysisChartController extends BaseController {

        public chart: IChart;
        public newsFeedType: NewsFeedTypeEnum;
        public chartOption: any;
        public generatedId: string;

        constructor(
            private eventManagerService: EventManagerService,
            private globals: any){
            super();
        }

        public removeFromView = () => {
            this.eventManagerService.publish(this.globals.events.removeChartFromList, this.chart.id, this.chart.uid);
        };

        $onInit(){
            this.generatedId = Date.now().toString();
            switch(this.chart.id) {
                case 1:
                    this.chartOption = {
                        type: "pie"
                    };
                    break;
                case 2:
                    this.chartOption = {
                        type: "column"
                    };
                    break;
                case 3:
                    this.chartOption = {
                        type: "column"
                    };
                    break;
                case 4:
                    this.chartOption = {
                        type: "column"
                    };
                    break;
                case 5:
                    this.chartOption = {
                        type: "column",
                        stacking: 'percent'
                    };
                    break;
                case 6:
                    this.chartOption = {
                        type: "column"
                    };
                    break;
                case 7:
                    this.chartOption = {
                        type: "column",
                        stacking: 'percent'
                    };
                    break;
                case 8:
                    this.chartOption = {
                        type: "column"
                    };
                    break;
                case 9:
                    this.chartOption = {
                        type: "pie"
                    };
                    break;
                case 10:
                    this.chartOption = {
                        type: 'line',
                    };
                    break;
                case 11:
                    this.chartOption = {
                        type: "column"
                    };
                    break;
                case 12:
                    this.chartOption = {
                        type: "pie"
                    };
                    break;
                case 13:
                    this.chartOption = {
                        type: "column"
                    };
                    break;
                case 14:
                    this.chartOption = {
                        type: "column",
                        stacking: 'percent'
                    };
                    break;
                case 15:
                    this.chartOption = {
                        type: "column",
                        stacking: 'percent'
                    };
                    break;
            }
        }
    }

    export class AnalysisChartComponent implements ng.IComponentOptions {
        public static id = "analysisChart";
        templateUrl = "/components/analysis-components/analysis-chart/analysis-chart.component.html";
        controllerAs = "vm";
        bindings: any;
        constructor() {
            this.bindings = {
                chart: "<",
                newsFeedType: "<?"
            };
        }
        controller = [
            'eventManagerService',
            'globals',
            AnalysisChartController
        ];
    }

    angular.module(Module)
        .component(AnalysisChartComponent.id, new AnalysisChartComponent());

}
