module App {
  export class CustomerViewController extends BaseController {
    public customerId: number;
    public changeToList () {
      this.viewMode = ViewMode.List;
    }
    constructor(private $mdDialog: any){
      super();
    }

    $onInit(){
      this.LoadCustomer(this.customerId);
    }

    public LoadCustomer(id: number) {
      this.customer = new Customer(id);
      this.customer.loadFromDB((err) => {
        if (err) {
          console.error("Cannot get customer ", err);
        }
      });
    }

    public customer: Customer = new Customer();

    public header = {
      title: "CUSTOMER",
      icon: "action:account_box",
      buttons: {
        btn_edit: {
          tooltip: "EDIT_CUSTOMER",
          permission_name: "edit_customer",
          icon: "editor:mode_edit",
          fnc: ($event) => {
            var options: any;
            
            this.$mdDialog.show({
              template: '<customer-edit options="options" customer-id="customerId"></customer-edit>',
              parent: angular.element(document.body),
              targetEvent: $event || false,
              //clickOutsideToClose: options.clickOutsideToClose || true,
              locals: {options: options},
              controller: ['$scope', 'options', ($scope, options) => {
                $scope.options = options;
                $scope.customerId = this.customerId} ]
            }).then(() => {
              this.customer.loadFromDB();
            });

          },
        }
      }
    };

    public customer_form_options = {
      fields: {
        name: {
          type: "text",
          readonly: "true",
          label: "NAME",
          get: (item) => item
        },
        registration_date: {
          type: "text",
          readonly: "true",
          label: "REGISTRATION_DATE",
          get: (item) => item,
          format: 'date'
        }
      },
      rows: [
        [{field: "name", col_class: "col-md-6"}, {field: "registration_date", col_class: "col-md-6"}]
      ]
    };

    public customer_tabs = [
      { title: 'PROJECTS', template: $("#customerProjectsTabTemplate").html() },
      { title: 'USERS', template: $("#customerUsersTabTemplate").html() },
      { title: 'MASS_MEDIA_SECTIONS', template: $("#massMediaSectionsTabTemplate").html() }
    ];

  }

  export class CustomerViewComponent implements ng.IComponentOptions {
    public static id = "customerView";
    templateUrl = "/components/customer-components/customer-view-component/customer-view.component.html";
    controllerAs = "vm";
    bindings: any;
    constructor(){
      this.bindings = {
          viewMode: "=",
          customerId: "<"
      };
    }
    controller = [
      '$mdDialog',
      CustomerViewController
    ];
  }

  angular.module(Module)
    .component(CustomerViewComponent.id, new CustomerViewComponent());

}
