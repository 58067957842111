module App {
    export class AddArticleComponentController  {

        public article: Article;
        public MassMediaTypeEnum = MassMediaTypeEnum;
        constructor(private $mdDialog: any,
                    public newsFeedService: INewsFeedService,
                    public eventManagerService: EventManagerService,
                    public globals: any) {
        }
        $onInit(){
            this.article = new Article();
        }

        public save = (): void => {
            this.newsFeedService.saveArticle(this.article).then((response: boolean) => {
                if (response) {
                    Notifier.show("Պահպանված է։", MessageType.success);
                    this.closeWindow();
                }
            });
        };

        public cancel = (): void => {
            this.closeWindow();
        };

        private closeWindow = (): void =>{
            this.$mdDialog.hide();
        }
    }

    export class AddArticleComponent implements ng.IComponentOptions {
        public static id = "addArticle";
        templateUrl = "/components/news-feed-components/add-article/add-article.component.html";
        controllerAs = "vm";
        bindings: any;
        constructor() {
            this.bindings = {
            };
        }
        controller = ['$mdDialog', 'newsFeedService', 'eventManagerService', 'globals',
            AddArticleComponentController
        ];
    }
    angular.module(Module)
        .component(AddArticleComponent.id, new AddArticleComponent());
}
