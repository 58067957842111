module App {
    export class AddFbLinkComponentController  {

        public fbData: any;
        public options: any;
        constructor(private $mdDialog: any,
                    public newsFeedService: INewsFeedService,
                    public eventManagerService: EventManagerService,
                    public globals: any) {
                        this.getFbLinkData()
        }

        $onInit(){  }

        public getFbLinkData = () => {
            this.newsFeedService.getFbLinkData(this.options.articleId).then((res) => {
                this.fbData = res[0]
            })
        };

        public save = (): void => {
            this.newsFeedService.saveFbLink(this.fbData, this.options.articleId).then((response: boolean) => {
                if (response) {
                    Notifier.show("Պահպանված է։", MessageType.success);
                    this.closeWindow();
                }
            });
        };

        public cancel = (): void => {
            this.closeWindow();
        };

        private closeWindow = (): void =>{
            this.$mdDialog.hide();
        }
    }

    export class AddFbLinkComponent implements ng.IComponentOptions {
        public static id = "addFbLink";
        templateUrl = "/components/news-feed-components/add-fb-link/add-fb-link.component.html";
        controllerAs = "vm";
        bindings: any;
        constructor() {
            this.bindings = {
                options: "="
            };
        }
        controller = ['$mdDialog', 'newsFeedService', 'eventManagerService', 'globals',
            AddFbLinkComponentController
        ];
    }
    angular.module(Module)
        .component(AddFbLinkComponent.id, new AddFbLinkComponent());
}
