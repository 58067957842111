module App {

    export interface IProjectService {
        saveProject(project: Project): ng.IPromise<boolean>;
		saveSubject(subject: any): ng.IPromise<boolean>;
		saveKeyword(keyword: any): ng.IPromise<boolean>;
        setProjectToParentObject(projectId: number, parentObjectId: number,className: string): ng.IPromise<boolean>;
        removeProjectFromParentObject(rowId: number, className: string): ng.IPromise<boolean>;
    }

    export class ProjectService implements IProjectService {
        static id = "projectService";

        /*@ngInject*/
        constructor(
            private $q: ng.IQService,
            private $filter: ng.IFilterService){
        }

        public saveProject = (project: Project): any => {
            return HttpRequester.post("/request/save_project", {project: project});
        };

		public saveSubject = (subject: any): any => {
			return HttpRequester.post("/request/save_subject", {subject: subject});
		};

		public saveKeyword = (keyword: any): any => {
			return HttpRequester.post("/request/save_keyword", {keyword: keyword});
		};

        public setProjectToParentObject = (projectId: number, parentObjectId: number, className: string): ng.IPromise<boolean> => {
            return HttpRequester.post<boolean>("/request/set_project_to_parent_object", {projectId: projectId, parentObjectId: parentObjectId, className: className})
                .then((response: boolean) => {
                    return true;
                }).catch( (err) => {
                    return false;
                });
        };

        public removeProjectFromParentObject = (rowId: number, className: string): ng.IPromise<boolean> => {
            return HttpRequester.post<boolean>("/request/remove_project_from_parent_object", {rowId: rowId, className: className})
                .then((response: boolean) => {
                    return true;
                }).catch( (err) => {
                    return false;
                });
        };

    }

    angular.module(App.Module)
        .service(ProjectService.id, ProjectService);
}
