///<reference path='db-loader.ts' />
module App {

    export interface ISocialMedia {
        id: number;
        name: string;
        type: Dictionary;
        main_url: string;
        status: Dictionary;
        image: string
    }

    export class SocialMedia extends DBLoader implements ISocialMedia {
        public id: number;
        public name: string;
        public type: Dictionary;
        public main_url: string;
        public status: Dictionary;
        public image: string;
        constructor(id?: number) {
            super();
            this.id = id || null;
            this.name = null;
            this.type = null;
            this.main_url = null;
            this.status = null;
            this.image = null
        }
    }
}