module App {
    export class MassMediaListController extends BaseController {
        public changeToView() {
            this.viewMode = ViewMode.View;
        }
        /*@ngInject*/
        constructor(private $timeout: ng.ITimeoutService,
                    private $mdDialog: any,
                    private authenticationService: Authentication,
                    public eventManagerService: EventManagerService,
                    public globals: any,
                    public $http: ng.IHttpService) {
            super();
        }

        $onInit() {
            this.eventManagerService.subscribe(this.globals.events.refreshMassMediaList, this.refreshList);
        }

        public refreshList = (): void => {
            this.mainGrid.dataSource.read();
            this.mainGrid.refresh();
        };

        public mainGrid: kendo.ui.Grid;
        public massMediaId: number;

        public mainGridOptions = {
            dataSource: {
                transport: {
                    read: {
                        url: "/request/get_mass_media_list",
                        dataType: "json",
                        type: "POST"
                    }
                },
                pageSize: 20,
            },
            hierarchical: true,
            sortable: true,
            pageable: true,
            resizable: true,

            filterable: true,
            columnMenu: true,
            reorderable: true,
            editable: false,
            change: (e: kendo.ui.GridChangeEvent) => {
                var grid = e.sender;
                var data = grid.dataItem(grid.select());
                this.massMediaId = data["id"];
            },
            columns: [
                {
                    field: "name",
                    title: "NAME"
                },
                {
                    field: "type_description",
                    title: "TYPE",
                },
                {
                    field: "rating_description",
                    title: "RATING",
                },
                {
                    field: "country_description",
                    title: "COUNTRY",
                },
                {
                    template: `<md-button ng-if="vm.controlVisibility('edit_mass_media')" class="md-icon-button custom-cell-button" ng-click="vm.showDialog($event, dataItem)" aria-label="Edit"><md-tooltip><span data-translate="EDIT"></span></md-tooltip><md-icon md-svg-icon="editor:mode_edit" aria-label="..."></md-icon></md-button>`,
                    width: 70
                }
            ]
        };

        public getProjectGridOptions = (massMediaId: number) => {
            return {
                toolbar: [
                    {
                        name: "EDIT",
                        template:  `<span flex></span><md-button ng-if="vm.controlVisibility('add_project_to_mass_media')" class="md-icon-button custom-cell-button"  ng-click="vm.editProjectList($event, dataItem.id)" aria-label="Edit"><md-tooltip><span data-translate="EDIT"></span></md-tooltip><md-icon md-svg-icon="editor:mode_edit" aria-label="..." class="edit-mass"></md-icon></md-button>`
                    }
                ],
                dataSource: {
                    transport: {
                        read: {
                            url: "/request/get_projects_by_mass_media",
                            dataType: "json",
                            type: "POST",
                            data: {id: massMediaId }
                        }
                    },
                    pageSize: 10,
                },
                sortable: true,
                pageable: true,
                resizable: true,
                filterable: true,
                columnMenu: true,
                columns: [
                    { field: "name", title: "{{'NAME' | translate}}"},
                    { field: "status", title: "{{'STATUS' | translate}}"}]
            }
        };

        public editProjectList($event, massMediaId:any ){
            this.$http.post('request/get_project_full_list_for_mass_media/', {id: massMediaId}).success((res) => {
                for(var p in res) {
                    res[p].assigned = res[p].assigned == 0 ? false : true;
                }
                this.$mdDialog.show({
                    template: $('#ProjectListPopupTemplate').html(),
                    parent: angular.element(document.body),
                    locals: {options: {projectList: res}},
                    controller: ['$scope', 'options', ($scope, options) => {
                        $scope.isChaked = false;
                        $scope.projectList = options.projectList;
                        $scope.cancel = () => {
                            this.$mdDialog.hide();
                        };
                        $scope.checkAll = (isChecked: boolean) => {
                            for( var p in $scope.projectList) {
                                $scope.projectList[p].assigned = isChecked;
                            }
                        };
                        $scope.saveProjectList = () => {
                            var arr: number[] = [];
                            for (var i in $scope.projectList) {
                                $scope.projectList[i].assigned && arr.push($scope.projectList[i].id);
                            }
                            this.$http.post('request/save_mass_media_project', {mass_media_id: massMediaId, project_ids: arr.join()}).success(() => {
                                Notifier.show("CHANGES_SAVED", MessageType.success);
                                var grid: kendo.ui.Grid = $("#projectGrid" + massMediaId).data("kendoGrid");
                                grid.dataSource.read();
                                grid.refresh();
                                this.$mdDialog.cancel();
                            }).error((err) => {
                                Notifier.show(err.message, MessageType.error);
                            })
                        }
                    }]
                })

            }).error((err) => {
                Notifier.show(err.message, MessageType.error)
            })
        };

        public getSectionGridOptions = (massMediaId: number) => {
            return {
                toolbar: [
                    {
                        name: "EDIT",
                        template:  `<span flex></span>
                                    <md-button ng-if="vm.controlVisibility('add_section_to_mass_media')" class="md-icon-button custom-cell-button"  ng-click="vm.editSectionList($event, dataItem.id)" aria-label="Edit">
                                        <md-tooltip><span data-translate="EDIT"></span></md-tooltip>
                                        <md-icon md-svg-icon="editor:mode_edit" aria-label="..." class="edit-mass"></md-icon>
                                    </md-button>`
                    }
                ],
                dataSource: {
                    transport: {
                        read: {
                            url: "/request/get_article_sections_by_mass_media_id",
                            dataType: "json",
                            type: "POST",
                            data: {id: massMediaId }
                        }
                    },
                    pageSize: 10,
                },
                sortable: true,
                pageable: true,
                resizable: true,
                filterable: true,
                columnMenu: true,
                columns: [
                    { field: "description", title: "{{'NAME' | translate}}"},
                    { field: "code", title: "{{'CODE' | translate}}"}
                ]
            }
        };

        public editSectionList($event, massMediaId:any ){
            this.$http.post('request/get_article_section_full_list_for_mass_media/', {id: massMediaId}).success((res) => {
                for(var s in res) {
                    res[s].assigned = res[s].assigned == 0 ? false : true;
                }
                this.$mdDialog.show({
                    template: $('#SectionListPopupTemplate').html(),
                    parent: angular.element(document.body),
                    locals: {options: {sectionList: res}},
                    controller: ['$scope', 'options', ($scope, options) => {
                        $scope.isChaked = false;
                        $scope.sectionList = options.sectionList;
                        $scope.cancel = () => {
                            this.$mdDialog.hide();
                        };
                        $scope.checkAll = (isChecked: boolean) => {
                            for( var s in $scope.sectionList) {
                                $scope.sectionList[s].assigned = isChecked;
                            }
                        };
                        $scope.saveSectionList = () => {
                            var arr: number[] = [];
                            for (var i in $scope.sectionList) {
                                $scope.sectionList[i].assigned && arr.push($scope.sectionList[i].id);
                            }
                            this.$http.post('request/save_article_sections_to_mass_media', {mass_media_id: massMediaId, section_ids: arr.join()}).success(() => {
                                Notifier.show("CHANGES_SAVED", MessageType.success);
                                var grid: kendo.ui.Grid = $("#sectionGrid" + massMediaId).data("kendoGrid");
                                grid.dataSource.read();
                                grid.refresh();
                                this.$mdDialog.cancel();
                            }).error((err) => {
                                Notifier.show(err.message, MessageType.error);
                            })
                        }
                     }]
                })

            }).error((err) => {
                Notifier.show(err.message, MessageType.error)
            })
        }

        public showDialog($event, massMedia: any) {
            var options: any;
            if(massMedia){
                options = angular.copy(massMedia);
            }
            this.$mdDialog.show({
                template: '<mass-media-edit options="options" mass-media-id="options.id"></mass-media-edit>',
                parent: angular.element(document.body),
                targetEvent: $event || false,
                locals: {options: options},
                controller: ['$scope', 'options', ($scope, options) => $scope.options = options]
            })
        }



        public controlVisibility = (controlName: string): boolean => {
            return this.authenticationService.user.controlPermissions.indexOf(controlName) != -1;
        }
    }

    export class MassMediaListComponent implements ng.IComponentOptions {
        public static id = "massMediaList";
        templateUrl = "/components/mass-media-components/mass-media-list-component/mass-media-list.component.html";
        controllerAs = "vm";
        bindings: any;

        constructor() {
            this.bindings = {
                viewMode: "=",
                massMediaId: "=?"
            };
        }

        controller = ['$timeout', '$mdDialog', 'authenticationService', 'eventManagerService', 'globals', '$http',
            MassMediaListController
        ];
    }

    angular.module(Module)
        .component(MassMediaListComponent.id, new MassMediaListComponent());
}
