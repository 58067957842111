module App {
  'use strict';

  export class CustomerEditController extends BaseController {
    static id = "customersController";
    public options: any;
    public customerId: number;

      constructor(
          private $timeout: ng.ITimeoutService,
          public $mdDialog: any,
          public eventManagerService: EventManagerService,
          public globals: any){
          super();
      }

    $onInit() {
      var c: Customer;
      if (this.customerId) {
        c = new Customer(this.customerId).loadFromDB()
      } else {
          c = new Customer();
      }

      this.options = {
          title: this.customerId ? "EDIT_CUSTOMER" : "NEW_CUSTOMER",
          size: "small",
          buttons: {
              acceptBtn: {
                  text: "SAVE",
                  btnClass: "btn-primary",
                  disableIBfInvalid: true,
                  fnc: () => {
                      this.options.item.saveToDB((err, res?) => {
                          if (!err) {
                              this.$mdDialog.hide();
                              if (!this.customerId) {
                                this.eventManagerService.publish(this.globals.events.refreshCustomerList);
                              }
                          }
                      });
                  }
              },
              cancelBtn: {
                  text: "CANCEL",
                  btnClass: "btn-default",
                  fnc:() => {
                      this.$mdDialog.cancel()
                  }
              }
          },
          item: c,
          item_options: {
              fields: {
                  name: {type: "text", label: "NAME", get: (item) => item, required: () => true},
              },
              rows: [
                  [{field: "name", col_class: "col-md-12"}]
              ]
          }
      }
      }
  }

  export class CustomerEditComponent implements ng.IComponentOptions {
    static id = "customerEdit";
    templateUrl = "/components/customer-components/customer-edit-component/customer-edit.component.html";
    controllerAs = "vm";
    bindings: any;
    constructor(){
        this.bindings = {
            customerId: "=?",
            options: "="
        };
    }
    controller = ['$timeout', '$mdDialog', 'eventManagerService', 'globals',
        CustomerEditController
    ];
  }

  angular.module(Module)
    .component(CustomerEditComponent.id,  new CustomerEditComponent());

}
