module App {
	'use strict';

	export class AppController {

		static id = "appController";
        private language: string;

        public setLanguage(l: string) {
            this.language = l;
            this.$translate.use(this.languages[l]);
            window.localStorage['language'] = l;
        }
        public getLanguage() {
            return this.language;
        }
        public getLanguageCode() {
            return this.languages[this.language];
        }

        private languages = {
            "English": "en",
            "Русский": "ru",
            "Հայերեն": "am"
        }

        public getLanguages() {
            return this.languages;
        }

      public menu: string = "vertical";

      public layout: string = "wide";
      public fixed_header: boolean = true;
      public fixed_sidebar: boolean = true;
      public skin: string = "12";

      public page_transition_opts = [
        {
          name: "Fade up",
          "class": "animate-fade-up"
        }, {
          name: "Scale up",
          "class": "ainmate-scale-up"
        }, {
          name: "Slide in from right",
          "class": "ainmate-slide-in-right"
        }, {
          name: "Flip Y",
          "class": "animate-flip-y"
        }
      ]
      public page_transition = this.page_transition_opts[0];

      public header_class: string;
      public sidebar_color_class: string;

      public setLayoutTheme(element: string): string {
        let theme: string = "";
        let header_color_class: string;
        let sidebar_color_class: string;
        let logo_color_class: string;

        if (element == "header") {
          if (['11', '12', '13', '14', '15', '16', '21'].indexOf(this.skin) >= 0) {
            header_color_class = "bg-white"
          } else if (['22', '32'].indexOf(this.skin) >= 0) {
            header_color_class = "bg-primary"
          } else if (['23', '33'].indexOf(this.skin) >= 0) {
            header_color_class = "bg-success"
          } else if (['24', '34'].indexOf(this.skin) >= 0) {
            header_color_class = "bg-info"
          } else if (['25', '35'].indexOf(this.skin) >= 0) {
            header_color_class = "bg-warning"
          } else if (['26', '36'].indexOf(this.skin) >= 0) {
            header_color_class = "bg-danger"
          } else if (this.skin == '31') {
            header_color_class = "bg-dark"
          }

          theme = (header_color_class || "bg-white") + " " + (this.fixed_header ? "header-fixed" : "")
        } else if (element == "sidebar") {
          if (['31','32','33','34','35','36'].indexOf(this.skin) >= 0) {
            sidebar_color_class = "bg-white"
          } else { //else if (['31','32','33','34','35','36'].indexOf(this.skin) < 0) {
            sidebar_color_class = "bg-dark"
          }

          theme = (sidebar_color_class || "bg-white") + " " + (this.fixed_sidebar ? "nav-fixed" : "") + " " + (this.menu === 'horizontal' ? "nav-horizontal" : "nav-vertical")
        } else if (element == "logo") {
          if (this.skin === '21') {
            logo_color_class = "bg-white"
          } else if (['12','22','32'].indexOf(this.skin) >= 0) {
            logo_color_class = "bg-primary"
          } else if (['13','23','33'].indexOf(this.skin) >= 0) {
            logo_color_class = "bg-success"
          } else if (['14','24','34'].indexOf(this.skin) >= 0) {
            logo_color_class = "bg-info"
          } else if (['15','25','35'].indexOf(this.skin) >= 0) {
            logo_color_class = "bg-warning"
          } else if (['16','26','36'].indexOf(this.skin) >= 0) {
            logo_color_class = "bg-danger"
          } else if (['11','31'].indexOf(this.skin) >= 0) {
            logo_color_class = "bg-dark"
          }

          theme = (logo_color_class || "bg-white")
        }

        return theme;
      }

        public  controlVisibility(controlName: string): boolean {
            return this.authenticationService.user.controlPermissions.indexOf(controlName) != -1;
        }

      /*@ngInject*/
      constructor(private $translate: ng.translate.ITranslateProvider,
                  private authenticationService: Authentication) {
          this.setLanguage(window.localStorage['language'] || "Հայերեն");
      }
  }

  angular.module(Module)
    .controller(AppController.id, AppController);
}
