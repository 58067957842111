module App {
    export interface IEventManagerService {
        subscribe(eventName: string, listener: (...args: any[]) => any): void;
        publish(eventName: string, ...args: any[]): void;
    }

    type Listener = (...args: any[]) => any;

    export class EventManagerService implements IEventManagerService {
        static id = "eventManagerService";
        private events: Map<Listener> = new Map<Listener>();
        /*@ngInject*/
        constructor(
            private $rootScope: ng.IRootScopeService) {
        }

        public subscribe = (eventName: string, listener: Listener): void => {
            this.events.set(eventName, listener);
        }

        public publish = (eventName: string, ...args: any[]): void => {
            if (this.events.hasKey(eventName)) {
                this.events.get(eventName)(...args);
            }
        }
    }
    angular.module(App.Module)
        .service(EventManagerService.id, EventManagerService);
}
