///<reference path='db-loader.ts' />
module App {
    export interface IArticle {
        id: number;
        feed_element_id: number;
        title: string;
        content: string;
        publish_date: Date;
        publish_time: Date;
        publish_datetime: Date;
        url: string;
        fb_link: string;
        mass_media: MassMedia;
        language: LanguageEnum;
        keywords: string;
        subjects: string;
    }

    export class Article extends DBLoader implements  IArticle {

        public id: number;
        public feed_element_id: number;
        public title: string;
        public content: string;
        public publish_date: Date;
        public publish_time: Date;
        public publish_datetime: Date;
        public url: string;
        public fb_link: string;
        public mass_media: MassMedia;
        public language: LanguageEnum;
        public keywords: string;
        public subjects: string;

        constructor (vArticle?: any) {
            super();
            if(vArticle) {
                this.id = vArticle.id;
                this.feed_element_id = vArticle.feed_element_id;
                this.title = vArticle.title;
                this.content = vArticle.content;
                this.publish_date = vArticle.publish_date;
                this.publish_time = vArticle.publish_time;
                this.publish_datetime = vArticle.publish_datetime;
                this.url = vArticle.url;
                this.fb_link = vArticle.fb_link;
                this.mass_media = new MassMedia();
                this.mass_media.id = vArticle.mass_media_id;
                this.mass_media.name = vArticle.mass_media_name;
                //this.mass_media.type.id = vArticle.mass_media_type_id;
                //this.mass_media.type.description = vArticle.mass_media_type_description;
                this.mass_media.type = {id: vArticle.mass_media_type_id, description: vArticle.mass_media_type_description};
                //this.mass_media.rating.id = vArticle.mass_media_rating_id;
                //this.mass_media.rating.description = vArticle.mass_media_rating_description;
                this.mass_media.rating = {id: vArticle.mass_media_rating_id, description:  vArticle.mass_media_rating_description};
                this.language = vArticle.language;
                this.keywords = vArticle.keywords;
                this.subjects =  vArticle.subjects;
            }

        }
    }
}
