var App;
(function (App) {
    App.Module = "media-app";
})(App || (App = {}));

var App;
(function (App) {
    var app = angular.module(App.Module, [
        "ngAnimate",
        "ngCookies",
        "ngSanitize",
        "ui.router",
        "ui.bootstrap",
        "ngMaterial",
        "pascalprecht.translate",
        "kendo.directives",
        "ngMessages",
        'angular-growl'
    ]);
    app.run(function ($rootScope, $location, config, loggerFactory, authenticationService, notifierService, httpRequesterService, $state) {
        var logger = loggerFactory("app.startup");
        logger.info("run", "app launched!");
        $rootScope.$on(config.events.uiRouter.$stateChangeError, function (event, toState, toParams, fromState, fromParams, error) {
            logger.error("$stateChangeError", "an error occured while loading state.", error);
            $state.go("error");
        });
        $rootScope.$on('$locationChangeStart', function (event, next, current) {
            // redirect to login page if not logged in and trying to access a restricted page
            var restrictedPage = $.inArray($location.path(), ['/home', '/login']) === -1;
            authenticationService.checkLogin().then(function () {
                if (restrictedPage) {
                    if (!authenticationService.isAuthorized()) {
                        $state.go("home");
                    }
                }
                else if (authenticationService.isAuthorized()) {
                    $state.go("layout.newsFeed");
                }
            });
        });
    });
})(App || (App = {}));

var App;
(function (App) {
    angular.module(App.Module)
        .config([
        "$stateProvider",
        "$urlRouterProvider",
        function ($stateProvider, $urlRouterProvider) {
            'use strict';
            var defaultControllerAs = "vm";
            // $stateProvider.state("login", {
            //     url: "/login",
            //     templateUrl: "areas/login/login.html",
            //     controller: LoginController.id,
            //     controllerAs: defaultControllerAs,
            //     resolve: {
            //       user: ['authenticationService', function (authenticationService: Authentication) {
            //         return authenticationService.checkLogin();
            //       }]
            //     }
            // });
            $stateProvider.state("home", {
                url: "/home",
                templateUrl: "areas/home/home.html",
                controller: App.HomeController.id,
                controllerAs: defaultControllerAs,
                resolve: {
                    user: ['authenticationService', function (authenticationService) {
                            return authenticationService.checkLogin();
                        }]
                }
            });
            $stateProvider.state("layout", {
                url: "/",
                abstract: true,
                templateUrl: "areas/layout/layout.html",
                controller: App.LayoutController.id,
                controllerAs: defaultControllerAs,
                resolve: {
                    user: ['authenticationService', function (authenticationService) {
                            return authenticationService.checkLogin();
                        }]
                }
            });
            $stateProvider.state("layout.dashboard", {
                url: "dashboard",
                templateUrl: "areas/dashboard/dashboard.html",
                //controller: DashboardController.id,
                controllerAs: defaultControllerAs
            });
            $stateProvider.state("layout.customer", {
                url: "customer",
                templateUrl: "areas/customer/customer.html",
                controller: App.CustomerController.id,
                controllerAs: defaultControllerAs
            });
            $stateProvider.state("layout.mass_media", {
                url: "mass_media",
                templateUrl: "areas/mass-media/mass-media.html",
                controller: App.MassMediaController.id,
                controllerAs: defaultControllerAs
            });
            $stateProvider.state("layout.newsFeed", {
                url: "news-feed",
                templateUrl: "areas/news-feed/news-feed.html",
                controller: App.NewsFeedController.id,
                controllerAs: defaultControllerAs
            });
            $stateProvider.state("layout.social_media", {
                url: "social_media",
                templateUrl: "areas/social-media/social-media.html",
                controller: App.SocialMediaController.id,
                controllerAs: defaultControllerAs
            });
            $stateProvider.state("layout.social_media_account", {
                url: "social_media_account",
                templateUrl: "areas/social-media-accounts/social-media-accounts.html",
                controller: App.SocialMediaAccountsController.id,
                controllerAs: defaultControllerAs
            });
            $stateProvider.state("layout.analysis", {
                url: "analysis",
                templateUrl: "areas/analysis/analysis.html",
                controller: App.AnalysisController.id,
                controllerAs: defaultControllerAs
            });
            $stateProvider.state("layout.social_media_analysis", {
                url: "social_media_analysis",
                templateUrl: "areas/social-media-analysis/social-media-analysis.html",
                controller: App.SocialMediaAnalysisController.id,
                controllerAs: defaultControllerAs
            });
            $stateProvider.state("layout.press_event", {
                url: "press_event",
                templateUrl: "areas/press-event/press-event.html",
                controller: App.PressEventController.id,
                controllerAs: defaultControllerAs
            });
            $stateProvider.state("layout.project", {
                url: "project",
                templateUrl: "areas/project/project.html",
                controller: App.ProjectController.id,
                controllerAs: defaultControllerAs
            });
            $stateProvider.state("layout.reports", {
                url: "reports",
                templateUrl: "areas/reports/reports.html",
                controller: App.ReportsController.id,
                controllerAs: defaultControllerAs
            });
            $stateProvider.state("layout.reportView", {
                url: "report-view/:reportId",
                params: {
                    "reportDescription": null
                },
                templateUrl: "areas/report-view/report-view.html",
                controller: App.ReportViewController.id,
                controllerAs: defaultControllerAs
            });
            $stateProvider.state("layout.catalog", {
                url: "catalog",
                templateUrl: "areas/catalog/catalog.html",
                controller: App.CatalogController.id,
                controllerAs: defaultControllerAs
            });
            $stateProvider.state("layout.users", {
                url: "users",
                templateUrl: "areas/users/users.html",
                controller: App.UsersController.id,
                controllerAs: defaultControllerAs
            });
            $stateProvider.state("layout.permissionGroups", {
                url: "permission-groups",
                templateUrl: "areas/permission-groups/permission-groups.html",
                controller: App.PermissionGroupsController.id,
                controllerAs: defaultControllerAs
            });
            $stateProvider.state("error", {
                url: "/error",
                templateUrl: "areas/error/error.html",
                controller: App.ErrorController.id,
                controllerAs: defaultControllerAs
            });
            $urlRouterProvider.otherwise("/home");
        }]);
})(App || (App = {}));

var App;
(function (App) {
    var app = angular.module(App.Module);
    var Config = (function () {
        function Config() {
            this.name = "Monitoring";
            this.version = "1.0.0-alpha";
            this.events = {
                myEvent: "example",
                uiRouter: {
                    $stateChangeError: "$stateChangeError",
                    $stateChangeStart: "$stateChangeStart",
                    $stateChangeSuccess: "$stateChangeSuccess",
                    $stateNotFound: "$stateNotFound"
                }
            };
            this.remoteUri = new RemoteUriConfig();
        }
        Config.id = "config";
        return Config;
    }());
    App.Config = Config;
    var RemoteUriConfig = (function () {
        function RemoteUriConfig() {
            this.base = "/api/";
            this.account = {
                login: this.base + "/login"
            };
        }
        return RemoteUriConfig;
    }());
    App.RemoteUriConfig = RemoteUriConfig;
    app.constant(Config.id, new Config());
    app.config(function ($logProvider, $locationProvider) {
        // turn debugging off/on (no info or warn)
        if ($logProvider.debugEnabled) {
            $logProvider.debugEnabled(true);
        }
    });
    app.config(function ($translateProvider) {
        $translateProvider.useStaticFilesLoader({
            prefix: 'translations/locale-',
            suffix: '.json'
        });
        $translateProvider.preferredLanguage('en');
    });
    app.config(function ($mdIconProvider) {
        $mdIconProvider
            .iconSet('navigation', 'svg/navigation-icons.svg', 24)
            .iconSet('notification', 'svg/notification-icons.svg', 24)
            .iconSet('communication', 'svg/communication-icons.svg', 24)
            .iconSet('social', 'svg/social-icons.svg', 24)
            .iconSet('action', 'svg/action-icons.svg', 24)
            .iconSet('editor', 'svg/editor-icons.svg', 24)
            .iconSet('content', 'svg/content-icons.svg', 24)
            .iconSet('hardware', 'svg/hardware-icons.svg', 24)
            .iconSet('map', 'svg/maps-icons.svg', 24)
            .iconSet('av', 'svg/av-icons.svg', 24)
            .iconSet('file', 'svg/file-icons.svg', 24)
            .iconSet('image', 'svg/image-icons.svg', 24)
            .iconSet('custom', 'svg/custom-icons.svg', 24);
    });
    app.config(['growlProvider', function (growlProvider) {
            growlProvider.onlyUniqueMessages(false);
            growlProvider.globalTimeToLive({ success: 3000, error: 10000, warning: 3000, info: 3000 });
            growlProvider.globalDisableCountDown(true);
        }]);
    app.constant('globals', {
        events: {
            getNewsFeedList: 'getNewsFeedList',
            selectedElementsEdit: 'selectedElementsEdit',
            elementComparison: 'elementComparison',
            pageChangeEvent: 'pageChangeEvent',
            generateAnalysisChart: 'generateAnalysisChart',
            removeChartFromList: 'removeChartFromList',
            refreshCustomerList: 'refreshCustomerList',
            refreshMassMediaList: 'refreshMassMediaList',
            refreshPressEventList: 'refreshPressEventList',
            setPaginationCurrentPage: 'setPaginationCurrentPage',
            unSelectAllElements: 'unSelectAllElements',
            allCheckUnSelect: 'allCheckUnSelect',
            feedUpdateInterval: 'feedUpdateInterval',
            getPostList: 'getPostList',
            selectedPostsEdit: 'selectedPostsEdit',
            postComparison: 'postComparison',
            allPostCheckUnSelect: 'allPostCheckUnSelect',
            unSelectAllSocialMediaPosts: 'unSelectAllSocialMediaPosts'
        },
        defaultValues: {
            countPerPage: 10
        },
        dataSources: {
            countPerPageDataSource: [{ id: 5, value: 5 }, { id: 10, value: 10 }, { id: 20, value: 20 }, { id: 50, value: 50 }]
        }
    });
})(App || (App = {}));

var App;
(function (App) {
    'use strict';
    var AppController = (function () {
        /*@ngInject*/
        function AppController($translate, authenticationService) {
            this.$translate = $translate;
            this.authenticationService = authenticationService;
            this.languages = {
                "English": "en",
                "Русский": "ru",
                "Հայերեն": "am"
            };
            this.menu = "vertical";
            this.layout = "wide";
            this.fixed_header = true;
            this.fixed_sidebar = true;
            this.skin = "12";
            this.page_transition_opts = [
                {
                    name: "Fade up",
                    "class": "animate-fade-up"
                }, {
                    name: "Scale up",
                    "class": "ainmate-scale-up"
                }, {
                    name: "Slide in from right",
                    "class": "ainmate-slide-in-right"
                }, {
                    name: "Flip Y",
                    "class": "animate-flip-y"
                }
            ];
            this.page_transition = this.page_transition_opts[0];
            this.setLanguage(window.localStorage['language'] || "Հայերեն");
        }
        AppController.prototype.setLanguage = function (l) {
            this.language = l;
            this.$translate.use(this.languages[l]);
            window.localStorage['language'] = l;
        };
        AppController.prototype.getLanguage = function () {
            return this.language;
        };
        AppController.prototype.getLanguageCode = function () {
            return this.languages[this.language];
        };
        AppController.prototype.getLanguages = function () {
            return this.languages;
        };
        AppController.prototype.setLayoutTheme = function (element) {
            var theme = "";
            var header_color_class;
            var sidebar_color_class;
            var logo_color_class;
            if (element == "header") {
                if (['11', '12', '13', '14', '15', '16', '21'].indexOf(this.skin) >= 0) {
                    header_color_class = "bg-white";
                }
                else if (['22', '32'].indexOf(this.skin) >= 0) {
                    header_color_class = "bg-primary";
                }
                else if (['23', '33'].indexOf(this.skin) >= 0) {
                    header_color_class = "bg-success";
                }
                else if (['24', '34'].indexOf(this.skin) >= 0) {
                    header_color_class = "bg-info";
                }
                else if (['25', '35'].indexOf(this.skin) >= 0) {
                    header_color_class = "bg-warning";
                }
                else if (['26', '36'].indexOf(this.skin) >= 0) {
                    header_color_class = "bg-danger";
                }
                else if (this.skin == '31') {
                    header_color_class = "bg-dark";
                }
                theme = (header_color_class || "bg-white") + " " + (this.fixed_header ? "header-fixed" : "");
            }
            else if (element == "sidebar") {
                if (['31', '32', '33', '34', '35', '36'].indexOf(this.skin) >= 0) {
                    sidebar_color_class = "bg-white";
                }
                else {
                    sidebar_color_class = "bg-dark";
                }
                theme = (sidebar_color_class || "bg-white") + " " + (this.fixed_sidebar ? "nav-fixed" : "") + " " + (this.menu === 'horizontal' ? "nav-horizontal" : "nav-vertical");
            }
            else if (element == "logo") {
                if (this.skin === '21') {
                    logo_color_class = "bg-white";
                }
                else if (['12', '22', '32'].indexOf(this.skin) >= 0) {
                    logo_color_class = "bg-primary";
                }
                else if (['13', '23', '33'].indexOf(this.skin) >= 0) {
                    logo_color_class = "bg-success";
                }
                else if (['14', '24', '34'].indexOf(this.skin) >= 0) {
                    logo_color_class = "bg-info";
                }
                else if (['15', '25', '35'].indexOf(this.skin) >= 0) {
                    logo_color_class = "bg-warning";
                }
                else if (['16', '26', '36'].indexOf(this.skin) >= 0) {
                    logo_color_class = "bg-danger";
                }
                else if (['11', '31'].indexOf(this.skin) >= 0) {
                    logo_color_class = "bg-dark";
                }
                theme = (logo_color_class || "bg-white");
            }
            return theme;
        };
        AppController.prototype.controlVisibility = function (controlName) {
            return this.authenticationService.user.controlPermissions.indexOf(controlName) != -1;
        };
        AppController.id = "appController";
        return AppController;
    }());
    App.AppController = AppController;
    angular.module(App.Module)
        .controller(AppController.id, AppController);
})(App || (App = {}));

var App;
(function (App) {
    var ComboBoxDirective = (function () {
        function ComboBoxDirective() {
            this.restrict = 'AE';
            this.require = "ngModel";
            this.scope = {
                kOptions: '=?',
                options: '='
            };
        }
        ComboBoxDirective.prototype.link = function ($scope, element, attributes, ctrls) {
            $scope["kOptions"] = $scope["kOptions"] || [];
            angular.extend($scope["kOptions"], {
                animation: {
                    close: {
                        effects: "fadeOut zoom:out",
                        duration: 300
                    },
                    open: {
                        effects: "fadeIn zoom:in",
                        duration: 300
                    }
                },
                dataValueField: $scope["options"].id || "id",
                dataTextField: $scope["options"].description || "description",
                cascadeFrom: $scope["options"].cascade || "",
                autoBind: $scope["options"].autoBind || true,
                filter: "contains"
            });
            if ($scope["options"].source_url) {
                angular.extend($scope["kOptions"], {
                    dataSource: {
                        transport: {
                            read: {
                                dataType: "json",
                                url: $scope["options"].source_url,
                                type: "POST",
                                data: $scope["options"].data || {}
                            }
                        },
                        requestEnd: function (e) {
                            if ($scope["options"].firstable && e.response && e.response.length) {
                                $scope["options"].kendoComboValue = e.response[0].id;
                            }
                        }
                    }
                });
            }
            else {
                angular.extend($scope["kOptions"], {
                    dataSource: {
                        transport: {
                            read: function (options) {
                                setTimeout(function () {
                                    return options.success($scope["options"].source);
                                });
                            }
                        }
                    }
                });
            }
        };
        ComboBoxDirective.id = "kendoComboBox";
        return ComboBoxDirective;
    }());
    App.ComboBoxDirective = ComboBoxDirective;
    angular.module(App.Module)
        .directive(ComboBoxDirective.id, function () { return new ComboBoxDirective(); });
})(App || (App = {}));

var App;
(function (App) {
    var DatePickerDirective = (function () {
        function DatePickerDirective() {
            this.restrict = 'AE';
            this.scope = {
                kOptions: '=',
                kNgModel: '='
            };
        }
        DatePickerDirective.prototype.link = function ($scope, element, attributes) {
            $scope["kOptions"] = $scope["kOptions"] || [];
            $scope.$watch("kNgModel", function (value) {
                $scope.$parent["stringValue"] = "";
                if (value) {
                    var d = (new Date(value));
                    $scope.$parent["stringValue"] = d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear() + " " +
                        d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
                }
            });
            angular.extend($scope["kOptions"], {
                parseFormats: ["dd-MM-yyyy HH:mm:ss"],
                animation: {
                    close: {
                        effects: "fadeOut zoom:out",
                        duration: 300
                    },
                    open: {
                        effects: "fadeIn zoom:in",
                        duration: 300
                    }
                }
            });
        };
        DatePickerDirective.id = "kendoDatePicker";
        return DatePickerDirective;
    }());
    App.DatePickerDirective = DatePickerDirective;
    angular.module(App.Module)
        .directive(DatePickerDirective.id, function () { return new DatePickerDirective(); });
})(App || (App = {}));

var App;
(function (App) {
    var KendoGridDirective = (function () {
        /*@ngInject*/
        function KendoGridDirective() {
            var _this = this;
            this.restrict = 'A';
            //public replace = true;
            this.scope = {
                options: '=',
                kendoGrid: '='
            };
            this.link = function ($scope, element, attributes) {
                $("#grid").delegate("tbody>tr", "dblclick", function () { $scope["options"].dblClick && $scope["options"].dblClick(); });
                if ($scope["options"] && (angular.isUndefined($scope["options"].translateColumnTitles) || $scope["options"].translateColumnTitles)) {
                    for (var i in $scope["options"].columns) {
                        if ($scope["options"].columns[i].title) {
                            $scope["options"].columns[i].title = "{{'" + $scope["options"].columns[i].title + "' | translate}}"; //this.$translate.instant($scope["options"].columns[i].title); //
                        }
                    }
                }
                if ($scope["options"] && angular.isDefined($scope["options"].savedSettings)) {
                    var s = {
                        settings: "Настройки",
                        saveSettings: "Сохранить настройки",
                        restoreToDefaults: "Восстановить по умолчанию",
                        settingsSaved: "Настройки сохранены.",
                        settingsRestored: "Настройки восстановлены по умолчанию. Обновите страницу."
                    };
                    var options = window.localStorage[$scope["options"].savedSettings];
                    if (options) {
                        var saved_columns_options = (JSON.parse(options)).columns;
                        for (var i in $scope["options"].columns) {
                            var index = _this.getObjectIndexInArrayByProperty(saved_columns_options, 'field', $scope["options"].columns[i].field);
                            $scope["options"].columns[i].hidden = (index != -1 ? saved_columns_options[index].hidden || false : false);
                            if (saved_columns_options[i].width) {
                                $scope["options"].columns[i].width = saved_columns_options[i].width;
                            }
                        }
                    }
                    $scope["options"].columnMenuInit = function (e) {
                        var menu = e.container.find(".k-menu").data("kendoMenu");
                        menu.append([
                            {
                                text: s["settings"],
                                items: [{ text: s["saveSettings"] }, { text: s["restoreToDefaults"] }]
                            }
                        ]);
                        menu.bind("select", function (e) {
                            var menuText = $(e.item).text();
                            if (menuText == s["saveSettings"]) {
                                e.preventDefault();
                                window.localStorage[$scope["options"].savedSettings] = kendo.stringify($scope["kendoGrid"].getOptions());
                                App.Notifier.show(s["settingsSaved"], App.MessageType.success);
                            }
                            else if (menuText == s["restoreToDefaults"]) {
                                e.preventDefault();
                                window.localStorage.removeItem($scope["options"].savedSettings);
                                App.Notifier.show(s["settingsRestored"], App.MessageType.success);
                            }
                        });
                    };
                }
            };
        }
        KendoGridDirective.prototype.getObjectIndexInArrayByProperty = function (array, propertyName, value) {
            for (var i = 0; i < array.length; ++i) {
                if (array[i][propertyName] === value) {
                    return i;
                }
            }
            return -1;
        };
        KendoGridDirective.id = "kendoGrid";
        return KendoGridDirective;
    }());
    App.KendoGridDirective = KendoGridDirective;
    angular.module(App.Module)
        .directive(KendoGridDirective.id, function () { return new KendoGridDirective(); });
})(App || (App = {}));

var App;
(function (App) {
    var MultiSelectDirective = (function () {
        function MultiSelectDirective() {
            this.restrict = 'AE';
            this.scope = {
                mOptions: '='
            };
        }
        MultiSelectDirective.prototype.link = function ($scope, element, attributes) {
            $.ajax({
                type: "POST",
                url: $scope["mOptions"].source_url,
                success: function (res) {
                    if ($scope["mOptions"].description) {
                        for (var i in res) {
                            //if(res[i].hasOwnProperty($scope["mOptions"].description)) {
                            res[i].description = res[i][$scope["mOptions"].description];
                            delete res[i][$scope["mOptions"].description];
                        }
                    }
                    $scope["mOptions"].source = res;
                }
            }).fail(function (err) {
                App.Notifier.show(err.message, App.MessageType.error);
            });
        };
        MultiSelectDirective.id = "multiSelect";
        return MultiSelectDirective;
    }());
    App.MultiSelectDirective = MultiSelectDirective;
    angular.module(App.Module)
        .directive(MultiSelectDirective.id, function () { return new MultiSelectDirective(); });
})(App || (App = {}));

var App;
(function (App) {
    var SprootChartDirective = (function () {
        /*ngInject*/
        function SprootChartDirective($filter, $state) {
            var _this = this;
            this.$filter = $filter;
            this.$state = $state;
            this.template = "<div style=\"height: 400px; max-width: 100%; margin: 0 auto;\"></div>";
            this.restrict = 'E';
            this.replace = true;
            this.scope = {
                options: "<",
                data: '<',
                filter: '<?',
                additionalProperty: '<?',
                newsFeedType: '<?'
            };
            this.returnSubtitle = function (start_date, end_date) {
                var str = _this.$filter('translate')('PERIOD_BEGIN') + '  ' + _this.$filter('date')(start_date, 'dd/MM/yyyy') + "   ";
                str += _this.$filter('translate')('PERIOD_END') + '  ' + _this.$filter('date')(end_date ? end_date : new Date(), 'dd/MM/yyyy');
                return str;
            };
            this.link = function ($scope, element, attributes, ctrl) {
                var chartOptions;
                var commonOptions = {
                    chart: {
                        type: $scope.options.type
                    },
                    title: {
                        text: $scope.filter.project.name
                    },
                    subtitle: {
                        text: _this.returnSubtitle($scope.filter.start_date, $scope.filter.end_date)
                    },
                    credits: {
                        enabled: false
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true,
                                format: '<b>{point.name}</b>: {point.y} ({point.percentage:.1f} %)'
                            }
                        },
                        column: {
                            dataLabels: {
                                enabled: true,
                                formatter: function () {
                                    if (this.y != 0) {
                                        return this.y;
                                    }
                                    else {
                                        return null;
                                    }
                                }
                            },
                            stacking: $scope.options.stacking
                        },
                        line: {
                            dataLabels: {
                                enabled: true,
                                formatter: function () {
                                    if (this.y != 0) {
                                        return this.y;
                                    }
                                    else {
                                        return null;
                                    }
                                }
                            },
                            stacking: $scope.options.stacking
                        },
                        series: {
                            cursor: 'pointer',
                            point: {
                                events: {
                                    click: function (event) {
                                        localStorage.removeItem('filter');
                                        var subject_id;
                                        var copyFilter = angular.copy($scope.filter);
                                        copyFilter.subject = [];
                                        if ($scope.options.type === "pie") {
                                            subject_id = event.point.subject_id;
                                        }
                                        else if ($scope.options.type === "line") {
                                            var period = $scope.data.response.find(function (item) { return item.x == event.point.category; });
                                            copyFilter.start_date = period.period_start;
                                            copyFilter.end_date = period.period_end;
                                            subject_id = event.point.series.userOptions.id;
                                        }
                                        else {
                                            copyFilter[$scope.additionalProperty] = event.point.series.userOptions.id;
                                            subject_id = $scope.data.response.find(function (item) { return item.x == event.point.category; }).subject_id;
                                        }
                                        copyFilter.subject.push(subject_id);
                                        localStorage.setItem('filter', JSON.stringify(copyFilter));
                                        if ($scope.options.type === "column" && copyFilter.undefined) {
                                            return;
                                        }
                                        var path = $scope.newsFeedType === App.NewsFeedTypeEnum.MassMedia ? 'layout.newsFeed' : 'layout.social_media';
                                        window.open(_this.$state.href(path));
                                    }
                                }
                            }
                        }
                    },
                };
                switch ($scope.options.type) {
                    case "pie":
                        chartOptions = {
                            tooltip: {
                                pointFormat: "<b>{point.y} ({point.percentage:.1f}%)<\/b>"
                            },
                            series: [{
                                    data: $scope.data,
                                    showInLegend: true
                                }]
                        };
                        break;
                    case "column":
                        chartOptions = {
                            xAxis: { categories: $scope.data.xAxis },
                            yAxis: {
                                title: {
                                    text: null
                                }
                            },
                            series: $scope.data.series
                        };
                        break;
                    case "line":
                        chartOptions = {
                            xAxis: {
                                categories: $scope.data.xAxis,
                                min: 0.5,
                                max: $scope.data.xAxis.length - 1.5,
                                tickmarkPlacement: 'on',
                            },
                            yAxis: {
                                title: {
                                    text: null
                                }
                            },
                            colors: $scope.data.colors,
                            series: $scope.data.series,
                        };
                        break;
                }
                angular.extend(chartOptions, commonOptions);
                Highcharts.chart(element[0], chartOptions);
            };
        }
        SprootChartDirective.id = "sprootChart";
        return SprootChartDirective;
    }());
    App.SprootChartDirective = SprootChartDirective;
    angular.module(App.Module)
        .directive(SprootChartDirective.id, ['$filter', '$state', function ($filter, $state) { return new SprootChartDirective($filter, $state); }]);
})(App || (App = {}));

var App;
(function (App) {
    var SprootComboBoxDirective = (function () {
        function SprootComboBoxDirective() {
            this.template = "\n            <md-input-container class=\"md-block\">\n              <label>{{label | translate}}</label>\n              <input kendo-combo-box\n                     k-ng-model=\"value\"\n                     ng-model=\"ngModelValue\"\n                     k-options=\"options\"\n                     k-on-change=\"onChange()\" \n                     name=\"{{fieldName}}\"\n                     ng-required=\"form && required\"\n                     aria-label=\"...\" />\n              <div ng-messages=\"form[fieldName].$error\">\n                <div ng-message=\"required\">{{'THIS_IS_REQUIRED' | translate}}</div>\n              </div>\n            </md-input-container>";
            this.restrict = 'AE';
            this.require = ['^?form'];
            this.replace = true;
            this.scope = {
                value: '=ngModel',
                url: "<?",
                source: "<?",
                label: "<?",
                onChange: "&?",
                valueField: "<?",
                textField: "<?",
                valuePrimitive: "<?",
                filterable: "<?",
                name: "<?",
                required: "<?",
                filter: "<?"
            };
            this.link = function ($scope, element, attributes, ctrl) {
                $scope.watcher = $scope.$watch("value", function (newValue, oldValue) {
                    if (newValue != oldValue) {
                        if (!newValue) {
                            $scope.ngModelValue = null;
                        }
                        else if (typeof newValue === "string" || typeof newValue === "number") {
                            $scope.ngModelValue = newValue;
                        }
                        else if (typeof newValue === "object") {
                            var idField = $scope.valueField || "id";
                            $scope.ngModelValue = newValue[idField];
                        }
                    }
                });
                $scope.$watch("filter", function (newValue, oldValue) {
                    if (newValue != oldValue) {
                        $scope.dropDownList.setDataSource(new kendo.data.DataSource($scope.generateDataSource()));
                        $scope.value = null;
                    }
                });
                $scope["form"] = ctrl && ctrl[0];
                $scope["fieldName"] = $scope["name"] || $scope["label"];
                $scope["options"] = {
                    animation: {
                        close: {
                            effects: "fadeOut zoom:out",
                            duration: 300
                        },
                        open: {
                            effects: "fadeIn zoom:in",
                            duration: 300
                        }
                    },
                    valuePrimitive: angular.isUndefined($scope["valuePrimitive"]) ? true : $scope["primitive"],
                    filter: $scope["filterable"] ? "contains" : false,
                    dataValueField: $scope["valueField"] || "id",
                    dataTextField: $scope["textField"] || "description",
                    dataSource: $scope["source"] || {
                        transport: {
                            read: {
                                dataType: "json",
                                type: "POST",
                                url: $scope["url"],
                                data: angular.isUndefined($scope.filter) ? {} : { id: $scope.filter }
                            }
                        }
                    }
                };
                $scope.generateDataSource = function () {
                    return $scope["source"] || {
                        transport: {
                            read: {
                                dataType: "json",
                                type: "POST",
                                url: $scope["url"],
                                data: angular.isUndefined($scope.filter) ? {} : { id: $scope.filter }
                            }
                        }
                    };
                };
            };
        }
        SprootComboBoxDirective.id = "sprootComboBox";
        return SprootComboBoxDirective;
    }());
    App.SprootComboBoxDirective = SprootComboBoxDirective;
    angular.module(App.Module)
        .directive(SprootComboBoxDirective.id, function () { return new SprootComboBoxDirective(); });
})(App || (App = {}));

var App;
(function (App) {
    var SprootDatePickerDirective = (function () {
        function SprootDatePickerDirective() {
            var _this = this;
            this.template = "\n            <md-input-container class=\"md-block\">\n                <label>{{label | translate}}</label>\n                <input kendo-date-picker = \"kendoDatepicker\"\n                        k-ng-model=\"value\" \n                        ng-model=\"stringValue\" \n                        k-format = \"format || 'dd/MM/yy'\" \n                        k-options=\"options\" \n                        name=\"{{fieldName}}\"\n                        ng-required=\"form && required\"\n                        aria-label=\"...\"/>\n                <div ng-messages=\"form[fieldName].$error\">\n                    <div ng-message=\"required\">{{'THIS_IS_REQUIRED' | translate}}</div>\n                </div>\n            </md-input-container>";
            this.restrict = 'AE';
            this.require = ['^?form'];
            this.replace = false;
            this.scope = {
                value: '=ngModel',
                label: "<?",
                min: "<?",
                format: "<?",
                name: "<?",
                required: "<?"
            };
            this.link = function ($scope, element, attributes, ctrl) {
                $scope["form"] = ctrl && ctrl[0];
                $scope["fieldName"] = $scope["name"] || $scope["label"];
                $scope["options"] = _this.options;
                if (attributes.$attr['min']) {
                    $scope.$watch('min', function (newValue) {
                        if (newValue) {
                            $scope['kendoDatepicker'].min(newValue);
                        }
                        else {
                            $scope['kendoDatepicker'].min(new Date(2000, 0, 1));
                        }
                    });
                }
            };
            this.options = {
                animation: {
                    close: {
                        effects: "fadeOut zoom:out",
                        duration: 300
                    },
                    open: {
                        effects: "fadeIn zoom:in",
                        duration: 300
                    }
                }
            };
        }
        SprootDatePickerDirective.id = "sprootDatePicker";
        return SprootDatePickerDirective;
    }());
    App.SprootDatePickerDirective = SprootDatePickerDirective;
    angular.module(App.Module)
        .directive(SprootDatePickerDirective.id, function () { return new SprootDatePickerDirective(); });
})(App || (App = {}));

var App;
(function (App) {
    var SprootDateTimePickerDirective = (function () {
        function SprootDateTimePickerDirective() {
            var _this = this;
            this.template = "\n            <md-input-container class=\"md-block\">\n                <label>{{label | translate}}</label>\n                <input kendo-date-time-picker \n                        k-ng-model=\"value\" \n                        ng-model=\"stringValue\" \n                        k-format = \"format || 'dd/MM/yy HH:mm'\" \n                        k-options=\"options\" \n                        name=\"{{fieldName}}\"\n                        ng-required=\"form && required\"\n                        aria-label=\"...\"/>\n                <div ng-messages=\"form[fieldName].$error\">\n                    <div ng-message=\"required\">{{'THIS_IS_REQUIRED' | translate}}</div>\n                </div>\n            </md-input-container>";
            this.restrict = 'AE';
            this.require = ['^?form'];
            this.replace = false;
            this.scope = {
                value: '=ngModel',
                label: "<?",
                format: "<?",
                name: "<?",
                required: "<?"
            };
            this.link = function ($scope, element, attributes, ctrl) {
                $scope["form"] = ctrl && ctrl[0];
                $scope["fieldName"] = $scope["name"] || $scope["label"];
                $scope["options"] = _this.options;
                $scope.watcher = $scope.$watch("value", function (newValue, oldValue) {
                    if (newValue !== undefined) {
                        if (newValue) {
                            $scope["stringValue"] = new Date($scope["value"]);
                        }
                    }
                    else {
                        element.find("input").val("");
                    }
                });
            };
            this.options = {
                animation: {
                    close: {
                        effects: "fadeOut zoom:out",
                        duration: 300
                    },
                    open: {
                        effects: "fadeIn zoom:in",
                        duration: 300
                    }
                }
            };
        }
        SprootDateTimePickerDirective.id = "sprootDateTimePicker";
        return SprootDateTimePickerDirective;
    }());
    App.SprootDateTimePickerDirective = SprootDateTimePickerDirective;
    angular.module(App.Module)
        .directive(SprootDateTimePickerDirective.id, function () { return new SprootDateTimePickerDirective(); });
})(App || (App = {}));

var App;
(function (App) {
    var SprootDropDownDirective = (function () {
        function SprootDropDownDirective(eventManagerService) {
            var _this = this;
            this.eventManagerService = eventManagerService;
            this.template = "\n            <md-input-container class=\"md-block\" >\n              <label>{{label | translate}}</label>\n              <input kendo-drop-down-list=\"dropDownList\"\n                     k-ng-model=\"value\"\n                     ng-model=\"ngModelValue\"\n                     k-options=\"options\"\n                     k-on-change=\"onChange()\" \n                     name=\"{{fieldName}}\"\n                     ng-required=\"form && required\"\n                     aria-label=\"...\" \n                     ng-disabled=\"disabled\"/>\n              <div ng-messages=\"form[fieldName].$error\">\n                <div ng-message=\"required\">{{'THIS_IS_REQUIRED' | translate}}</div>\n              </div>\n            </md-input-container>";
            this.restrict = 'AE';
            this.require = ['^?form'];
            this.replace = true;
            this.scope = {
                value: '=ngModel',
                url: "<?",
                source: "<?",
                label: "<?",
                onChange: "&?",
                valueField: "<?",
                textField: "<?",
                valuePrimitive: "<?",
                optionLabel: "<?",
                filterable: "<?",
                name: "<?",
                required: "<?",
                filter: "<?",
                postData: "=?",
                disabled: "<?"
            };
            this.link = function ($scope, element, attributes, ctrl) {
                $scope.watcher = $scope.$watch("value", function (newValue, oldValue) {
                    if (newValue !== oldValue) {
                        if (angular.isUndefined(newValue) || newValue == "" || newValue == null) {
                            $scope.ngModelValue = null;
                        }
                        else if (typeof newValue === "string" || typeof newValue === "number") {
                            $scope.ngModelValue = newValue;
                        }
                        else if (typeof newValue === "object") {
                            var idField = $scope.valueField || "id";
                            $scope.ngModelValue = newValue[idField];
                        }
                    }
                });
                $scope.$watch("filter", function (newValue, oldValue) {
                    if (newValue != oldValue) {
                        $scope.refreshSource();
                    }
                });
                $scope["form"] = ctrl && ctrl[0];
                $scope["fieldName"] = $scope["name"] || $scope["label"];
                $scope.getPostData = function () {
                    var postData = $scope.postData || {};
                    if (!angular.isUndefined($scope.filter)) {
                        angular.extend(postData, { id: $scope.filter });
                    }
                    return postData;
                };
                $scope["options"] = {
                    animation: {
                        close: {
                            effects: "fadeOut zoom:out",
                            duration: 300
                        },
                        open: {
                            effects: "fadeIn zoom:in",
                            duration: 300
                        }
                    },
                    valuePrimitive: angular.isUndefined($scope["valuePrimitive"]) ? true : $scope["primitive"],
                    optionLabel: angular.isUndefined($scope["optionLabel"]) ? " " : false,
                    filter: angular.isUndefined($scope["filterable"]) ? "contains" : $scope["filterable"],
                    disabled: angular.isUndefined($scope["disabled"]) ? false : $scope["disabled"],
                    dataValueField: $scope["valueField"] || "id",
                    dataTextField: $scope["textField"] || "description",
                    dataSource: $scope["source"] || {
                        transport: {
                            read: {
                                dataType: "json",
                                type: "POST",
                                url: $scope["url"],
                                data: $scope.getPostData()
                            }
                        }
                    }
                };
                $scope.generateDataSource = function () {
                    return $scope["source"] || {
                        transport: {
                            read: {
                                dataType: "json",
                                type: "POST",
                                url: $scope["url"],
                                data: $scope.getPostData()
                            }
                        },
                        requestEnd: function (e) {
                            var response = e.response;
                            var allIds = _.map(response, function (obj) {
                                return obj.id;
                            });
                            if (allIds.indexOf(+$scope.value) == -1) {
                                $scope.value = null;
                            }
                        }
                    };
                };
                $scope.refreshSource = function () {
                    $scope.dropDownList.setDataSource(new kendo.data.DataSource($scope.generateDataSource()));
                };
                _this.eventManagerService.subscribe($scope["label"], $scope.refreshSource);
            };
        }
        SprootDropDownDirective.id = "sprootDropDown";
        return SprootDropDownDirective;
    }());
    App.SprootDropDownDirective = SprootDropDownDirective;
    angular.module(App.Module)
        .directive(SprootDropDownDirective.id, ['eventManagerService', function (eventManagerService) { return new SprootDropDownDirective(eventManagerService); }]);
})(App || (App = {}));

var App;
(function (App) {
    var SprootMultiSelectDirective = (function () {
        function SprootMultiSelectDirective() {
            this.template = "\n            <md-input-container class=\"md-block\" ng-class=\"{'md-input-invalid': !ngModelValue && form[fieldName].$touched}\">\n              <label ng-class=\"{'md-required': required}\">{{label | translate}}</label>\n              <select kendo-multi-select=\"multiSelect\"\n                      k-ng-model=\"value\"\n                      ng-model=\"ngModelValue\"\n                      k-options=\"options\"\n                      k-on-change=\"onChange()\" \n                      name=\"{{fieldName}}\"\n                      ng-required=\"form && required\"\n                      aria-label=\"...\"></select>\n              <div class=\"md-errors-spacer\"></div>\n              <div ng-messages=\"form[fieldName].$error\">\n                <div ng-message=\"required\" ng-class=\"{'multi-select-require': !ngModelValue && form[fieldName].$touched}\">{{'THIS_IS_REQUIRED' | translate}}</div>\n              </div>\n            </md-input-container>";
            this.restrict = 'AE';
            this.require = ['^?form'];
            this.replace = true;
            this.scope = {
                value: '=ngModel',
                url: "<?",
                source: "<?",
                label: "<?",
                onChange: "&?",
                valueField: "<?",
                textField: "<?",
                valuePrimitive: "<?",
                optionLabel: "<?",
                filterable: "<?",
                name: "<?",
                required: "<?",
                clearButton: "<?",
                filter: "<?"
            };
            this.link = function ($scope, element, attributes, ctrl) {
                $scope.watcher = $scope.$watch("value", function (newValue, oldValue) {
                    if (newValue !== undefined) {
                        if (!newValue || newValue.length == 0) {
                            element.removeClass('md-input-has-value');
                            $scope.ngModelValue = null;
                        }
                        else {
                            $scope.ngModelValue = newValue;
                            element.addClass('md-input-has-value');
                        }
                        element.find("input").val("");
                    }
                });
                setTimeout(function () {
                    var input = element.find("input");
                    input.on('focusin', function () {
                        if (!$scope.ngModelValue && !element.hasClass('md-input-has-value')) {
                            element.addClass('md-input-has-value');
                        }
                    });
                    input.on('focusout', function () {
                        if (!$scope.ngModelValue && element.hasClass('md-input-has-value')) {
                            element.removeClass('md-input-has-value');
                        }
                    });
                }, 1000);
                $scope.$watch("filter", function (newValue, oldValue) {
                    if (newValue != oldValue) {
                        var data = angular.isUndefined($scope.filter) ? {} : { id: $scope.filter };
                        $scope.multiSelect.dataSource.read(data).then(function () {
                            var view = $scope.multiSelect.dataSource.view();
                            $scope.value = _.filter($scope.value, function (item) {
                                var allIds = _.map(view, function (obj) {
                                    return obj.id;
                                });
                                return allIds.indexOf(+item) != -1;
                            });
                        });
                    }
                });
                $scope["form"] = ctrl && ctrl[0];
                $scope["fieldName"] = $scope["name"] || $scope["label"];
                $scope["options"] = {
                    animation: {
                        close: {
                            effects: "fadeOut zoom:out",
                            duration: 300
                        },
                        open: {
                            effects: "fadeIn zoom:in",
                            duration: 300
                        }
                    },
                    autoClose: false,
                    valuePrimitive: angular.isUndefined($scope["valuePrimitive"]) ? true : $scope["primitive"],
                    clearButton: angular.isUndefined($scope["valuePrimitive"]) ? false : $scope["primitive"],
                    optionLabel: $scope["optionLabel"] ? " " : false,
                    filter: angular.isUndefined($scope[$scope["filterable"]]) ? "contains" : $scope["filterable"],
                    dataValueField: $scope["valueField"] || "id",
                    dataTextField: $scope["textField"] || "description",
                    dataSource: $scope["source"] || {
                        transport: {
                            read: {
                                dataType: "json",
                                type: "POST",
                                url: $scope["url"],
                                data: angular.isUndefined($scope.filter) ? {} : { id: $scope.filter }
                            }
                        }
                    }
                };
            };
        }
        SprootMultiSelectDirective.id = "sprootMultiSelect";
        return SprootMultiSelectDirective;
    }());
    App.SprootMultiSelectDirective = SprootMultiSelectDirective;
    angular.module(App.Module)
        .directive(SprootMultiSelectDirective.id, function () { return new SprootMultiSelectDirective(); });
})(App || (App = {}));

var App;
(function (App) {
    "use strict";
    angular.module(App.Module)
        .directive("highlightActive", function () {
        function e(e, t, a, o) {
            var n, r, i;
            r = t.find("a");
            i = function () {
                return o.path();
            };
            n = function (e, t) {
                return t = "#" + t, angular.forEach(e, function (e) {
                    var a, o, n;
                    return o = angular.element(e), a = o.parent("li"), n = o.attr("href"), a.hasClass("active") && a.removeClass("active"), 0 === t.indexOf(n) ? a.addClass("active") : void 0;
                });
            };
            n(r, o.path());
            e.$watch(i, function (e, t) {
                return e !== t ? n(r, o.path()) : void 0;
            });
        }
        var t = {
            restrict: "A",
            controller: ["$scope", "$element", "$attrs", "$location", e]
        };
        return t;
    })
        .directive("collapseNav", [
        function () {
            return {
                restrict: "A",
                link: function (scope, ele) {
                    var $a, $aRest, $app, $lists, $listsRest, $nav, $window, Timer, prevWidth, updateClass;
                    $window = $(window);
                    $lists = ele.find("ul").parent("li");
                    $lists.append('<i class="glyphicon glyphicon-chevron-down icon-has-ul-h"></i>');
                    $a = $lists.children("a");
                    $a.append('<i class="glyphicon glyphicon-chevron-down icon-has-ul"></i>');
                    $listsRest = ele.children("li").not($lists);
                    $aRest = $listsRest.children("a");
                    $app = $("#app");
                    $nav = $("#nav-container");
                    $a.on("click", function (event) {
                        var $parent, $this;
                        if (!$app.hasClass("nav-collapsed-min") && !($nav.hasClass("nav-horizontal") && $window.width() >= 768)) {
                            $this = $(this);
                            $parent = $this.parent("li");
                            $lists.not($parent).removeClass("open").find("ul").slideUp();
                            $parent.toggleClass("open").find("ul").stop().slideToggle();
                            event.preventDefault();
                        }
                    });
                    $aRest.on("click", function () {
                        return $lists.removeClass("open").find("ul").slideUp();
                    });
                    scope.$on("nav:reset", function () {
                        return $lists.removeClass("open").find("ul").slideUp();
                    });
                    Timer = void 0;
                    prevWidth = $window.width();
                    updateClass = function () {
                        var currentWidth = $window.width();
                        if (768 > currentWidth) {
                            $app.removeClass("nav-collapsed-min");
                        }
                        else if (768 > prevWidth && $nav.hasClass("nav-horizontal")) {
                            $lists.removeClass("open").find("ul").slideUp();
                        }
                        prevWidth = currentWidth;
                    };
                    $window.resize(function () {
                        var t;
                        clearTimeout(t);
                        t = setTimeout(updateClass, 300);
                    });
                }
            };
        }
    ])
        .directive("toggleOffCanvas", [
        function () {
            return {
                restrict: "A",
                link: function (scope, ele) {
                    return ele.on("click", function () {
                        return $("#app").toggleClass("on-canvas");
                    });
                }
            };
        }
    ])
        .directive("toggleNavCollapsedMin", ["$rootScope",
        function ($rootScope) {
            return {
                restrict: "A",
                link: function (scope, ele) {
                    var app = $("#app");
                    ele.bind("click", function () {
                        if (app.hasClass("nav-collapsed-min")) {
                            $("#logo").attr("src", "imgs/sproot_logo_white.png");
                            app.removeClass("nav-collapsed-min");
                        }
                        else {
                            $("#logo").attr("src", "imgs/sproot_logo_white_short.png");
                            app.addClass("nav-collapsed-min");
                            $rootScope.$broadcast("nav:reset");
                        }
                    });
                }
            };
        }
    ])
        .directive('ngEnter', function () {
        return {
            restrict: "A",
            link: function (scope, element, attrs) {
                element.bind("keydown keypress", function (event) {
                    if (event.which === 13) {
                        scope.$apply(function () {
                            scope.$eval(attrs["ngEnter"]);
                        });
                        event.preventDefault();
                    }
                });
            }
        };
    })
        .directive('dynAttr', [
        function () {
            return {
                scope: { list: '=dynAttr' },
                link: function (scope, elem) {
                    //for(attr in scope.list){
                    elem.attr(scope.list, '');
                    //}
                    //console.log(scope.list);
                }
            };
        }
    ])
        .directive('fileModel', ['$parse', function ($parse) {
            return {
                restrict: 'A',
                link: function (scope, element, attrs) {
                    var model = $parse(attrs.fileModel);
                    var modelSetter = model.assign;
                    element.bind('change', function () {
                        scope.$apply(function () {
                            modelSetter(scope, element[0].files[0]);
                        });
                    });
                }
            };
        }])
        .directive('treeModel', ['$compile', function ($compile) {
            return {
                restrict: 'A',
                link: function (scope, element, attrs) {
                    //tree id
                    var treeId = attrs.treeId;
                    //tree model
                    var treeModel = attrs.treeModel;
                    //node id
                    var nodeId = attrs.nodeId || 'id';
                    //node label
                    var nodeLabel = attrs.nodeLabel || 'label';
                    //children
                    var nodeChildren = attrs.nodeChildren || 'children';
                    //add children
                    var addChildren = attrs.addChildren || 'vm.addChildren(node, $event)';
                    //add button visibility
                    var addButtonVisibility = attrs.addButtonVisibility || 'vm.addButtonVisibility(node)';
                    //tree template
                    var template = '<ul>' +
                        '<li data-ng-repeat="node in ' + treeModel + '">' +
                        '<md-icon data-ng-class="node.selected" class="node-head" md-svg-icon="custom:right_arrow" aria-label="..." data-ng-show="node.' + nodeChildren + '.length && node.collapsed" data-ng-click="' + treeId + '.selectNodeHead(node)"></md-icon>' +
                        '<md-icon data-ng-class="node.selected" class="node-head" md-svg-icon="custom:down_arrow" aria-label="..." data-ng-show="node.' + nodeChildren + '.length && !node.collapsed" data-ng-click="' + treeId + '.selectNodeHead(node)"></md-icon>' +
                        '<md-icon data-ng-class="node.selected" class="node-head" md-svg-icon="" aria-label="..." data-ng-hide="node.' + nodeChildren + '.length"></md-icon>' +
                        '<span data-ng-class="node.selected" data-ng-click="' + treeId + '.selectNodeLabel(node)">{{node.' + nodeLabel + '}}</span>' +
                        '<md-icon data-ng-class="node.selected" md-svg-icon="content:add_circle_outline" data-ng-show="node.selected && ' + addButtonVisibility + '" data-ng-click="' + addChildren + '"></md-icon>' +
                        '<div data-ng-hide="node.collapsed" data-tree-id="' + treeId + '" data-tree-model="node.' + nodeChildren + '" data-node-id=' + nodeId + ' data-node-label=' + nodeLabel + ' data-node-children=' + nodeChildren + '></div>' +
                        '</li>' +
                        '</ul>';
                    //check tree id, tree model
                    if (treeId && treeModel) {
                        //root node
                        if (attrs.angularTreeview) {
                            //create tree object if not exists
                            scope[treeId] = scope[treeId] || {};
                            //if node head clicks,
                            scope[treeId].selectNodeHead = scope[treeId].selectNodeHead || function (selectedNode) {
                                //Collapse or Expand
                                selectedNode.collapsed = !selectedNode.collapsed;
                            };
                            //if node label clicks,
                            scope[treeId].selectNodeLabel = scope[treeId].selectNodeLabel || function (selectedNode) {
                                //remove highlight from previous node
                                if (scope[treeId].currentNode && scope[treeId].currentNode.selected) {
                                    scope[treeId].currentNode.selected = undefined;
                                }
                                //set highlight to selected node
                                selectedNode.selected = 'selected';
                                //set currentNode
                                scope[treeId].currentNode = selectedNode;
                            };
                        }
                        //Rendering template.
                        element.html('').append($compile(template)(scope));
                    }
                }
            };
        }])
        .directive('format', ['$filter', function ($filter) {
            return {
                require: '?ngModel',
                link: function (scope, elem, attrs, ctrl) {
                    if (!ctrl)
                        return;
                    ctrl.$parsers.push(function (data) {
                        //View -> Model
                        return data;
                    });
                    ctrl.$formatters.push(function (data) {
                        //Model -> View
                        if (attrs.format == 'date') {
                            return $filter(attrs.format)(data, "dd/MM/yyyy");
                        }
                        else if (attrs.format == 'currency') {
                            return $filter(attrs.format)(data);
                        }
                        else {
                            return data;
                        }
                    });
                }
            };
        }])
        .directive('includeReplace', ['$templateRequest', '$compile', function ($templateRequest, $compile) {
            return {
                restrict: 'A',
                replace: true,
                link: function (scope, element, attrs) {
                    $templateRequest(attrs.includeReplace).then(function (html) {
                        var template = angular.element(html);
                        element.parent().append(template);
                        element.children().unwrap();
                        //element.remove();
                        $compile(template)(scope);
                    });
                }
            };
        }])
        .directive("bindHtmlCompile", ['$compile', function ($compile) {
            return {
                restrict: "A",
                link: function (scope, element, attrs) {
                    scope.$watch(function () {
                        return scope.$eval(attrs.bindHtmlCompile);
                    }, function (value) {
                        element.html(value);
                        $compile(element.contents())(scope);
                    });
                }
            };
        }])
        .directive('accordion', function () {
        return {
            restrict: "A",
            link: function (scope, element, attrs) {
                element.bind('click', function () {
                    var panel = this.nextElementSibling;
                    var li = element.parent().parent();
                    var ul = li.parent();
                    var nodes = ul.children();
                    if (li.hasClass("active")) {
                        li.removeClass("active");
                        panel.style.maxHeight = null;
                    }
                    else {
                        li.addClass("active");
                        panel.style.maxHeight = panel.scrollHeight + "px";
                        for (var i = 0; i < nodes.length; ++i) {
                            if (nodes[i].hasAttribute("class", "active") && nodes[i].id != li[0].id) {
                                nodes[i].removeAttribute("class", "active");
                                nodes[i].children[1].style.maxHeight = null;
                            }
                        }
                    }
                });
            },
        };
    })
        .directive('togglePanel', function () {
        return {
            restrict: "A",
            link: function (scope, element, attrs) {
                element.bind('click', function () {
                    var ibox = $(this).closest('div.ibox');
                    var button = $(this).find('i');
                    var content = ibox.children('.ibox-content');
                    content.slideToggle(200);
                    button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
                    ibox.toggleClass('').toggleClass('border-bottom');
                    setTimeout(function () {
                        ibox.resize();
                        ibox.find('[id^=map-]').resize();
                    }, 50);
                });
            }
        };
    })
        .directive('mmSlideToggle', function () {
        return {
            restrict: "A",
            link: function (scope, element, attrs) {
                /* Variables */
                var duration = (attrs["slideDuration"] != null) ? attrs["slideDuration"] : "200";
                var toggleContentSel = attrs["mmSlideToggle"];
                var groupSel = attrs["groupSel"];
                var closeOnContentClick = attrs["closeOnContentClick"];
                var toggleElClass = "mm-slide-toggle";
                var toggleElContentClass = "mm-slide-toggle-content";
                var openClass = "mm-open";
                var closedClass = "mm-closed";
                var animateClass = "mm-animate";
                /* Functions */
                /**
                 * Closes other elements when clicking on group element
                 */
                var groupFunc = function () {
                    $("." + toggleElContentClass + groupSel + "." + openClass).not(toggleContentSel).stop(true, true).slideToggle(duration, function () {
                        var groupToggleSel = "." + toggleElContentClass + groupSel + "." + openClass;
                        $(this).toggleClass(openClass);
                        $(groupToggleSel).addClass(animateClass);
                        setTimeout(function () {
                            $(groupToggleSel).not(element).toggleClass(openClass);
                            $(groupToggleSel).removeClass(animateClass);
                        }, 5);
                    });
                };
                /**
                 * Toggles element
                 */
                var elToggleFunc = function () {
                    $(toggleContentSel).stop(true, true).slideToggle(duration, function () {
                        element.toggleClass(openClass).toggleClass(closedClass);
                        element.addClass(animateClass);
                        setTimeout(function () {
                            $(toggleContentSel).toggleClass(openClass).toggleClass(closedClass);
                            element.removeClass(animateClass);
                        }, 200);
                    });
                };
                /* Click Events */
                // Element click event
                element.on('click', function (e) {
                    e.preventDefault();
                    // If group option is on
                    if (groupSel != null) {
                        groupFunc();
                    }
                    elToggleFunc();
                });
                // Content click event
                if (closeOnContentClick != null) {
                    $('body').off('click', toggleContentSel);
                    $('body').on('click', toggleContentSel, function (e) {
                        e.preventDefault();
                        elToggleFunc();
                    });
                }
            }
        };
    })
        .directive('mmDropdownToggle', function () {
        return {
            restrict: "A",
            link: function (scope, element, attrs) {
                var duration = (attrs["toggleDuration"] != null) ? attrs["toggleDuration"] : "200";
                var dropdownToggleSel = '.mm-dropdown-toggle';
                var toggleContentInnerSel = '.mm-toggle-content-inner';
                var selectedClass = 'mm-open';
                var toggleContentEl = element.find('.mm-toggle-content');
                var toggleEl = element.find('.mm-toggle-el');
                toggleEl.on('click', function (e) {
                    e.stopPropagation();
                    $(dropdownToggleSel).not(element).removeClass(selectedClass);
                    toggleContentEl.show();
                    toggleContentEl.find(toggleContentInnerSel).show();
                    toggleContentEl.height(toggleContentEl.find(toggleContentInnerSel).outerHeight());
                    toggleContentEl.width(toggleContentEl.find(toggleContentInnerSel).outerWidth());
                    setTimeout(function () {
                        toggleContentEl.closest(dropdownToggleSel).toggleClass(selectedClass);
                        if (!toggleContentEl.closest(dropdownToggleSel).hasClass(selectedClass))
                            setTimeout(function () {
                                toggleContentEl.hide();
                            }, duration);
                        toggleContentEl.height(toggleContentEl.find(toggleContentInnerSel).outerHeight());
                        toggleContentEl.width(toggleContentEl.find(toggleContentInnerSel).outerWidth());
                    }, 5);
                });
                $('body').on('click', function (e) {
                    $(dropdownToggleSel).removeClass(selectedClass);
                });
                // toggleContentEl.closest(dropdownToggleSel).on('click', function(e){
                //     e.stopPropagation();
                // });
            }
        };
    })
        .directive('mmMenuActiveOnClick', ['$timeout', function ($timeout) {
            return {
                restrict: "A",
                link: function (scope, element, attrs) {
                    var groupSel = attrs["groupSel"];
                    var activeClass = "active";
                    var defaultMenuHref = '#/news_feed';
                    element.on('click', function (e) {
                        if (groupSel != null)
                            $(groupSel).not(this).removeClass(activeClass);
                        $(this).addClass(activeClass);
                    });
                    // Set default active menu
                    $(groupSel).find('a[href="' + defaultMenuHref + '"]').parent(groupSel).addClass('active');
                    // Use this code if you change place of news feed menu item and fix menu item's rendering based on permission
                    // if (scope.$last === true) {
                    //     $timeout(function () {
                    //         scope.$emit('ngRepeatFinished');
                    //         $(groupSel).find('a[href="#/news_feed"]').parent(groupSel).addClass('active');
                    //     });
                    // }
                }
            };
        }])
        .directive('mmSidebarShowHide', function () {
        return {
            restrict: "A",
            link: function (scope, element, attrs) {
                /* Variables */
                var contentColSel = '.mm-content-col';
                var sidebarColSel = '.mm-sidebar-col';
                var parentElSel = '.mm-sidebar-show-hide > .row';
                var sidebarToggleSel = '.mm-sidebar-toggle';
                var sidebarHiddenCl = 'mm-sidebar-hidden';
                var hideAnimateCl = 'mm-hide-animate';
                var hiddenCl = 'mm-hidden';
                /* Functions */
                function hideSidebar(curEl, callback) {
                    var wToShrink = $(parentElSel).width();
                    $(contentColSel).css('width', wToShrink);
                    $(sidebarColSel).addClass(hideAnimateCl);
                    setTimeout(function () {
                        $(sidebarColSel).addClass(hiddenCl);
                        curEl.addClass(sidebarHiddenCl);
                        callback();
                    }, 100);
                }
                function showSidebar(curEl, callback) {
                    $(contentColSel).removeAttr('style');
                    setTimeout(function () {
                        $(sidebarColSel).removeClass(hiddenCl);
                        callback();
                    }, 295);
                    setTimeout(function () {
                        $(sidebarColSel).removeClass(hideAnimateCl);
                        curEl.removeClass(sidebarHiddenCl);
                    }, 310);
                }
                function refreshCharts() {
                    var wToShrink = $('.mm-chart-list .chart-list-item').width();
                    $('.mm-chart-list .chart-el-wrapper > div').each(function () {
                        $(this).highcharts().setSize(wToShrink, 400);
                    });
                }
                function refreshChartsWithDelay() {
                    setTimeout(function () {
                        refreshCharts();
                    }, 300);
                }
                function refreshLayout() {
                    $(parentElSel).css('width', 'auto');
                    $(contentColSel).css('width', 'auto');
                    setTimeout(function () {
                        if (!$(sidebarToggleSel).hasClass(sidebarHiddenCl))
                            showSidebar($(sidebarToggleSel), refreshCharts);
                        else
                            hideSidebar($(sidebarToggleSel), refreshCharts);
                    }, 300);
                }
                /* Events */
                element.on('click', function (e) {
                    e.stopPropagation();
                    if (!$(this).hasClass(sidebarHiddenCl))
                        hideSidebar($(this), refreshChartsWithDelay);
                    else
                        showSidebar($(this), refreshCharts);
                });
                $('body').on('click', ' .admin-options md-radio-button, .top-nav .toggle-min', function () {
                    refreshLayout();
                });
                $(window).on('resize', function () {
                    refreshLayout();
                });
            }
        };
    });
})(App || (App = {}));

var App;
(function (App) {
    function highlight($sce) {
        return function (text, phrase, searchPattern) {
            text = text || "";
            if (phrase) {
                text = text.replace(new RegExp("(" + phrase + ")", "gmi"), "<span class='keyword'>$1</span>");
            }
            if (searchPattern) {
                text = text.replace(new RegExp("(" + searchPattern + ")", "gmi"), "<span class='searchPattern'>$1</span>");
            }
            return $sce.trustAsHtml(text);
        };
    }
    App.highlight = highlight;
    highlight.$inject = ["$sce"];
    angular.module(App.Module)
        .filter("highlight", highlight);
})(App || (App = {}));

var App;
(function (App) {
    var AnalysisList = (function () {
        function AnalysisList() {
        }
        return AnalysisList;
    }());
    App.AnalysisList = AnalysisList;
})(App || (App = {}));

var App;
(function (App) {
    var DBLoader = (function () {
        function DBLoader() {
            this.className = "";
            this.property_types = {};
        }
        DBLoader.registerClass = function (n, c) {
            DBLoader[n] = c;
        };
        DBLoader.clone = function (obj) {
            var copy;
            // Handle the 3 simple types, and null or undefined
            if (null == obj || "object" != typeof obj)
                return obj;
            // Handle Date
            if (obj instanceof Date) {
                copy = new Date();
                copy.setTime(obj.getTime());
                return copy;
            }
            // Handle Array
            if (obj instanceof Array) {
                copy = [];
                for (var i = 0, len = obj.length; i < len; i++) {
                    copy[i] = DBLoader.clone(obj[i]);
                }
                return copy;
            }
            // Handle Object
            if (obj instanceof Object) {
                copy = {};
                if (obj.getClassName) {
                    //if object class is described in objects.json
                    for (var attr in obj.getProperties()) {
                        copy[attr] = DBLoader.clone(obj[attr]);
                    }
                }
                else {
                    for (var attr in obj) {
                        if (obj.hasOwnProperty(attr))
                            copy[attr] = DBLoader.clone(obj[attr]);
                    }
                }
                return copy;
            }
            throw new Error("Unable to copy obj! Its type isn't supported.");
        };
        DBLoader.prototype.getClassName = function () {
            return this.constructor["name"];
        };
        DBLoader.prototype.getProperties = function () {
            var c, cn = this.getClassName(), a = {};
            while (cn) {
                if (!this.getClassName() || !(c = DBLoader.objects[cn])) {
                    console.error("There is no ", cn, " class description in objects.json");
                }
                for (var i in c.properties) {
                    a[i] = c.properties[i];
                }
                cn = c.extends;
            }
            return a;
        };
        DBLoader.prototype.saveToDB = function (cb) {
            var className = this.getClassName();
            DBLoader.checkAndloadObjects();
            if (!className) {
                console.error("Called saveToDB on object which class can't be determined");
            }
            if (!DBLoader.objects[className]) {
                console.error("There is no ", className, " object in objects.json file.");
                return;
            }
            var s = DBLoader.objects[className]["set"];
            if (!s) {
                console.error("There is no set property in objects.json file for type ", className);
            }
            $.ajax({
                type: "POST",
                url: s["url"],
                //data: DBLoader.clone(this),
                data: { jsondata: JSON.stringify(DBLoader.clone(this)) },
                success: function (res) {
                    App.Notifier.show("CHANGES_SAVED", App.MessageType.success);
                    cb && cb(null, res);
                },
                dataType: "json"
            }).fail(function (err) {
                var err_message;
                try {
                    err_message = JSON.parse(err.responseText)["message"];
                }
                catch (ex) {
                    console.error("JSON.parse ERROR");
                }
                App.Notifier.show(err_message, App.MessageType.error);
                console.error(err);
                cb && cb(err);
            });
        };
        DBLoader.prototype.removeFromDB = function (cb) {
            var className = this.getClassName();
            DBLoader.checkAndloadObjects();
            if (!className) {
                console.error("Called removeFromDB on object which class can't be determined");
            }
            if (!DBLoader.objects[className]) {
                console.error("There is no ", className, " object in objects.json file.");
                return;
            }
            var s = DBLoader.objects[className]["remove"];
            if (!s) {
                console.error("There is no remove property in objects.json file for type ", className);
            }
            $.ajax({
                type: "POST",
                url: s["url"],
                //data: DBLoader.clone(this),
                data: { jsondata: JSON.stringify(DBLoader.clone(this)) },
                success: function (res) {
                    App.Notifier.show("CHANGES_SAVED", App.MessageType.success);
                    console.log("success");
                    cb && cb(null, res);
                },
                dataType: "json"
            }).fail(function (err) {
                var err_message;
                try {
                    err_message = JSON.parse(err.responseText)["message"];
                }
                catch (ex) {
                    console.log("JSON.parse ERROR");
                }
                App.Notifier.show(err_message, App.MessageType.error);
                console.error(err);
                cb && cb(err);
            });
        };
        DBLoader.prototype.loadFromDB = function (cb) {
            var _this = this;
            var className = this.getClassName();
            DBLoader.checkAndloadObjects();
            if (!className) {
                console.error("Called saveToDB on object which class can't be determined");
            }
            if (!DBLoader.objects[className]) {
                console.error("There is no ", className, " object in objects.json file.");
                return;
            }
            var g = DBLoader.objects[className]["get"];
            if (!g) {
                console.error("There is no get property in objects.json file for type ", className);
            }
            App.HttpRequester.post(g["url"], { id: this.id })
                .then(function (res) {
                _this.loadFromDbObject(res);
                cb && cb(null);
            }, function (err) {
                console.error(err);
                cb && cb(err);
            });
            return this;
        };
        DBLoader.prototype.loadFromDbObject = function (obj, className, fieldPrefix) {
            loadFromDBObject.call(this, obj, className, fieldPrefix);
        };
        DBLoader.setProperty = function (n, prop, obj, fieldPrefix) {
            fieldPrefix = fieldPrefix ? fieldPrefix + "_" : "";
            var type;
            if (!this.hasOwnProperty(n)) {
            }
            var f = fieldPrefix + prop.field;
            if (!prop || !prop.type) {
                console.error("No type field on property ", prop);
            }
            if (!obj.hasOwnProperty(f)
                && prop.type.match(/((number)|(string)|(boolean)|(date))/)
                && !(prop.optional && JSON.parse(prop.optional))) {
                console.warn("There is no ", f, " property on object ", obj);
            }
            type = prop.type;
            if (type.match(/((^.*\[\]$)|(number)|(string)|(boolean)|(date))/) &&
                (!obj.hasOwnProperty(f) || obj[f] == null)) {
                this[n] = obj[f];
                return;
            }
            if (type == "number") {
                this[n] = +obj[f];
            }
            else if (type == "string") {
                this[n] = obj[f];
            }
            else if (type == "boolean") {
                this[n] = !!+obj[f];
            }
            else if (type == "date") {
                this[n] = new Date(obj[f]);
            }
            else if (type.match(/^.*\[\]$/)) {
                if (Array.isArray(this[n])) {
                    this[n].length = 0;
                }
                else {
                    this[n] = [];
                }
                type = type.substr(0, type.length - 2);
                for (var i in obj[f]) {
                    this[n].push(DBLoader.instantiateType(type, obj[f][i]));
                    loadFromDBObject.call(this[n][this[n].length - 1], obj[f][i], this[n][this[n].length - 1].getClassName && this[n][this[n].length - 1].getClassName() || type);
                }
            }
            else {
                if (!this[n]) {
                    this[n] = DBLoader.instantiateType(type, obj, f);
                }
                loadFromDBObject.call(this[n], obj, this[n].getClassName && this[n].getClassName() || type, f);
            }
        };
        DBLoader.instantiateType = function (type, obj, fieldPrefix) {
            var t = DBLoader.objects[type];
            fieldPrefix = fieldPrefix ? fieldPrefix + "_" : "";
            if (t && t["classes"]) {
                if (!t["classDeterminerField"]) {
                    console.error("No classDeterminerField on type ", type);
                    return;
                }
                if (!obj.hasOwnProperty(fieldPrefix + t["classDeterminerField"])) {
                    console.error("No classDeterminerField: ", fieldPrefix + t["classDeterminerField"], " value on object ", obj);
                    return;
                }
                if (!t["classes"][obj[fieldPrefix + t["classDeterminerField"]]]) {
                    console.error("No class specified for classDeterminerField value ", obj[t["classDeterminerField"]], " of ", t);
                    return;
                }
                if (!App[t["classes"][obj[fieldPrefix + t["classDeterminerField"]]]]) {
                    console.error("Class ", t["classes"][obj[t["classDeterminerField"]]], " doesn't exist");
                    return;
                }
                return new App[t["classes"][obj[fieldPrefix + t["classDeterminerField"]]]]();
            }
            return new (App[type] || Object)();
        };
        DBLoader.objects = null;
        DBLoader.checkAndloadObjects = function () {
            if (DBLoader.objects) {
                return;
            }
            $.ajax({
                url: 'objects.json',
                async: false,
                dataType: 'json',
                success: function (response) {
                    DBLoader.objects = response;
                }
            })
                .fail(function () {
                //console.error("Failed to load objects.json file");
            });
        };
        return DBLoader;
    }());
    App.DBLoader = DBLoader;
    function loadFromDBObject(obj, className, fieldPrefix) {
        className = className || this.getClassName();
        fieldPrefix = fieldPrefix || "";
        DBLoader.checkAndloadObjects();
        if (!DBLoader.objects[className]) {
            //console.error("There is no ", className, " object in objects.json file.");
            return;
        }
        var co = DBLoader.objects[className];
        for (var i in co.properties) {
            DBLoader.setProperty.call(this, i, co.properties[i], obj, fieldPrefix);
        }
        if (co.extends) {
            loadFromDBObject.call(this, obj, co.extends, fieldPrefix);
        }
    }
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
///<reference path='db-loader.ts' />
var App;
(function (App) {
    var Article = (function (_super) {
        __extends(Article, _super);
        function Article(vArticle) {
            _super.call(this);
            if (vArticle) {
                this.id = vArticle.id;
                this.feed_element_id = vArticle.feed_element_id;
                this.title = vArticle.title;
                this.content = vArticle.content;
                this.publish_date = vArticle.publish_date;
                this.publish_time = vArticle.publish_time;
                this.publish_datetime = vArticle.publish_datetime;
                this.url = vArticle.url;
                this.fb_link = vArticle.fb_link;
                this.mass_media = new App.MassMedia();
                this.mass_media.id = vArticle.mass_media_id;
                this.mass_media.name = vArticle.mass_media_name;
                //this.mass_media.type.id = vArticle.mass_media_type_id;
                //this.mass_media.type.description = vArticle.mass_media_type_description;
                this.mass_media.type = { id: vArticle.mass_media_type_id, description: vArticle.mass_media_type_description };
                //this.mass_media.rating.id = vArticle.mass_media_rating_id;
                //this.mass_media.rating.description = vArticle.mass_media_rating_description;
                this.mass_media.rating = { id: vArticle.mass_media_rating_id, description: vArticle.mass_media_rating_description };
                this.language = vArticle.language;
                this.keywords = vArticle.keywords;
                this.subjects = vArticle.subjects;
            }
        }
        return Article;
    }(App.DBLoader));
    App.Article = Article;
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
///<reference path='db-loader.ts' />
var App;
(function (App) {
    var Customer = (function (_super) {
        __extends(Customer, _super);
        function Customer(id) {
            _super.call(this);
            this.id = id || null;
            this.name = null;
            this.registration_date = null;
        }
        return Customer;
    }(App.DBLoader));
    App.Customer = Customer;
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
///<reference path='db-loader.ts' />
var App;
(function (App) {
    var NewsFeedResponse = (function (_super) {
        __extends(NewsFeedResponse, _super);
        function NewsFeedResponse() {
            _super.call(this);
            this.total_count = 0;
            this.feed_elements = [];
        }
        return NewsFeedResponse;
    }(App.DBLoader));
    App.NewsFeedResponse = NewsFeedResponse;
    var FeedCardElement = (function (_super) {
        __extends(FeedCardElement, _super);
        function FeedCardElement() {
            _super.call(this);
            this.articles = [];
            this.selected = false;
        }
        return FeedCardElement;
    }(App.DBLoader));
    App.FeedCardElement = FeedCardElement;
    var AnalyzeDetail = (function () {
        function AnalyzeDetail() {
            this.tags = [];
        }
        return AnalyzeDetail;
    }());
    App.AnalyzeDetail = AnalyzeDetail;
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
///<reference path='db-loader.ts' />
var App;
(function (App) {
    ;
    ;
    ;
    var NewsFilter = (function (_super) {
        __extends(NewsFilter, _super);
        function NewsFilter() {
            _super.call(this);
            if (!this.start_date) {
                this.start_date = new Date();
                this.start_date.setHours(0, 0, 0, 0);
                this.start_date.setDate(this.start_date.getDate() - 5);
            }
        }
        return NewsFilter;
    }(App.DBLoader));
    App.NewsFilter = NewsFilter;
    ;
    var PostFilter = (function (_super) {
        __extends(PostFilter, _super);
        function PostFilter() {
            _super.call(this);
            if (!this.start_date) {
                this.start_date = new Date();
                this.start_date.setHours(0, 0, 0, 0);
                this.start_date.setDate(this.start_date.getDate() - 5);
            }
        }
        return PostFilter;
    }(App.DBLoader));
    App.PostFilter = PostFilter;
})(App || (App = {}));

///<reference path='db-loader.ts' />
var App;
(function (App) {
    var Dictionary = (function () {
        function Dictionary() {
        }
        return Dictionary;
    }());
    App.Dictionary = Dictionary;
    var RequestParameterModel = (function () {
        function RequestParameterModel() {
        }
        return RequestParameterModel;
    }());
    App.RequestParameterModel = RequestParameterModel;
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
///<reference path='db-loader.ts' />
var App;
(function (App) {
    var Keyword = (function (_super) {
        __extends(Keyword, _super);
        function Keyword() {
            _super.call(this);
        }
        return Keyword;
    }(App.DBLoader));
    App.Keyword = Keyword;
})(App || (App = {}));

var App;
(function (App) {
    var Map = (function () {
        function Map() {
            var _this = this;
            this.get = function (key) {
                return _this.map[key];
            };
            this.set = function (key, value) {
                _this.map[key] = value;
            };
            this.keys = function () {
                var keys = [];
                for (var key in _this.map) {
                    if (_this.map.hasOwnProperty(key)) {
                        keys.push(key);
                    }
                }
                return keys;
            };
            this.remove = function (key) {
                if (_this.map.hasOwnProperty(key)) {
                    delete _this.map[key];
                    return true;
                }
                return false;
            };
            this.hasKey = function (key) {
                return _this.map.hasOwnProperty(key);
            };
            this.hasKeys = function () {
                var keys = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    keys[_i - 0] = arguments[_i];
                }
                for (var key in keys) {
                    if (!_this.hasKey(key)) {
                        return false;
                    }
                }
                return true;
            };
            this.values = function () {
                var values = [];
                var keys = _this.keys();
                keys.forEach(function (key) { return values.push(_this.map[key]); });
                return values;
            };
            this.updateFrom = function (externalMap) {
                for (var key in externalMap.keys) {
                    _this.set(key, externalMap.get(key));
                }
            };
            this.map = new Object();
        }
        return Map;
    }());
    App.Map = Map;
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
///<reference path='db-loader.ts' />
var App;
(function (App) {
    var MassMedia = (function (_super) {
        __extends(MassMedia, _super);
        function MassMedia(id) {
            _super.call(this);
            this.id = id || null;
            this.name = null;
            this.type = null;
            this.rating = null;
            this.main_url = null;
            this.logo = null;
            this.country = null;
            this.fb_id = null;
            this.date_format = null;
            this.language = null;
        }
        Object.defineProperty(MassMedia.prototype, "updatesAvailable", {
            get: function () { return this._updatesAvailable; },
            set: function (up) { this._updatesAvailable = up ? true : false; },
            enumerable: true,
            configurable: true
        });
        return MassMedia;
    }(App.DBLoader));
    App.MassMedia = MassMedia;
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
///<reference path='db-loader.ts' />
var App;
(function (App) {
    var Notification = (function (_super) {
        __extends(Notification, _super);
        function Notification() {
            _super.apply(this, arguments);
        }
        return Notification;
    }(App.DBLoader));
    App.Notification = Notification;
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
///<reference path='db-loader.ts' />
var App;
(function (App) {
    var PressEvent = (function (_super) {
        __extends(PressEvent, _super);
        function PressEvent(id) {
            _super.call(this);
            this.id = id || null;
            this.name = null;
            this.event_date = null;
            this.projects = [];
        }
        return PressEvent;
    }(App.DBLoader));
    App.PressEvent = PressEvent;
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
///<reference path='db-loader.ts' />
var App;
(function (App) {
    var Project = (function (_super) {
        __extends(Project, _super);
        function Project(id) {
            _super.call(this);
            this.id = id || null;
            this.name = null;
            this.start_date = null;
            this.subjects = [];
            this.updatesAvailable = null;
        }
        return Project;
    }(App.DBLoader));
    App.Project = Project;
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
///<reference path='db-loader.ts' />
var App;
(function (App) {
    var SocialMediaFeedResponse = (function (_super) {
        __extends(SocialMediaFeedResponse, _super);
        function SocialMediaFeedResponse() {
            _super.call(this);
            this.total_count = 0;
            this.elements = [];
        }
        return SocialMediaFeedResponse;
    }(App.DBLoader));
    App.SocialMediaFeedResponse = SocialMediaFeedResponse;
    var SocialMediaElement = (function (_super) {
        __extends(SocialMediaElement, _super);
        function SocialMediaElement() {
            _super.call(this);
            this.selected = false;
        }
        return SocialMediaElement;
    }(App.DBLoader));
    App.SocialMediaElement = SocialMediaElement;
    var Post = (function (_super) {
        __extends(Post, _super);
        function Post(vPost) {
            _super.call(this);
            if (vPost) {
                this.id = vPost.id;
                this.title = vPost.title;
                this.content = vPost.content;
                this.publish_datetime = vPost.publish_datetime;
                this.url = vPost.url;
                this.comments_url = vPost.comments_url;
                this.image = vPost.image;
                this.emoji_like = vPost.emoji_like;
                this.emoji_love = vPost.emoji_love;
                this.emoji_haha = vPost.emoji_haha;
                this.emoji_wow = vPost.emoji_wow;
                this.emoji_sad = vPost.emoji_sad;
                this.emoji_angry = vPost.emoji_angry;
                this.total_likes = vPost.emoji_like + vPost.emoji_love + vPost.emoji_haha + vPost.emoji_wow + vPost.emoji_sad + vPost.emoji_angry;
                this.shares = vPost.shares;
                this.comments = vPost.comments;
                this.social_media = new App.SocialMedia();
                this.social_media.id = vPost.social_media_id;
                this.social_media.name = vPost.social_media_name;
                this.social_media.type = {
                    id: vPost.social_media_type_id,
                    description: vPost.social_media_type_description
                };
                this.keywords = vPost.keywords;
                this.subjects = vPost.subjects;
                this.rated = vPost.rated;
            }
        }
        return Post;
    }(App.DBLoader));
    App.Post = Post;
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
///<reference path='db-loader.ts' />
var App;
(function (App) {
    var SocialMedia = (function (_super) {
        __extends(SocialMedia, _super);
        function SocialMedia(id) {
            _super.call(this);
            this.id = id || null;
            this.name = null;
            this.type = null;
            this.main_url = null;
            this.status = null;
            this.image = null;
        }
        return SocialMedia;
    }(App.DBLoader));
    App.SocialMedia = SocialMedia;
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
///<reference path='db-loader.ts' />
var App;
(function (App) {
    var Subject = (function (_super) {
        __extends(Subject, _super);
        function Subject() {
            _super.call(this);
        }
        return Subject;
    }(App.DBLoader));
    App.Subject = Subject;
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
///<reference path='db-loader.ts' />
var App;
(function (App) {
    var User = (function (_super) {
        __extends(User, _super);
        function User(_id) {
            _super.call(this);
            this._id = _id;
            if (_id) {
                this._id = _id;
            }
            else {
                this.reset();
            }
        }
        Object.defineProperty(User.prototype, "id", {
            get: function () { return this._id; },
            set: function (pId) { this._id = pId; },
            enumerable: true,
            configurable: true
        });
        User.prototype.reset = function () {
            this.id = null;
            this.firstName = "";
            this.lastName = "";
            this.login = "";
            this.password = null;
            this.group = null;
            this.status = null;
            this.role = null;
            this.customerId = null;
            this.sectionPermissions = [];
            this.controlPermissions = [];
            this.email = "";
            this.feedRefreshInterval = 0;
        };
        User.prototype.init = function (u) {
            this.id = u.id;
            this.firstName = u.firstName;
            this.lastName = u.lastName;
            this.login = u.login;
            this.role = u.role;
            this.customerId = u.customerId;
            this.sectionPermissions = u.sectionPermissions && u.sectionPermissions.slice() || [];
            this.controlPermissions = u.controlPermissions && u.controlPermissions.slice() || [];
            this.group = { id: u.groupId };
            this.email = u.email;
            this.feedRefreshInterval = u.feedRefreshInterval;
        };
        User.prototype.isAuthorized = function () {
            return this.id != null;
        };
        return User;
    }(App.DBLoader));
    App.User = User;
})(App || (App = {}));

var App;
(function (App) {
    var AnalysisService = (function () {
        /*@ngInject*/
        function AnalysisService($q) {
            var _this = this;
            this.$q = $q;
            this.Attitudes = [
                { id: App.AttitudeEnum.Positive, name: 'Positive', color: '#73d41e', data: [] },
                { id: App.AttitudeEnum.Neutral, name: 'Neutral', color: '#e6c544', data: [] },
                { id: App.AttitudeEnum.Negative, name: 'Negative', color: '#da1b1b', data: [] }
            ];
            /*private FB: any[] = [
                {id: 1, name: 'Positive Likes', color: '#73d41e', data: [] },
                {id: 2, name: 'Neutral Likes', color: '#e6c544', data: [] },
                {id: 3, name: 'Negative Likes', color: '#d4172f', data: [] },
                {id: 4, name: 'Positive Shares', color: '#5b3ce6', data: [] },
                {id: 5, name: 'Neutral Shares', color: '#af8ae6', data: [] },
                {id: 6, name: 'Negative Shares', color: '#da2e94', data: [] },
                {id: 7, name: 'Comment', color: '#2fe6dd', data: [] }
            ];*/
            this.FB = [
                { id: 1, name: 'Positive Likes', color: '#73d41e', data: [] },
                { id: 2, name: 'Negative Likes', color: '#d4172f', data: [] },
                { id: 3, name: 'Positive Shares', color: '#5b3ce6', data: [] },
                { id: 4, name: 'Negative Shares', color: '#da2e94', data: [] },
                { id: 5, name: 'Comment', color: '#2fe6dd', data: [] }
            ];
            this.getAnalysisByUser = function (newsFeedType) {
                if (newsFeedType === void 0) { newsFeedType = App.NewsFeedTypeEnum.MassMedia; }
                return App.HttpRequester.post("/request/get_analysis_by_user", { user_id: null, feed_type: newsFeedType })
                    .then(function (response) {
                    return response;
                });
            };
            this.analysisByDistributionOfMediaCoverage = function (analysis) {
                var validFilter = _this.generateValidFilter(analysis.filters);
                return App.HttpRequester.post("/request/analysis_by_distribution_of_media_coverage", { project: validFilter.project.id, subject: validFilter.subject, mass_media_type: validFilter.mass_media_type, start_date: validFilter.start_date, end_date: validFilter.end_date })
                    .then(function (response) {
                    return response;
                });
            };
            this.analysisByAttitudes = function (analysis) {
                var validFilter = _this.generateValidFilter(analysis.filters);
                return App.HttpRequester.post("/request/analysis_by_attitudes", { project: validFilter.project.id, subject: validFilter.subject, mass_media_type: validFilter.mass_media_type, start_date: validFilter.start_date, end_date: validFilter.end_date })
                    .then(function (response) {
                    return _this.generateColumnChartData(response, _this.Attitudes);
                });
            };
            this.analysisByMassMediaRatings = function (analysis) {
                var validFilter = _this.generateValidFilter(analysis.filters);
                return App.HttpRequester.post("/request/analysis_by_mass_media_ratings", { project: validFilter.project.id, subject: validFilter.subject, mass_media_type: validFilter.mass_media_type, start_date: validFilter.start_date, end_date: validFilter.end_date })
                    .then(function (response) {
                    return _this.generateColumnChartData(response.data, response.mass_media_ratings);
                });
            };
            this.analysisByReferenceTypes = function (analysis) {
                var validFilter = _this.generateValidFilter(analysis.filters);
                return App.HttpRequester.post("/request/analysis_by_reference_types", { project: validFilter.project.id, subject: validFilter.subject, mass_media_type: validFilter.mass_media_type, start_date: validFilter.start_date, end_date: validFilter.end_date })
                    .then(function (response) {
                    return _this.generateColumnChartData(response.data, response.reference_types);
                });
            };
            this.analysisByCategory = function (analysis) {
                var validFilter = _this.generateValidFilter(analysis.filters);
                return App.HttpRequester.post("/request/analysis_by_category", { project: validFilter.project.id, subject: validFilter.subject, mass_media_type: validFilter.mass_media_type, start_date: validFilter.start_date, end_date: validFilter.end_date })
                    .then(function (response) {
                    return _this.generateColumnChartData(response.data, response.category_types);
                });
            };
            this.analysisScore = function (analysis) {
                var validFilter = _this.generateValidFilter(analysis.filters);
                return App.HttpRequester.post("/request/analysis_score", { project: validFilter.project.id, subject: validFilter.subject, mass_media_type: validFilter.mass_media_type, start_date: validFilter.start_date, end_date: validFilter.end_date })
                    .then(function (response) {
                    return _this.generateColumnChartData(response.data, response.mass_media_ratings);
                });
            };
            this.analysisBySphere = function (analysis) {
                var validFilter = _this.generateValidFilter(analysis.filters);
                return App.HttpRequester.post("/request/analysis_by_sphere", { project: validFilter.project.id, subject: validFilter.subject, mass_media_type: validFilter.mass_media_type, start_date: validFilter.start_date, end_date: validFilter.end_date })
                    .then(function (response) {
                    return _this.generateColumnChartData(response.data, response.sphere_types);
                });
            };
            this.analysisFB = function (analysis) {
                var validFilter = _this.generateValidFilter(analysis.filters);
                return App.HttpRequester.post("/request/analysis_fb", { project: validFilter.project.id, subject: validFilter.subject, mass_media_type: validFilter.mass_media_type, start_date: validFilter.start_date, end_date: validFilter.end_date })
                    .then(function (response) {
                    return _this.generateColumnChartData(response, _this.FB);
                });
            };
            this.analysisByPressEvents = function (analysis) {
                var validFilter = _this.generateValidFilter(analysis.filters);
                return App.HttpRequester.post("/request/analysis_by_press_events", { project: validFilter.project.id, subject: validFilter.subject, mass_media_type: validFilter.mass_media_type, start_date: validFilter.start_date, end_date: validFilter.end_date })
                    .then(function (response) {
                    return response;
                });
            };
            this.analysisByTags = function (analysis) {
                var validFilter = _this.generateValidFilter(analysis.filters);
                return App.HttpRequester.post("/request/analysis_by_tags", { project: validFilter.project.id, subject: validFilter.subject, mass_media_type: validFilter.mass_media_type, start_date: validFilter.start_date, end_date: validFilter.end_date })
                    .then(function (response) {
                    return _this.generateColumnChartData(response.data, response.tag_types);
                });
            };
            this.analysisWeeklyBySubject = function (analysis) {
                var validFilter = _this.generateValidFilter(analysis.filters);
                return App.HttpRequester.post("/request/analysis_weekly_by_subject", { project: validFilter.project.id, subject: +validFilter.subject, mass_media_type: validFilter.mass_media_type, start_date: validFilter.start_date, end_date: validFilter.end_date })
                    .then(function (response) {
                    return _this.generateColumnChartData(response, _this.Attitudes);
                });
            };
            this.analysisCompareForPeriod = function (analysis) {
                var validFilter = _this.generateValidFilter(analysis.filters);
                return App.HttpRequester.post("/request/analysis_compare_for_period", { project: validFilter.project.id, subject: validFilter.subject, mass_media_type: validFilter.mass_media_type, start_date: validFilter.start_date, end_date: validFilter.end_date })
                    .then(function (response) {
                    return _this.generateLineChartData(response.data, response.subjects);
                });
            };
            this.generateChartData = function (response, keyArray) {
                var generatedData = [];
                for (var _i = 0, keyArray_1 = keyArray; _i < keyArray_1.length; _i++) {
                    var key = keyArray_1[_i];
                    generatedData[key.id - 1] = { key: key.description, color: key.color, values: [] };
                    generatedData[key.id - 1].values = _.where(response, { key_property: key.id });
                }
                return generatedData;
            };
            this.generateColumnChartData = function (response, keyArray) {
                var localKeyArray = angular.copy(keyArray);
                var xAxis = [];
                for (var i = 0; i < response.length; ++i) {
                    if (_.findIndex(xAxis, function (item) { return item == response[i].x; }) == -1) {
                        xAxis.push(response[i].x);
                    }
                    var obj = _.find(localKeyArray, function (item) { return item.id == response[i].key_property; });
                    if (obj) {
                        if (!obj.hasOwnProperty('data')) {
                            obj.data = [];
                        }
                        obj.data.push(response[i].y);
                    }
                }
                return { xAxis: xAxis, series: localKeyArray, response: response };
            };
            this.generateLineChartData = function (response, keyArray) {
                var localKeyArray = angular.copy(keyArray);
                var xAxis = [];
                var colors = [];
                for (var i = 0; i < response.length; ++i) {
                    if (_.findIndex(xAxis, function (item) { return item == response[i].x; }) == -1) {
                        xAxis.push(response[i].x);
                    }
                    var obj = _.find(localKeyArray, function (item) { return item.id == response[i].key_property; });
                    if (obj) {
                        if (!obj.hasOwnProperty('data')) {
                            obj.data = [];
                        }
                        obj.data.push(response[i].y);
                    }
                }
                for (var i = 0; i < keyArray.length; ++i) {
                    if (_.findIndex(colors, function (item) { return item == keyArray[i].subject_color; }) == -1) {
                        colors.push(keyArray[i].subject_color);
                    }
                }
                return { xAxis: xAxis, series: localKeyArray, colors: colors, response: response };
            };
            this.generateValidFilter = function (filter) {
                if (!filter.start_date) {
                    App.Utilities.getStartDateByProject(filter.project.id).then(function (response) {
                        filter.start_date = response.start_date;
                    });
                }
                var validFilter;
                validFilter = angular.copy(filter);
                if (filter.subject) {
                    validFilter.subject = validFilter.subject.join();
                }
                return validFilter;
            };
            this.fbAnalysisByDistributionOfMediaCoverage = function (analysis) {
                var validFilter = _this.generateValidFilter(analysis.filters);
                return App.HttpRequester.post("/request/fb_analysis_by_distribution_of_media_coverage", { project: validFilter.project.id, subject: validFilter.subject, mass_media_type: validFilter.mass_media_type, start_date: validFilter.start_date, end_date: validFilter.end_date })
                    .then(function (response) {
                    return response;
                });
            };
            this.fbAnalysisByAttitudes = function (analysis) {
                var validFilter = _this.generateValidFilter(analysis.filters);
                return App.HttpRequester.post("/request/fb_analysis_by_attitudes", { project: validFilter.project.id, subject: validFilter.subject, start_date: validFilter.start_date, end_date: validFilter.end_date })
                    .then(function (response) {
                    return _this.generateColumnChartData(response, _this.Attitudes);
                });
            };
            this.fbAnalysisByCategory = function (analysis) {
                var validFilter = _this.generateValidFilter(analysis.filters);
                return App.HttpRequester.post("/request/fb_analysis_by_category", { project: validFilter.project.id, subject: validFilter.subject, start_date: validFilter.start_date, end_date: validFilter.end_date })
                    .then(function (response) {
                    return _this.generateColumnChartData(response.data, response.category_types);
                });
            };
            this.fbAnalysisBySphere = function (analysis) {
                var validFilter = _this.generateValidFilter(analysis.filters);
                return App.HttpRequester.post("/request/fb_analysis_by_sphere", { project: validFilter.project.id, subject: validFilter.subject, start_date: validFilter.start_date, end_date: validFilter.end_date })
                    .then(function (response) {
                    return _this.generateColumnChartData(response.data, response.sphere_types);
                });
            };
        }
        AnalysisService.id = "analysisService";
        return AnalysisService;
    }());
    App.AnalysisService = AnalysisService;
    angular.module(App.Module)
        .service(AnalysisService.id, AnalysisService);
})(App || (App = {}));

var App;
(function (App) {
    var Authentication = (function () {
        /*@ngInject*/
        function Authentication($http, $q, $timeout) {
            var _this = this;
            this.$http = $http;
            this.$q = $q;
            this.$timeout = $timeout;
            this.login_checked = false;
            this.isAuthorized = function () { return _this.user.isAuthorized(); };
            this.user = new App.User();
        }
        Authentication.prototype.loginUser = function (login, password, callback) {
            var _this = this;
            this.$http.post('/request/login/', { login: login, password: password }).success(function (res) {
                _this.login_checked = true;
                if (res.success) {
                    _this.user.init(res.user);
                }
                else {
                    _this.user.reset();
                }
                if (callback) {
                    callback();
                }
            }).error(function (err) {
                _this.user.reset();
                if (callback) {
                    callback(err);
                }
            });
        };
        Authentication.prototype.checkLogin = function () {
            var d = this.$q.defer();
            if (this.login_checked) {
                this.$timeout(function () {
                    d.resolve();
                });
            }
            else {
                this.loginUser("", "", function (err) {
                    d.resolve();
                });
            }
            return d.promise;
        };
        Authentication.prototype.logoutUser = function (callback) {
            var _this = this;
            this.$http.post('/request/logout/', '').success(function () {
                _this.user.reset();
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                if (callback) {
                    callback();
                }
            });
        };
        Object.defineProperty(Authentication.prototype, "isCustomerRole", {
            get: function () {
                return this.user.role == App.UserRoleEnum.Customer;
            },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(Authentication.prototype, "isBackOfficeRole", {
            get: function () {
                return this.user.role == App.UserRoleEnum.BackOffice;
            },
            enumerable: true,
            configurable: true
        });
        Authentication.id = "authenticationService";
        return Authentication;
    }());
    App.Authentication = Authentication;
    angular.module(App.Module)
        .service(Authentication.id, Authentication);
})(App || (App = {}));

var App;
(function (App) {
    var ConfigurationService = (function () {
        /*@ngInject*/
        function ConfigurationService(eventManagerService, globals) {
            this.eventManagerService = eventManagerService;
            this.globals = globals;
            this.newsFeedSelectionMode = this.newsFeedSelectionMode || App.NewsFeedSelectionModeEnum.Keywords;
        }
        Object.defineProperty(ConfigurationService.prototype, "newsFeedSelectionMode", {
            get: function () {
                return this._newsFeedSelectionMode;
            },
            set: function (value) {
                this._newsFeedSelectionMode = value;
                this.eventManagerService.publish(this.globals.events.getNewsFeedList, 1);
            },
            enumerable: true,
            configurable: true
        });
        ConfigurationService.id = "configurationService";
        return ConfigurationService;
    }());
    App.ConfigurationService = ConfigurationService;
    angular.module(App.Module)
        .service(ConfigurationService.id, ConfigurationService);
})(App || (App = {}));

var App;
(function (App) {
    var CustomerService = (function () {
        /*@ngInject*/
        function CustomerService($q) {
            this.$q = $q;
            this.getCustomers = function () {
                return App.HttpRequester.post("/request/get_customers", null)
                    .then(function (response) {
                    return response;
                });
            };
            this.saveSubjectForMailing = function (subjectForMailing) {
                return App.HttpRequester.post("/request/save_user_mailing_subject", { subjectForMailing: subjectForMailing })
                    .then(function (response) {
                    return true;
                }).catch(function (err) {
                    return false;
                });
            };
            this.removeSubjectForMailing = function (id) {
                return App.HttpRequester.post("/request/remove_subject_for_mailing", { id: id })
                    .then(function (response) {
                    return true;
                }).catch(function (err) {
                    return false;
                });
            };
            this.getMassMediaSectionsByCustomerId = function (customerId, massMediaId) {
                return App.HttpRequester.post("request/get_mass_media_sections_by_customer_id", { customer_id: customerId, mass_media_id: massMediaId })
                    .then(function (response) {
                    return response;
                }).catch(function (err) {
                    return false;
                });
            };
            this.saveMassMediaSectionToCustomer = function (customerId, massMediaId, sections) {
                return App.HttpRequester.post("request/save_mass_media_section_to_customer", { customer_id: customerId, mass_media_id: massMediaId, sections: sections })
                    .then(function (response) {
                    return response;
                }).catch(function (err) {
                    return false;
                });
            };
            this.removeMassMediaSections = function (customerId, massMediaId) {
                return App.HttpRequester.post("request/remove_mass_media_sections_from_customer", { customer_id: customerId, mass_media_id: massMediaId })
                    .then(function (response) {
                    return response;
                }).catch(function (err) {
                    return false;
                });
            };
        }
        CustomerService.id = "customerService";
        return CustomerService;
    }());
    App.CustomerService = CustomerService;
    angular.module(App.Module)
        .service(CustomerService.id, CustomerService);
})(App || (App = {}));

var App;
(function (App) {
    var EventManagerService = (function () {
        /*@ngInject*/
        function EventManagerService($rootScope) {
            var _this = this;
            this.$rootScope = $rootScope;
            this.events = new App.Map();
            this.subscribe = function (eventName, listener) {
                _this.events.set(eventName, listener);
            };
            this.publish = function (eventName) {
                var args = [];
                for (var _i = 1; _i < arguments.length; _i++) {
                    args[_i - 1] = arguments[_i];
                }
                if (_this.events.hasKey(eventName)) {
                    _this.events.get(eventName).apply(void 0, args);
                }
            };
        }
        EventManagerService.id = "eventManagerService";
        return EventManagerService;
    }());
    App.EventManagerService = EventManagerService;
    angular.module(App.Module)
        .service(EventManagerService.id, EventManagerService);
})(App || (App = {}));

var App;
(function (App) {
    var HttpRequester = (function () {
        /*
        .catch(reason => {
                    throw reason;
                });
        */
        /*    private showUserFriendlyMessage = <T>(response: Response<T>): void => {
                if (response.HasError && response.ResponseStatus == ResponseStatuses.UserFriendlyMessage) {
                    Notifier.show("Oops...", response.Errors[0].Message, "error");
                }
            };*/
        /*@ngInject*/
        function HttpRequester($http) {
            this.$http = $http;
            HttpRequester.http = $http;
        }
        HttpRequester.id = "httpRequesterService";
        HttpRequester.post = function (url, data) {
            return HttpRequester.http.post(url, data)
                .then(function (response) {
                return response.data;
            }).catch(function (err) {
                App.Notifier.show(err.data.message || "ERROR_OCCURRED", App.MessageType.error);
                return new Error();
            });
        };
        /*static post = <TEntity>(url: string, entity: any): ng.IPromise<TEntity> => {
            return HttpRequester.http.post<Response<TEntity>>(url, entity)
                .then((response) => {
                    return response.data.Result;
                });
        };*/
        HttpRequester.get = function (url) {
            return HttpRequester.http.get(url)
                .then(function (response) {
                return response.data.Result;
            });
        };
        return HttpRequester;
    }());
    App.HttpRequester = HttpRequester;
    angular.module(App.Module)
        .service(HttpRequester.id, HttpRequester);
})(App || (App = {}));

var App;
(function (App) {
    var LoggerService = (function () {
        /*@ngInject*/
        function LoggerService($log) {
            this.$log = $log;
        }
        LoggerService.prototype.log = function (logType, message, data) {
            switch (logType) {
                case 0 /* Debug */:
                    //console.debug(message, data);
                    this.$log.debug(message, data);
                    break;
                case 1 /* Info */:
                    //console.info(message, data);
                    this.$log.info(message, data);
                    break;
                case 3 /* Error */:
                    //console.error(message, data);
                    this.$log.error(message, data);
                    break;
                case 2 /* Warning */:
                    //console.warn(message, data);
                    this.$log.warn(message, data);
                    break;
                default:
                    //console.log(message, data);
                    this.$log.log(message, data);
                    break;
            }
        };
        LoggerService.id = "loggerService";
        return LoggerService;
    }());
    App.LoggerService = LoggerService;
    var Logger = (function () {
        function Logger(sourceId, loggerService) {
            this.sourceId = sourceId;
            this.loggerService = loggerService;
        }
        Logger.prototype.debug = function (source, message, data) {
            this._log(this.sourceId, source, 0 /* Debug */, message, data);
        };
        Logger.prototype.info = function (source, message, data) {
            this._log(this.sourceId, source, 1 /* Info */, message, data);
        };
        Logger.prototype.error = function (source, message, data) {
            this._log(this.sourceId, source, 3 /* Error */, message, data);
        };
        Logger.prototype.warn = function (source, message, data) {
            this._log(this.sourceId, source, 2 /* Warning */, message, data);
        };
        Logger.prototype._log = function (sourceId, source, logType, message, data) {
            var msg = "[" + sourceId + "::" + source + "] " + message;
            this.loggerService.log(logType, msg, data);
        };
        return Logger;
    }());
    App.Logger = Logger;
    angular.module(App.Module)
        .service(LoggerService.id, LoggerService)
        .factory("loggerFactory", function (loggerService) {
        return function (sourceId) {
            return new Logger(sourceId, loggerService);
        };
    });
})(App || (App = {}));

var App;
(function (App) {
    var MailingService = (function () {
        /*@ngInject*/
        function MailingService($q, $filter) {
            var _this = this;
            this.$q = $q;
            this.$filter = $filter;
            this.sendMail = function (articles, receiver) {
                var html = App.Utilities.generateHtmlFromSelectedArticles(articles, _this.$filter);
                return App.HttpRequester.post("/request/send_email", { html: html, subject: "Articles", receiver: receiver })
                    .then(function (response) {
                    return response;
                });
            };
        }
        MailingService.id = "mailingService";
        return MailingService;
    }());
    App.MailingService = MailingService;
    angular.module(App.Module)
        .service(MailingService.id, MailingService);
})(App || (App = {}));

var App;
(function (App) {
    var NewsFeedService = (function () {
        /*@ngInject*/
        function NewsFeedService($q, $http, configurationService) {
            var _this = this;
            this.$q = $q;
            this.$http = $http;
            this.configurationService = configurationService;
            this.getNewsFeedList = function (requestParameter) {
                var requestURL = _this.configurationService.newsFeedSelectionMode == App.NewsFeedSelectionModeEnum.Sections ? "/request/get_news_feed_by_sections" : "/request/get_news_feed_for_customer";
                return App.HttpRequester.post(requestURL, requestParameter)
                    .then(function (response) {
                    return _this.generateFeedCardElements(response);
                });
            };
            this.generateFeedCardElements = function (response) {
                var generatedElements = new App.NewsFeedResponse();
                if (response) {
                    generatedElements.total_count = response.total_count;
                    var i = void 0;
                    var _loop_1 = function(article) {
                        i = _.findIndex(response.feed_elements, function (element) { return element.id == article.feed_element_id; });
                        if (i != -1) {
                            if (!response.feed_elements[i].articles) {
                                response.feed_elements[i].articles = [];
                            }
                            response.feed_elements[i].articles.push(_this.makeArticleModel(article));
                        }
                    };
                    for (var _i = 0, _a = response.articles; _i < _a.length; _i++) {
                        var article = _a[_i];
                        _loop_1(article);
                    }
                    generatedElements.feed_elements = response.feed_elements;
                }
                return generatedElements;
            };
            this.makeArticleModel = function (responseArticle) {
                return new App.Article(responseArticle);
            };
            this.changeReadState = function (elementsList, state) {
                return App.HttpRequester.post("/request/change_feed_element_read_state", { state: state, elementsList: _this.generateStringFromArray(elementsList) })
                    .then(function () {
                    return true;
                }).catch(function (err) {
                    App.Notifier.show(err.message, App.MessageType.error);
                    return false;
                });
            };
            this.saveFilter = function (filter) {
                var convertedFilter = angular.copy(filter);
                convertedFilter.subject = convertedFilter.subject && convertedFilter.subject.join();
                convertedFilter.mass_media = convertedFilter.mass_media && convertedFilter.mass_media.join();
                return App.HttpRequester.post("/request/save_filter", convertedFilter)
                    .then(function () {
                    return true;
                }).catch(function (err) {
                    App.Notifier.show(err.message, App.MessageType.error);
                    return false;
                });
            };
            this.saveArticle = function (article) {
                return App.HttpRequester.post("/request/save_article", article)
                    .then(function () {
                    return true;
                }).catch(function (err) {
                    App.Notifier.show(err.message, App.MessageType.error);
                    return false;
                });
            };
            this.saveFbLink = function (fbLinkData, articleId) {
                return App.HttpRequester.post("/request/save_fb_link", { fbLinkData: fbLinkData, articleId: articleId })
                    .then(function () {
                    return true;
                }).catch(function (err) {
                    App.Notifier.show(err.message, App.MessageType.error);
                    return false;
                });
            };
            this.getFbLinkData = function (articleId) {
                return App.HttpRequester.post("/request/get_article_fb_data", { articleId: articleId })
                    .then(function (response) {
                    return response;
                });
            };
            this.groupAction = function (elementsList, action, groupId) {
                if (action == App.FeedElementActionsEnum.ArticleMerge) {
                    return _this.$http.post('/request/merge_articles', { ids: _this.generateStringFromArray(elementsList) })
                        .success(function (response) {
                        App.Notifier.show('CHANGES_SAVED', App.MessageType.success);
                        return true;
                    }).error(function (err) {
                        App.Notifier.show(err.message || "ERROR_OCCURRED", App.MessageType.error);
                        return false;
                    });
                }
                else if (action == App.FeedElementActionsEnum.PressEventJoin) {
                    return _this.$http.post('/request/join_in_press_event', { ids: _this.generateStringFromArray(elementsList), pressEvent: groupId })
                        .success(function (response) {
                        App.Notifier.show('CHANGES_SAVED', App.MessageType.success);
                        return true;
                    }).error(function (err) {
                        App.Notifier.show(err.message || "ERROR_OCCURRED", App.MessageType.error);
                        return false;
                    });
                }
                else if (action == App.FeedElementActionsEnum.RemoveElementFromProject) {
                    return _this.$http.post('/request/remove_element_from_project', { ids: _this.generateStringFromArray(elementsList), projectId: groupId })
                        .success(function (response) {
                        App.Notifier.show('ARTICLE_REMOVED', App.MessageType.success);
                        return true;
                    }).error(function (err) {
                        App.Notifier.show(err.message || "ERROR_OCCURRED", App.MessageType.error);
                        return false;
                    });
                }
                else if (action == App.FeedElementActionsEnum.RemoveElementFromSubjects) {
                    return _this.$http.post('/request/remove_element_from_subjects', { ids: _this.generateStringFromArray(elementsList), subjectIds: groupId.join() })
                        .success(function (response) {
                        App.Notifier.show('ARTICLE_REMOVED', App.MessageType.success);
                        return true;
                    }).error(function (err) {
                        App.Notifier.show(err.message || "ERROR_OCCURRED", App.MessageType.error);
                        return false;
                    });
                }
            };
            this.getAnalyzeDetailsBySubject = function (item) {
                var requestURL = item.post ? "/request/get_post_analyze_details_by_subject" : "/request/get_analyze_details_by_subject";
                return App.HttpRequester.post(requestURL, { id: item.elementId })
                    .then(function (response) {
                    return response;
                });
            };
            this.getAnalyzeDetailsBySelectedArticle = function (elementsList, feedType) {
                var requestURL = (feedType === App.NewsFeedTypeEnum.SocialMedia) ? "/request/get_post_analyze_details_by_selected_articles" : "/request/get_analyze_details_by_selected_articles";
                return App.HttpRequester.post(requestURL, { ids: _this.generateStringFromArray(elementsList) })
                    .then(function (response) {
                    return response;
                });
            };
            // private generateAnalyzeDetails = (response: any): Array<IAnalyzeDetail> => {
            //     let generatedAnalyzeDetails: IAnalyzeDetail[] = [];
            //
            //     for (let detail of response.details) {
            //         detail.tags =_.map(_.filter(response.tags, (tag: any) => { return tag.subject_id == detail.subject_id}), (item) => {
            //              if(item.subject_id == detail.subject_id) {
            //                  return [item.tag]
            //             }
            //         });
            //         generatedAnalyzeDetails.push(detail);
            //     }
            //
            //     return generatedAnalyzeDetails;
            // };
            this.analyzeFeedElementBySubject = function (analyzeData) {
                var requestURL = analyzeData.post_id ? "/request/save_post_analyze_details" : "/request/save_analyze_details";
                return App.HttpRequester.post(requestURL, { details: analyzeData })
                    .then(function () {
                    return true;
                }).catch(function (err) {
                    App.Notifier.show(err.message, App.MessageType.error);
                    return false;
                });
            };
            this.analyzeFeedElementBySelectedArticles = function (analyzeData, analyzeList, feedType) {
                var requestURL = (feedType === App.NewsFeedTypeEnum.MassMedia) ? "/request/save_analyze_details_by_articles" : "/request/save_post_analyze_details_by_articles";
                return App.HttpRequester.post(requestURL, { ids: _this.generateStringFromArray(analyzeList), details: analyzeData })
                    .then(function () {
                    return true;
                }).catch(function (err) {
                    App.Notifier.show(err.message, App.MessageType.error);
                    return false;
                });
            };
            this.generateStringFromArray = function (arr, property) {
                if (property === void 0) { property = "id"; }
                var result = [];
                arr.forEach(function (item) {
                    result.push(item[property]);
                });
                return result.join();
            };
            this.getFilterById = function (id) {
                return App.HttpRequester.post("/request/get_user_filter_by_id", { id: id })
                    .then(function (response) {
                    var filter = new App.NewsFilter();
                    filter.id = response.id;
                    filter.name = response.name;
                    filter.start_date = response.start_date;
                    filter.end_date = response.end_date;
                    filter.project = response.project;
                    filter.subject = response.subjects && response.subjects.split(",");
                    filter.mass_media_type = response.mass_media_type;
                    filter.mass_media = response.mass_medias && response.mass_medias.split(",");
                    filter.press_event = response.press_event;
                    filter.rated = response.rated;
                    filter.attitude = response.attitude;
                    filter.mass_media_rating = response.mass_media_rating;
                    filter.reference_type = response.reference_type;
                    return filter;
                }).catch(function (err) {
                    App.Notifier.show(err.message, App.MessageType.error);
                    return null;
                });
            };
            this.getAttitudeDatasourse = function () {
                return App.HttpRequester.post("/request/get_attitudes", null)
                    .then(function (response) {
                    return response;
                });
            };
            this.getCountriesDatasourse = function () {
                return App.HttpRequester.post("/request/get_countries_for_combo", null)
                    .then(function (response) {
                    return response;
                });
            };
        }
        /*        public getNewsFeedList = (requestParameter: IRequestParameterModel): ng.IPromise<IFeedCardElement[]> => {
                    var defer = this.$q.defer<IFeedCardElement>();
                    var ret: IFeedCardElement[] = this.dummyData;
                    defer.resolve(<IFeedCardElement>ret);
                    return defer.promise;
                }*/
        NewsFeedService.prototype.getSubjectsBySelectedArticles = function (selectedElements) {
            return App.HttpRequester.post("/request/get_common_subjects", { id: this.generateStringFromArray(selectedElements) })
                .then(function (response) {
                return response;
            });
        };
        NewsFeedService.id = "newsFeedService";
        return NewsFeedService;
    }());
    App.NewsFeedService = NewsFeedService;
    angular.module(App.Module)
        .service(NewsFeedService.id, NewsFeedService);
})(App || (App = {}));

var App;
(function (App) {
    (function (MessageType) {
        MessageType[MessageType["info"] = 1] = "info";
        MessageType[MessageType["success"] = 2] = "success";
        MessageType[MessageType["warning"] = 3] = "warning";
        MessageType[MessageType["error"] = 4] = "error";
    })(App.MessageType || (App.MessageType = {}));
    var MessageType = App.MessageType;
    var Notifier = (function () {
        /*@ngInject*/
        function Notifier(growl) {
            this.growl = growl;
            Notifier.growl = growl;
            Notifier.show = this.show;
        }
        Notifier.prototype.show = function (message, messageTypeIndex) {
            if (angular.isUndefined(messageTypeIndex)) {
                messageTypeIndex = 1;
            }
            if (angular.isUndefined(message) && messageTypeIndex == 4) {
                message = "Error Occurred";
            }
            Notifier.growl[MessageType[messageTypeIndex]](message);
        };
        Notifier.id = "notifierService";
        return Notifier;
    }());
    App.Notifier = Notifier;
    angular.module(App.Module)
        .service(Notifier.id, Notifier);
})(App || (App = {}));

var App;
(function (App) {
    var ProjectService = (function () {
        /*@ngInject*/
        function ProjectService($q, $filter) {
            this.$q = $q;
            this.$filter = $filter;
            this.saveProject = function (project) {
                return App.HttpRequester.post("/request/save_project", { project: project });
            };
            this.saveSubject = function (subject) {
                return App.HttpRequester.post("/request/save_subject", { subject: subject });
            };
            this.saveKeyword = function (keyword) {
                return App.HttpRequester.post("/request/save_keyword", { keyword: keyword });
            };
            this.setProjectToParentObject = function (projectId, parentObjectId, className) {
                return App.HttpRequester.post("/request/set_project_to_parent_object", { projectId: projectId, parentObjectId: parentObjectId, className: className })
                    .then(function (response) {
                    return true;
                }).catch(function (err) {
                    return false;
                });
            };
            this.removeProjectFromParentObject = function (rowId, className) {
                return App.HttpRequester.post("/request/remove_project_from_parent_object", { rowId: rowId, className: className })
                    .then(function (response) {
                    return true;
                }).catch(function (err) {
                    return false;
                });
            };
        }
        ProjectService.id = "projectService";
        return ProjectService;
    }());
    App.ProjectService = ProjectService;
    angular.module(App.Module)
        .service(ProjectService.id, ProjectService);
})(App || (App = {}));

var App;
(function (App) {
    var SocialMediaService = (function () {
        /*@ngInject*/
        function SocialMediaService($q, $http, configurationService) {
            var _this = this;
            this.$q = $q;
            this.$http = $http;
            this.configurationService = configurationService;
            this.saveEmojies = function (post) {
                return App.HttpRequester.post("/request/save_emojies", post)
                    .then(function () {
                    return true;
                }).catch(function (err) {
                    App.Notifier.show(err.message, App.MessageType.error);
                    return false;
                });
            };
            this.changeReadState = function (postList, state) {
                return App.HttpRequester.post("/request/change_post_read_state", { state: state, posts: _this.generateStringFromArray(postList) })
                    .then(function () {
                    return true;
                }).catch(function (err) {
                    App.Notifier.show(err.message, App.MessageType.error);
                    return false;
                });
            };
            this.getPostList = function (requestParameter) {
                return App.HttpRequester.post("/request/get_social_media_feed", requestParameter)
                    .then(function (res) {
                    return res;
                });
            };
            this.saveSocialmediaAccount = function (social_account) {
                return App.HttpRequester.post("/request/save_social_media_account", social_account)
                    .then(function () {
                    return true;
                }).catch(function (err) {
                    App.Notifier.show(err.message, App.MessageType.error);
                    return false;
                });
            };
            this.savePost = function (post) {
                return App.HttpRequester.post("/request/save_post", post)
                    .then(function () {
                    return true;
                }).catch(function (err) {
                    App.Notifier.show(err.message, App.MessageType.error);
                    return false;
                });
            };
            this.generateStringFromArray = function (arr, property) {
                if (property === void 0) { property = "id"; }
                var result = [];
                arr.forEach(function (item) {
                    result.push(item[property]);
                });
                return result.join();
            };
            this.removePosts = function (elementsList, action, groupId) {
                var requestUrl = action == App.FeedElementActionsEnum.RemoveElementFromProject ? "/request/remove_post_from_project" : "/request/remove_post_from_subjects";
                var requestParameter = action == App.FeedElementActionsEnum.RemoveElementFromProject
                    ? { ids: _this.generateStringFromArray(elementsList), projectId: groupId }
                    : { ids: _this.generateStringFromArray(elementsList), subjectIds: groupId.join() };
                return _this.$http.post(requestUrl, requestParameter)
                    .then(function () {
                    App.Notifier.show('ARTICLE_REMOVED', App.MessageType.success);
                    return true;
                }).catch(function (err) {
                    App.Notifier.show(err.message, App.MessageType.error);
                    return false;
                });
            };
            this.savePostFilter = function (filter) {
                var convertedFilter = angular.copy(filter);
                convertedFilter.subject = convertedFilter.subject && convertedFilter.subject.join();
                convertedFilter.social_media_accounts = convertedFilter.social_media_accounts && convertedFilter.social_media_accounts.join();
                return App.HttpRequester.post("/request/save_post_filter", convertedFilter)
                    .then(function () {
                    return true;
                }).catch(function (err) {
                    App.Notifier.show(err.message, App.MessageType.error);
                    return false;
                });
            };
            this.getPostFilterById = function (id) {
                return App.HttpRequester.post("/request/get_post_filter_by_id", { id: id })
                    .then(function (response) {
                    var filter = new App.PostFilter();
                    filter.id = response.id;
                    filter.name = response.name;
                    filter.start_date = response.start_date;
                    filter.end_date = response.end_date;
                    filter.pattern = response.pattern;
                    filter.project = response.project;
                    filter.subject = response.subjects && response.subjects.split(",");
                    filter.social_media_account_type = response.social_media_type;
                    filter.social_media_accounts = response.social_medias && response.social_medias.split(",");
                    return filter;
                }).catch(function (err) {
                    App.Notifier.show(err.message, App.MessageType.error);
                    return null;
                });
            };
            this.getSocialMediaPostBYId = function (id) {
                return App.HttpRequester.post("/request/get_social_media_post_by_id", { id: id })
                    .then(function (res) {
                    return res;
                });
            };
        }
        SocialMediaService.prototype.getSubjectsBySelectedPosts = function (selectedElements) {
            return App.HttpRequester.post("/request/get_post_common_subjects", { id: this.generateStringFromArray(selectedElements) })
                .then(function (res) {
                return res;
            });
        };
        ;
        SocialMediaService.id = "socialMediaService";
        return SocialMediaService;
    }());
    App.SocialMediaService = SocialMediaService;
    angular.module(App.Module)
        .service(SocialMediaService.id, SocialMediaService);
})(App || (App = {}));

var App;
(function (App) {
    var TranslationService = (function () {
        function TranslationService() {
        }
        Object.defineProperty(TranslationService.prototype, "language", {
            get: function () {
                return this._language;
            },
            set: function (language) {
                this._language = language;
            },
            enumerable: true,
            configurable: true
        });
        TranslationService.id = "translationService";
        return TranslationService;
    }());
    angular.module(App.Module)
        .service(TranslationService.id, TranslationService);
})(App || (App = {}));

var App;
(function (App) {
    var Utilities = (function () {
        /*@ngInject*/
        function Utilities($http) {
            this.$http = $http;
            App.HttpRequester.http = $http;
        }
        Utilities.base64toBlob = function (base64Data, contentType, sliceSize) {
            contentType = contentType || '';
            sliceSize = sliceSize || 512;
            var byteCharacters = atob(base64Data);
            var byteArrays = [];
            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);
                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }
            var blob = new Blob(byteArrays, { type: contentType });
            return blob;
        };
        Utilities.getFiltersDescription = function (filters, options) {
            var filtersDescriptions = "";
            var label;
            var value = "";
            var arr = [];
            var objIndex;
            for (var key in filters) {
                value = "";
                label = options.fields[key].label;
                if (filters[key] != null) {
                    if (Array.isArray(filters[key])) {
                        var s = options.fields[key].source;
                        value = this.getDescriptionsFromArrayByIds(filters[key], s);
                    }
                    else if (key.indexOf("date") != -1) {
                        var d = filters[key];
                        value = d.getDate().toString() + "/" + (d.getMonth() + 1).toString() + "/" + d.getFullYear().toString();
                    }
                    else if (typeof filters[key] === 'object') {
                        value = filters[key].description;
                    }
                    else {
                        value = filters[key].toString();
                    }
                }
                objIndex = this.getObjectIndexInArrayByProperty(label, "description", arr);
                if (objIndex == -1) {
                    var obj;
                    if (options.fields[key].label != "") {
                        obj = { description: label, firstValue: value };
                    }
                    else {
                        obj = { description: label, firstValue: "", secondValue: value };
                    }
                    if (options.fields[key].range) {
                        obj.range = true;
                    }
                    arr.push(obj);
                }
                else {
                    if (options.fields[key].label != "") {
                        arr[objIndex].firstValue = value;
                    }
                    else {
                        arr[objIndex].secondValue = value;
                    }
                }
            }
            for (var i = 0; i < arr.length; ++i) {
                if (arr[i].firstValue && arr[i].secondValue) {
                    filtersDescriptions += arr[i].description + ": " + (arr[i].range ? "(>=)" : "") + arr[i].firstValue + " - " + (arr[i].range ? "(<=)" : "") + arr[i].secondValue + ", ";
                }
                else if (arr[i].firstValue) {
                    filtersDescriptions += arr[i].description + ": " + (arr[i].range ? "(>=)" : "") + arr[i].firstValue + ", ";
                }
                else if (arr[i].secondValue) {
                    filtersDescriptions += arr[i].description + ": " + (arr[i].range ? "(<=)" : "") + arr[i].secondValue + ", ";
                }
            }
            return filtersDescriptions.substr(0, filtersDescriptions.length - 2);
        };
        Utilities.getDescriptionsFromArrayByIds = function (ids, source) {
            var descriptions = "";
            for (var i = 0; i < ids.length; ++i) {
                var result = $.grep(source, function (e) { return e["id"] == ids[i]; });
                descriptions += result[0]["description"] + (i == ids.length - 1 ? "" : ", ");
            }
            return descriptions;
        };
        Utilities.getObjectIndexInArrayByProperty = function (value, property, arr) {
            var index = -1;
            for (var i = 0; i < arr.length; ++i) {
                if (arr[i][property] == value) {
                    return i;
                }
            }
            return index;
        };
        Utilities.changeCreatedExcel = function (workbook, filtersDescription, reportDescription) {
            var sheet = workbook.sheets[0];
            var alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            sheet.freezePane.rowSplit = 0;
            for (var i = 0; i < 3; ++i) {
                sheet.rows.unshift({});
            }
            sheet.mergedCells = ["A1:" + alphabet.substr(sheet.columns.length - 1, 1) + "1", "A2:" + alphabet.substr(sheet.columns.length - 1, 1) + "2"];
            sheet.rows[0].cells = [{ value: reportDescription, hAlign: "center" }];
            sheet.rows[1].cells = [{ value: filtersDescription, hAlign: "center" }];
            sheet.columns[0].autoWidth = false;
            sheet.columns[0].width = 200;
        };
        Utilities.id = "utilitiesService";
        Utilities.generateHtmlFromSelectedArticles = function (selectedArticles, filter) {
            var html = "\n                <style>\n                    @media print { body { -webkit-print-color-adjust: exact; } }\n                    .item{display:flex; }\n                    img {width:30px; height:30px, margin-top: 15px; margin-right:10px}\n                    p {margin: 0;}\n                    .title{margin-right:10px; margin-bottom: 10px}\n                    .fb_button{color:#fff;  border-radius: 50%; border:1px solid #47598D ; font-weight: bold; cursor:pointer; background-color:#47598D}\n                    .fb_link{margin-left:15px; }\n                </style>";
            var arr = angular.copy(selectedArticles);
            arr.sort(function (a, b) {
                var nameA = a.selected_article.mass_media.name.toUpperCase(); // ignore upper and lowercase
                var nameB = b.selected_article.mass_media.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
            var index = 0;
            for (var i = 0; i < arr.length; ++i) {
                index++;
                html += "<div class=\"item\">";
                html += "<img src=\"imgs/massMediaLogos/svg/" + arr[i].selected_article.mass_media.name + ".svg\"/>";
                html += "<h4>" + index + ". " + arr[i].selected_article.mass_media.name + " - " + filter("date")(arr[i].selected_article.publish_datetime, "dd/MM/yyyy HH:mm") + "</h4>";
                html += "</div>";
                for (var j = 0; j < arr[i].articles.length; ++j) {
                    var article = arr[i].articles[j];
                    html += "<a href=\"" + article.url + "\" class=\"title\">" + article.title + "</a>";
                    html += article.fb_link ? "  |  <a  ng-if=\"article.fb_link\" href=\"" + article.fb_link + "\" class=\"fb_link\"><button class=\"fb_button\">f</button></a><br> " : "<br>";
                    html = "<div class=\"title\">" + html + "</div>";
                }
            }
            return html;
        };
        Utilities.generateHtmlFromSelectedPosts = function (selectedPosts, filter) {
            var html = "\n                <style>\n                \t@media print { body { -webkit-print-color-adjust: exact; } }\n                    .content{margin-right:10px; margin-bottom: 10px;  text-decoration: none;}\n                </style>";
            var arr = angular.copy(selectedPosts);
            var index = 0;
            for (var i = 0; i < arr.length; ++i) {
                index++;
                html += "<h4>" + index + "." + (arr[i].social_media_account_type_id == 1 ? 'Պրոֆիլ ' : 'Էջ ') + " " + arr[i].social_media_account_name + " - " + filter("date")(arr[i].publish_datetime, "dd/MM/yyyy HH:mm") + "</h4>";
                html += "<a href=\"" + arr[i].url + "\" class=\"content\">" + (arr[i].content.length > 50 ? filter("limitTo")(arr[i].content, 50) + '...' : arr[i].content) + "</a>";
            }
            return html;
        };
        Utilities.getStartDateByProject = function (id) {
            return App.HttpRequester.post("/request/get_project_start_date", { id: id })
                .then(function (response) {
                return response;
            });
        };
        return Utilities;
    }());
    App.Utilities = Utilities;
    ;
    angular.module(App.Module)
        .service(Utilities.id, Utilities);
})(App || (App = {}));

var App;
(function (App) {
    'use strict';
    var BaseController = (function () {
        /*@ngInject*/
        function BaseController() {
            var _this = this;
            this.setListMode = function () {
                _this.viewMode = App.ViewMode.List;
            };
            this.setViewMode = function () {
                _this.viewMode = App.ViewMode.View;
            };
            this.setCreateMode = function () {
                _this.viewMode = App.ViewMode.Create;
            };
            this.setEditMode = function () {
                _this.viewMode = App.ViewMode.Edit;
            };
        }
        Object.defineProperty(BaseController.prototype, "isListMode", {
            get: function () {
                return this.viewMode == App.ViewMode.List;
            },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(BaseController.prototype, "isViewMode", {
            get: function () {
                return this.viewMode == App.ViewMode.View;
            },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(BaseController.prototype, "isCreateMode", {
            get: function () {
                return this.viewMode == App.ViewMode.Create;
            },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(BaseController.prototype, "isEditMode", {
            get: function () {
                return this.viewMode == App.ViewMode.Edit;
            },
            enumerable: true,
            configurable: true
        });
        BaseController.id = "baseController";
        return BaseController;
    }());
    App.BaseController = BaseController;
    angular.module(App.Module)
        .controller(BaseController.id, BaseController);
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    'use strict';
    var AnalysisController = (function (_super) {
        __extends(AnalysisController, _super);
        /*@ngInject*/
        function AnalysisController() {
            _super.call(this);
            this.newsFeedTypeEnum = App.NewsFeedTypeEnum;
        }
        AnalysisController.id = "analysisController";
        return AnalysisController;
    }(App.BaseController));
    App.AnalysisController = AnalysisController;
    angular.module(App.Module)
        .controller(AnalysisController.id, AnalysisController);
})(App || (App = {}));

var App;
(function (App) {
    'use strict';
    var CatalogController = (function () {
        /*@ngInject*/
        function CatalogController($http, $mdDialog) {
            this.$http = $http;
            this.$mdDialog = $mdDialog;
            this.catalog = {};
            this.mainGridOptions = {
                toolbar: [
                    {
                        name: "ADD",
                        template: '<span flex></span><md-button ng-if="app.controlVisibility(\'edit_item_in_catalog\')" class="md-icon-button custom-cell-button"  ng-click="vm.addDescription($event)"><md-tooltip><span data-translate="ADD"></span></md-tooltip><md-icon md-svg-icon="content:add_circle" aria-label="..." style="color:white"></md-icon></md-button>'
                    }
                ],
                dataSource: {
                    transport: {
                        read: {
                            url: "",
                            dataType: "json",
                            type: "POST"
                        },
                    },
                    schema: {
                        model: {
                            fields: {
                                id: { type: "number" },
                                description: { validation: { required: true }, type: "string" },
                                parent_id: { type: "number" }
                            }
                        }
                    },
                    pageSize: 20
                },
                sortable: true,
                pageable: true,
                resizable: true,
                reorderable: true,
                columns: [
                    {
                        field: "description",
                        title: "DESCRIPTION",
                        width: "120px"
                    },
                    {
                        command: [
                            {
                                name: "edit",
                                template: '<md-button ng-if="app.controlVisibility(\'edit_item_in_catalog\')" class="md-icon-button custom-cell-button" ng-click="vm.editDescription($event)" aria-label="Edit"><md-tooltip><span data-translate="EDIT"></span></md-tooltip><md-icon md-svg-icon="editor:mode_edit" aria-label="..."></md-icon></md-button>',
                            }
                        ],
                        width: "10px"
                    }
                ]
            };
            this.init();
        }
        CatalogController.prototype.mainDialogOptions = function (event, item) {
            var _this = this;
            var dialog_options = {
                title: "EDIT_CATALOG",
                size: "small",
                buttons: {
                    acceptBtn: {
                        text: "SAVE",
                        btnClass: 'btn-primary',
                        disableIfInvalid: true,
                        fnc: function () {
                            _this.$http.post("/request/save_catalog", { id: dialog_options.item.id, description: dialog_options.item.description, parentId: dialog_options.item.parentObject && dialog_options.item.parentObject.id, catalogId: _this.selected_catalog["id"] }).success(function () {
                                _this.mainGrid.dataSource.read();
                                _this.mainGrid.refresh();
                                _this.$mdDialog.hide();
                                App.Notifier.show("CHANGES_SAVED", App.MessageType.success);
                            }).error(function (err) {
                                if (err) {
                                    App.Notifier.show(err.message, App.MessageType.error);
                                }
                            });
                        }
                    },
                    cancelBtn: {
                        text: "CANCEL",
                        btnClass: 'btn-default',
                        fnc: function () {
                            _this.$mdDialog.hide();
                        }
                    }
                },
                item: item,
                item_options: {
                    fields: {
                        id: { type: "number", label: "ID", readonly: true },
                        description: { type: "text", label: "DESCRIPTION", required: function () { return true; } },
                        parentObject: { type: "combo", label: "PARENT", source_url: this.selected_catalog["parent_url"], isNotVisible: function (item) { return !_this.selected_catalog["parent_url"]; }, get: function (item) { return item; }, required: function () { return true; }, description: "name" }
                    },
                    rows: [
                        [{ field: "description", col_class: "col-md-12" }],
                        [{ field: "parentObject", col_class: "col-md-6" }],
                    ]
                }
            };
            this.$mdDialog.show({
                template: '<dialog-box options="options"></dialog-box>',
                parent: angular.element(document.body),
                targetEvent: event || false,
                locals: { options: dialog_options },
                controller: ['$scope', 'options', function ($scope, options) { return $scope.options = options; }]
            }).then(function () {
                _this.mainGrid.dataSource.read();
                _this.mainGrid.refresh();
            });
        };
        CatalogController.prototype.addDescription = function ($event) {
            if (!this.selected_catalog) {
                App.Notifier.show("CHOOSE_CATALOG_TYPE", App.MessageType.info);
            }
            else {
                this.mainDialogOptions($event, {});
            }
        };
        CatalogController.prototype.editDescription = function ($event) {
            var sender = $event.currentTarget;
            var row = angular.element(sender).closest("tr");
            var dataItem = this.mainGrid.dataItem(row);
            angular.extend(dataItem, { parentObject: { id: dataItem["parent_id"], description: "" } });
            this.mainDialogOptions($event, angular.copy(dataItem));
        };
        CatalogController.prototype.init = function () {
            var _this = this;
            this.$http.post("/request/get_catalog", '').success(function (res) {
                _this.catalog = res;
            }).error(function (err) {
                if (err) {
                    console.log(err.message);
                }
            });
        };
        CatalogController.prototype.viewCatalog = function (selected_catalog) {
            this.selected_catalog = selected_catalog;
            if (selected_catalog.url) {
                this.mainGridOptions.dataSource.transport.read["url"] = selected_catalog.url;
                this.mainGrid.dataSource.read();
                this.mainGrid.refresh();
            }
        };
        CatalogController.id = "catalogController";
        return CatalogController;
    }());
    App.CatalogController = CatalogController;
    angular.module(App.Module)
        .controller(CatalogController.id, CatalogController);
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    'use strict';
    var CustomerController = (function (_super) {
        __extends(CustomerController, _super);
        /*@ngInject*/
        function CustomerController($filter) {
            _super.call(this);
            this.$filter = $filter;
            if (!this.viewMode) {
                this.setListMode();
            }
        }
        CustomerController.id = "customerController";
        return CustomerController;
    }(App.BaseController));
    App.CustomerController = CustomerController;
    angular.module(App.Module)
        .controller(CustomerController.id, CustomerController);
})(App || (App = {}));

var App;
(function (App) {
    'use strict';
    var ErrorController = (function () {
        /*@ngInject*/
        function ErrorController(loggerFactory, $rootElement) {
            this.loggerFactory = loggerFactory;
            this.$rootElement = $rootElement;
            this._logger = loggerFactory(ErrorController.id);
            this._logger.info("ctor", "init");
            $rootElement.addClass("error");
        }
        ErrorController.id = "errorController";
        return ErrorController;
    }());
    App.ErrorController = ErrorController;
    angular.module(App.Module)
        .controller(ErrorController.id, ErrorController);
})(App || (App = {}));

var App;
(function (App) {
    'use strict';
    var HomeController = (function () {
        /*@ngInject*/
        function HomeController($http, $state, authenticationService) {
            this.$http = $http;
            this.$state = $state;
            this.authenticationService = authenticationService;
            this.navItems = [
                {
                    title: "HOME",
                    href: "home",
                    id: "first"
                },
                {
                    title: "ABOUT",
                    href: "about",
                    id: ""
                },
                {
                    title: "SERVICES",
                    href: "services",
                    id: ""
                },
                {
                    title: "CONTACT",
                    href: "contact",
                    id: ""
                },
            ];
            this.services = [
                {
                    title: "SERVICE_2",
                    contents: ["SERVICE_2_2", "SERVICE_2_3"]
                },
                {
                    title: "SERVICE_1",
                    contents: ["SERVICE_1_2", "SERVICE_1_3", "SERVICE_1_4", "SERVICE_1_5", "SERVICE_1_6", "SERVICE_1_7"]
                },
                {
                    title: "SERVICE_3",
                    contents: ["SERVICE_3_2", "SERVICE_3_3"]
                }];
            this.username = "";
            this.password = "";
            this.err_message = "";
            $(window).scroll(function () {
                var windscroll = $(window).scrollTop();
                if (windscroll >= 100) {
                    $('#container').find('section').each(function (i) {
                        if ($(this).position().top <= windscroll + 80) {
                            $('#nav-menu ').find('a.active').removeClass('active');
                            $('#nav-menu').find('a').eq(i).addClass('active');
                        }
                    });
                }
                else {
                    $('#nav-menu').removeClass('fixed');
                    $('#nav-menu').find('a.active').removeClass('active');
                    $('#nav-menu').find('a:first').addClass('active');
                }
            }).scroll();
        }
        HomeController.prototype.login = function () {
            var _this = this;
            if (this.username == "" || this.password == "") {
                this.err_message = "Fill all fields";
            }
            this.authenticationService.loginUser(this.username, this.password, function (err) {
                if (_this.authenticationService.isAuthorized()) {
                    _this.$state.go("layout.newsFeed");
                }
                else if (err) {
                    _this.err_message = err.message;
                }
            });
        };
        HomeController.prototype.scrollToSection = function (index) {
            var scrollAnchor = this.navItems[index].href, scrollPoint = $('section[data-anchor="' + scrollAnchor + '"]').offset().top - 50;
            $('body,html').animate({
                scrollTop: scrollPoint
            }, 500);
            return false;
        };
        HomeController.id = "homeController";
        return HomeController;
    }());
    App.HomeController = HomeController;
    angular.module(App.Module)
        .controller(HomeController.id, HomeController);
})(App || (App = {}));

var App;
(function (App) {
    'use strict';
    var LayoutController = (function () {
        /*@ngInject*/
        function LayoutController(config, authenticationService, $state, $mdDialog, $http, globals, configurationService) {
            this.config = config;
            this.authenticationService = authenticationService;
            this.$state = $state;
            this.$mdDialog = $mdDialog;
            this.$http = $http;
            this.globals = globals;
            this.configurationService = configurationService;
            this.sections = [
                //{id: 100, name: "DASHBOARD", icon: "glyphicon glyphicon-home", url: "dashboard"},
                { id: 110, name: "NEWS_FEED", icon: "icon icon-mm-newsfeed", url: "news_feed" },
                { id: 120, name: "ANALYSIS", icon: "icon icon-mm-analysys", url: "analysis" },
                { id: 280, name: "SOCIAL_MEDIA", icon: "icon icon-facebook", url: "social_media" },
                { id: 300, name: "SOCIAL_MEDIA_ANALYSIS", icon: "icon icon-mm-facebook", url: "social_media_analysis" },
                { id: 230, name: "PROJECTS", icon: "icon icon-folder-open", url: "project" },
                { id: 220, name: "CUSTOMERS", icon: "icon icon-clent-meetup", url: "customer" },
                { id: 250, name: "MASS_MEDIA", icon: "icon icon-mm-mass-media", url: "mass_media" },
                { id: 270, name: "PRESS_EVENT", icon: "icon icon-mm-press-event", url: "press_event" },
                { id: 290, name: "SOCIAL_MEDIA_ACCOUNT", icon: "icon icon-user", url: "social_media_account" },
                { id: 260, name: "REPORTS", icon: "icon icon-mm-reports", url: "reports" },
                { id: 240, name: "CATALOG", icon: "icon icon-info-circle", url: "catalog" },
                { id: 200, name: "PERMISSION_GROUPS", icon: "icon icon-locked", url: "permission-groups" },
                { id: 210, name: "USERS", icon: "icon icon-user", url: "users" }
            ];
            this.user = this.authenticationService.user;
            this.setSectionsVisibility();
        }
        Object.defineProperty(LayoutController.prototype, "appTitle", {
            get: function () {
                return this.config.name;
            },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(LayoutController.prototype, "appVersion", {
            get: function () {
                return this.config.version;
            },
            enumerable: true,
            configurable: true
        });
        LayoutController.prototype.logOut = function () {
            var _this = this;
            this.authenticationService.logoutUser(function (err) {
                if (!_this.authenticationService.isAuthorized()) {
                    _this.$state.go("home");
                    clearInterval(_this.globals.events.feedUpdateInterval);
                }
                else {
                    err && alert(err.message);
                }
            });
        };
        LayoutController.prototype.changeUserPassword = function ($event) {
            var _this = this;
            var options = {
                title: "CHANGE_PASSWORD",
                event: $event,
                buttons: {
                    acceptBtn: {
                        text: "CHANGE",
                        disableIfInvalid: true,
                        fnc: function () {
                            _this.$http.post('request/change_user_password', options.item).success(function () {
                                App.Notifier.show("CHANGES_SAVED", App.MessageType.success);
                                _this.$mdDialog.cancel();
                            }).error(function (err) {
                                App.Notifier.show(err.message, App.MessageType.error);
                            });
                        }
                    },
                    cancelBtn: {
                        text: "CANCEL",
                        fnc: function () {
                            _this.$mdDialog.cancel();
                        }
                    }
                },
                item: {},
                item_options: {
                    fields: {
                        currentPassword: { type: "password", label: "CURRENT_PASSWORD", get: function (item) { return item; }, required: function () { return true; } },
                        newPassword: { type: "password", label: "NEW_PASSWORD", get: function (item) { return item; }, required: function () { return true; } },
                        newPasswordConfirm: { type: "password", label: "CONFIRM_NEW_PASSWORD", get: function (item) { return item; }, required: function () { return true; } }
                    },
                    rows: [
                        [{ field: "currentPassword", col_class: "col-md-9" }],
                        [{ field: "newPassword", col_class: "col-md-9" }],
                        [{ field: "newPasswordConfirm", col_class: "col-md-9" }]
                    ]
                },
                size: "small"
            };
            this.$mdDialog.show({
                template: '<dialog-box options="options"></dialog-box>',
                parent: angular.element(document.body),
                targetEvent: options.event || false,
                locals: { options: options },
                controller: ['$scope', 'options', function ($scope, options) { return $scope.options = options; }]
            });
        };
        LayoutController.prototype.setSectionsVisibility = function () {
            var section_permissions = this.authenticationService.user.sectionPermissions;
            for (var k in this.sections) {
                this.sections[k].visible = section_permissions.indexOf(this.sections[k].id) != -1;
            }
        };
        LayoutController.id = "layoutController";
        return LayoutController;
    }());
    App.LayoutController = LayoutController;
    angular.module(App.Module)
        .controller(LayoutController.id, LayoutController);
})(App || (App = {}));

var App;
(function (App) {
    'use strict';
    var LoginController = (function () {
        /*@ngInject*/
        function LoginController($http, $state, authenticationService) {
            this.$http = $http;
            this.$state = $state;
            this.authenticationService = authenticationService;
            this.username = "";
            this.password = "";
            this.err_message = "";
        }
        LoginController.prototype.login = function () {
            var _this = this;
            if (this.username == "" || this.password == "") {
                this.err_message = "Fill all fields";
            }
            this.authenticationService.loginUser(this.username, this.password, function (err) {
                if (_this.authenticationService.isAuthorized()) {
                    _this.$state.go("layout.newsFeed");
                }
                else if (err) {
                    _this.err_message = err.message;
                }
            });
        };
        LoginController.id = "loginController";
        return LoginController;
    }());
    App.LoginController = LoginController;
    angular.module(App.Module)
        .controller(LoginController.id, LoginController);
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    'use strict';
    var MassMediaController = (function (_super) {
        __extends(MassMediaController, _super);
        /*@ngInject*/
        function MassMediaController($filter) {
            _super.call(this);
            this.$filter = $filter;
            if (!this.viewMode) {
                this.setListMode();
            }
        }
        MassMediaController.id = "massMediaController";
        return MassMediaController;
    }(App.BaseController));
    App.MassMediaController = MassMediaController;
    angular.module(App.Module)
        .controller(MassMediaController.id, MassMediaController);
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    'use strict';
    var NewsFeedController = (function (_super) {
        __extends(NewsFeedController, _super);
        /*@ngInject*/
        function NewsFeedController(authenticationService, eventManagerService, globals) {
            var _this = this;
            _super.call(this);
            this.authenticationService = authenticationService;
            this.eventManagerService = eventManagerService;
            this.globals = globals;
            this.filter = {};
            this.filterFunction = function () {
                _this.eventManagerService.publish(_this.globals.events.unSelectAllElements);
                _this.eventManagerService.publish(_this.globals.events.getNewsFeedList, 1);
            };
            this.clearFilters = function () {
                _this.filter = new App.NewsFilter();
                _this.filterFunction();
            };
        }
        ;
        NewsFeedController.id = "newsFeedController";
        return NewsFeedController;
    }(App.BaseController));
    App.NewsFeedController = NewsFeedController;
    angular.module(App.Module)
        .controller(NewsFeedController.id, NewsFeedController);
})(App || (App = {}));

var App;
(function (App) {
    'use strict';
    var PermissionGroupsController = (function () {
        /*@ngInject*/
        function PermissionGroupsController($http, $mdDialog, authenticationService) {
            this.$http = $http;
            this.$mdDialog = $mdDialog;
            this.authenticationService = authenticationService;
            this.permissionGroups = {};
            this.init();
        }
        PermissionGroupsController.prototype.controlVisibility = function (controlName) {
            if (this.authenticationService.user.controlPermissions.indexOf(controlName) != -1) {
                return true;
            }
            return false;
        };
        PermissionGroupsController.prototype.init = function () {
            var _this = this;
            this.$http.post('request/get_user_groups', null).success(function (res) {
                _this.permissionGroups = res;
            }).error(function (err) {
                App.Notifier.show(err.message, App.MessageType.error);
            });
        };
        PermissionGroupsController.prototype.showGroupPermissions = function (id) {
            var _this = this;
            this.$http.post('request/get_permissions_by_group/', { id: id }).success(function (res) {
                for (var p in res) {
                    res[p].status = res[p].status == 0 ? false : true;
                }
                _this.permissions = res;
            }).error(function (err) {
                App.Notifier.show(err.message, App.MessageType.error);
            });
        };
        PermissionGroupsController.prototype.savePermissions = function () {
            var arr = [];
            for (var i in this.permissions) {
                this.permissions[i].status && arr.push(this.permissions[i].id);
            }
            this.$http.post('request/save_user_group_permissions', { group_id: this.selectedGroup, permissions_ids: arr.join() }).success(function () {
                App.Notifier.show("Changes Saved", App.MessageType.success);
            }).error(function (err) {
                App.Notifier.show(err.message, App.MessageType.error);
            });
        };
        PermissionGroupsController.prototype.addPermissionGroup = function ($event) {
            var _this = this;
            var options = {
                title: "NEW_PERMISSION_GROUP",
                event: $event,
                buttons: {
                    acceptBtn: {
                        text: "SAVE",
                        btnClass: 'btn-primary',
                        disableIfInvalid: true,
                        fnc: function () {
                            _this.$http.post("/request/add_permission_group", { groupName: options.item["groupName"] }).success(function () {
                                _this.init();
                                App.Notifier.show("Changes Saved", App.MessageType.success);
                                _this.$mdDialog.cancel();
                            }).error(function (err) {
                                App.Notifier.show(err.message, App.MessageType.error);
                            });
                        }
                    },
                    cancelBtn: {
                        text: "CANCEL",
                        btnClass: 'mm-btn-link clr-default',
                        fnc: function () {
                            _this.$mdDialog.cancel();
                        }
                    }
                },
                item: {},
                item_options: {
                    fields: {
                        groupName: {
                            type: "text",
                            label: "GROUP_NAME",
                            get: function (item) { return item; },
                            required: function () { return true; }
                        },
                        permission_group_role: {
                            type: "combo",
                            label: "PERMISSION_GROUP_ROLE",
                            get: function (item) { return item; },
                            source_url: "/request/get_permission_group_roles",
                            description: "description",
                        }
                    },
                    rows: [
                        [{ field: "groupName", col_class: "col-md-9" }],
                        [{ field: "permission_group_role", col_class: "col-md-9" }]
                    ]
                },
                size: "small"
            };
            this.$mdDialog.show({
                template: '<dialog-box options="options"></dialog-box>',
                parent: angular.element(document.body),
                targetEvent: options.event || false,
                locals: { options: options },
                controller: ['$scope', 'options', function ($scope, options) { return $scope.options = options; }]
            });
        };
        PermissionGroupsController.id = "permissionGroupsController";
        return PermissionGroupsController;
    }());
    App.PermissionGroupsController = PermissionGroupsController;
    angular.module(App.Module)
        .controller(PermissionGroupsController.id, PermissionGroupsController);
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    'use strict';
    var PressEventController = (function (_super) {
        __extends(PressEventController, _super);
        /*@ngInject*/
        function PressEventController($filter, authenticationService, $mdDialog, globals, $http, eventManagerService) {
            var _this = this;
            _super.call(this);
            this.$filter = $filter;
            this.authenticationService = authenticationService;
            this.$mdDialog = $mdDialog;
            this.globals = globals;
            this.$http = $http;
            this.eventManagerService = eventManagerService;
            this.getFilteredData = function () {
                _this.eventManagerService.publish(_this.globals.events.refreshPressEventList, _this.filter);
            };
            this.showDialog = function ($event) {
                var options;
                _this.$mdDialog.show({
                    template: '<press-event-edit options="options"></press-event-edit>',
                    parent: angular.element(document.body),
                    targetEvent: $event || false,
                    locals: { options: options },
                    controller: ['$scope', 'options', function ($scope, options) { return $scope.options = options; }]
                });
            };
            this.clearFilters = function () {
                _this.filter = {};
                _this.getFilteredData();
            };
            this.controlVisibility = function (controlName) {
                return _this.authenticationService.user.controlPermissions.indexOf(controlName) != -1;
            };
            if (!this.viewMode) {
                this.setListMode();
            }
        }
        PressEventController.prototype.changeToView = function () {
            this.viewMode = App.ViewMode.View;
        };
        PressEventController.id = "pressEventController";
        return PressEventController;
    }(App.BaseController));
    App.PressEventController = PressEventController;
    angular.module(App.Module)
        .controller(PressEventController.id, PressEventController);
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    var ProjectController = (function (_super) {
        __extends(ProjectController, _super);
        /*@ngInject*/
        function ProjectController(projectService, authenticationService, $mdDialog, $http) {
            var _this = this;
            _super.call(this);
            this.projectService = projectService;
            this.authenticationService = authenticationService;
            this.$mdDialog = $mdDialog;
            this.$http = $http;
            this.statusDataSource = [{ id: 1, description: "Ակտիվ" }, { id: 2, description: "Պասիվ" }];
            this.initGridOptions = function () {
                _this.gridOptions = {
                    dataSource: {
                        transport: {
                            read: {
                                url: "/request/get_projects",
                                dataType: "json",
                                type: "POST"
                            }
                        },
                        pageSize: 20,
                    },
                    sortable: true,
                    pageable: true,
                    resizable: true,
                    filterable: true,
                    columnMenu: true,
                    columns: [
                        { field: "name", title: "NAME" },
                        { field: "status", title: "STATUS" },
                        { template: "<md-button ng-if=\"vm.controlVisibility('edit_project')\" class=\"md-icon-button custom-cell-button\" ng-click=\"vm.saveProject(dataItem, $event)\" aria-label=\"Edit\"><md-tooltip><span data-translate=\"EDIT\"></span></md-tooltip><md-icon md-svg-icon=\"editor:mode_edit\" aria-label=\"...\"></md-icon></md-button>\n\t\t\t\t\t\t\t\t<md-button ng-if=\"vm.controlVisibility('edit_project')\" class=\"md-icon-button custom-cell-button\" ng-click=\"vm.removeProject(dataItem)\" aria-label=\"Remove\"><md-tooltip><span data-translate=\"DELETE\"></span></md-tooltip><md-icon md-svg-icon=\"action:delete\" aria-label=\"...\"></md-icon></md-button>", width: 150 }
                    ]
                };
            };
            this.getSubjectGridOptions = function (projectId) {
                return {
                    dataSource: {
                        transport: {
                            read: {
                                url: "/request/get_subjects",
                                dataType: "json",
                                type: "POST",
                                data: { id: projectId }
                            }
                        },
                        pageSize: 10,
                    },
                    sortable: true,
                    pageable: true,
                    resizable: true,
                    filterable: false,
                    columnMenu: false,
                    columns: [
                        { field: "name", title: "{{'NAME' | translate}}" },
                        { field: "status", title: "{{'STATUS' | translate}}" },
                        { field: "rateable", title: "{{'RATEABLE' | translate}}", template: "<div class='full-width text-center' ng-init='dataItem.rateable=(dataItem.rateable ? true : false)'><input ng-model='dataItem.rateable' type='checkbox' disabled></input></div>", width: 200 },
                        { template: "<md-button ng-if=\"vm.controlVisibility('edit_subject')\" class=\"md-icon-button custom-cell-button\" ng-click=\"vm.saveSubject(dataItem, null, $event)\" aria-label=\"Edit\"><md-tooltip><span data-translate=\"EDIT\"></span></md-tooltip><md-icon md-svg-icon=\"editor:mode_edit\" aria-label=\"...\"></md-icon></md-button>\n\t\t\t\t\t\t\t\t<md-button ng-if=\"vm.controlVisibility('edit_subject')\" class=\"md-icon-button custom-cell-button\" ng-click=\"vm.removeSubject(dataItem)\" aria-label=\"Remove\"><md-tooltip><span data-translate=\"DELETE\"></span></md-tooltip><md-icon md-svg-icon=\"action:delete\" aria-label=\"...\"></md-icon></md-button>", width: 150 }
                    ]
                };
            };
            this.saveSubjectToDB = function (subjectObj) {
                if (subjectObj.existingSubject) {
                    subjectObj.id = subjectObj.existingSubject.id;
                }
                _this.projectService.saveSubject(subjectObj).then(function (b) {
                    if (!(b instanceof Error)) {
                        var grid = $("#subjectGrid" + subjectObj.project_id).data("kendoGrid");
                        grid.dataSource.read();
                        grid.refresh();
                        _this.$mdDialog.hide();
                    }
                });
            };
            this.getKeywordGridOptions = function (subjectId) {
                return {
                    dataSource: {
                        transport: {
                            read: {
                                url: "/request/get_keywords",
                                dataType: "json",
                                type: "POST",
                                data: { subject_id: subjectId }
                            }
                        },
                        pageSize: 20,
                    },
                    sortable: true,
                    pageable: true,
                    resizable: true,
                    filterable: true,
                    columnMenu: true,
                    columns: [
                        { field: "name", title: "{{'NAME' | translate}}" },
                        { field: "language_description", title: "{{'LANGUAGE' | translate}}" },
                        { field: "status", title: "{{'STATUS' | translate}}" },
                        { template: "<md-button ng-if=\"vm.controlVisibility('edit_keyword')\" class=\"md-icon-button custom-cell-button\" ng-click=\"vm.saveKeyword(dataItem, null, $event)\" aria-label=\"Edit\"><md-tooltip><span data-translate=\"EDIT\"></span></md-tooltip><md-icon md-svg-icon=\"editor:mode_edit\" aria-label=\"...\"></md-icon></md-button>\n\t\t\t\t\t\t\t\t<md-button ng-if=\"vm.controlVisibility('edit_keyword')\" class=\"md-icon-button custom-cell-button\" ng-click=\"vm.removeKeyword(dataItem)\" aria-label=\"Remove\"><md-tooltip><span data-translate=\"DELETE\"></span></md-tooltip><md-icon md-svg-icon=\"action:delete\" aria-label=\"...\"></md-icon></md-button>", width: 150 },
                    ]
                };
            };
            this.removeProject = function (project) {
                if (confirm("Հեռացնե՞լ նախագիծը։")) {
                    project.status = { id: 3 };
                    _this.projectService.saveProject(project).then(function (b) {
                        if (!(b instanceof Error)) {
                            _this.$mdDialog.hide();
                            _this.grid.dataSource.read();
                            _this.grid.refresh();
                        }
                    });
                }
            };
            this.removeSubject = function (subject) {
                if (confirm("Հեռացնե՞լ թեման։")) {
                    subject.status = { id: 3 };
                    _this.projectService.saveSubject(subject).then(function (b) {
                        if (!(b instanceof Error)) {
                            var grid = $("#subjectGrid" + subject.project_id).data("kendoGrid");
                            grid.dataSource.read();
                            grid.refresh();
                        }
                    });
                }
            };
            this.removeKeyword = function (keyword) {
                if (confirm("Հեռացնե՞լ բանալի բառը։")) {
                    keyword.status = { id: 3 };
                    keyword.language = { id: keyword.language };
                    _this.projectService.saveKeyword(keyword).then(function (b) {
                        if (!(b instanceof Error)) {
                            var grid = $("#keywordGrid" + keyword.subject_id).data("kendoGrid");
                            grid.dataSource.read();
                            grid.refresh();
                        }
                    });
                }
            };
            this.getMassMediaGridOptions = function (projectId) {
                return {
                    toolbar: [
                        {
                            name: "EDIT",
                            template: '<span flex></span><md-button ng-if="app.controlVisibility(\'edit_mass_media_list\')" class="md-icon-button custom-cell-button middle-icon"  ng-click="vm.editMassMediaList(dataItem.id, $event)"><md-tooltip><span data-translate="EDIT"></span></md-tooltip><md-icon md-svg-icon="editor:mode_edit" aria-label="..." class="edit-mass"></md-icon></md-button> '
                        }
                    ],
                    dataSource: {
                        transport: {
                            read: {
                                url: "/request/get_mass_media_by_project",
                                dataType: "json",
                                type: "POST",
                                data: { id: projectId }
                            }
                        },
                        pageSize: 10,
                    },
                    sortable: true,
                    pageable: true,
                    resizable: true,
                    filterable: true,
                    columnMenu: true,
                    columns: [
                        { field: "name", title: "{{'NAME' | translate}}" },
                        { field: "country", title: "{{'COUNTRY' | translate}}" }]
                };
            };
            this.editMassMediaList = function (projectId, $event) {
                _this.$http.post('request/get_mass_media_full_list_for_project/', { id: projectId }).success(function (res) {
                    for (var p in res) {
                        res[p].assigned = res[p].assigned == 0 ? false : true;
                    }
                    _this.$mdDialog.show({
                        template: $('#massMediaListPopupTemplate').html(),
                        parent: angular.element(document.body),
                        locals: { options: { massMediaList: res } },
                        controller: ['$scope', 'options', function ($scope, options) {
                                $scope.isChaked = false;
                                $scope.selectedCountry = "";
                                $scope.massMediaList = options.massMediaList;
                                $scope.cancel = function () {
                                    _this.$mdDialog.hide();
                                };
                                $scope.checkAll = function (isChecked) {
                                    for (var p in $scope.massMediaList) {
                                        if ($scope.selectedCountry && $scope.selectedCountry == $scope.massMediaList[p].country_id) {
                                            $scope.massMediaList[p].assigned = isChecked;
                                        }
                                        else if (!$scope.selectedCountry) {
                                            $scope.massMediaList[p].assigned = isChecked;
                                        }
                                    }
                                };
                                $scope.saveMassMediaList = function () {
                                    var arr = [];
                                    for (var i in $scope.massMediaList) {
                                        $scope.massMediaList[i].assigned && arr.push($scope.massMediaList[i].id);
                                    }
                                    _this.$http.post('request/save_project_mass_media', { project_id: projectId, mass_media_ids: arr.join() }).success(function () {
                                        App.Notifier.show("CHANGES_SAVED", App.MessageType.success);
                                        var grid = $("#massMediaGrid" + projectId).data("kendoGrid");
                                        grid.dataSource.read();
                                        grid.refresh();
                                        _this.$mdDialog.cancel();
                                    }).error(function (err) {
                                        App.Notifier.show(err.message, App.MessageType.error);
                                    });
                                };
                                $scope.filterMassMediaList = function (massMedia) {
                                    return $scope.selectedCountry == "" || massMedia.country_id == $scope.selectedCountry;
                                };
                            }]
                    });
                }).error(function (err) {
                    App.Notifier.show(err.message, App.MessageType.error);
                });
            };
            this.getSocialMediaAccountGridOptions = function (projectId) {
                return {
                    toolbar: [
                        {
                            name: "EDIT",
                            template: '<span flex></span><md-button ng-if="app.controlVisibility(\'add_social_media_account\')" class="md-icon-button custom-cell-button middle-icon"  ng-click="vm.editSocialMediaAccountList(dataItem.id, $event)"><md-tooltip><span data-translate="EDIT"></span></md-tooltip><md-icon md-svg-icon="editor:mode_edit" aria-label="..." class="edit-mass"></md-icon></md-button> '
                        }
                    ],
                    dataSource: {
                        transport: {
                            read: {
                                url: "/request/get_social_media_by_project",
                                dataType: "json",
                                type: "POST",
                                data: { id: projectId }
                            }
                        },
                        pageSize: 10,
                    },
                    sortable: true,
                    pageable: true,
                    resizable: true,
                    filterable: true,
                    columnMenu: true,
                    columns: [
                        { field: "name", title: "{{'SOCIAL_MEDIA_ACCOUNT_NAME' | translate}}" },
                        { field: "type", title: "{{'SOCIAL_MEDIA_ACCOUNT_TYPE' | translate}}" }]
                };
            };
            this.editSocialMediaAccountList = function (projectId, $event) {
                _this.$http.post('request/get_social_media_full_list_for_project/', { id: projectId }).success(function (res) {
                    for (var sm in res) {
                        res[sm].assigned = res[sm].assigned == 0 ? false : true;
                    }
                    _this.$mdDialog.show({
                        template: $('#socialMediaAccountListPopupTemplate').html(),
                        parent: angular.element(document.body),
                        locals: { options: { socialMediaAccountList: res } },
                        controller: ['$scope', 'options', function ($scope, options) {
                                $scope.isChaked = false;
                                $scope.selectedType = "";
                                $scope.socialMediaAccountList = options.socialMediaAccountList;
                                $scope.cancel = function () {
                                    _this.$mdDialog.hide();
                                };
                                $scope.checkAll = function (isChecked) {
                                    for (var sm in $scope.socialMediaAccountList) {
                                        if ($scope.selectedType && $scope.selectedType == $scope.socialMediaAccountList[sm].type_id) {
                                            $scope.socialMediaAccountList[sm].assigned = isChecked;
                                        }
                                        else if (!$scope.selectedType) {
                                            $scope.socialMediaAccountList[sm].assigned = isChecked;
                                        }
                                    }
                                };
                                $scope.saveSocialMediaAccountList = function () {
                                    var arr = [];
                                    for (var i in $scope.socialMediaAccountList) {
                                        $scope.socialMediaAccountList[i].assigned && arr.push($scope.socialMediaAccountList[i].id);
                                    }
                                    _this.$http.post('request/save_project_social_media_account', { project_id: projectId, social_media_account_ids: arr.join() }).success(function () {
                                        App.Notifier.show("CHANGES_SAVED", App.MessageType.success);
                                        var grid = $("#socialMediaAccountGrid" + projectId).data("kendoGrid");
                                        grid.dataSource.read();
                                        grid.refresh();
                                        _this.$mdDialog.cancel();
                                    }).error(function (err) {
                                        App.Notifier.show(err.message, App.MessageType.error);
                                    });
                                };
                                $scope.filterSocialMediaAccountList = function (socialMediaAccount) {
                                    return $scope.selectedType == "" || socialMediaAccount.type_id == $scope.selectedType;
                                };
                            }]
                    });
                }).error(function (err) {
                    App.Notifier.show(err.message, App.MessageType.error);
                });
            };
            this.controlVisibility = function (controlName) {
                return _this.authenticationService.user.controlPermissions.indexOf(controlName) != -1;
            };
            this.setListMode();
            this.initGridOptions();
        }
        ProjectController.prototype.saveProject = function (project, $event) {
            var _this = this;
            var projectCopy;
            if (project) {
                projectCopy = angular.copy(project);
                projectCopy.status = { id: projectCopy.status_id, name: projectCopy.status };
                projectCopy.updatesAvailable = project.updatesAvailable ? true : false;
            }
            else {
                projectCopy = new App.Project();
            }
            var dialog_options = {
                title: project ? "EDIT_PROJECT" : "ADD_PROJECT",
                size: "small",
                buttons: {
                    acceptBtn: {
                        text: "SAVE",
                        disableIfInvalid: true,
                        fnc: function () {
                            _this.projectService.saveProject(dialog_options.item).then(function (b) {
                                if (!(b instanceof Error)) {
                                    _this.$mdDialog.hide();
                                    _this.grid.dataSource.read();
                                    _this.grid.refresh();
                                }
                            });
                        }
                    },
                    cancelBtn: {
                        text: "CANCEL",
                        fnc: function () {
                            _this.$mdDialog.hide();
                        }
                    }
                },
                item: projectCopy,
                item_options: {
                    fields: {
                        name: { type: "text", label: "NAME", required: function () { return true; } },
                        status: { type: "combo", label: "STATUS", get: function (item) { return item; }, source: this.statusDataSource },
                        updatesAvailable: { type: "checkbox", label: "UPDATES_AVAILABLE" }
                    },
                    rows: [
                        [{ field: "name", col_class: "col-xs-10" }],
                        [{ field: "status", col_class: "col-xs-10" }],
                        [{ field: "updatesAvailable", col_class: "col-xs-10" }]
                    ]
                }
            };
            this.$mdDialog.show({
                template: '<dialog-box options="options"></dialog-box>',
                parent: angular.element(document.body),
                targetEvent: $event || false,
                locals: { options: dialog_options },
                controller: ['$scope', 'options', function ($scope, options) { return $scope.options = options; }]
            });
        };
        ProjectController.prototype.saveSubject = function (subject, projectId, $event) {
            var _this = this;
            var subjectCopy;
            if (subject) {
                subjectCopy = angular.copy(subject);
                subjectCopy.status = { id: subjectCopy.status_id, name: subjectCopy.status };
            }
            else {
                subjectCopy = { project_id: projectId };
            }
            var dialog_options = {
                title: subject ? "EDIT_SUBJECT" : "ADD_SUBJECT",
                size: "small",
                buttons: {
                    acceptBtn: {
                        text: "SAVE",
                        disableIfInvalid: true,
                        fnc: function () {
                            _this.saveSubjectToDB(angular.copy(dialog_options.item));
                        }
                    },
                    cancelBtn: {
                        text: "CANCEL",
                        fnc: function () {
                            _this.$mdDialog.hide();
                        }
                    }
                },
                item: subjectCopy,
                item_options: {
                    fields: {
                        chooseFromExisting: { type: "checkbox", label: "CHOOSE_FROM_EXISTING", isNotVisible: function (item) { return item.id; } },
                        existingSubject: { type: "combo", label: "SUBJECTS", source_url: "/request/get_subjects", description: "name", required: function () { return true; }, isNotVisible: function (item) { return !item.chooseFromExisting; } },
                        name: { type: "text", label: "NAME", required: function () { return true; }, isNotVisible: function (item) { return item.chooseFromExisting; } },
                        status: { type: "combo", label: "STATUS", get: function (item) { return item; }, source: this.statusDataSource, isNotVisible: function (item) { return item.chooseFromExisting; } },
                        rateable: { type: "checkbox", label: "RATEABLE" }
                    },
                    rows: [
                        [{ field: "chooseFromExisting", col_class: "col-xs-10" }],
                        [{ field: "existingSubject", col_class: "col-xs-10" }],
                        [{ field: "name", col_class: "col-xs-10" }],
                        [{ field: "status", col_class: "col-xs-10" }],
                        [{ field: "rateable", col_class: "col-xs-10" }]
                    ]
                }
            };
            this.$mdDialog.show({
                template: '<dialog-box options="options"></dialog-box>',
                parent: angular.element(document.body),
                targetEvent: $event || false,
                locals: { options: dialog_options },
                controller: ['$scope', 'options', function ($scope, options) { return $scope.options = options; }]
            });
        };
        ProjectController.prototype.saveKeyword = function (keyword, subjectId, $event) {
            var _this = this;
            var keyWordCopy;
            if (!keyword) {
                keyWordCopy = { subject_id: subjectId };
            }
            else {
                keyWordCopy = angular.copy(keyword);
                keyWordCopy.language = { id: keyWordCopy.language, name: keyWordCopy.language_description };
                keyWordCopy.status = { id: keyWordCopy.status_id, name: keyWordCopy.status };
            }
            var dialog_options = {
                title: keyword ? "EDIT_KEYWORD" : "ADD_KEYWORD",
                size: "small",
                buttons: {
                    acceptBtn: {
                        text: "SAVE",
                        disableIfInvalid: true,
                        fnc: function () {
                            _this.projectService.saveKeyword(dialog_options.item).then(function (b) {
                                if (!(b instanceof Error)) {
                                    var grid = $("#keywordGrid" + dialog_options.item.subject_id).data("kendoGrid");
                                    grid.dataSource.read();
                                    grid.refresh();
                                    _this.$mdDialog.hide();
                                }
                            });
                        }
                    },
                    cancelBtn: {
                        text: "CANCEL",
                        fnc: function () {
                            _this.$mdDialog.hide();
                        }
                    }
                },
                item: keyWordCopy,
                item_options: {
                    fields: {
                        name: { type: "text", label: "NAME", required: function () { return true; } },
                        language: { type: "combo", label: "LANGUAGE", get: function (item) { return item; }, source_url: "/request/get_languages", required: function () { return true; } },
                        status: { type: "combo", label: "STATUS", get: function (item) { return item; }, source: this.statusDataSource }
                    },
                    rows: [
                        [{ field: "name", col_class: "col-xs-10" }],
                        [{ field: "language", col_class: "col-xs-10" }],
                        [{ field: "status", col_class: "col-xs-10" }]
                    ]
                }
            };
            this.$mdDialog.show({
                template: '<dialog-box options="options"></dialog-box>',
                parent: angular.element(document.body),
                targetEvent: $event || false,
                locals: { options: dialog_options },
                controller: ['$scope', 'options', function ($scope, options) { return $scope.options = options; }]
            });
        };
        ProjectController.id = "projectController";
        return ProjectController;
    }(App.BaseController));
    App.ProjectController = ProjectController;
    angular.module(App.Module)
        .controller(ProjectController.id, ProjectController);
})(App || (App = {}));

var App;
(function (App) {
    var ProjectStructureController = (function () {
        /*
                public showProject(i: number) {
                    this.isLoaded = false;
                    HttpRequester.post("/request/get_project_structure", null)
                        .then((response: IProject[]) => {
                            this.projects = response;
                            this.treeViewData = [d];
                            this.isLoaded = true;
                        });
                    var d = new ProjectStructureElement(i);
        
                    d.loadFromDB(() => {
                        d.loadChildrenFromStructureData();
                        this.treeViewData = [d];
                        this.isLoaded = true;
                    });
                }
        
                public collapseBtnClick() {
                    this.b = !this.b;
                    this.collapseBtnName = this.b ? "EXPAND_ALL" : "COLLAPSE_ALL";
                    this.collapseOrExpandAll(this.treeViewData)
                }
        
                public collapseOrExpandAll(obj) {
                    for(var i in obj) {
                        obj[i].collapsed = this.b;
                        if (obj[i].structureData) {
                            this.collapseOrExpandAll(obj[i].structureData);
                        }
                    }
                }
        
                public departmentSelected(d) {
                    this.selected_element = new ProjectStructureElement(d.id);
                    this.initDepartmentFormOptions();
                    this.selected_element.loadFromDB((err) => {
                        if (err) {
                            console.error("Cannot get department properties ", err);
                            return;
                        }
                        this.header.title = this.selected_element.title;
                    });
                }
        
                public header = {
                    title: "",
                    icon: "action:view_quilt",
                    buttons: {
                        btn_edit: {
                            tooltip: "EDIT_DEPARTMENT",
                            icon: "editor:mode_edit",
                            fnc: ($event) => {
                                if(!this.selected_department) {
                                    Notifier.show("SELECT_DEPARTMENT", MessageType.warning);
                                    return;
                                }
                                var newItem = new Department(this.selected_department.id).loadFromDB();
                                var dialog_options = {
                                    title: "EDIT_DEPARTMENT",
                                    size: "medium",
                                    buttons: {
                                        acceptBtn: {
                                            text: "SAVE",
                                            fnc: () => {
                                                dialog_options.item.saveToDB((err) => {
                                                    if (!err) {
                                                        this.$mdDialog.hide();
                                                        this.showDepartment(this.project_structure_id);
                                                    }
                                                });
                                            }
                                        },
                                        cancelBtn: {
                                            text: "CANCEL",
                                            fnc: () => {
                                                this.$mdDialog.hide();
                                            }
                                        }
                                    },
                                    item: newItem,
                                    item_options: this.department_form_options_edit //getOptions(this.department_form_options)
                                };
        
                                function getOptions(o: any) {
                                    var obj = angular.copy(o)
                                    for(var i in obj.fields) {
                                        delete obj["fields"][i]["readonly"];
                                        if (obj["fields"][i]["format"] == "date") {
                                            delete obj["fields"][i]["format"];
                                            obj["fields"][i]["type"] = "date";
                                        }
                                        if (obj["fields"][i]["source_url"]) {
                                            obj["fields"][i]["type"] = "combo";
                                            if (obj["fields"][i]["property_name"]) {
                                                delete obj["fields"][i]["property_name"];
                                            }
                                            if (obj["fields"][i]["get"]) {
                                                delete obj["fields"][i]["get"];
                                                angular.extend(obj["fields"][i], {get: (item) => item})
                                            }
                                        }
                                    }
        
                                    return obj;
                                }
        
                                this.$mdDialog.show({
                                    template: '<dialog-box options="options"></dialog-box>',
                                    parent: angular.element(document.body),
                                    targetEvent: $event || false,
                                    locals: {options: dialog_options},
                                    controller: ['$scope', 'options', ($scope, options) => $scope.options = options]
                                }).then(() => {
                                    this.selected_department.loadFromDB((err) => {
                                        if (!err) {
                                            this.header.title = this.selected_department.title;
                                        }
                                    });
                                });
                            }
                        },
                        btn_remove: {
                            tooltip: "REMOVE_DEPARTMENT",
                            icon: "action:delete",
                            fnc: ($event) => {
                                if(confirm('Հեռացնե՞լ ընտրված ստորաբաժանումը')) {
                                    this.$http.post('request/remove_department', {id: this.selected_department.id}).success(() => {
                                        Notifier.show("DEPARTMENT_REMOVED", MessageType.info);
                                        this.showDepartment(this.project_structure_id);
                                    }).error((err) => {
                                        Notifier.show(err.message, MessageType.error)
                                    })
                                }
                            }
                        }
                    }
                };
        
                private initDepartmentFormOptions() {
                    this.department_form_options = {
                        fields: {
                            status: {type: "text", readonly: "true", label: "STATUS", get: (item) => item.status, property_name: "description"},
                            head: {type: "text", readonly: "true", label: "HEAD", get: (item) => item.head, property_name: "description"},
                            description: {type: "textarea", readonly: "true", label: "DESCRIPTION"}
                        },
                        rows: [
                            [{field: "status", col_class: "col-md-6"}],
                            [{field: "head", col_class: "col-md-6"}],
                            [{field: "description", col_class: "col-md-6"}]
                        ]
                    };
        
                    this.department_form_options_edit = {
                        fields: {
                            title: {type: "text", label: "TITLE"},
                            status: {type: "combo", label: "STATUS", source_url: "/request/get_department_statuses", get: (item) => item},
                            description: {type: "textarea", label: "DESCRIPTION"},
                            head: {type: "combo", label: "HEAD", source_url: "/request/get_employees_for_combo", get: (item) => item},
                        },
                        rows: [
                            [{field: "title", col_class: "col-md-6"}],
                            [{field: "status", col_class: "col-md-6"}],
                            [{field: "description", col_class: "col-md-6"}],
                            [{field: "head", col_class: "col-md-6"}]
                        ]
                    }
                }
        
                public addButtonVisibility(node): boolean {
                    var b: boolean = true;
                    b = node.canHaveChild;
                    return b;
                }
        
                public addChildren(node) {
                    var options: any = {
                        title: node.title,
                        //event: $event,
                        size: "small",
                        buttons: {
                            acceptBtn: {
                                text: "ADD",
                                fnc: () => {
                                    this.$http.post('request/add_departments/', options.item).success(() => {
                                        this.showDepartment(this.project_structure_id);
                                        this.$mdDialog.cancel();
                                    }).error((err) => {
                                        Notifier.show(err.message, MessageType.error);
                                    })
                                }
                            },
                            cancelBtn: {
                                text: "CANCEL",
                                fnc:() => {
                                    this.$mdDialog.cancel()
                                }
                            }
                        },
                        item: {"parent_id": node.id, children_count: 1},
                        item_options: {
                            fields: {
                                children_type: {type: "combo", label: "CHILDREN", source_url: "/request/get_department_possible_children/" + node.type.id.toString(), "id": "department_type_id", "description": "department_type_description"},
                                children_count: {type: "text", label: "COUNT"}
                            },
                            rows: [
                                [{field: "children_type", col_class: "col-md-6"}],
                                [{field: "children_count", col_class: "col-md-6"}]
                            ]
                        }
                    };
        
                    this.$mdDialog.show({
                        template: '<dialog-box options="options"></dialog-box>',
                        parent: angular.element(document.body),
                        targetEvent: false,
                        locals: {options: options},
                        controller: ['$scope', 'options', ($scope, options) => $scope.options = options]
                    });
                }
        */
        /*@ngInject*/
        function ProjectStructureController($state, $scope, $mdDialog, $http, authenticationService) {
            this.$state = $state;
            this.$scope = $scope;
            this.$mdDialog = $mdDialog;
            this.$http = $http;
            this.authenticationService = authenticationService;
            this.project_structure_id = $state.params["projectId"];
            this.element_form_options = { fields: {}, rows: {} };
            this.fields_array = [];
            this.collapseBtnName = "COLLAPSE_ALL";
            this.b = false;
            this.isLoaded = false;
            //this.showDepartment(this.project_structure_id);
            $scope.$watch("project_structure_tree.currentNode", function (value) {
                //value && this.departmentSelected(value);
            });
        }
        ProjectStructureController.id = "projectStructureController";
        return ProjectStructureController;
    }());
    App.ProjectStructureController = ProjectStructureController;
    angular.module(App.Module)
        .controller(ProjectStructureController.id, ProjectStructureController);
})(App || (App = {}));

var App;
(function (App) {
    'use strict';
    var ReportViewController = (function () {
        /*@ngInject*/
        function ReportViewController($state, $http, $mdDialog, authenticationService, $translate, $mdSidenav) {
            this.$state = $state;
            this.$http = $http;
            this.$mdDialog = $mdDialog;
            this.authenticationService = authenticationService;
            this.$translate = $translate;
            this.$mdSidenav = $mdSidenav;
            this.isLoaded = false;
            this.reportId = $state.params["reportId"];
            this.reportDescription = $state.params["reportDescription"];
            this.filtersDescriptionForExcel = "";
            this.initGridColumns();
        }
        ReportViewController.prototype.initGridColumns = function () {
            var _this = this;
            $.ajax({
                type: "POST",
                url: 'request/get_report_columns',
                data: { reportId: this.reportId },
                async: false,
                dataType: 'json',
                success: function (options) {
                    _this.gridOptions = options;
                    _this.initFilters(options.filters, options.rows);
                    _this.initGridOptions(options, _this.generateFilterString());
                    _this.dataSource = { id: _this.reportId, filter: _this.generateFilterString() };
                }
            }).fail(function (err) {
                _this.isLoaded = true;
                App.Notifier.show("ERROR_OCCURRED", App.MessageType.error);
            });
        };
        ReportViewController.prototype.initGridOptions = function (options, filters) {
            var _this = this;
            this.mainGridOptions = {
                toolbar: [
                    {
                        template: '<h4>{{vm.reportDescription}}</h4><span flex></span>' +
                            '<md-button class="md-icon-button custom-cell-button" ng-click="vm.exportToExcel($event)">' +
                            '<md-tooltip><span data-translate="EXPORT_TO_EXCEL"></span></md-tooltip>' +
                            '<md-icon md-svg-icon="custom:excel" style="color: white;" aria-label="..."></md-icon>' +
                            '</md-button>'
                    }
                ],
                excel: {
                    fileName: "report_" + this.reportDescription + ".xlsx",
                    allPages: true
                },
                excelExport: function (e) {
                    _this.changeCreatedExcel(e.workbook);
                },
                dataSource: {
                    transport: {
                        read: function (e) {
                            _this.isLoaded = false;
                            _this.$http.post("/request/get_report_data/", _this.dataSource)
                                .success(function (res) {
                                if (res) {
                                    e.success(res);
                                    _this.isLoaded = true;
                                }
                            })
                                .error(function (err) {
                                e.error(err);
                                _this.isLoaded = true;
                                App.Notifier.show("ERROR_OCCURRED", App.MessageType.error);
                            });
                        }
                    },
                    schema: options.schema,
                    pageSize: 30,
                    aggregate: options.aggregate || null
                },
                sortable: true,
                pageable: true,
                selectable: "row",
                columnMenu: true,
                resizable: true,
                editable: false,
                groupable: true,
                columns: options.columns,
            };
        };
        ReportViewController.prototype.initFilters = function (fields, rows) {
            this.filters = {};
            for (var key in fields) {
                if (fields[key].hasOwnProperty("default_value")) {
                    this.filters[key] = this.getDefaultValues(fields[key].default_value);
                }
            }
            this.filter_form_options = {
                fields: fields,
                rows: rows,
                label_small_size: true
            };
            this.filtersDescriptionForExcel = this.getFiltersDescription();
        };
        ReportViewController.prototype.exportToExcel = function ($event) {
            this.reportGrid.saveAsExcel();
        };
        ReportViewController.prototype.clearFilters = function () {
            this.initFilters(this.gridOptions.filters, this.gridOptions.rows);
            this.getReportData();
        };
        ReportViewController.prototype.generateFilterString = function () {
            var filterStr = "";
            if (this.filters != {}) {
                for (var i in this.filters) {
                    if (this.filters[i] != "" && this.filters[i] != null) {
                        if (i.indexOf("date") != -1) {
                            var d = this.filters[i].getDate().toString();
                            var m = ((this.filters[i].getMonth()) + 1).toString();
                            var y = this.filters[i].getFullYear().toString();
                            filterStr += i + "#" + y + '-' + (m.length == 1 ? "0" + m : m) + '-' + (d.length == 1 ? "0" + d : d);
                        }
                        else if (Array.isArray(this.filters[i])) {
                            filterStr += i + "#";
                            for (var j = 0; j < this.filters[i].length; ++j) {
                                filterStr += this.filters[i][j].toString() + (j != this.filters[i].length - 1 ? "," : "");
                            }
                        }
                        else if (typeof this.filters[i] === 'object' && this.filters[i].id) {
                            filterStr += i + "#" + this.filters[i].id.toString();
                        }
                        else {
                            filterStr += i + "#" + this.filters[i].toString();
                        }
                        filterStr += "^";
                    }
                }
                filterStr = filterStr.substr(0, filterStr.length - 1);
            }
            return filterStr;
        };
        ReportViewController.prototype.getReportData = function () {
            this.filtersDescriptionForExcel = this.getFiltersDescription();
            this.dataSource = { id: this.reportId, filter: this.generateFilterString() };
            this.reportGrid.dataSource.read();
            this.reportGrid.refresh();
        };
        ReportViewController.prototype.getDefaultValues = function (description) {
            switch (description) {
                case "currentDate":
                    return new Date();
                case "firstDayOfCurrentMonth":
                    var date = new Date();
                    return new Date(date.getFullYear(), date.getMonth(), 1);
                default:
                    return description;
            }
        };
        ReportViewController.prototype.changeCreatedExcel = function (workbook) {
            var sheet = workbook.sheets[0];
            var alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            sheet.freezePane.rowSplit = 0;
            for (var i = 0; i < 3; ++i) {
                sheet.rows.unshift({});
            }
            sheet.mergedCells = ["A1:" + alphabet.substr(sheet.columns.length - 1, 1) + "1", "A2:" + alphabet.substr(sheet.columns.length - 1, 1) + "2"];
            sheet.rows[0].cells = [{ value: this.reportDescription, hAlign: "center" }];
            sheet.rows[1].cells = [{ value: this.filtersDescriptionForExcel, hAlign: "center" }];
            sheet.columns[0].autoWidth = false;
            sheet.columns[0].width = 200;
            for (var i = 3; i < sheet.rows.length; ++i) {
                for (var j = 0; j < sheet.rows[i].cells.length; ++j) {
                    var v = sheet.rows[i].cells[j].value;
                    if (v && v.toString().indexOf("' | translate}}") != -1) {
                        var substringForReplase = v.substring(v.indexOf("{{'"), v.indexOf("' | translate}}") + 15);
                        var phraseForTranslate = v.substring(v.indexOf("{{'") + 3, v.indexOf("' | translate}}"));
                        sheet.rows[i].cells[j].value = v.replace(substringForReplase, this.$translate.instant(phraseForTranslate));
                    }
                }
            }
        };
        ReportViewController.prototype.getFiltersDescription = function () {
            var filtersDescriptions = "";
            var translatedLabel;
            var value = "";
            var arr = [];
            var objIndex;
            for (var key in this.filters) {
                value = "";
                translatedLabel = this.$translate.instant(this.filter_form_options.fields[key].label) || this.$translate.instant(this.filter_form_options.fields[this.filter_form_options.fields[key].associate].label);
                if (this.filters[key] != null) {
                    if (Array.isArray(this.filters[key])) {
                        var s = this.filter_form_options.fields[key].source;
                        value = this.getDescriptionsFromArrayByIds(this.filters[key], s);
                    }
                    else if (key.indexOf("date") != -1) {
                        var d = this.filters[key];
                        value = d.getDate().toString() + "/" + (d.getMonth() + 1).toString() + "/" + d.getFullYear().toString();
                    }
                    else if (typeof this.filters[key] === 'object') {
                        value = this.filters[key].description;
                    }
                    else {
                        value = this.filters[key].toString();
                    }
                }
                objIndex = this.getObjectIndexInArrayByProperty(translatedLabel, "description", arr);
                if (objIndex == -1) {
                    var obj;
                    if (this.filter_form_options.fields[key].label != "") {
                        obj = { description: translatedLabel, firstValue: value };
                    }
                    else {
                        obj = { description: translatedLabel, firstValue: "", secondValue: value };
                    }
                    if (this.filter_form_options.fields[key].range) {
                        obj.range = true;
                    }
                    arr.push(obj);
                }
                else {
                    if (this.filter_form_options.fields[key].label != "") {
                        arr[objIndex].firstValue = value;
                    }
                    else {
                        arr[objIndex].secondValue = value;
                    }
                }
            }
            for (var i = 0; i < arr.length; ++i) {
                if (arr[i].firstValue && arr[i].secondValue) {
                    filtersDescriptions += arr[i].description + ": " + (arr[i].range ? "(>=)" : "") + arr[i].firstValue + " - " + (arr[i].range ? "(<=)" : "") + arr[i].secondValue + ", ";
                }
                else if (arr[i].firstValue) {
                    filtersDescriptions += arr[i].description + ": " + (arr[i].range ? "(>=)" : "") + arr[i].firstValue + ", ";
                }
                else if (arr[i].secondValue) {
                    filtersDescriptions += arr[i].description + ": " + (arr[i].range ? "(<=)" : "") + arr[i].secondValue + ", ";
                }
            }
            return filtersDescriptions.substr(0, filtersDescriptions.length - 2);
        };
        ReportViewController.prototype.getDescriptionsFromArrayByIds = function (ids, source) {
            var descriptions = "";
            for (var i = 0; i < ids.length; ++i) {
                var result = $.grep(source, function (e) { return e["id"] == ids[i]; });
                descriptions += result[0]["description"] + (i == ids.length - 1 ? "" : ", ");
            }
            return descriptions;
        };
        ReportViewController.prototype.getObjectIndexInArrayByProperty = function (value, property, arr) {
            var index = -1;
            for (var i = 0; i < arr.length; ++i) {
                if (arr[i][property] == value) {
                    return i;
                }
            }
            return index;
        };
        ReportViewController.id = "reportViewController";
        return ReportViewController;
    }());
    App.ReportViewController = ReportViewController;
    angular.module(App.Module)
        .controller(ReportViewController.id, ReportViewController);
})(App || (App = {}));

var App;
(function (App) {
    'use strict';
    var ReportsController = (function () {
        /*@ngInject*/
        function ReportsController($state, $mdDialog) {
            var _this = this;
            this.$state = $state;
            this.$mdDialog = $mdDialog;
            this.mainGridOptions = {
                viewReport: function (id, description) {
                    _this.$state.go("layout.reportView", { reportId: id, reportDescription: description });
                },
                dataSource: {
                    transport: {
                        read: {
                            url: "/request/get_report_types",
                            dataType: "json",
                            type: "POST"
                        }
                    },
                    pageSize: 100,
                },
                hierarchical: true,
                sortable: true,
                pageable: true,
                editable: false,
                detailInit: this.detailInit,
                columns: [{ field: "description", title: "DESCRIPTION" }]
            };
        }
        ReportsController.prototype.detailInit = function (e) {
            var _this = this;
            $("<div id='childGrid'></div>'").appendTo(e.detailCell).kendoGrid({
                dataSource: {
                    transport: {
                        read: {
                            url: "/request/get_reports_by_user",
                            dataType: "json",
                            type: "POST",
                            data: { userId: null }
                        }
                    },
                    schema: {
                        model: {
                            fields: {
                                id: { type: "number" },
                                description: { type: "string" },
                                report_type: { type: "number" }
                            }
                        }
                    },
                    filter: { field: "report_type", operator: "eq", value: e.data.id }
                },
                change: function (e) {
                    var grid = e.sender;
                    var data = grid.dataItem(grid.select());
                    _this["options"]["viewReport"](data["id"], data["description"]);
                },
                selectable: "row",
                columns: [
                    { field: "number", title: "NUMBER", width: "100px" },
                    { field: "description", title: "DESCRIPTION" }
                ]
            });
        };
        ReportsController.id = "reportsController";
        return ReportsController;
    }());
    App.ReportsController = ReportsController;
    angular.module(App.Module)
        .controller(ReportsController.id, ReportsController);
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    'use strict';
    var SocialMediaController = (function (_super) {
        __extends(SocialMediaController, _super);
        /*@ngInject*/
        function SocialMediaController(authenticationService, eventManagerService, globals) {
            var _this = this;
            _super.call(this);
            this.authenticationService = authenticationService;
            this.eventManagerService = eventManagerService;
            this.globals = globals;
            this.filter = {};
            this.filterFunction = function () {
                _this.eventManagerService.publish(_this.globals.events.getPostList, 1);
            };
            this.clearFilters = function () {
                _this.filter = new App.PostFilter();
                _this.filterFunction();
            };
            if (!this.viewMode) {
                this.setListMode();
            }
        }
        SocialMediaController.id = "socialMediaController";
        return SocialMediaController;
    }(App.BaseController));
    App.SocialMediaController = SocialMediaController;
    angular.module(App.Module)
        .controller(SocialMediaController.id, SocialMediaController);
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    'use strict';
    var SocialMediaAccountsController = (function (_super) {
        __extends(SocialMediaAccountsController, _super);
        /*@ngInject*/
        function SocialMediaAccountsController($filter, authenticationService, socialMediaService, $mdDialog) {
            var _this = this;
            _super.call(this);
            this.$filter = $filter;
            this.authenticationService = authenticationService;
            this.socialMediaService = socialMediaService;
            this.$mdDialog = $mdDialog;
            this.acceptedFileTypes = "image/png, image/jpeg, image/jpg";
            this.bace64 = '';
            this.initGridOptions = function () {
                _this.gridOptions = {
                    dataSource: {
                        transport: {
                            read: {
                                url: "/request/get_social_media_accounts",
                                dataType: "json",
                                type: "POST",
                                data: { id: null }
                            }
                        },
                        pageSize: 20,
                    },
                    sortable: true,
                    pageable: true,
                    resizable: true,
                    filterable: true,
                    columnMenu: true,
                    columns: [
                        { field: "image", title: "IMAGE", template: "<img class=\"img-circle img-grid\" src=\"{{dataItem.image.length > 0 ? dataItem.image : 'imgs/user.png'}}\" />", width: 100 },
                        { field: "name", template: "{{dataItem.name.split('(')[0]}}", title: "NAME", width: 350 },
                        { field: "type", title: "TYPE", width: 250 },
                        { field: "status", title: "STATUS", width: 250 },
                        { field: "main_url", template: "<a href=\"{{dataItem.main_url}}\" target=\"_blank\">{{dataItem.main_url.length > 80 ? (dataItem.main_url | limitTo: 80) + '... ': dataItem.main_url}}</a>", title: "URL" },
                        { template: "<md-button ng-if=\"vm.controlVisibility('edit_social_media_account')\" class=\"md-icon-button custom-cell-button\" ng-click=\"vm.saveSocialMediaAccount(dataItem, $event)\" aria-label=\"Edit\"><md-tooltip><span data-translate=\"EDIT\"></span></md-tooltip><md-icon md-svg-icon=\"editor:mode_edit\" aria-label=\"...\"></md-icon></md-button>", width: 70 }
                    ]
                };
            };
            this.getSrc = function (img) {
                return img ? img : 'imgs/user.png';
            };
            this.addEvent = function (e) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    if (reader.result) {
                        _this.bace64 = reader.result;
                        document.getElementById('#social')['src'] = reader.result;
                    }
                };
                if (e.target && e.target.files) {
                    reader.readAsDataURL(e.target.files[0]);
                }
            };
            this.save = function (item) {
                if (item.image && item.image.size / 1024 > 100) {
                    App.Notifier.show("Ընտրված նկարի չափը գերազանցում է 100KB -ն․", App.MessageType.error);
                    return;
                }
                else {
                    item.image = _this.bace64;
                    _this.socialMediaService.saveSocialmediaAccount(item).then(function (b) {
                        if (!(b instanceof Error)) {
                            App.Notifier.show("Պահպանված է։", App.MessageType.success);
                            _this.$mdDialog.hide();
                            _this.grid.dataSource.read();
                            _this.grid.refresh();
                        }
                    });
                }
            };
            this.controlVisibility = function (controlName) {
                return _this.authenticationService.user.controlPermissions.indexOf(controlName) != -1;
            };
            this.setListMode();
            this.initGridOptions();
        }
        SocialMediaAccountsController.prototype.saveSocialMediaAccount = function (social_account, $event) {
            var _this = this;
            var socialAccountCopy;
            if (social_account) {
                socialAccountCopy = angular.copy(social_account);
                socialAccountCopy.status = { id: socialAccountCopy.status_id, name: socialAccountCopy.status };
                socialAccountCopy.type = { id: socialAccountCopy.type_id, name: socialAccountCopy.type };
                socialAccountCopy.name = socialAccountCopy.name.split('(')[0];
            }
            else {
                socialAccountCopy = new App.SocialMedia();
            }
            var dialog_options = {
                title: social_account ? "EDIT_SOCIAL_ACCOUNT" : "ADD_SOCIAL_ACCOUNT",
                size: "medium",
                buttons: {
                    acceptBtn: {
                        text: "SAVE",
                        disableIfInvalid: true,
                        fnc: function () {
                            _this.save(socialAccountCopy);
                        }
                    },
                    cancelBtn: {
                        text: "CANCEL",
                        fnc: function () {
                            _this.$mdDialog.hide();
                        }
                    }
                },
                item: socialAccountCopy,
                item_options: {
                    fields: {
                        image: { type: "file",
                            addEvent: function () { return _this.addEvent(event); },
                            src: this.getSrc(socialAccountCopy.image),
                            acceptedFileTypes: this.acceptedFileTypes,
                            get: function (item) { return item; }
                        },
                        name: { type: "text", label: "NAME", required: function () { return true; } },
                        type: { type: "combo", label: "SOCIAL_MEDIA_ACOOUNT_TYPE", source_url: "/request/get_social_media_types", description: "name", required: function () { return true; } },
                        status: { type: "combo", label: "STATUS", get: function (item) { return item; }, source_url: "/request/get_statuses" },
                        main_url: { type: "text", label: "URL", required: function () { return true; } }
                    },
                    rows: [
                        [{ field: "image", col_class: "col-xs-12" }],
                        [{ field: "name", col_class: "col-xs-6" }, { field: "type", col_class: "col-xs-6" }],
                        [{ field: "main_url", col_class: "col-xs-6" }, { field: "status", col_class: "col-xs-6" }]
                    ]
                }
            };
            this.$mdDialog.show({
                template: '<dialog-box options="options"></dialog-box>',
                parent: angular.element(document.body),
                targetEvent: $event || false,
                locals: { options: dialog_options },
                controller: ['$scope', 'options', function ($scope, options) { return $scope.options = options; }]
            });
        };
        ;
        SocialMediaAccountsController.id = "socialMediaAccountsController";
        return SocialMediaAccountsController;
    }(App.BaseController));
    App.SocialMediaAccountsController = SocialMediaAccountsController;
    angular.module(App.Module)
        .controller(SocialMediaAccountsController.id, SocialMediaAccountsController);
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    'use strict';
    var SocialMediaAnalysisController = (function (_super) {
        __extends(SocialMediaAnalysisController, _super);
        /*@ngInject*/
        function SocialMediaAnalysisController() {
            _super.call(this);
            this.newsFeedTypeEnum = App.NewsFeedTypeEnum;
            this.filter = {};
        }
        SocialMediaAnalysisController.id = "SocialMediaAnalysisController";
        return SocialMediaAnalysisController;
    }(App.BaseController));
    App.SocialMediaAnalysisController = SocialMediaAnalysisController;
    angular.module(App.Module)
        .controller(SocialMediaAnalysisController.id, SocialMediaAnalysisController);
})(App || (App = {}));

'use strict';
var App;
(function (App) {
    var UsersController = (function () {
        /*@ngInject*/
        function UsersController(authenticationService, $mdDialog, $http, $translate) {
            var _this = this;
            this.authenticationService = authenticationService;
            this.$mdDialog = $mdDialog;
            this.$http = $http;
            this.$translate = $translate;
            this.newsFeedTypeEnum = App.NewsFeedTypeEnum;
            this.mainGridOptions = {
                dataSource: {
                    transport: {
                        read: {
                            url: "/request/get_users",
                            dataType: "json",
                            type: "POST",
                            cache: false
                        }
                    },
                    pageSize: 20
                },
                hierarchical: true,
                sortable: true,
                pageable: true,
                resizable: true,
                selectable: "row",
                filterable: true,
                columnMenu: true,
                reorderable: true,
                editable: false,
                change: function (e) {
                    var grid = e.sender;
                    var data = grid.dataItem(grid.select());
                    _this.user.id = data["id"];
                },
                dblClick: function () {
                    if (_this.controlVisibility('edit_user')) {
                        _this.saveUser(null, 'edit');
                    }
                },
                columns: [
                    { field: "name", title: "NAME" },
                    { field: "username", title: "USERNAME" },
                    { field: "email", title: "EMAIL" },
                    { field: "group", title: "GROUP" },
                    { field: "status", title: "STATUS" },
                    { field: "customer", title: "CUSTOMER" },
                    { field: "last_login_date", title: "LOGIN_DATE", type: "date", format: "{0:yyyy/MM/dd HH:mm:ss}" }
                ]
            };
            this.getUserReports = function (userId) {
                return {
                    toolbar: [
                        {
                            name: "EDIT",
                            template: "<span flex></span><md-button ng-if=\"vm.controlVisibility('add_project_to_mass_media')\"  ng-click=\"vm.editUserReportsList($event, dataItem.id)\" class=\"md-icon-button custom-cell-button\"  aria-label=\"Edit\"><md-tooltip><span data-translate=\"EDIT\"></span></md-tooltip><md-icon md-svg-icon=\"editor:mode_edit\" aria-label=\"...\" class=\"edit-mass\"></md-icon></md-button>"
                        }
                    ],
                    dataSource: {
                        transport: {
                            read: {
                                url: "/request/get_reports_by_user",
                                dataType: "json",
                                type: "POST",
                                data: { userId: userId }
                            }
                        },
                        pageSize: 10,
                    },
                    sortable: true,
                    pageable: true,
                    resizable: true,
                    filterable: true,
                    columnMenu: true,
                    columns: [
                        { field: "description", title: "{{'NAME' | translate}}" },
                        { field: "report_type_description", title: "{{'TYPE' | translate}}" }]
                };
            };
            this.editUserReportsList = function ($event, userId) {
                _this.$http.post('request/get_reports/', { id: userId }).success(function (res) {
                    for (var p in res) {
                        res[p].assigned = res[p].assigned == 0 ? false : true;
                    }
                    _this.$mdDialog.show({
                        template: $('#ReportListPopupTemplate').html(),
                        parent: angular.element(document.body),
                        locals: { options: { reportList: res } },
                        controller: ['$scope', 'options', function ($scope, options) {
                                $scope.isChaked = false;
                                $scope.reportList = options.reportList;
                                $scope.cancel = function () {
                                    _this.$mdDialog.hide();
                                };
                                $scope.checkAll = function (isChecked) {
                                    for (var p in $scope.reportList) {
                                        $scope.reportList[p].assigned = isChecked;
                                    }
                                };
                                $scope.saveReportList = function () {
                                    var arr = [];
                                    for (var i in $scope.reportList) {
                                        $scope.reportList[i].assigned && arr.push($scope.reportList[i].id);
                                    }
                                    _this.$http.post('request/save_user_reports', { user_id: userId, report_ids: arr.join() }).success(function () {
                                        App.Notifier.show("CHANGES_SAVED", App.MessageType.success);
                                        var grid = $("#reportGrid" + userId).data("kendoGrid");
                                        grid.dataSource.read();
                                        grid.refresh();
                                        _this.$mdDialog.cancel();
                                    }).error(function (err) {
                                        App.Notifier.show(err.message, App.MessageType.error);
                                    });
                                };
                            }]
                    });
                }).error(function (err) {
                    App.Notifier.show(err.message, App.MessageType.error);
                });
            };
            this.getUserAnalysis = function (userId) {
                return {
                    toolbar: [
                        {
                            name: "EDIT",
                            template: "<span flex></span><md-button ng-if=\"vm.controlVisibility('add_project_to_mass_media')\"  ng-click=\"vm.editUserAnalysisList($event, dataItem.id)\" class=\"md-icon-button custom-cell-button\"  aria-label=\"Edit\"><md-tooltip><span data-translate=\"EDIT\"></span></md-tooltip><md-icon md-svg-icon=\"editor:mode_edit\" aria-label=\"...\" class=\"edit-mass\"></md-icon></md-button>"
                        }
                    ],
                    dataSource: {
                        transport: {
                            read: {
                                url: "/request/get_analysis_by_user",
                                dataType: "json",
                                type: "POST",
                                data: { user_id: userId }
                            }
                        },
                        pageSize: 10,
                    },
                    sortable: true,
                    pageable: true,
                    resizable: true,
                    filterable: true,
                    columnMenu: true,
                    columns: [
                        { field: "name", title: "{{'NAME' | translate}}", template: "{{dataItem.name | translate}}" },
                        { field: "name", title: "{{'TYPE' | translate}}", template: "{{dataItem.feed_type === vm.newsFeedTypeEnum.MassMedia ? ('MASS_MEDIA' | translate): ('SOCIAL_MEDIA' | translate)}}" }
                    ]
                };
            };
            this.editUserAnalysisList = function ($event, userId) {
                _this.$http.post('request/get_analysis_list/', { id: userId, feed_type: null }).success(function (res) {
                    for (var p in res) {
                        res[p].assigned = res[p].assigned == 0 ? false : true;
                    }
                    _this.$mdDialog.show({
                        template: $('#AnalysisListPopupTemplate').html(),
                        parent: angular.element(document.body),
                        locals: { options: { analysisList: res } },
                        controller: ['$scope', 'options', function ($scope, options) {
                                $scope.sourceDescriptions = {
                                    mass_media: _this.$translate.instant('MASS_MEDIA'),
                                    social_media: _this.$translate.instant('SOCIAL_MEDIA')
                                };
                                $scope.isChaked = false;
                                $scope.analysisList = options.analysisList;
                                $scope.selectedFeedType = "";
                                $scope.cancel = function () {
                                    _this.$mdDialog.hide();
                                };
                                $scope.checkAll = function (isChecked) {
                                    for (var p in $scope.analysisList) {
                                        $scope.analysisList[p].assigned = isChecked;
                                    }
                                };
                                $scope.saveAnalysisList = function () {
                                    var arr = [];
                                    for (var i in $scope.analysisList) {
                                        $scope.analysisList[i].assigned && arr.push($scope.analysisList[i].id);
                                    }
                                    _this.$http.post('request/save_user_analysis', { user_id: userId, analysis_ids: arr.join() }).success(function () {
                                        App.Notifier.show("CHANGES_SAVED", App.MessageType.success);
                                        var grid = $("#analysisGrid" + userId).data("kendoGrid");
                                        grid.dataSource.read();
                                        grid.refresh();
                                        _this.$mdDialog.cancel();
                                    }).error(function (err) {
                                        App.Notifier.show(err.message, App.MessageType.error);
                                    });
                                };
                                $scope.filterAnalysisList = function (type) {
                                    return $scope.selectedFeedType === "" || type.feed_type == $scope.selectedFeedType;
                                };
                            }]
                    });
                }).error(function (err) {
                    App.Notifier.show(err.message, App.MessageType.error);
                });
            };
            this.getUserLogs = function (userId) {
                return {
                    dataSource: {
                        transport: {
                            read: {
                                url: "/request/get_user_logs",
                                dataType: "json",
                                type: "POST",
                                data: { userId: userId }
                            }
                        },
                        pageSize: 10,
                    },
                    sortable: true,
                    pageable: true,
                    resizable: true,
                    filterable: true,
                    columnMenu: true,
                    columns: [{ field: "date", title: "{{'LOG_DATE' | translate}}", type: "date", format: "{0:yyyy/MM/dd HH:mm:ss}" }]
                };
            };
            this.user = new App.User();
        }
        UsersController.prototype.$onInit = function () {
        };
        UsersController.prototype.controlVisibility = function (controlName) {
            return this.authenticationService.user.controlPermissions.indexOf(controlName) != -1;
        };
        UsersController.prototype.saveUser = function ($event, mode) {
            var _this = this;
            if (mode == 'new') {
                this.user = new App.User();
            }
            var options = {
                title: (this.user.id ? "EDIT_USER" : "NEW_USER"),
                event: $event,
                buttons: {
                    acceptBtn: {
                        text: "SAVE",
                        btnClass: "btn-primary",
                        disableIfInvalid: true,
                        fnc: function () {
                            options.item.saveToDB(function (err, res) {
                                if (!err) {
                                    _this.user = new App.User();
                                    _this.$mdDialog.cancel();
                                    _this.usersGrid.dataSource.read();
                                    _this.usersGrid.refresh();
                                }
                            });
                        }
                    },
                    cancelBtn: {
                        text: "CANCEL",
                        btnClass: "mm-btn-link clr-default",
                        fnc: function () {
                            _this.user = new App.User();
                            _this.$mdDialog.cancel();
                        }
                    }
                },
                item: (this.user.id ? (new App.User(this.user.id)).loadFromDB() : new App.User()),
                item_options: {
                    fields: {
                        firstName: { type: "text", label: "FIRST_NAME", get: function (item) { return item; }, required: function () { return true; } },
                        lastName: { type: "text", label: "LAST_NAME", get: function (item) { return item; }, required: function () { return true; } },
                        email: { type: "email", label: "EMAIL", get: function (item) { return item; } },
                        login: { type: "text", label: "USERNAME", get: function (item) { return item; }, required: function () { return true; } },
                        group: { type: "combo", label: "GROUP", source_url: "/request/get_user_groups", get: function (item) { return item; }, required: function () { return true; } },
                        status: { type: "combo", label: "STATUS", source_url: "/request/get_user_statuses", get: function (item) { return item; }, required: function () { return true; } },
                        customer: { type: "combo", label: "CUSTOMER", source_url: "/request/get_customers", get: function (item) { return item; }, description: 'name' },
                        feedRefreshInterval: { type: "number", label: "FEED_REFRESH_INTERVAL", get: function (item) { return item; } },
                    },
                    rows: [
                        [{ field: "firstName", col_class: "col-md-6" }, { field: "lastName", col_class: "col-md-6" }],
                        [{ field: "login", col_class: "col-md-6" }, { field: "email", col_class: "col-md-6" }],
                        [{ field: "group", col_class: "col-md-6" }, { field: "status", col_class: "col-md-6" }],
                        [{ field: "customer", col_class: "col-md-6" }, { field: "feedRefreshInterval", col_class: "col-md-6" }]
                    ]
                },
                size: "medium"
            };
            this.$mdDialog.show({
                template: '<dialog-box options="options"></dialog-box>',
                parent: angular.element(document.body),
                targetEvent: options.event || false,
                locals: { options: options },
                controller: ['$scope', 'options', function ($scope, options) { return $scope.options = options; }]
            });
        };
        UsersController.id = "usersController";
        return UsersController;
    }());
    App.UsersController = UsersController;
    angular.module(App.Module)
        .controller(UsersController.id, UsersController);
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    var massMediaSectionsController = (function (_super) {
        __extends(massMediaSectionsController, _super);
        function massMediaSectionsController(authenticationService, customerService, $http, $mdDialog) {
            var _this = this;
            _super.call(this);
            this.authenticationService = authenticationService;
            this.customerService = customerService;
            this.$http = $http;
            this.$mdDialog = $mdDialog;
            this.$onInit = function () {
                _this.initGridOptions();
            };
            this.initGridOptions = function () {
                _this.gridOptions = {
                    dataSource: {
                        transport: {
                            read: {
                                url: "/request/get_mass_media_sections_for_customer",
                                dataType: "json",
                                type: "POST",
                                data: { id: _this.customerId }
                            }
                        },
                        pageSize: 20,
                    },
                    sortable: true,
                    pageable: true,
                    resizable: true,
                    filterable: true,
                    columnMenu: true,
                    columns: [
                        { field: "mass_media", title: "MASS_MEDIA_LIST", width: 200 },
                        { field: "sections", title: "SECTIONS" },
                        { template: "<md-button ng-if=\"vm.controlVisibility('edit_mass_media')\" class=\"md-icon-button custom-cell-button\" ng-click=\"vm.saveMassMediaSections($event, dataItem.id)\" aria-label=\"Edit\"><md-tooltip><span data-translate=\"EDIT\"></span></md-tooltip><md-icon md-svg-icon=\"editor:mode_edit\" aria-label=\"...\"></md-icon></md-button>\n\t\t\t\t\t\t\t\t<md-button ng-if=\"vm.controlVisibility('edit_mass_media')\" class=\"md-icon-button custom-cell-button\" ng-click=\"vm.removeMassMediaSections($event, dataItem.id)\" aria-label=\"Remove\"><md-tooltip><span data-translate=\"DELETE\"></span></md-tooltip><md-icon md-svg-icon=\"action:delete\" aria-label=\"...\"></md-icon></md-button>", width: 140 }
                    ]
                };
            };
        }
        massMediaSectionsController.prototype.saveMassMediaSections = function ($event, massMediaId) {
            var _this = this;
            this.$mdDialog.show({
                template: $('#MassMediaSectionTemplate').html(),
                parent: angular.element(document.body),
                targetEvent: $event || false,
                locals: { options: { customer_id: this.customerId } },
                controller: ['$scope', 'options', function ($scope, options) {
                        $scope.options = options;
                        $scope.title = massMediaId ? "EDIT" : "ADD_MASS_MEDIA_SECTION";
                        if (massMediaId) {
                            $scope.disabledMassMedia = true;
                            _this.customerService.getMassMediaSectionsByCustomerId(_this.customerId, massMediaId)
                                .then(function (res) {
                                if (res) {
                                    $scope.options = res[0];
                                }
                                else {
                                    App.Notifier.show("ERROR_OCCURRED", App.MessageType.error);
                                }
                            });
                        }
                        $scope.save = function () {
                            _this.customerService.saveMassMediaSectionToCustomer(_this.customerId, $scope.options.mass_media_id, $scope.options.sections.join())
                                .then(function (res) {
                                if (res) {
                                    App.Notifier.show("CHANGES_SAVED", App.MessageType.success);
                                    _this.refreshGrid();
                                    _this.$mdDialog.cancel();
                                }
                                else {
                                    App.Notifier.show("ERROR_OCCURRED", App.MessageType.error);
                                }
                            });
                        };
                        $scope.cancel = function () {
                            _this.$mdDialog.hide();
                        };
                    }]
            });
        };
        massMediaSectionsController.prototype.controlVisibility = function (controlName) {
            return this.authenticationService.user.controlPermissions.indexOf(controlName) != -1;
        };
        massMediaSectionsController.prototype.refreshGrid = function () {
            var grid = $("#sectionGrid" + this.customerId).data("kendoGrid");
            grid.dataSource.read();
            grid.refresh();
        };
        massMediaSectionsController.prototype.removeMassMediaSections = function ($event, massMediaId) {
            var _this = this;
            if (confirm("Հեռացնե՞լ նախագիծը ։")) {
                this.customerService.removeMassMediaSections(this.customerId, massMediaId)
                    .then(function (res) {
                    if (res) {
                        App.Notifier.show("SUCCESSFULLY_REMOVED", App.MessageType.info);
                        _this.refreshGrid();
                    }
                    else {
                        App.Notifier.show("ERROR_OCCURRED", App.MessageType.error);
                    }
                });
            }
        };
        return massMediaSectionsController;
    }(App.BaseController));
    var massMediaSectionsComponent = (function () {
        function massMediaSectionsComponent() {
            this.templateUrl = "/components/mass-media-sections/mass-media-sections.component.html";
            this.controllerAs = "vm";
            this.controller = [
                'authenticationService',
                'customerService',
                '$http',
                '$mdDialog',
                massMediaSectionsController
            ];
            this.bindings = {
                customerId: "=",
            };
        }
        massMediaSectionsComponent.id = "massMediaSections";
        return massMediaSectionsComponent;
    }());
    App.massMediaSectionsComponent = massMediaSectionsComponent;
    angular.module(App.Module)
        .component(massMediaSectionsComponent.id, new massMediaSectionsComponent());
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    var PaginationComponentController = (function (_super) {
        __extends(PaginationComponentController, _super);
        function PaginationComponentController(eventManagerService, $scope, $timeout, globals) {
            var _this = this;
            _super.call(this);
            this.eventManagerService = eventManagerService;
            this.$scope = $scope;
            this.$timeout = $timeout;
            this.globals = globals;
            this.buttonsMaxCount = 5;
            this.$onInit = function () {
                _this.eventManagerService.subscribe(_this.globals.events.setPaginationCurrentPage, _this.setCurrentPage);
            };
            this.goTo = function () {
                _this.eventManagerService.publish(_this.pageChangeEvent, _this.currentPage);
            };
            this.setCurrentPage = function (pageNumber) {
                _this.currentPage = pageNumber;
            };
            this.$scope.$watch('vm.countPerPage', function (newValue, oldValue) {
                if (newValue && newValue != oldValue) {
                    _this.currentPage = 1;
                    _this.goTo();
                }
            });
        }
        return PaginationComponentController;
    }(App.BaseController));
    App.PaginationComponentController = PaginationComponentController;
    var PaginationComponent = (function () {
        function PaginationComponent() {
            this.templateUrl = "/components/pagination/pagination.component.html";
            this.controllerAs = "vm";
            this.controller = ['eventManagerService', '$scope', '$timeout', 'globals',
                PaginationComponentController
            ];
            this.bindings = {
                total: "<",
                countPerPage: "<",
                pageChangeEvent: "<",
                showPrevNext: "<?",
                showFirstLast: "<?"
            };
        }
        PaginationComponent.id = "pagination";
        return PaginationComponent;
    }());
    App.PaginationComponent = PaginationComponent;
    angular.module(App.Module)
        .component(PaginationComponent.id, new PaginationComponent());
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    var projectsController = (function (_super) {
        __extends(projectsController, _super);
        function projectsController($http, $mdDialog, authenticationService) {
            _super.call(this);
            this.$http = $http;
            this.$mdDialog = $mdDialog;
            this.authenticationService = authenticationService;
            this.initProjectProperties();
            this.project_form_options = {
                fields: {
                    project: { type: "combo", label: "PROJECT", source_url: "/request/get_projects", get: function (item) { return item; }, description: "name" }
                },
                rows: [
                    [{ field: "project", col_class: "col-md-4" }]
                ],
                label_small_size: true
            };
        }
        projectsController.prototype.initProjectProperties = function () {
            this.selected_project = null;
            this.mode = "view";
        };
        projectsController.prototype.addNewProject = function () {
            this.selected_project = new App.Project();
            this.mode = "new";
        };
        projectsController.prototype.removeProject = function (project) {
            var _this = this;
            if (confirm("Հեռացնե՞լ նախագիծը։")) {
                var className = this.className || this.object.getClassName();
                //project.object = <IObject> {id: this.object['id'], type: {description: className}};
                project.removeFromDB(function (err) {
                    if (!err) {
                        _this.object["loadFromDB"]();
                        _this.initProjectProperties();
                    }
                });
            }
        };
        projectsController.prototype.controlVisibility = function (controlName) {
            return this.authenticationService.user.controlPermissions.indexOf(controlName) != -1;
        };
        projectsController.prototype.saveProject = function (project) {
            var _this = this;
            var className = this.className || this.object.getClassName();
            //project.object = <IObject> {id: this.object['id'], type: {description: className}};
            project.saveToDB(function (err) {
                if (!err) {
                    _this.object["loadFromDB"]();
                    _this.initProjectProperties();
                }
            });
        };
        projectsController.prototype.cancelEditing = function () {
            this.initProjectProperties();
        };
        return projectsController;
    }(App.BaseController));
    var projectsComponent = (function () {
        function projectsComponent() {
            this.templateUrl = "/components/project-components/projects.component.html";
            this.controllerAs = "vm";
            this.controller = ['$http',
                '$mdDialog',
                'authenticationService',
                projectsController
            ];
            this.bindings = {
                object: "=",
                className: "=?"
            };
        }
        projectsComponent.id = "projects";
        return projectsComponent;
    }());
    App.projectsComponent = projectsComponent;
    angular.module(App.Module)
        .component(projectsComponent.id, new projectsComponent());
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    var relatedProjectsController = (function (_super) {
        __extends(relatedProjectsController, _super);
        function relatedProjectsController(authenticationService, projectService) {
            _super.call(this);
            this.authenticationService = authenticationService;
            this.projectService = projectService;
            this.initProjectProperties();
            this.project_form_options = {
                fields: {
                    project: { type: "combo", label: "PROJECT", source_url: "/request/get_projects", get: function (item) { return item; }, description: "name", required: function () { return true; } }
                },
                rows: [
                    [{ field: "project", col_class: "col-md-10" }]
                ],
                label_small_size: true
            };
        }
        relatedProjectsController.prototype.$onInit = function () {
            this.className = this.className || this.parentObject.getClassName();
        };
        relatedProjectsController.prototype.initProjectProperties = function () {
            this.selected_project = null;
            this.mode = "view";
        };
        relatedProjectsController.prototype.addNewProject = function () {
            this.selected_project = {};
            this.mode = "new";
        };
        relatedProjectsController.prototype.removeProjectFromParentObject = function (item) {
            var _this = this;
            if (confirm("Հեռացնե՞լ նախագիծը ։")) {
                this.projectService.removeProjectFromParentObject(item.id, this.className).then(function (response) {
                    if (response) {
                        _this.parentObject.loadFromDB();
                        _this.initProjectProperties();
                        App.Notifier.show("PROJECT_SUCCESSFULLY_REMOVED", App.MessageType.info);
                    }
                    else {
                        App.Notifier.show("ERROR_OCCURRED", App.MessageType.error);
                    }
                });
            }
        };
        relatedProjectsController.prototype.controlVisibility = function (controlName) {
            return this.authenticationService.user.controlPermissions.indexOf(controlName) != -1;
        };
        relatedProjectsController.prototype.setProjectToParentObject = function (item) {
            var _this = this;
            this.projectService.setProjectToParentObject(item.project.id, this.parentObject.id, this.className).then(function (response) {
                if (response) {
                    _this.parentObject.loadFromDB();
                    _this.initProjectProperties();
                    App.Notifier.show("CHANGES_SAVED", App.MessageType.info);
                }
                else {
                    App.Notifier.show("ERROR_OCCURRED", App.MessageType.error);
                }
            });
        };
        relatedProjectsController.prototype.cancelEditing = function () {
            this.initProjectProperties();
        };
        return relatedProjectsController;
    }(App.BaseController));
    var relatedProjectsComponent = (function () {
        function relatedProjectsComponent() {
            this.templateUrl = "/components/related-projects-components/related-projects.component.html";
            this.controllerAs = "vm";
            this.controller = ['authenticationService', 'projectService',
                relatedProjectsController
            ];
            this.bindings = {
                parentObject: "=",
            };
        }
        relatedProjectsComponent.id = "relatedProjects";
        return relatedProjectsComponent;
    }());
    App.relatedProjectsComponent = relatedProjectsComponent;
    angular.module(App.Module)
        .component(relatedProjectsComponent.id, new relatedProjectsComponent());
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    var usersController = (function (_super) {
        __extends(usersController, _super);
        function usersController() {
            _super.call(this);
        }
        return usersController;
    }(App.BaseController));
    var usersComponent = (function () {
        function usersComponent() {
            this.templateUrl = "/components/user-components/users.component.html";
            this.controllerAs = "vm";
            this.controller = [
                usersController
            ];
            this.bindings = {
                object: "=",
                className: "=?"
            };
        }
        usersComponent.id = "users";
        return usersComponent;
    }());
    App.usersComponent = usersComponent;
    angular.module(App.Module)
        .component(usersComponent.id, new usersComponent());
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    var UserMailingSubjectsComponentController = (function (_super) {
        __extends(UserMailingSubjectsComponentController, _super);
        function UserMailingSubjectsComponentController(customerService, authenticationService, $mdDialog) {
            var _this = this;
            _super.call(this);
            this.customerService = customerService;
            this.authenticationService = authenticationService;
            this.$mdDialog = $mdDialog;
            this.postDataObj = { customer_id: this.customerId };
            this.$onInit = function () {
                _this.initGridOptions();
            };
            this.initGridOptions = function () {
                _this.gridOptions = {
                    dataSource: {
                        transport: {
                            read: {
                                url: "/request/get_customer_users",
                                dataType: "json",
                                type: "POST",
                                data: { customer_id: _this.customerId }
                            }
                        },
                        pageSize: 20,
                    },
                    sortable: true,
                    pageable: true,
                    resizable: true,
                    filterable: true,
                    columnMenu: true,
                    columns: [
                        { field: "name", title: "NAME" }
                    ]
                };
            };
            this.getMailingSubjectsGridOptions = function (userId) {
                return {
                    dataSource: {
                        transport: {
                            read: {
                                url: "/request/get_mailing_subjects_by_user",
                                dataType: "json",
                                type: "POST",
                                data: { id: userId }
                            }
                        },
                        pageSize: 10,
                    },
                    sortable: true,
                    pageable: true,
                    resizable: true,
                    filterable: true,
                    columnMenu: true,
                    columns: [
                        { field: "name", title: "NAME" },
                        { template: "<md-button ng-if=\"vm.controlVisibility('remove_mailing_subject')\" class=\"md-icon-button custom-cell-button\" ng-click=\"vm.removeSubject(dataItem.id, dataItem.user_id)\" aria-label=\"Remove\"><md-tooltip><span data-translate=\"DELETE\"></span></md-tooltip><md-icon md-svg-icon=\"action:delete\" aria-label=\"...\"></md-icon></md-button>", width: 75 }
                    ]
                };
            };
            this.removeSubject = function (id, user_id) {
                if (confirm("Հեռացնե՞լ թեման։")) {
                    _this.customerService.removeSubjectForMailing(id).then(function (b) {
                        if (!(b instanceof Error)) {
                            var grid = $("#subjectsForMailingGrid" + user_id).data("kendoGrid");
                            grid.dataSource.read();
                            grid.refresh();
                        }
                    });
                }
            };
            this.controlVisibility = function (controlName) {
                return _this.authenticationService.user.controlPermissions.indexOf(controlName) != -1;
            };
        }
        UserMailingSubjectsComponentController.prototype.saveSubjectForMailing = function (user_id, $event) {
            var _this = this;
            this.$mdDialog.show({
                template: $('#mailingSubjectPopupTemplate').html(),
                parent: angular.element(document.body),
                targetEvent: $event || false,
                locals: { options: { user_id: user_id } },
                controller: ['$scope', 'options', function ($scope, options) {
                        $scope.options = options;
                        $scope.postDataObj = { customer_id: _this.customerId };
                        $scope.saveSubjectForMailing = function () {
                            _this.customerService.saveSubjectForMailing(options).then(function (b) {
                                if (!(b instanceof Error)) {
                                    var grid = $("#subjectsForMailingGrid" + user_id).data("kendoGrid");
                                    grid.dataSource.read();
                                    grid.refresh();
                                    _this.$mdDialog.hide();
                                }
                            });
                        };
                        $scope.cancel = function () {
                            _this.$mdDialog.hide();
                        };
                    }]
            });
        };
        return UserMailingSubjectsComponentController;
    }(App.BaseController));
    var UserMailingSubjectsComponent = (function () {
        function UserMailingSubjectsComponent() {
            this.templateUrl = "/components/user-mailing-subjects/user-mailing-subjects.component.html";
            this.controllerAs = "vm";
            this.controller = [
                'customerService',
                'authenticationService',
                '$mdDialog',
                UserMailingSubjectsComponentController
            ];
            this.bindings = {
                customerId: "<",
            };
        }
        UserMailingSubjectsComponent.id = "userMailingSubjects";
        return UserMailingSubjectsComponent;
    }());
    App.UserMailingSubjectsComponent = UserMailingSubjectsComponent;
    angular.module(App.Module)
        .component(UserMailingSubjectsComponent.id, new UserMailingSubjectsComponent());
})(App || (App = {}));

var App;
(function (App) {
    var DialogBoxController = (function () {
        /*@ngInject*/
        function DialogBoxController($mdDialog) {
            var _this = this;
            this.$mdDialog = $mdDialog;
            this.cancel = function () {
                _this.$mdDialog.hide();
            };
            document.getElementsByTagName("md-dialog")[0].className += (' ' + this.options.size + '-dialog') || '';
            this.options.title = this.options.title || "";
            this.options.item_options = this.options.item_options || "";
            this.options.item = this.options.item || "";
            this.options.acceptBtn = this.options.acceptBtn || false;
            this.options.cancelBtn = this.options.cancelBtn || false;
        }
        return DialogBoxController;
    }());
    var DialogBoxDirective = (function () {
        function DialogBoxDirective() {
            this.restrict = 'AE';
            this.templateUrl = './directives/dialog-box/dialog-box.html';
            this.replace = true;
            this.scope = {
                options: '='
            };
            this.controller = DialogBoxController;
            this.controllerAs = "vm";
            this.bindToController = true;
        }
        DialogBoxDirective.prototype.link = function ($scope, element, attributes) {
        };
        DialogBoxDirective.id = "dialogBox";
        return DialogBoxDirective;
    }());
    App.DialogBoxDirective = DialogBoxDirective;
    angular.module(App.Module)
        .directive(DialogBoxDirective.id, function () { return new DialogBoxDirective(); });
})(App || (App = {}));

var App;
(function (App) {
    var Filters = (function () {
        /*@ngInject*/
        function Filters($templateRequest, $compile) {
            var _this = this;
            this.$templateRequest = $templateRequest;
            this.$compile = $compile;
            this.restrict = 'A';
            this.scope = {
                filters: "=",
                filterFormOptions: "=",
                showFilters: "&",
                getFilteredData: "&",
                clearFilters: "&"
            };
            this.link = function ($scope, element, attributes) {
                _this.$templateRequest("directives/filters/filters.html").then(function (html) {
                    var template = angular.element(html);
                    element.append(template);
                    _this.$compile(template)($scope);
                });
            };
        }
        Filters.id = "filters";
        return Filters;
    }());
    App.Filters = Filters;
    angular.module(App.Module)
        .directive(Filters.id, ['$templateRequest', '$compile', function ($templateRequest, $compile) { return new Filters($templateRequest, $compile); }]);
})(App || (App = {}));

var App;
(function (App) {
    var ItemViewForm = (function () {
        /*@ngInject*/
        function ItemViewForm($templateRequest, $compile, authenticationService) {
            var _this = this;
            this.$templateRequest = $templateRequest;
            this.$compile = $compile;
            this.authenticationService = authenticationService;
            this.restrict = 'AE';
            this.scope = {
                options: '=',
                item: '=',
                header: '='
            };
            this.link = function ($scope, element, attributes) {
                if (!$scope["options"]) {
                    console.error("No options attribute on item-view-form");
                }
                $scope["getFieldParent"] = function (field, item) {
                    return field.get && field.get(item) || item;
                };
                for (var i in $scope["options"]["fields"]) {
                    if ($scope["item"]
                        && angular.isUndefined($scope["item"][i])
                        && angular.isDefined($scope["options"]["fields"][i].default_value)) {
                    }
                    if ($scope["options"]["fields"][i].type == "combo") {
                        $scope["options"]["fields"][i].kendoComboWatcher = $scope.$watch("getFieldParent('" + i + "', item)['" + ($scope["options"]["fields"].property_name || i) + "']", (function (field) {
                            return function (value) {
                                if (value && field && field.options) {
                                    field.kendoComboValue = value[field.options.id || "id"];
                                    field.kendoComboWatcher();
                                }
                            };
                        })($scope["options"]["fields"][i]));
                    }
                    if ($scope["options"]["fields"][i].type == "file") {
                        $scope["options"]["fields"][i].fileWatcher = $scope.$watch("getFieldParent('" + i + "', item)['" + ($scope["options"]["fields"].property_name || i) + "']", (function (field) {
                            return function (value) {
                                if (value) {
                                    field.addEvent();
                                }
                            };
                        })($scope["options"]["fields"][i]));
                    }
                    if ($scope["options"]["fields"][i].type == "multiselect") {
                        $scope["options"]["fields"][i].kendoMultiSelectWatcher = $scope.$watch("getFieldParent('" + i + "', item)['" + ($scope["options"]["fields"].property_name || i) + "']", (function (field) {
                            return function (value) {
                                if (value && field && field.options && field.options.id) {
                                    field.kendoMultiSelectValue = value[field.options.id || "id"];
                                    field.kendoMultiSelectWatcher();
                                }
                            };
                        })($scope["options"]["fields"][i]));
                    }
                }
                $scope["controlVisibility"] = function (controlName) {
                    if (_this.authenticationService.user.controlPermissions.indexOf(controlName) != -1) {
                        return true;
                    }
                    return false;
                };
                _this.$templateRequest("directives/item-view-form/item-view-form.html").then(function (html) {
                    var template = angular.element(html);
                    element.append(template);
                    _this.$compile(template)($scope);
                });
            };
        }
        ItemViewForm.id = "itemViewForm";
        return ItemViewForm;
    }());
    App.ItemViewForm = ItemViewForm;
    angular.module(App.Module)
        .directive(ItemViewForm.id, ['$templateRequest', '$compile', 'authenticationService', function ($templateRequest, $compile, authenticationService) { return new ItemViewForm($templateRequest, $compile, authenticationService); }]);
})(App || (App = {}));

var App;
(function (App) {
    /*    export class BindHtmlCompile implements ng.IDirective {
        static id = "bindHtmlCompile";
        public restrict = 'A';
        public link = (scope, element, attrs) => {
          scope.$watch(() => {
            return scope.$eval(attrs.bindHtmlCompile);
          }, (value) => {
            element.html(value);
            this.$compile(element.contents())(scope);
          });
        }
    
        constructor(public $compile: ng.ICompileService) {
        }
      }*/
    var TabPanel = (function () {
        function TabPanel() {
            this.restrict = 'AE';
            this.templateUrl = 'directives/tab-panel/tab-panel.html';
        }
        TabPanel.prototype.link = function ($scope, element, attrs) {
            $scope["tabs"] = $scope.$eval(attrs["tabs"]);
            $scope["selected_tab"] = 0;
            $scope["onTabSelected"] = function (i) {
                $scope["selected_tab"] = $scope["tabs"][i];
                $scope["selected_tab"].is_loaded = true;
            };
        };
        TabPanel.id = "tabPanel";
        return TabPanel;
    }());
    App.TabPanel = TabPanel;
    angular.module(App.Module)
        .directive(TabPanel.id, function () { return new TabPanel(); });
})(App || (App = {}));

var App;
(function (App) {
    (function (LanguageEnum) {
        LanguageEnum[LanguageEnum["am"] = 1] = "am";
        LanguageEnum[LanguageEnum["ru"] = 2] = "ru";
        LanguageEnum[LanguageEnum["en"] = 3] = "en";
    })(App.LanguageEnum || (App.LanguageEnum = {}));
    var LanguageEnum = App.LanguageEnum;
    (function (FeedElementActionsEnum) {
        FeedElementActionsEnum[FeedElementActionsEnum["ArticleMerge"] = 1] = "ArticleMerge";
        FeedElementActionsEnum[FeedElementActionsEnum["PressEventJoin"] = 2] = "PressEventJoin";
        FeedElementActionsEnum[FeedElementActionsEnum["RemoveElementFromProject"] = 3] = "RemoveElementFromProject";
        FeedElementActionsEnum[FeedElementActionsEnum["SendMailToPartner"] = 4] = "SendMailToPartner";
        FeedElementActionsEnum[FeedElementActionsEnum["AnalyzeElement"] = 5] = "AnalyzeElement";
        FeedElementActionsEnum[FeedElementActionsEnum["RemoveElementFromSubjects"] = 6] = "RemoveElementFromSubjects";
    })(App.FeedElementActionsEnum || (App.FeedElementActionsEnum = {}));
    var FeedElementActionsEnum = App.FeedElementActionsEnum;
    (function (AttitudeEnum) {
        AttitudeEnum[AttitudeEnum["Positive"] = 1] = "Positive";
        AttitudeEnum[AttitudeEnum["Neutral"] = 2] = "Neutral";
        AttitudeEnum[AttitudeEnum["Negative"] = 3] = "Negative";
    })(App.AttitudeEnum || (App.AttitudeEnum = {}));
    var AttitudeEnum = App.AttitudeEnum;
    (function (SelectionOperationEnum) {
        SelectionOperationEnum[SelectionOperationEnum["Add"] = 1] = "Add";
        SelectionOperationEnum[SelectionOperationEnum["Remove"] = 2] = "Remove";
    })(App.SelectionOperationEnum || (App.SelectionOperationEnum = {}));
    var SelectionOperationEnum = App.SelectionOperationEnum;
    (function (GroupSelectionEnum) {
        GroupSelectionEnum[GroupSelectionEnum["All"] = 1] = "All";
        GroupSelectionEnum[GroupSelectionEnum["None"] = 2] = "None";
        GroupSelectionEnum[GroupSelectionEnum["Read"] = 3] = "Read";
        GroupSelectionEnum[GroupSelectionEnum["Unread"] = 4] = "Unread";
    })(App.GroupSelectionEnum || (App.GroupSelectionEnum = {}));
    var GroupSelectionEnum = App.GroupSelectionEnum;
})(App || (App = {}));

var App;
(function (App) {
    (function (SocialMediaTypeEnum) {
        SocialMediaTypeEnum[SocialMediaTypeEnum["Facebook"] = 1] = "Facebook";
        SocialMediaTypeEnum[SocialMediaTypeEnum["Twitter"] = 2] = "Twitter";
    })(App.SocialMediaTypeEnum || (App.SocialMediaTypeEnum = {}));
    var SocialMediaTypeEnum = App.SocialMediaTypeEnum;
    (function (ResponseStatuses) {
        ResponseStatuses[ResponseStatuses["NotSet"] = -3] = "NotSet";
        ResponseStatuses[ResponseStatuses["Success"] = 0] = "Success";
        ResponseStatuses[ResponseStatuses["PartialSuccess"] = -2] = "PartialSuccess";
        ResponseStatuses[ResponseStatuses["Failure"] = -1] = "Failure";
        ResponseStatuses[ResponseStatuses["Unknown"] = 1] = "Unknown";
        ResponseStatuses[ResponseStatuses["UserFriendlyMessage"] = 2] = "UserFriendlyMessage";
    })(App.ResponseStatuses || (App.ResponseStatuses = {}));
    var ResponseStatuses = App.ResponseStatuses;
    (function (ViewMode) {
        ViewMode[ViewMode["List"] = 1] = "List";
        ViewMode[ViewMode["View"] = 2] = "View";
        ViewMode[ViewMode["Create"] = 3] = "Create";
        ViewMode[ViewMode["Edit"] = 4] = "Edit";
    })(App.ViewMode || (App.ViewMode = {}));
    var ViewMode = App.ViewMode;
    (function (FilterUsingPageEnum) {
        FilterUsingPageEnum[FilterUsingPageEnum["NewsFeed"] = 1] = "NewsFeed";
        FilterUsingPageEnum[FilterUsingPageEnum["Analysis"] = 2] = "Analysis";
    })(App.FilterUsingPageEnum || (App.FilterUsingPageEnum = {}));
    var FilterUsingPageEnum = App.FilterUsingPageEnum;
    (function (NewsFeedSelectionModeEnum) {
        NewsFeedSelectionModeEnum[NewsFeedSelectionModeEnum["Keywords"] = 1] = "Keywords";
        NewsFeedSelectionModeEnum[NewsFeedSelectionModeEnum["Sections"] = 2] = "Sections";
    })(App.NewsFeedSelectionModeEnum || (App.NewsFeedSelectionModeEnum = {}));
    var NewsFeedSelectionModeEnum = App.NewsFeedSelectionModeEnum;
    (function (NewsFeedTypeEnum) {
        NewsFeedTypeEnum[NewsFeedTypeEnum["MassMedia"] = 1] = "MassMedia";
        NewsFeedTypeEnum[NewsFeedTypeEnum["SocialMedia"] = 2] = "SocialMedia";
    })(App.NewsFeedTypeEnum || (App.NewsFeedTypeEnum = {}));
    var NewsFeedTypeEnum = App.NewsFeedTypeEnum;
})(App || (App = {}));

var App;
(function (App) {
    (function (HttpStatusCode) {
        //
        // Summary:
        //     Equivalent to HTTP status 100. System.Net.HttpStatusCode.Continue indicates that
        //     the client can continue with its request.
        HttpStatusCode[HttpStatusCode["Continue"] = 100] = "Continue";
        //
        // Summary:
        //     Equivalent to HTTP status 101. System.Net.HttpStatusCode.SwitchingProtocols indicates
        //     that the protocol version or protocol is being changed.
        HttpStatusCode[HttpStatusCode["SwitchingProtocols"] = 101] = "SwitchingProtocols";
        //
        // Summary:
        //     Equivalent to HTTP status 200. System.Net.HttpStatusCode.OK indicates that the
        //     request succeeded and that the requested information is in the response. This
        //     is the most common status code to receive.
        HttpStatusCode[HttpStatusCode["OK"] = 200] = "OK";
        //
        // Summary:
        //     Equivalent to HTTP status 201. System.Net.HttpStatusCode.Created indicates that
        //     the request resulted in a new resource created before the response was sent.
        HttpStatusCode[HttpStatusCode["Created"] = 201] = "Created";
        //
        // Summary:
        //     Equivalent to HTTP status 202. System.Net.HttpStatusCode.Accepted indicates that
        //     the request has been accepted for further processing.
        HttpStatusCode[HttpStatusCode["Accepted"] = 202] = "Accepted";
        //
        // Summary:
        //     Equivalent to HTTP status 203. System.Net.HttpStatusCode.NonAuthoritativeInformation
        //     indicates that the returned metainformation is from a cached copy instead of
        //     the origin server and therefore may be incorrect.
        HttpStatusCode[HttpStatusCode["NonAuthoritativeInformation"] = 203] = "NonAuthoritativeInformation";
        //
        // Summary:
        //     Equivalent to HTTP status 204. System.Net.HttpStatusCode.NoContent indicates
        //     that the request has been successfully processed and that the response is intentionally
        //     blank.
        HttpStatusCode[HttpStatusCode["NoContent"] = 204] = "NoContent";
        //
        // Summary:
        //     Equivalent to HTTP status 205. System.Net.HttpStatusCode.ResetContent indicates
        //     that the client should reset (not reload) the current resource.
        HttpStatusCode[HttpStatusCode["ResetContent"] = 205] = "ResetContent";
        //
        // Summary:
        //     Equivalent to HTTP status 206. System.Net.HttpStatusCode.PartialContent indicates
        //     that the response is a partial response as requested by a GET request that includes
        //     a byte range.
        HttpStatusCode[HttpStatusCode["PartialContent"] = 206] = "PartialContent";
        //
        // Summary:
        //     Equivalent to HTTP status 300. System.Net.HttpStatusCode.MultipleChoices indicates
        //     that the requested information has multiple representations. The default action
        //     is to treat this status as a redirect and follow the contents of the Location
        //     header associated with this response.
        HttpStatusCode[HttpStatusCode["MultipleChoices"] = 300] = "MultipleChoices";
        //
        // Summary:
        //     Equivalent to HTTP status 300. System.Net.HttpStatusCode.Ambiguous indicates
        //     that the requested information has multiple representations. The default action
        //     is to treat this status as a redirect and follow the contents of the Location
        //     header associated with this response.
        HttpStatusCode[HttpStatusCode["Ambiguous"] = 300] = "Ambiguous";
        //
        // Summary:
        //     Equivalent to HTTP status 301. System.Net.HttpStatusCode.MovedPermanently indicates
        //     that the requested information has been moved to the URI specified in the Location
        //     header. The default action when this status is received is to follow the Location
        //     header associated with the response.
        HttpStatusCode[HttpStatusCode["MovedPermanently"] = 301] = "MovedPermanently";
        //
        // Summary:
        //     Equivalent to HTTP status 301. System.Net.HttpStatusCode.Moved indicates that
        //     the requested information has been moved to the URI specified in the Location
        //     header. The default action when this status is received is to follow the Location
        //     header associated with the response. When the original request method was POST,
        //     the redirected request will use the GET method.
        HttpStatusCode[HttpStatusCode["Moved"] = 301] = "Moved";
        //
        // Summary:
        //     Equivalent to HTTP status 302. System.Net.HttpStatusCode.Found indicates that
        //     the requested information is located at the URI specified in the Location header.
        //     The default action when this status is received is to follow the Location header
        //     associated with the response. When the original request method was POST, the
        //     redirected request will use the GET method.
        HttpStatusCode[HttpStatusCode["Found"] = 302] = "Found";
        //
        // Summary:
        //     Equivalent to HTTP status 302. System.Net.HttpStatusCode.Redirect indicates that
        //     the requested information is located at the URI specified in the Location header.
        //     The default action when this status is received is to follow the Location header
        //     associated with the response. When the original request method was POST, the
        //     redirected request will use the GET method.
        HttpStatusCode[HttpStatusCode["Redirect"] = 302] = "Redirect";
        //
        // Summary:
        //     Equivalent to HTTP status 303. System.Net.HttpStatusCode.SeeOther automatically
        //     redirects the client to the URI specified in the Location header as the result
        //     of a POST. The request to the resource specified by the Location header will
        //     be made with a GET.
        HttpStatusCode[HttpStatusCode["SeeOther"] = 303] = "SeeOther";
        //
        // Summary:
        //     Equivalent to HTTP status 303. System.Net.HttpStatusCode.RedirectMethod automatically
        //     redirects the client to the URI specified in the Location header as the result
        //     of a POST. The request to the resource specified by the Location header will
        //     be made with a GET.
        HttpStatusCode[HttpStatusCode["RedirectMethod"] = 303] = "RedirectMethod";
        //
        // Summary:
        //     Equivalent to HTTP status 304. System.Net.HttpStatusCode.NotModified indicates
        //     that the client's cached copy is up to date. The contents of the resource are
        //     not transferred.
        HttpStatusCode[HttpStatusCode["NotModified"] = 304] = "NotModified";
        //
        // Summary:
        //     Equivalent to HTTP status 305. System.Net.HttpStatusCode.UseProxy indicates that
        //     the request should use the proxy server at the URI specified in the Location
        //     header.
        HttpStatusCode[HttpStatusCode["UseProxy"] = 305] = "UseProxy";
        //
        // Summary:
        //     Equivalent to HTTP status 306. System.Net.HttpStatusCode.Unused is a proposed
        //     extension to the HTTP/1.1 specification that is not fully specified.
        HttpStatusCode[HttpStatusCode["Unused"] = 306] = "Unused";
        //
        // Summary:
        //     Equivalent to HTTP status 307. System.Net.HttpStatusCode.TemporaryRedirect indicates
        //     that the request information is located at the URI specified in the Location
        //     header. The default action when this status is received is to follow the Location
        //     header associated with the response. When the original request method was POST,
        //     the redirected request will also use the POST method.
        HttpStatusCode[HttpStatusCode["TemporaryRedirect"] = 307] = "TemporaryRedirect";
        //
        // Summary:
        //     Equivalent to HTTP status 307. System.Net.HttpStatusCode.RedirectKeepVerb indicates
        //     that the request information is located at the URI specified in the Location
        //     header. The default action when this status is received is to follow the Location
        //     header associated with the response. When the original request method was POST,
        //     the redirected request will also use the POST method.
        HttpStatusCode[HttpStatusCode["RedirectKeepVerb"] = 307] = "RedirectKeepVerb";
        //
        // Summary:
        //     Equivalent to HTTP status 400. System.Net.HttpStatusCode.BadRequest indicates
        //     that the request could not be understood by the server. System.Net.HttpStatusCode.BadRequest
        //     is sent when no other error is applicable, or if the exact error is unknown or
        //     does not have its own error code.
        HttpStatusCode[HttpStatusCode["BadRequest"] = 400] = "BadRequest";
        //
        // Summary:
        //     Equivalent to HTTP status 401. System.Net.HttpStatusCode.Unauthorized indicates
        //     that the requested resource requires authentication. The WWW-Authenticate header
        //     contains the details of how to perform the authentication.
        HttpStatusCode[HttpStatusCode["Unauthorized"] = 401] = "Unauthorized";
        //
        // Summary:
        //     Equivalent to HTTP status 402. System.Net.HttpStatusCode.PaymentRequired is reserved
        //     for future use.
        HttpStatusCode[HttpStatusCode["PaymentRequired"] = 402] = "PaymentRequired";
        //
        // Summary:
        //     Equivalent to HTTP status 403. System.Net.HttpStatusCode.Forbidden indicates
        //     that the server refuses to fulfill the request.
        HttpStatusCode[HttpStatusCode["Forbidden"] = 403] = "Forbidden";
        //
        // Summary:
        //     Equivalent to HTTP status 404. System.Net.HttpStatusCode.NotFound indicates that
        //     the requested resource does not exist on the server.
        HttpStatusCode[HttpStatusCode["NotFound"] = 404] = "NotFound";
        //
        // Summary:
        //     Equivalent to HTTP status 405. System.Net.HttpStatusCode.MethodNotAllowed indicates
        //     that the request method (POST or GET) is not allowed on the requested resource.
        HttpStatusCode[HttpStatusCode["MethodNotAllowed"] = 405] = "MethodNotAllowed";
        //
        // Summary:
        //     Equivalent to HTTP status 406. System.Net.HttpStatusCode.NotAcceptable indicates
        //     that the client has indicated with Accept headers that it will not accept any
        //     of the available representations of the resource.
        HttpStatusCode[HttpStatusCode["NotAcceptable"] = 406] = "NotAcceptable";
        //
        // Summary:
        //     Equivalent to HTTP status 407. System.Net.HttpStatusCode.ProxyAuthenticationRequired
        //     indicates that the requested proxy requires authentication. The Proxy-authenticate
        //     header contains the details of how to perform the authentication.
        HttpStatusCode[HttpStatusCode["ProxyAuthenticationRequired"] = 407] = "ProxyAuthenticationRequired";
        //
        // Summary:
        //     Equivalent to HTTP status 408. System.Net.HttpStatusCode.RequestTimeout indicates
        //     that the client did not send a request within the time the server was expecting
        //     the request.
        HttpStatusCode[HttpStatusCode["RequestTimeout"] = 408] = "RequestTimeout";
        //
        // Summary:
        //     Equivalent to HTTP status 409. System.Net.HttpStatusCode.Conflict indicates that
        //     the request could not be carried out because of a conflict on the server.
        HttpStatusCode[HttpStatusCode["Conflict"] = 409] = "Conflict";
        //
        // Summary:
        //     Equivalent to HTTP status 410. System.Net.HttpStatusCode.Gone indicates that
        //     the requested resource is no longer available.
        HttpStatusCode[HttpStatusCode["Gone"] = 410] = "Gone";
        //
        // Summary:
        //     Equivalent to HTTP status 411. System.Net.HttpStatusCode.LengthRequired indicates
        //     that the required Content-length header is missing.
        HttpStatusCode[HttpStatusCode["LengthRequired"] = 411] = "LengthRequired";
        //
        // Summary:
        //     Equivalent to HTTP status 412. System.Net.HttpStatusCode.PreconditionFailed indicates
        //     that a condition set for this request failed, and the request cannot be carried
        //     out. Conditions are set with conditional request headers like If-Match, If-None-Match,
        //     or If-Unmodified-Since.
        HttpStatusCode[HttpStatusCode["PreconditionFailed"] = 412] = "PreconditionFailed";
        //
        // Summary:
        //     Equivalent to HTTP status 413. System.Net.HttpStatusCode.RequestEntityTooLarge
        //     indicates that the request is too large for the server to process.
        HttpStatusCode[HttpStatusCode["RequestEntityTooLarge"] = 413] = "RequestEntityTooLarge";
        //
        // Summary:
        //     Equivalent to HTTP status 414. System.Net.HttpStatusCode.RequestUriTooLong indicates
        //     that the URI is too long.
        HttpStatusCode[HttpStatusCode["RequestUriTooLong"] = 414] = "RequestUriTooLong";
        //
        // Summary:
        //     Equivalent to HTTP status 415. System.Net.HttpStatusCode.UnsupportedMediaType
        //     indicates that the request is an unsupported type.
        HttpStatusCode[HttpStatusCode["UnsupportedMediaType"] = 415] = "UnsupportedMediaType";
        //
        // Summary:
        //     Equivalent to HTTP status 416. System.Net.HttpStatusCode.RequestedRangeNotSatisfiable
        //     indicates that the range of data requested from the resource cannot be returned,
        //     either because the beginning of the range is before the beginning of the resource,
        //     or the end of the range is after the end of the resource.
        HttpStatusCode[HttpStatusCode["RequestedRangeNotSatisfiable"] = 416] = "RequestedRangeNotSatisfiable";
        //
        // Summary:
        //     Equivalent to HTTP status 417. System.Net.HttpStatusCode.ExpectationFailed indicates
        //     that an expectation given in an Expect header could not be met by the server.
        HttpStatusCode[HttpStatusCode["ExpectationFailed"] = 417] = "ExpectationFailed";
        //
        // Summary:
        //     Equivalent to HTTP status 426. System.Net.HttpStatusCode.UpgradeRequired indicates
        //     that the client should switch to a different protocol such as TLS/1.0.
        HttpStatusCode[HttpStatusCode["UpgradeRequired"] = 426] = "UpgradeRequired";
        //
        // Summary:
        //     Equivalent to HTTP status 500. System.Net.HttpStatusCode.InternalServerError
        //     indicates that a generic error has occurred on the server.
        HttpStatusCode[HttpStatusCode["InternalServerError"] = 500] = "InternalServerError";
        //
        // Summary:
        //     Equivalent to HTTP status 501. System.Net.HttpStatusCode.NotImplemented indicates
        //     that the server does not support the requested function.
        HttpStatusCode[HttpStatusCode["NotImplemented"] = 501] = "NotImplemented";
        //
        // Summary:
        //     Equivalent to HTTP status 502. System.Net.HttpStatusCode.BadGateway indicates
        //     that an intermediate proxy server received a bad response from another proxy
        //     or the origin server.
        HttpStatusCode[HttpStatusCode["BadGateway"] = 502] = "BadGateway";
        //
        // Summary:
        //     Equivalent to HTTP status 503. System.Net.HttpStatusCode.ServiceUnavailable indicates
        //     that the server is temporarily unavailable, usually due to high load or maintenance.
        HttpStatusCode[HttpStatusCode["ServiceUnavailable"] = 503] = "ServiceUnavailable";
        //
        // Summary:
        //     Equivalent to HTTP status 504. System.Net.HttpStatusCode.GatewayTimeout indicates
        //     that an intermediate proxy server timed out while waiting for a response from
        //     another proxy or the origin server.
        HttpStatusCode[HttpStatusCode["GatewayTimeout"] = 504] = "GatewayTimeout";
        //
        // Summary:
        //     Equivalent to HTTP status 505. System.Net.HttpStatusCode.HttpVersionNotSupported
        //     indicates that the requested HTTP version is not supported by the server.
        HttpStatusCode[HttpStatusCode["HttpVersionNotSupported"] = 505] = "HttpVersionNotSupported";
    })(App.HttpStatusCode || (App.HttpStatusCode = {}));
    var HttpStatusCode = App.HttpStatusCode;
})(App || (App = {}));

var App;
(function (App) {
    (function (MassMediaTypeEnum) {
        MassMediaTypeEnum[MassMediaTypeEnum["online"] = 1] = "online";
        MassMediaTypeEnum[MassMediaTypeEnum["tv"] = 2] = "tv";
        MassMediaTypeEnum[MassMediaTypeEnum["press"] = 3] = "press";
    })(App.MassMediaTypeEnum || (App.MassMediaTypeEnum = {}));
    var MassMediaTypeEnum = App.MassMediaTypeEnum;
    (function (MassMediaRatingEnum) {
        MassMediaRatingEnum[MassMediaRatingEnum["Top1"] = 1] = "Top1";
        MassMediaRatingEnum[MassMediaRatingEnum["Top2"] = 2] = "Top2";
        MassMediaRatingEnum[MassMediaRatingEnum["Top3"] = 3] = "Top3";
        MassMediaRatingEnum[MassMediaRatingEnum["Top4"] = 4] = "Top4";
    })(App.MassMediaRatingEnum || (App.MassMediaRatingEnum = {}));
    var MassMediaRatingEnum = App.MassMediaRatingEnum;
})(App || (App = {}));

var App;
(function (App) {
    (function (NotificationStatusEnum) {
        NotificationStatusEnum[NotificationStatusEnum["info"] = 0] = "info";
        NotificationStatusEnum[NotificationStatusEnum["success"] = 1] = "success";
        NotificationStatusEnum[NotificationStatusEnum["warning"] = 2] = "warning";
        NotificationStatusEnum[NotificationStatusEnum["error"] = 3] = "error";
    })(App.NotificationStatusEnum || (App.NotificationStatusEnum = {}));
    var NotificationStatusEnum = App.NotificationStatusEnum;
})(App || (App = {}));

var App;
(function (App) {
    (function (UserRoleEnum) {
        UserRoleEnum[UserRoleEnum["BackOffice"] = 1] = "BackOffice";
        UserRoleEnum[UserRoleEnum["Customer"] = 2] = "Customer";
    })(App.UserRoleEnum || (App.UserRoleEnum = {}));
    var UserRoleEnum = App.UserRoleEnum;
})(App || (App = {}));

var App;
(function (App) {
    var BaseResponse = (function () {
        function BaseResponse(data) {
            var self = this;
            self.load(data);
        }
        BaseResponse.prototype.load = function (data) {
            var self = this;
            if (data) {
                self.Errors = data.Errors;
                self.HasError = data.HasError;
                self.HttpStatusCode = data.HttpStatusCode;
                self.ResponseStatus = data.ResponseStatus;
            }
        };
        ;
        return BaseResponse;
    }());
    App.BaseResponse = BaseResponse;
    ;
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    var Response = (function (_super) {
        __extends(Response, _super);
        function Response(data) {
            _super.call(this, data);
            var self = this;
            self.load(data);
        }
        Response.prototype.load = function (data) {
            var self = this;
            if (data) {
                self.Result = data.Result;
            }
        };
        ;
        return Response;
    }(App.BaseResponse));
    App.Response = Response;
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    var AnalysisChartController = (function (_super) {
        __extends(AnalysisChartController, _super);
        function AnalysisChartController(eventManagerService, globals) {
            var _this = this;
            _super.call(this);
            this.eventManagerService = eventManagerService;
            this.globals = globals;
            this.removeFromView = function () {
                _this.eventManagerService.publish(_this.globals.events.removeChartFromList, _this.chart.id, _this.chart.uid);
            };
        }
        AnalysisChartController.prototype.$onInit = function () {
            this.generatedId = Date.now().toString();
            switch (this.chart.id) {
                case 1:
                    this.chartOption = {
                        type: "pie"
                    };
                    break;
                case 2:
                    this.chartOption = {
                        type: "column"
                    };
                    break;
                case 3:
                    this.chartOption = {
                        type: "column"
                    };
                    break;
                case 4:
                    this.chartOption = {
                        type: "column"
                    };
                    break;
                case 5:
                    this.chartOption = {
                        type: "column",
                        stacking: 'percent'
                    };
                    break;
                case 6:
                    this.chartOption = {
                        type: "column"
                    };
                    break;
                case 7:
                    this.chartOption = {
                        type: "column",
                        stacking: 'percent'
                    };
                    break;
                case 8:
                    this.chartOption = {
                        type: "column"
                    };
                    break;
                case 9:
                    this.chartOption = {
                        type: "pie"
                    };
                    break;
                case 10:
                    this.chartOption = {
                        type: 'line',
                    };
                    break;
                case 11:
                    this.chartOption = {
                        type: "column"
                    };
                    break;
                case 12:
                    this.chartOption = {
                        type: "pie"
                    };
                    break;
                case 13:
                    this.chartOption = {
                        type: "column"
                    };
                    break;
                case 14:
                    this.chartOption = {
                        type: "column",
                        stacking: 'percent'
                    };
                    break;
                case 15:
                    this.chartOption = {
                        type: "column",
                        stacking: 'percent'
                    };
                    break;
            }
        };
        return AnalysisChartController;
    }(App.BaseController));
    App.AnalysisChartController = AnalysisChartController;
    var AnalysisChartComponent = (function () {
        function AnalysisChartComponent() {
            this.templateUrl = "/components/analysis-components/analysis-chart/analysis-chart.component.html";
            this.controllerAs = "vm";
            this.controller = [
                'eventManagerService',
                'globals',
                AnalysisChartController
            ];
            this.bindings = {
                chart: "<",
                newsFeedType: "<?"
            };
        }
        AnalysisChartComponent.id = "analysisChart";
        return AnalysisChartComponent;
    }());
    App.AnalysisChartComponent = AnalysisChartComponent;
    angular.module(App.Module)
        .component(AnalysisChartComponent.id, new AnalysisChartComponent());
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    var AnalysisFiltersController = (function (_super) {
        __extends(AnalysisFiltersController, _super);
        function AnalysisFiltersController() {
            _super.call(this);
            this.$onInit = function () {
            };
        }
        return AnalysisFiltersController;
    }(App.BaseController));
    App.AnalysisFiltersController = AnalysisFiltersController;
    var AnalysisFiltersComponent = (function () {
        function AnalysisFiltersComponent() {
            this.templateUrl = "/components/analysis-components/analysis-filters/analysis-filters.component.html";
            this.controllerAs = "vm";
            this.controller = [
                AnalysisFiltersController
            ];
            this.bindings = {
                filter: "<"
            };
        }
        AnalysisFiltersComponent.id = "analysisFilters";
        return AnalysisFiltersComponent;
    }());
    App.AnalysisFiltersComponent = AnalysisFiltersComponent;
    angular.module(App.Module)
        .component(AnalysisFiltersComponent.id, new AnalysisFiltersComponent());
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    var AnalysisListController = (function (_super) {
        __extends(AnalysisListController, _super);
        function AnalysisListController(analysisService, eventManagerService, globals) {
            var _this = this;
            _super.call(this);
            this.analysisService = analysisService;
            this.eventManagerService = eventManagerService;
            this.globals = globals;
            this.filterUsingPageEnum = App.FilterUsingPageEnum;
            this.newsFeedTypeEnum = App.NewsFeedTypeEnum;
            this.$onInit = function () {
                _this.analysisService.getAnalysisByUser(_this.newsFeedType).then(function (response) {
                    _this.analysisList = response;
                });
                _this.commonFilters = { project: null, subject: null, start_date: null, end_date: null };
            };
            this.clearFilters = function () {
                for (var key in _this.commonFilters) {
                    _this.commonFilters[key] = null;
                }
            };
            this.generateChart = function (analysis) {
                if (analysis.id == 11 && _this.commonFilters.subject.length == 0) {
                    App.Notifier.show("Նախ ընտրեք որևէ թեմա։", App.MessageType.warning);
                }
                else if (analysis.id == 11 && _this.commonFilters.subject.length > 1) {
                    App.Notifier.show("Վերլուծությունը նախատեսված է միայն մեկ թեմայի համար։", App.MessageType.warning);
                }
                else {
                    analysis.filters = _this.commonFilters;
                    _this.eventManagerService.publish(_this.globals.events.generateAnalysisChart, analysis, _this.newsFeedType);
                }
            };
        }
        return AnalysisListController;
    }(App.BaseController));
    App.AnalysisListController = AnalysisListController;
    var AnalysisListComponent = (function () {
        function AnalysisListComponent() {
            this.templateUrl = "/components/analysis-components/analysis-list/analysis-list.html";
            this.controllerAs = "vm";
            this.controller = [
                'analysisService',
                'eventManagerService',
                'globals',
                AnalysisListController
            ];
            this.bindings = {
                newsFeedType: "<?",
            };
        }
        AnalysisListComponent.id = "analysisList";
        return AnalysisListComponent;
    }());
    App.AnalysisListComponent = AnalysisListComponent;
    angular.module(App.Module)
        .component(AnalysisListComponent.id, new AnalysisListComponent());
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    var AnalysisViewController = (function (_super) {
        __extends(AnalysisViewController, _super);
        function AnalysisViewController(eventManagerService, analysisService, globals) {
            var _this = this;
            _super.call(this);
            this.eventManagerService = eventManagerService;
            this.analysisService = analysisService;
            this.globals = globals;
            this.transferEvent = 'transfer';
            this.charts = {
                "analysis1": [],
                "analysis2": [],
                "analysis3": [],
                "analysis4": [],
                "analysis5": [],
                "analysis6": [],
                "analysis7": [],
                "analysis8": [],
                "analysis9": [],
                "analysis10": [],
                "analysis11": [],
                "analysis12": [],
                "analysis13": [],
                "analysis14": [],
                "analysis15": [],
            };
            this.generateAnalysisChart = function (analysis, newsFeedType) {
                var uid = "uid_" + Date.now().toString();
                _this.newsFeedType = newsFeedType;
                switch (analysis.id) {
                    case 1:
                        _this.analysisService.analysisByDistributionOfMediaCoverage(analysis).then(function (response) {
                            var c = {
                                id: analysis.id,
                                name: analysis.name,
                                data: response,
                                uid: uid,
                                filter: analysis.filters
                            };
                            _this.charts.analysis1.unshift(c);
                        }).catch(function (err) {
                            App.Notifier.show("ERROR_OCCURRED", App.MessageType.error);
                        });
                        break;
                    case 2:
                        _this.analysisService.analysisByAttitudes(analysis).then(function (response) {
                            var c = {
                                id: analysis.id,
                                name: analysis.name,
                                data: response,
                                additionalProperty: "attitude",
                                uid: uid,
                                filter: analysis.filters
                            };
                            _this.charts.analysis2.unshift(c);
                        }).catch(function (err) {
                            App.Notifier.show("ERROR_OCCURRED", App.MessageType.error);
                        });
                        break;
                    case 3:
                        _this.analysisService.analysisByMassMediaRatings(analysis).then(function (response) {
                            var c = {
                                id: analysis.id,
                                name: analysis.name,
                                data: response,
                                additionalProperty: "mass_media_rating",
                                uid: uid,
                                filter: analysis.filters
                            };
                            _this.charts.analysis3.unshift(c);
                        }).catch(function (err) {
                            App.Notifier.show("ERROR_OCCURRED", App.MessageType.error);
                        });
                        break;
                    case 4:
                        _this.analysisService.analysisByReferenceTypes(analysis).then(function (response) {
                            var c = {
                                id: analysis.id,
                                uid: uid,
                                name: analysis.name,
                                data: response,
                                additionalProperty: "reference_type",
                                filter: analysis.filters
                            };
                            _this.charts.analysis4.unshift(c);
                        }).catch(function (err) {
                            console.log(err.message);
                        });
                        break;
                    case 5:
                        _this.analysisService.analysisByCategory(analysis).then(function (response) {
                            var c = {
                                id: analysis.id,
                                uid: uid,
                                name: analysis.name,
                                data: response,
                                filter: analysis.filters
                            };
                            _this.charts.analysis5.unshift(c);
                        }).catch(function (err) {
                            console.log(err.message);
                        });
                        break;
                    case 6:
                        _this.analysisService.analysisScore(analysis).then(function (response) {
                            var c = {
                                id: analysis.id,
                                uid: uid,
                                name: analysis.name,
                                data: response,
                                filter: analysis.filters
                            };
                            _this.charts.analysis6.unshift(c);
                        }).catch(function (err) {
                            console.log(err.message);
                        });
                        break;
                    case 7:
                        _this.analysisService.analysisBySphere(analysis).then(function (response) {
                            var c = {
                                id: analysis.id,
                                uid: uid,
                                name: analysis.name,
                                data: response,
                                filter: analysis.filters
                            };
                            _this.charts.analysis7.unshift(c);
                        }).catch(function (err) {
                            console.log(err.message);
                        });
                        break;
                    case 8:
                        _this.analysisService.analysisFB(analysis).then(function (response) {
                            var c = {
                                id: analysis.id,
                                uid: uid,
                                name: analysis.name,
                                data: response,
                                filter: analysis.filters
                            };
                            _this.charts.analysis8.unshift(c);
                        }).catch(function (err) {
                            console.log(err.message);
                        });
                        break;
                    case 9:
                        _this.analysisService.analysisByPressEvents(analysis).then(function (response) {
                            var c = {
                                id: analysis.id,
                                name: analysis.name,
                                data: response,
                                uid: uid,
                                filter: analysis.filters
                            };
                            _this.charts.analysis9.unshift(c);
                        }).catch(function (err) {
                            App.Notifier.show("ERROR_OCCURRED", App.MessageType.error);
                        });
                        break;
                    case 10:
                        _this.analysisService.analysisCompareForPeriod(analysis).then(function (response) {
                            var c = {
                                id: analysis.id,
                                name: analysis.name,
                                data: response,
                                uid: uid,
                                filter: analysis.filters
                            };
                            _this.charts.analysis10.unshift(c);
                        }).catch(function (err) {
                            App.Notifier.show("ERROR_OCCURRED", App.MessageType.error);
                        });
                        break;
                    case 11:
                        _this.analysisService.analysisWeeklyBySubject(analysis).then(function (response) {
                            var c = {
                                id: analysis.id,
                                uid: uid,
                                name: analysis.name,
                                data: response,
                                filter: analysis.filters
                            };
                            _this.charts.analysis11.unshift(c);
                        }).catch(function (err) {
                            console.log(err.message);
                        });
                        break;
                    // case 11:
                    //     this.analysisService.analysisByTags(analysis).then((response) => {
                    //         let c: IChart = {
                    //             id: analysis.id,
                    //             uid: uid,
                    //             name: analysis.name,
                    //             data: response,
                    //             filter: analysis.filters
                    //         };
                    //         this.charts.analysis10.unshift(c)
                    //     }).catch((err: Error) => {
                    //         console.log(err.message);
                    //     });
                    //     break;
                    case 12:
                        _this.analysisService.fbAnalysisByDistributionOfMediaCoverage(analysis).then(function (response) {
                            var c = {
                                id: analysis.id,
                                uid: uid,
                                name: analysis.name,
                                data: response,
                                filter: analysis.filters
                            };
                            _this.charts.analysis12.unshift(c);
                        }).catch(function (err) {
                            console.log(err.message);
                        });
                        break;
                    case 13:
                        _this.analysisService.fbAnalysisByAttitudes(analysis).then(function (response) {
                            var c = {
                                id: analysis.id,
                                uid: uid,
                                name: analysis.name,
                                data: response,
                                filter: analysis.filters
                            };
                            _this.charts.analysis13.unshift(c);
                        }).catch(function (err) {
                            console.log(err.message);
                        });
                        break;
                    case 14:
                        _this.analysisService.fbAnalysisByCategory(analysis).then(function (response) {
                            var c = {
                                id: analysis.id,
                                uid: uid,
                                name: analysis.name,
                                data: response,
                                filter: analysis.filters
                            };
                            _this.charts.analysis14.unshift(c);
                        }).catch(function (err) {
                            console.log(err.message);
                        });
                        break;
                    case 15:
                        _this.analysisService.fbAnalysisBySphere(analysis).then(function (response) {
                            var c = {
                                id: analysis.id,
                                uid: uid,
                                name: analysis.name,
                                data: response,
                                filter: analysis.filters
                            };
                            _this.charts.analysis15.unshift(c);
                        }).catch(function (err) {
                            console.log(err.message);
                        });
                        break;
                }
            };
            this.removeChart = function (id, uid) {
                var idx = _.findIndex(_this.charts["analysis" + id.toString()], function (item) {
                    return item.uid == uid;
                });
                _this.charts["analysis" + id.toString()].splice(idx, 1);
            };
            this.isChartsExists = function () {
                for (var key in _this.charts) {
                    if (_this.charts[key].length) {
                        return false;
                    }
                }
                return true;
            };
            this.eventManagerService.subscribe(globals.events.removeChartFromList, this.removeChart);
        }
        AnalysisViewController.prototype.$onInit = function () {
            this.eventManagerService.subscribe(this.globals.events.generateAnalysisChart, this.generateAnalysisChart);
        };
        return AnalysisViewController;
    }(App.BaseController));
    App.AnalysisViewController = AnalysisViewController;
    var AnalysisViewComponent = (function () {
        function AnalysisViewComponent() {
            this.templateUrl = "/components/analysis-components/analysis-view/analysis-view.html";
            this.controllerAs = "vm";
            this.controller = [
                'eventManagerService',
                'analysisService',
                'globals',
                AnalysisViewController
            ];
            this.bindings = {};
        }
        AnalysisViewComponent.id = "analysisView";
        return AnalysisViewComponent;
    }());
    App.AnalysisViewComponent = AnalysisViewComponent;
    angular.module(App.Module)
        .component(AnalysisViewComponent.id, new AnalysisViewComponent());
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    'use strict';
    var CustomerEditController = (function (_super) {
        __extends(CustomerEditController, _super);
        function CustomerEditController($timeout, $mdDialog, eventManagerService, globals) {
            _super.call(this);
            this.$timeout = $timeout;
            this.$mdDialog = $mdDialog;
            this.eventManagerService = eventManagerService;
            this.globals = globals;
        }
        CustomerEditController.prototype.$onInit = function () {
            var _this = this;
            var c;
            if (this.customerId) {
                c = new App.Customer(this.customerId).loadFromDB();
            }
            else {
                c = new App.Customer();
            }
            this.options = {
                title: this.customerId ? "EDIT_CUSTOMER" : "NEW_CUSTOMER",
                size: "small",
                buttons: {
                    acceptBtn: {
                        text: "SAVE",
                        btnClass: "btn-primary",
                        disableIBfInvalid: true,
                        fnc: function () {
                            _this.options.item.saveToDB(function (err, res) {
                                if (!err) {
                                    _this.$mdDialog.hide();
                                    if (!_this.customerId) {
                                        _this.eventManagerService.publish(_this.globals.events.refreshCustomerList);
                                    }
                                }
                            });
                        }
                    },
                    cancelBtn: {
                        text: "CANCEL",
                        btnClass: "btn-default",
                        fnc: function () {
                            _this.$mdDialog.cancel();
                        }
                    }
                },
                item: c,
                item_options: {
                    fields: {
                        name: { type: "text", label: "NAME", get: function (item) { return item; }, required: function () { return true; } },
                    },
                    rows: [
                        [{ field: "name", col_class: "col-md-12" }]
                    ]
                }
            };
        };
        CustomerEditController.id = "customersController";
        return CustomerEditController;
    }(App.BaseController));
    App.CustomerEditController = CustomerEditController;
    var CustomerEditComponent = (function () {
        function CustomerEditComponent() {
            this.templateUrl = "/components/customer-components/customer-edit-component/customer-edit.component.html";
            this.controllerAs = "vm";
            this.controller = ['$timeout', '$mdDialog', 'eventManagerService', 'globals',
                CustomerEditController
            ];
            this.bindings = {
                customerId: "=?",
                options: "="
            };
        }
        CustomerEditComponent.id = "customerEdit";
        return CustomerEditComponent;
    }());
    App.CustomerEditComponent = CustomerEditComponent;
    angular.module(App.Module)
        .component(CustomerEditComponent.id, new CustomerEditComponent());
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    var CustomerListController = (function (_super) {
        __extends(CustomerListController, _super);
        function CustomerListController($timeout, $mdDialog, authenticationService, eventManagerService, globals) {
            var _this = this;
            _super.call(this);
            this.$timeout = $timeout;
            this.$mdDialog = $mdDialog;
            this.authenticationService = authenticationService;
            this.eventManagerService = eventManagerService;
            this.globals = globals;
            this.refreshList = function () {
                _this.mainGrid.dataSource.read();
                _this.mainGrid.refresh();
            };
            this.mainGridOptions = {
                dataSource: {
                    transport: {
                        read: {
                            url: "/request/get_customers",
                            dataType: "json",
                            type: "POST"
                        }
                    },
                    schema: {
                        model: {
                            fields: {
                                registration_date: { type: "date" }
                            }
                        }
                    },
                    pageSize: 20,
                },
                sortable: true,
                pageable: true,
                resizable: true,
                selectable: "row",
                filterable: true,
                columnMenu: true,
                reorderable: true,
                editable: false,
                change: function (e) {
                    var grid = e.sender;
                    var data = grid.dataItem(grid.select());
                    _this.customerId = data["id"];
                },
                dblClick: function () {
                    _this.$timeout(function () {
                        _this.setViewMode();
                    });
                },
                columns: [
                    {
                        field: "name",
                        title: "NAME"
                    },
                    {
                        field: "registration_date",
                        title: "REGISTRATION_DATE",
                        type: "date",
                        format: "{0:yyyy-MM-dd}"
                    }
                ]
            };
        }
        CustomerListController.prototype.changeToView = function () {
            this.viewMode = App.ViewMode.View;
        };
        CustomerListController.prototype.$onInit = function () {
            this.eventManagerService.subscribe(this.globals.events.refreshCustomerList, this.refreshList);
        };
        CustomerListController.prototype.showDialog = function ($event) {
            var options;
            this.$mdDialog.show({
                template: '<customer-edit options="options"></customer-edit>',
                parent: angular.element(document.body),
                targetEvent: $event || false,
                locals: { options: options },
                controller: ['$scope', 'options', function ($scope, options) { return $scope.options = options; }]
            });
        };
        CustomerListController.prototype.controlVisibility = function (controlName) {
            return this.authenticationService.user.controlPermissions.indexOf(controlName) != -1;
        };
        return CustomerListController;
    }(App.BaseController));
    App.CustomerListController = CustomerListController;
    var CustomerListComponent = (function () {
        function CustomerListComponent() {
            this.templateUrl = "/components/customer-components/customer-list-component/customer-list.component.html";
            this.controllerAs = "vm";
            this.controller = ['$timeout', '$mdDialog', 'authenticationService', 'eventManagerService', 'globals',
                CustomerListController
            ];
            this.bindings = {
                viewMode: "=",
                customerId: "=?"
            };
        }
        CustomerListComponent.id = "customerList";
        return CustomerListComponent;
    }());
    App.CustomerListComponent = CustomerListComponent;
    angular.module(App.Module)
        .component(CustomerListComponent.id, new CustomerListComponent());
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    var CustomerViewController = (function (_super) {
        __extends(CustomerViewController, _super);
        function CustomerViewController($mdDialog) {
            var _this = this;
            _super.call(this);
            this.$mdDialog = $mdDialog;
            this.customer = new App.Customer();
            this.header = {
                title: "CUSTOMER",
                icon: "action:account_box",
                buttons: {
                    btn_edit: {
                        tooltip: "EDIT_CUSTOMER",
                        permission_name: "edit_customer",
                        icon: "editor:mode_edit",
                        fnc: function ($event) {
                            var options;
                            _this.$mdDialog.show({
                                template: '<customer-edit options="options" customer-id="customerId"></customer-edit>',
                                parent: angular.element(document.body),
                                targetEvent: $event || false,
                                //clickOutsideToClose: options.clickOutsideToClose || true,
                                locals: { options: options },
                                controller: ['$scope', 'options', function ($scope, options) {
                                        $scope.options = options;
                                        $scope.customerId = _this.customerId;
                                    }]
                            }).then(function () {
                                _this.customer.loadFromDB();
                            });
                        },
                    }
                }
            };
            this.customer_form_options = {
                fields: {
                    name: {
                        type: "text",
                        readonly: "true",
                        label: "NAME",
                        get: function (item) { return item; }
                    },
                    registration_date: {
                        type: "text",
                        readonly: "true",
                        label: "REGISTRATION_DATE",
                        get: function (item) { return item; },
                        format: 'date'
                    }
                },
                rows: [
                    [{ field: "name", col_class: "col-md-6" }, { field: "registration_date", col_class: "col-md-6" }]
                ]
            };
            this.customer_tabs = [
                { title: 'PROJECTS', template: $("#customerProjectsTabTemplate").html() },
                { title: 'USERS', template: $("#customerUsersTabTemplate").html() },
                { title: 'MASS_MEDIA_SECTIONS', template: $("#massMediaSectionsTabTemplate").html() }
            ];
        }
        CustomerViewController.prototype.changeToList = function () {
            this.viewMode = App.ViewMode.List;
        };
        CustomerViewController.prototype.$onInit = function () {
            this.LoadCustomer(this.customerId);
        };
        CustomerViewController.prototype.LoadCustomer = function (id) {
            this.customer = new App.Customer(id);
            this.customer.loadFromDB(function (err) {
                if (err) {
                    console.error("Cannot get customer ", err);
                }
            });
        };
        return CustomerViewController;
    }(App.BaseController));
    App.CustomerViewController = CustomerViewController;
    var CustomerViewComponent = (function () {
        function CustomerViewComponent() {
            this.templateUrl = "/components/customer-components/customer-view-component/customer-view.component.html";
            this.controllerAs = "vm";
            this.controller = [
                '$mdDialog',
                CustomerViewController
            ];
            this.bindings = {
                viewMode: "=",
                customerId: "<"
            };
        }
        CustomerViewComponent.id = "customerView";
        return CustomerViewComponent;
    }());
    App.CustomerViewComponent = CustomerViewComponent;
    angular.module(App.Module)
        .component(CustomerViewComponent.id, new CustomerViewComponent());
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    'use strict';
    var MassMediaEditController = (function (_super) {
        __extends(MassMediaEditController, _super);
        function MassMediaEditController($timeout, $mdDialog, eventManagerService, globals) {
            _super.call(this);
            this.$timeout = $timeout;
            this.$mdDialog = $mdDialog;
            this.eventManagerService = eventManagerService;
            this.globals = globals;
        }
        MassMediaEditController.prototype.$onInit = function () {
            var _this = this;
            var m;
            if (this.massMediaId) {
                m = new App.MassMedia(this.massMediaId).loadFromDB();
            }
            else {
                m = new App.MassMedia();
            }
            this.options = {
                title: this.massMediaId ? "EDIT_MASS_MEDIA" : "NEW_MASS_MEDIA",
                size: "small",
                buttons: {
                    acceptBtn: {
                        text: "SAVE",
                        btnClass: "btn-primary",
                        disableIfInvalid: true,
                        fnc: function () {
                            _this.options.item.language && (_this.options.item.language = _this.options.item.language.join());
                            _this.options.item.saveToDB(function (err, res) {
                                if (!err) {
                                    _this.$mdDialog.hide();
                                    if (!_this.massMediaId) {
                                        _this.eventManagerService.publish(_this.globals.events.refreshMassMediaList);
                                    }
                                }
                            });
                        }
                    },
                    cancelBtn: {
                        text: "CANCEL",
                        btnClass: "btn-default",
                        fnc: function () {
                            _this.$mdDialog.cancel();
                        }
                    }
                },
                item: m,
                item_options: {
                    fields: {
                        name: { type: "text", label: "NAME", get: function (item) { return item; }, required: function () { return true; } },
                        type: { type: "combo", label: "TYPE", get: function (item) { return item; }, source_url: "/request/get_mass_media_types", required: function () { return true; } },
                        rating: { type: "combo", label: "RATING", get: function (item) { return item; }, source_url: "/request/get_mass_media_ratings", required: function () { return true; } },
                        main_url: { type: "text", label: "MAIN_URL", get: function (item) { return item; }, isNotVisible: function (item) { return (item.type && item.type.id != App.MassMediaTypeEnum.online) || !item.type; }, required: function () { return true; } },
                        logo: { type: "text", label: "LOGO", get: function (item) { return item; } },
                        country: { type: "combo", label: "COUNTRY", get: function (item) { return item; }, source_url: "/request/get_countries", required: function () { return true; } },
                        fb_id: { type: "text", label: "FB_ID", get: function (item) { return item; }, isNotVisible: function (item) { return (item.type && item.type.id != App.MassMediaTypeEnum.online) || !item.type; } },
                        language: { type: "multiselect", label: "LANGUAGE", get: function (item) { return item; }, source_url: "/request/get_languages", required: function () { return true; } },
                        date_format: { type: "combo", label: "DATE_FORMAT", get: function (item) { return item; }, source_url: "/request/get_date_formats", required: function () { return true; } },
                        updatesAvailable: { type: "checkbox", label: "UPDATES_AVAILABLE" }
                    },
                    rows: [
                        [{ field: "name", col_class: "col-md-6" }, { field: "country", col_class: "col-md-6" }],
                        [{ field: "type", col_class: "col-md-6" }, { field: "rating", col_class: "col-md-6" }],
                        [{ field: "main_url", col_class: "col-md-6" }, { field: "fb_id", col_class: "col-md-6" }],
                        this.massMediaId ? null : [{ field: "language", col_class: "col-md-12" }],
                        [{ field: "date_format", col_class: "col-md-8" }],
                        [{ field: "updatesAvailable", col_class: "col-md-6" }]
                    ]
                }
            };
        };
        MassMediaEditController.id = "MassMediaEditController";
        return MassMediaEditController;
    }(App.BaseController));
    App.MassMediaEditController = MassMediaEditController;
    var MassMediaEditComponent = (function () {
        function MassMediaEditComponent() {
            this.templateUrl = "/components/mass-media-components/mass-media-edit-component/mass-media-edit.component.html";
            this.controllerAs = "vm";
            this.controller = ['$timeout', '$mdDialog', 'eventManagerService', 'globals',
                MassMediaEditController
            ];
            this.bindings = {
                massMediaId: "=?",
                options: "="
            };
        }
        MassMediaEditComponent.id = "massMediaEdit";
        return MassMediaEditComponent;
    }());
    App.MassMediaEditComponent = MassMediaEditComponent;
    angular.module(App.Module)
        .component(MassMediaEditComponent.id, new MassMediaEditComponent());
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    var MassMediaListController = (function (_super) {
        __extends(MassMediaListController, _super);
        /*@ngInject*/
        function MassMediaListController($timeout, $mdDialog, authenticationService, eventManagerService, globals, $http) {
            var _this = this;
            _super.call(this);
            this.$timeout = $timeout;
            this.$mdDialog = $mdDialog;
            this.authenticationService = authenticationService;
            this.eventManagerService = eventManagerService;
            this.globals = globals;
            this.$http = $http;
            this.refreshList = function () {
                _this.mainGrid.dataSource.read();
                _this.mainGrid.refresh();
            };
            this.mainGridOptions = {
                dataSource: {
                    transport: {
                        read: {
                            url: "/request/get_mass_media_list",
                            dataType: "json",
                            type: "POST"
                        }
                    },
                    pageSize: 20,
                },
                hierarchical: true,
                sortable: true,
                pageable: true,
                resizable: true,
                filterable: true,
                columnMenu: true,
                reorderable: true,
                editable: false,
                change: function (e) {
                    var grid = e.sender;
                    var data = grid.dataItem(grid.select());
                    _this.massMediaId = data["id"];
                },
                columns: [
                    {
                        field: "name",
                        title: "NAME"
                    },
                    {
                        field: "type_description",
                        title: "TYPE",
                    },
                    {
                        field: "rating_description",
                        title: "RATING",
                    },
                    {
                        field: "country_description",
                        title: "COUNTRY",
                    },
                    {
                        template: "<md-button ng-if=\"vm.controlVisibility('edit_mass_media')\" class=\"md-icon-button custom-cell-button\" ng-click=\"vm.showDialog($event, dataItem)\" aria-label=\"Edit\"><md-tooltip><span data-translate=\"EDIT\"></span></md-tooltip><md-icon md-svg-icon=\"editor:mode_edit\" aria-label=\"...\"></md-icon></md-button>",
                        width: 70
                    }
                ]
            };
            this.getProjectGridOptions = function (massMediaId) {
                return {
                    toolbar: [
                        {
                            name: "EDIT",
                            template: "<span flex></span><md-button ng-if=\"vm.controlVisibility('add_project_to_mass_media')\" class=\"md-icon-button custom-cell-button\"  ng-click=\"vm.editProjectList($event, dataItem.id)\" aria-label=\"Edit\"><md-tooltip><span data-translate=\"EDIT\"></span></md-tooltip><md-icon md-svg-icon=\"editor:mode_edit\" aria-label=\"...\" class=\"edit-mass\"></md-icon></md-button>"
                        }
                    ],
                    dataSource: {
                        transport: {
                            read: {
                                url: "/request/get_projects_by_mass_media",
                                dataType: "json",
                                type: "POST",
                                data: { id: massMediaId }
                            }
                        },
                        pageSize: 10,
                    },
                    sortable: true,
                    pageable: true,
                    resizable: true,
                    filterable: true,
                    columnMenu: true,
                    columns: [
                        { field: "name", title: "{{'NAME' | translate}}" },
                        { field: "status", title: "{{'STATUS' | translate}}" }]
                };
            };
            this.getSectionGridOptions = function (massMediaId) {
                return {
                    toolbar: [
                        {
                            name: "EDIT",
                            template: "<span flex></span>\n                                    <md-button ng-if=\"vm.controlVisibility('add_section_to_mass_media')\" class=\"md-icon-button custom-cell-button\"  ng-click=\"vm.editSectionList($event, dataItem.id)\" aria-label=\"Edit\">\n                                        <md-tooltip><span data-translate=\"EDIT\"></span></md-tooltip>\n                                        <md-icon md-svg-icon=\"editor:mode_edit\" aria-label=\"...\" class=\"edit-mass\"></md-icon>\n                                    </md-button>"
                        }
                    ],
                    dataSource: {
                        transport: {
                            read: {
                                url: "/request/get_article_sections_by_mass_media_id",
                                dataType: "json",
                                type: "POST",
                                data: { id: massMediaId }
                            }
                        },
                        pageSize: 10,
                    },
                    sortable: true,
                    pageable: true,
                    resizable: true,
                    filterable: true,
                    columnMenu: true,
                    columns: [
                        { field: "description", title: "{{'NAME' | translate}}" },
                        { field: "code", title: "{{'CODE' | translate}}" }
                    ]
                };
            };
            this.controlVisibility = function (controlName) {
                return _this.authenticationService.user.controlPermissions.indexOf(controlName) != -1;
            };
        }
        MassMediaListController.prototype.changeToView = function () {
            this.viewMode = App.ViewMode.View;
        };
        MassMediaListController.prototype.$onInit = function () {
            this.eventManagerService.subscribe(this.globals.events.refreshMassMediaList, this.refreshList);
        };
        MassMediaListController.prototype.editProjectList = function ($event, massMediaId) {
            var _this = this;
            this.$http.post('request/get_project_full_list_for_mass_media/', { id: massMediaId }).success(function (res) {
                for (var p in res) {
                    res[p].assigned = res[p].assigned == 0 ? false : true;
                }
                _this.$mdDialog.show({
                    template: $('#ProjectListPopupTemplate').html(),
                    parent: angular.element(document.body),
                    locals: { options: { projectList: res } },
                    controller: ['$scope', 'options', function ($scope, options) {
                            $scope.isChaked = false;
                            $scope.projectList = options.projectList;
                            $scope.cancel = function () {
                                _this.$mdDialog.hide();
                            };
                            $scope.checkAll = function (isChecked) {
                                for (var p in $scope.projectList) {
                                    $scope.projectList[p].assigned = isChecked;
                                }
                            };
                            $scope.saveProjectList = function () {
                                var arr = [];
                                for (var i in $scope.projectList) {
                                    $scope.projectList[i].assigned && arr.push($scope.projectList[i].id);
                                }
                                _this.$http.post('request/save_mass_media_project', { mass_media_id: massMediaId, project_ids: arr.join() }).success(function () {
                                    App.Notifier.show("CHANGES_SAVED", App.MessageType.success);
                                    var grid = $("#projectGrid" + massMediaId).data("kendoGrid");
                                    grid.dataSource.read();
                                    grid.refresh();
                                    _this.$mdDialog.cancel();
                                }).error(function (err) {
                                    App.Notifier.show(err.message, App.MessageType.error);
                                });
                            };
                        }]
                });
            }).error(function (err) {
                App.Notifier.show(err.message, App.MessageType.error);
            });
        };
        ;
        MassMediaListController.prototype.editSectionList = function ($event, massMediaId) {
            var _this = this;
            this.$http.post('request/get_article_section_full_list_for_mass_media/', { id: massMediaId }).success(function (res) {
                for (var s in res) {
                    res[s].assigned = res[s].assigned == 0 ? false : true;
                }
                _this.$mdDialog.show({
                    template: $('#SectionListPopupTemplate').html(),
                    parent: angular.element(document.body),
                    locals: { options: { sectionList: res } },
                    controller: ['$scope', 'options', function ($scope, options) {
                            $scope.isChaked = false;
                            $scope.sectionList = options.sectionList;
                            $scope.cancel = function () {
                                _this.$mdDialog.hide();
                            };
                            $scope.checkAll = function (isChecked) {
                                for (var s in $scope.sectionList) {
                                    $scope.sectionList[s].assigned = isChecked;
                                }
                            };
                            $scope.saveSectionList = function () {
                                var arr = [];
                                for (var i in $scope.sectionList) {
                                    $scope.sectionList[i].assigned && arr.push($scope.sectionList[i].id);
                                }
                                _this.$http.post('request/save_article_sections_to_mass_media', { mass_media_id: massMediaId, section_ids: arr.join() }).success(function () {
                                    App.Notifier.show("CHANGES_SAVED", App.MessageType.success);
                                    var grid = $("#sectionGrid" + massMediaId).data("kendoGrid");
                                    grid.dataSource.read();
                                    grid.refresh();
                                    _this.$mdDialog.cancel();
                                }).error(function (err) {
                                    App.Notifier.show(err.message, App.MessageType.error);
                                });
                            };
                        }]
                });
            }).error(function (err) {
                App.Notifier.show(err.message, App.MessageType.error);
            });
        };
        MassMediaListController.prototype.showDialog = function ($event, massMedia) {
            var options;
            if (massMedia) {
                options = angular.copy(massMedia);
            }
            this.$mdDialog.show({
                template: '<mass-media-edit options="options" mass-media-id="options.id"></mass-media-edit>',
                parent: angular.element(document.body),
                targetEvent: $event || false,
                locals: { options: options },
                controller: ['$scope', 'options', function ($scope, options) { return $scope.options = options; }]
            });
        };
        return MassMediaListController;
    }(App.BaseController));
    App.MassMediaListController = MassMediaListController;
    var MassMediaListComponent = (function () {
        function MassMediaListComponent() {
            this.templateUrl = "/components/mass-media-components/mass-media-list-component/mass-media-list.component.html";
            this.controllerAs = "vm";
            this.controller = ['$timeout', '$mdDialog', 'authenticationService', 'eventManagerService', 'globals', '$http',
                MassMediaListController
            ];
            this.bindings = {
                viewMode: "=",
                massMediaId: "=?"
            };
        }
        MassMediaListComponent.id = "massMediaList";
        return MassMediaListComponent;
    }());
    App.MassMediaListComponent = MassMediaListComponent;
    angular.module(App.Module)
        .component(MassMediaListComponent.id, new MassMediaListComponent());
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    var MassMediaViewController = (function (_super) {
        __extends(MassMediaViewController, _super);
        function MassMediaViewController($mdDialog) {
            var _this = this;
            _super.call(this);
            this.$mdDialog = $mdDialog;
            this.massMedia = new App.MassMedia();
            this.header = {
                title: "MASS_MEDIA",
                icon: "notification:live_tv",
                buttons: {
                    btn_edit: {
                        tooltip: "EDIT_MASS_MEDIA",
                        permission_name: "edit_mass_media",
                        icon: "editor:mode_edit",
                        fnc: function ($event) {
                            var options;
                            _this.$mdDialog.show({
                                template: '<mass-media-edit options="options" mass-media-id="massMediaId"></mass-media-edit>',
                                parent: angular.element(document.body),
                                targetEvent: $event || false,
                                //clickOutsideToClose: options.clickOutsideToClose || true,
                                locals: { options: options },
                                controller: ['$scope', 'options', function ($scope, options) {
                                        $scope.options = options;
                                        $scope.massMediaId = _this.massMediaId;
                                    }]
                            }).then(function () {
                                _this.massMedia.loadFromDB();
                            });
                        },
                    },
                }
            };
            this.mass_media_form_options = {
                fields: {
                    name: {
                        type: "text",
                        readonly: "true",
                        label: "NAME",
                        get: function (item) { return item; }
                    },
                    type: {
                        type: "text",
                        readonly: "true",
                        label: "TYPE",
                        get: function (item) { return item.type; },
                        property_name: "description"
                    },
                    rating: {
                        type: "text",
                        readonly: "true",
                        label: "RATING",
                        get: function (item) { return item.rating; },
                        property_name: "description"
                    },
                    main_url: {
                        type: "text",
                        readonly: "true",
                        label: "MAIN_URL",
                        get: function (item) { return item; },
                        isNotVisible: function (item) { return (item.type && item.type.id != App.MassMediaTypeEnum.online) || !item.type; }
                    },
                    country: {
                        type: "text",
                        readonly: "true",
                        label: "COUNTRY",
                        get: function (item) { return item.country; },
                        property_name: "description"
                    },
                    fb_id: {
                        type: "number",
                        label: "FB_ID",
                        readonly: "true",
                        get: function (item) { return item; }
                    },
                    date_format: {
                        type: "text",
                        label: "DATE_FORMAT",
                        readonly: "true",
                        get: function (item) { return item; },
                        property_name: "description"
                    }
                },
                rows: [
                    [{ field: "name", col_class: "col-md-6" }, { field: "main_url", col_class: "col-md-6" }],
                    [{ field: "type", col_class: "col-md-6" }, { field: "rating", col_class: "col-md-6" }],
                    [{ field: "country", col_class: "col-md-6" }, { field: "fb_id", col_class: "col-md-6" }],
                    [{ field: "date_format", col_class: "col-md-6" }]
                ]
            };
        }
        MassMediaViewController.prototype.changeToList = function () {
            this.viewMode = App.ViewMode.List;
        };
        MassMediaViewController.prototype.$onInit = function () {
            this.LoadMassMedia(this.massMediaId);
        };
        MassMediaViewController.prototype.LoadMassMedia = function (id) {
            this.massMedia = new App.MassMedia(id);
            this.massMedia.loadFromDB(function (err) {
                if (err) {
                    console.error("Cannot get mass media ", err);
                }
            });
        };
        return MassMediaViewController;
    }(App.BaseController));
    App.MassMediaViewController = MassMediaViewController;
    var MassMediaViewComponent = (function () {
        function MassMediaViewComponent() {
            this.templateUrl = "/components/mass-media-components/mass-media-view-component/mass-media-view.component.html";
            this.controllerAs = "vm";
            this.controller = [
                '$mdDialog',
                MassMediaViewController
            ];
            this.bindings = {
                viewMode: "=",
                massMediaId: "<"
            };
        }
        MassMediaViewComponent.id = "massMediaView";
        return MassMediaViewComponent;
    }());
    App.MassMediaViewComponent = MassMediaViewComponent;
    angular.module(App.Module)
        .component(MassMediaViewComponent.id, new MassMediaViewComponent());
})(App || (App = {}));

var App;
(function (App) {
    var AddArticleComponentController = (function () {
        function AddArticleComponentController($mdDialog, newsFeedService, eventManagerService, globals) {
            var _this = this;
            this.$mdDialog = $mdDialog;
            this.newsFeedService = newsFeedService;
            this.eventManagerService = eventManagerService;
            this.globals = globals;
            this.MassMediaTypeEnum = App.MassMediaTypeEnum;
            this.save = function () {
                _this.newsFeedService.saveArticle(_this.article).then(function (response) {
                    if (response) {
                        App.Notifier.show("Պահպանված է։", App.MessageType.success);
                        _this.closeWindow();
                    }
                });
            };
            this.cancel = function () {
                _this.closeWindow();
            };
            this.closeWindow = function () {
                _this.$mdDialog.hide();
            };
        }
        AddArticleComponentController.prototype.$onInit = function () {
            this.article = new App.Article();
        };
        return AddArticleComponentController;
    }());
    App.AddArticleComponentController = AddArticleComponentController;
    var AddArticleComponent = (function () {
        function AddArticleComponent() {
            this.templateUrl = "/components/news-feed-components/add-article/add-article.component.html";
            this.controllerAs = "vm";
            this.controller = ['$mdDialog', 'newsFeedService', 'eventManagerService', 'globals',
                AddArticleComponentController
            ];
            this.bindings = {};
        }
        AddArticleComponent.id = "addArticle";
        return AddArticleComponent;
    }());
    App.AddArticleComponent = AddArticleComponent;
    angular.module(App.Module)
        .component(AddArticleComponent.id, new AddArticleComponent());
})(App || (App = {}));

var App;
(function (App) {
    var AddFbLinkComponentController = (function () {
        function AddFbLinkComponentController($mdDialog, newsFeedService, eventManagerService, globals) {
            var _this = this;
            this.$mdDialog = $mdDialog;
            this.newsFeedService = newsFeedService;
            this.eventManagerService = eventManagerService;
            this.globals = globals;
            this.getFbLinkData = function () {
                _this.newsFeedService.getFbLinkData(_this.options.articleId).then(function (res) {
                    _this.fbData = res[0];
                });
            };
            this.save = function () {
                _this.newsFeedService.saveFbLink(_this.fbData, _this.options.articleId).then(function (response) {
                    if (response) {
                        App.Notifier.show("Պահպանված է։", App.MessageType.success);
                        _this.closeWindow();
                    }
                });
            };
            this.cancel = function () {
                _this.closeWindow();
            };
            this.closeWindow = function () {
                _this.$mdDialog.hide();
            };
            this.getFbLinkData();
        }
        AddFbLinkComponentController.prototype.$onInit = function () { };
        return AddFbLinkComponentController;
    }());
    App.AddFbLinkComponentController = AddFbLinkComponentController;
    var AddFbLinkComponent = (function () {
        function AddFbLinkComponent() {
            this.templateUrl = "/components/news-feed-components/add-fb-link/add-fb-link.component.html";
            this.controllerAs = "vm";
            this.controller = ['$mdDialog', 'newsFeedService', 'eventManagerService', 'globals',
                AddFbLinkComponentController
            ];
            this.bindings = {
                options: "="
            };
        }
        AddFbLinkComponent.id = "addFbLink";
        return AddFbLinkComponent;
    }());
    App.AddFbLinkComponent = AddFbLinkComponent;
    angular.module(App.Module)
        .component(AddFbLinkComponent.id, new AddFbLinkComponent());
})(App || (App = {}));

var App;
(function (App) {
    var NewsFeedComponentController = (function () {
        function NewsFeedComponentController(newsFeedService, eventManagerService, globals, authenticationService, $anchorScroll) {
            var _this = this;
            this.newsFeedService = newsFeedService;
            this.eventManagerService = eventManagerService;
            this.globals = globals;
            this.authenticationService = authenticationService;
            this.$anchorScroll = $anchorScroll;
            this.requestParameter = new App.RequestParameterModel();
            this.selectedElements = [];
            this.loading = false;
            this.selectedElementsEdit = function (id, operation) {
                var idx = _.findIndex(_this.selectedElements, function (item) {
                    return item.id == id;
                });
                if (!operation) {
                    if (idx == -1) {
                        _this.selectedElements.push(_.find(_this.feedList, function (item) {
                            return item.id == id;
                        }));
                    }
                    else {
                        _this.selectedElements = _.filter(_this.selectedElements, function (item) {
                            return item.id != id;
                        });
                    }
                }
                else {
                    if (operation == App.SelectionOperationEnum.Add) {
                        if (idx == -1) {
                            _this.selectedElements.push(_.find(_this.feedList, function (item) {
                                return item.id == id;
                            }));
                        }
                    }
                    else {
                        _this.selectedElements = _.filter(_this.selectedElements, function (item) {
                            return item.id != id;
                        });
                    }
                }
                _this.elementComparison();
            };
            this.unSelectAllElements = function () {
                _this.selectedElements.length = 0;
            };
            this.getNewsFeedList = function (currentPage) {
                _this.requestParameter.filter = angular.copy(_this.filter);
                if ((!_this.requestParameter.filter.subject || _this.requestParameter.filter.subject.length != 1) && _this.requestParameter.filter.attitude) {
                    App.Notifier.show("Երանգավորման ֆիլտրը կիրառելի է միայն և միայն մեկ թեմա ընտրելու դեպքում։", App.MessageType.warning);
                    return;
                }
                if ((!_this.requestParameter.filter.subject || _this.requestParameter.filter.subject.length != 1) && _this.requestParameter.filter.reference_type) {
                    App.Notifier.show("Հիշատակման տեսակի ֆիլտրը կիրառելի է միայն և միայն մեկ թեմա ընտրելու դեպքում։", App.MessageType.warning);
                    return;
                }
                _this.requestParameter.filter.subject = _this.requestParameter.filter.subject && _this.requestParameter.filter.subject.join();
                _this.requestParameter.filter.mass_media = _this.requestParameter.filter.mass_media && _this.requestParameter.filter.mass_media.join();
                _this.requestParameter.current_page = currentPage;
                _this.loading = true;
                _this.newsFeedService.getNewsFeedList(_this.requestParameter).then(function (response) {
                    if (response) {
                        _this.totalCount = response.total_count;
                        _this.feedList = response.feed_elements;
                        _this.elementComparison();
                        _this.eventManagerService.publish(_this.globals.events.setPaginationCurrentPage, currentPage || 1);
                        _this.searchPattern = _this.requestParameter.filter.pattern;
                    }
                }).finally(function () {
                    _this.loading = false;
                    _this.$anchorScroll();
                });
            };
            this.elementComparison = function () {
                var _loop_1 = function(i) {
                    _this.feedList[i].selected = (_.findIndex(_this.selectedElements, function (item) {
                        return item.id == _this.feedList[i].id;
                    }) != -1);
                };
                for (var i = 0; i < _this.feedList.length; i++) {
                    _loop_1(i);
                }
                var res = _this.feedList.filter(function (item1) { return _this.selectedElements.some(function (item2) { return item1.id === item2.id; }); }).length;
                _this.eventManagerService.publish(_this.globals.events.allCheckUnSelect, res);
            };
        }
        NewsFeedComponentController.prototype.$onInit = function () {
            var _this = this;
            this.eventManagerService.subscribe(this.globals.events.getNewsFeedList, this.getNewsFeedList);
            this.eventManagerService.subscribe(this.globals.events.selectedElementsEdit, this.selectedElementsEdit);
            this.eventManagerService.subscribe(this.globals.events.unSelectAllElements, this.unSelectAllElements);
            this.eventManagerService.subscribe(this.globals.events.elementComparison, this.elementComparison);
            this.requestParameter.count_per_page = +window.localStorage["countPerPage"] || this.globals.defaultValues.countPerPage;
            this.getNewsFeedList(1);
            if (this.authenticationService.user && this.authenticationService.user.feedRefreshInterval > 0) {
                this.globals.events.feedUpdateInterval = setInterval(function () {
                    _this.getNewsFeedList(1);
                }, 1000 * 60 * this.authenticationService.user.feedRefreshInterval);
            }
        };
        NewsFeedComponentController.prototype.$onDestroy = function () {
            if (this.authenticationService.user && this.authenticationService.user.feedRefreshInterval > 0) {
                clearInterval(this.globals.events.feedUpdateInterval);
            }
        };
        return NewsFeedComponentController;
    }());
    App.NewsFeedComponentController = NewsFeedComponentController;
    var NewsFeedComponent = (function () {
        function NewsFeedComponent() {
            this.templateUrl = "/components/news-feed-components/news-feed/news-feed.component.html";
            this.controllerAs = "vm";
            this.controller = ['newsFeedService', 'eventManagerService', 'globals', 'authenticationService', '$anchorScroll',
                NewsFeedComponentController
            ];
            this.bindings = {
                filter: "<?"
            };
        }
        NewsFeedComponent.id = "newsFeed";
        return NewsFeedComponent;
    }());
    App.NewsFeedComponent = NewsFeedComponent;
    angular.module(App.Module)
        .component(NewsFeedComponent.id, new NewsFeedComponent());
})(App || (App = {}));

var App;
(function (App) {
    var NewsFeedCardComponentController = (function () {
        function NewsFeedCardComponentController($mdDialog, newsFeedService, eventManagerService, globals, authenticationService, configurationService) {
            var _this = this;
            this.$mdDialog = $mdDialog;
            this.newsFeedService = newsFeedService;
            this.eventManagerService = eventManagerService;
            this.globals = globals;
            this.authenticationService = authenticationService;
            this.configurationService = configurationService;
            this.Languages = App.LanguageEnum;
            this.NewsFeedSelectionModeEnum = App.NewsFeedSelectionModeEnum;
            this.getFbLink = function () {
                if (_this.cardElement.selected_article.fb_link) {
                    return _this.cardElement.selected_article.fb_link;
                }
                else if (_this.cardElement.articles.length > 1) {
                    var a = _.find(_.sortBy(_this.cardElement.articles, 'language'), function (item) {
                        return item.fb_link;
                    });
                    return a && a.fb_link;
                }
                else {
                    return null;
                }
            };
            this.changeSelected = function () {
                _this.eventManagerService.publish(_this.globals.events.selectedElementsEdit, _this.cardElement.id);
            };
            this.refreshLanguage = function (lang) {
                _this.cardElement.selected_article = _.find(_this.cardElement.articles, function (item) { return (item.language == lang); });
                _this.cardElement.logo = _this.findLogo();
            };
            this.findLogo = function () {
                return "imgs/massMediaLogos/svg/" + _this.cardElement.selected_article.mass_media.name + ".svg";
            };
            this.showDialog = function (action, $event) {
                var options;
                if (action == 1) {
                    options = {
                        elementId: _this.cardElement.id
                    };
                }
                else {
                    options = {
                        articleId: _this.cardElement.selected_article.id
                    };
                }
                _this.$mdDialog.show({
                    template: action == 1 ? '<news-feed-element-analyze options="options"></news-feed-element-analyze>' : '<add-fb-link options="options"></add-fb-link>',
                    parent: angular.element(document.body),
                    targetEvent: $event || false,
                    locals: { options: options },
                    controller: ['$scope', 'options', function ($scope, options) {
                            $scope.options = options;
                        }
                    ]
                }).then(function () {
                    //this.eventManagerService.publish(this.globals.events.getNewsFeedList,1)
                });
            };
            this.changeReadUnreadState = function (state) {
                _this.newsFeedService.changeReadState([_this.cardElement], state).then(function (response) {
                    if (response) {
                        _this.cardElement.read_state = state;
                    }
                });
            };
        }
        NewsFeedCardComponentController.prototype.$onInit = function () {
            //this.refreshLanguage(this.cardElement.articles[0].language);
            this.refreshLanguage(_.sortBy(this.cardElement.articles, 'language')[0].language);
        };
        return NewsFeedCardComponentController;
    }());
    App.NewsFeedCardComponentController = NewsFeedCardComponentController;
    var NewsFeedCardComponent = (function () {
        function NewsFeedCardComponent() {
            this.templateUrl = "/components/news-feed-components/news-feed-card/news-feed-card.component.html";
            this.controllerAs = "vm";
            this.controller = ['$mdDialog', 'newsFeedService', 'eventManagerService', 'globals', 'authenticationService', 'configurationService',
                NewsFeedCardComponentController
            ];
            this.bindings = {
                cardElement: "<",
                searchPattern: "<?"
            };
        }
        NewsFeedCardComponent.id = "newsFeedCard";
        return NewsFeedCardComponent;
    }());
    App.NewsFeedCardComponent = NewsFeedCardComponent;
    angular.module(App.Module)
        .component(NewsFeedCardComponent.id, new NewsFeedCardComponent());
})(App || (App = {}));

var App;
(function (App) {
    var NewsFeedElementAnalyzeComponentController = (function () {
        function NewsFeedElementAnalyzeComponentController($mdDialog, newsFeedService, eventManagerService, globals) {
            var _this = this;
            this.$mdDialog = $mdDialog;
            this.newsFeedService = newsFeedService;
            this.eventManagerService = eventManagerService;
            this.globals = globals;
            this.analyzeList = [];
            this.AttitudeGroups = App.AttitudeEnum;
            this.cancel = function () {
                _this.closeWindow();
            };
            this.closeWindow = function () {
                _this.$mdDialog.hide();
            };
        }
        NewsFeedElementAnalyzeComponentController.prototype.$onInit = function () {
            var _this = this;
            this.newsFeedService.getAnalyzeDetailsBySubject(this.options).then(function (response) {
                if (response) {
                    _this.analyzeList = response;
                }
            });
        };
        return NewsFeedElementAnalyzeComponentController;
    }());
    App.NewsFeedElementAnalyzeComponentController = NewsFeedElementAnalyzeComponentController;
    var NewsFeedElementAnalyzeComponent = (function () {
        function NewsFeedElementAnalyzeComponent() {
            this.templateUrl = "/components/news-feed-components/news-feed-element-analyze/news-feed-element-analyze.component.html";
            this.controllerAs = "vm";
            this.controller = ['$mdDialog', 'newsFeedService', 'eventManagerService', 'globals',
                NewsFeedElementAnalyzeComponentController
            ];
            this.bindings = {
                options: "="
            };
        }
        NewsFeedElementAnalyzeComponent.id = "newsFeedElementAnalyze";
        return NewsFeedElementAnalyzeComponent;
    }());
    App.NewsFeedElementAnalyzeComponent = NewsFeedElementAnalyzeComponent;
    angular.module(App.Module)
        .component(NewsFeedElementAnalyzeComponent.id, new NewsFeedElementAnalyzeComponent());
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    var AnalyzeDetailsComponentController = (function (_super) {
        __extends(AnalyzeDetailsComponentController, _super);
        function AnalyzeDetailsComponentController(newsFeedService) {
            var _this = this;
            _super.call(this);
            this.newsFeedService = newsFeedService;
            //private AttitudeSource = [{id: AttitudeEnum.Positive, value: AttitudeEnum[AttitudeEnum.Positive]}, {id: AttitudeEnum.Neutral, value:AttitudeEnum[AttitudeEnum.Neutral]},{id: AttitudeEnum.Negative, value:AttitudeEnum[AttitudeEnum.Negative]}];
            this.AttitudeSource = [];
            this.AttitudeGroupSource = [];
            this.getAttitudeGroups = function () {
                for (var i = 0; i <= _this.AttitudeSource.length; i++) {
                    if (_this.AttitudeSource[i] != null) {
                        var index = _this.AttitudeSource[i].group_id - 1;
                        if (_this.AttitudeGroupSource[index] == undefined) {
                            _this.AttitudeGroupSource[index] = [];
                        }
                        _this.AttitudeGroupSource[index].push(_this.AttitudeSource[i]);
                    }
                }
            };
            this.execute = function () {
                if (_this.analyzeList) {
                    _this.newsFeedService.analyzeFeedElementBySelectedArticles(_this.analyzeData, _this.analyzeList, _this.feedType).then(function (response) {
                        if (response) {
                            App.Notifier.show("Կատարված է։", App.MessageType.success);
                        }
                    });
                }
                else {
                    _this.newsFeedService.analyzeFeedElementBySubject(_this.analyzeData).then(function (response) {
                        if (response) {
                            App.Notifier.show("Կատարված է։", App.MessageType.success);
                        }
                    });
                }
            };
        }
        AnalyzeDetailsComponentController.prototype.$onInit = function () {
            var _this = this;
            this.analyzeData = this.analyzeData || new App.AnalyzeDetail();
            this.newsFeedService.getAttitudeDatasourse().then(function (response) {
                if (response) {
                    _this.AttitudeSource = response;
                    _this.getAttitudeGroups();
                }
            });
        };
        return AnalyzeDetailsComponentController;
    }(App.BaseController));
    App.AnalyzeDetailsComponentController = AnalyzeDetailsComponentController;
    var AnalyzeDetailsComponent = (function () {
        function AnalyzeDetailsComponent() {
            this.templateUrl = "/components/news-feed-components/news-feed-element-analyze-details/news-feed-element-analyze-details.component.html";
            this.controllerAs = "vm";
            this.controller = ['newsFeedService',
                AnalyzeDetailsComponentController
            ];
            this.bindings = {
                analyzeData: "<",
                analyzeList: "<?",
                feedType: "<?"
            };
        }
        AnalyzeDetailsComponent.id = "analyzeDetails";
        return AnalyzeDetailsComponent;
    }());
    App.AnalyzeDetailsComponent = AnalyzeDetailsComponent;
    angular.module(App.Module)
        .component(AnalyzeDetailsComponent.id, new AnalyzeDetailsComponent());
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    var NewsFeedFilterComponentController = (function (_super) {
        __extends(NewsFeedFilterComponentController, _super);
        function NewsFeedFilterComponentController(newsFeedService, authenticationService, $mdDialog, eventManagerService, configurationService) {
            var _this = this;
            _super.call(this);
            this.newsFeedService = newsFeedService;
            this.authenticationService = authenticationService;
            this.$mdDialog = $mdDialog;
            this.eventManagerService = eventManagerService;
            this.configurationService = configurationService;
            this.filter = new App.NewsFilter();
            this.rated = "";
            this.FilterUsingPageEnum = App.FilterUsingPageEnum;
            this.NewsFeedSelectionModeEnum = App.NewsFeedSelectionModeEnum;
            this.saveFilter = function () {
                var options = {
                    title: "SAVE",
                    size: "small",
                    buttons: {
                        acceptBtn: {
                            text: "SAVE",
                            btnClass: "btn-primary",
                            disableIfInvalid: true,
                            fnc: function () {
                                _this.newsFeedService.saveFilter(_this.filter).then(function (response) {
                                    if (response) {
                                        if (_this.usingPage != App.FilterUsingPageEnum.Analysis) {
                                            _this.getFilteredData();
                                        }
                                        _this.eventManagerService.publish('SAVED_FILTERS');
                                        _this.$mdDialog.hide();
                                    }
                                }).catch(function (err) {
                                    App.Notifier.show(err.data.message, App.MessageType.error);
                                });
                            }
                        },
                        cancelBtn: {
                            text: "CANCEL",
                            btnClass: "btn-default",
                            fnc: function () {
                                _this.$mdDialog.hide();
                            }
                        }
                    },
                    item: _this.filter,
                    item_options: {
                        fields: {
                            name: { type: "text", label: "NAME", get: function (item) { return item; }, required: function () { return true; } }
                        },
                        rows: [
                            [{ field: "name", col_class: "col-md-12" }]
                        ]
                    }
                };
                _this.$mdDialog.show({
                    template: '<dialog-box options="options"></dialog-box>',
                    parent: angular.element(document.body),
                    targetEvent: false,
                    locals: { options: options },
                    controller: ['$scope', 'options', function ($scope, options) { return $scope.options = options; }]
                });
            };
            this.savedFilterChange = function () {
                if (_this.savedFilter) {
                    _this.newsFeedService.getFilterById(_this.savedFilter).then(function (response) {
                        _this.filter = response;
                    });
                }
                else {
                    _this.filter = null;
                }
            };
            this.rated_source = [{ id: 1, description: "չգնահատված" }, { id: 2, description: "գնահատված" }];
            if (localStorage.hasOwnProperty('filter')) {
                this.filter = JSON.parse(localStorage.getItem('filter'));
                setTimeout(function () {
                    _this.filter = JSON.parse(localStorage.getItem('filter'));
                    localStorage.removeItem('filter');
                }, 10);
            }
        }
        NewsFeedFilterComponentController.prototype.$onInit = function () {
            var _this = this;
            this.usingPage = this.usingPage || App.FilterUsingPageEnum.NewsFeed;
            this.newsFeedService.getCountriesDatasourse().then(function (response) {
                _this.countries = response;
            });
        };
        NewsFeedFilterComponentController.prototype.getStartDateByProject = function (project) {
            var _this = this;
            App.Utilities.getStartDateByProject(project.id).then(function (response) {
                _this.min_date = response.start_date;
            });
        };
        return NewsFeedFilterComponentController;
    }(App.BaseController));
    App.NewsFeedFilterComponentController = NewsFeedFilterComponentController;
    var NewsFeedFilterComponent = (function () {
        function NewsFeedFilterComponent() {
            this.templateUrl = "/components/news-feed-components/news-feed-filter/news-feed-filter.component.html";
            this.controllerAs = "vm";
            this.controller = ['newsFeedService', 'authenticationService', '$mdDialog', 'eventManagerService', 'configurationService',
                NewsFeedFilterComponentController
            ];
            this.bindings = {
                filter: "=",
                getFilteredData: "&?",
                clearFilters: "&",
                usingPage: "<?"
            };
        }
        NewsFeedFilterComponent.id = "newsFeedFilter";
        return NewsFeedFilterComponent;
    }());
    App.NewsFeedFilterComponent = NewsFeedFilterComponent;
    angular.module(App.Module)
        .component(NewsFeedFilterComponent.id, new NewsFeedFilterComponent());
})(App || (App = {}));

var App;
(function (App) {
    var NewsFeedHeaderComponentController = (function () {
        function NewsFeedHeaderComponentController($mdDialog, authenticationService, eventManagerService, globals, newsFeedService, mailingService, $filter) {
            var _this = this;
            this.$mdDialog = $mdDialog;
            this.authenticationService = authenticationService;
            this.eventManagerService = eventManagerService;
            this.globals = globals;
            this.newsFeedService = newsFeedService;
            this.mailingService = mailingService;
            this.$filter = $filter;
            this.FeedElementActions = App.FeedElementActionsEnum;
            this.GroupSelection = App.GroupSelectionEnum;
            this.pageOptions = {
                dataSource: this.globals.dataSources.countPerPageDataSource,
                dataTextField: "value",
                dataValueField: "id",
                // headerTemplate: '<div class="dropdown-header k-widget k-header">' +
                // '<span></span>' +
                // '</div>',
                valueTemplate: '<span class="val-txt">{{dataItem.value}}</span>',
                template: '<span class="mm-k-dropdown-val-txt">{{dataItem.value}}</span>'
            };
            this.listItems = [
                { text: "Small", value: 1 },
                { text: "Medium", value: 2 },
                { text: "Large", value: 3 }
            ];
            this.selectedValue = { text: "Foo", value: 1 };
            this.allCheckUnSelect = function (res) {
                res < _this.countPerPage ? _this.isChecked = false : _this.isChecked = true;
            };
            this.generatePdf = function () {
                if (_this.selectedElements.length > 0) {
                    var win = window.open();
                    var doc = win.document.open();
                    doc.write(App.Utilities.generateHtmlFromSelectedArticles(_this.selectedElements, _this.$filter));
                    doc.close();
                    win.print();
                }
                else {
                    App.Notifier.show("Please select articles.", App.MessageType.warning);
                }
            };
            this.changeCountPerPage = function () {
                window.localStorage["countPerPage"] = _this.countPerPage;
            };
            this.checkAll = function (isChecked) {
                if (isChecked) {
                    _this.groupSelection(App.GroupSelectionEnum.All);
                }
                else {
                    _this.groupSelection(App.GroupSelectionEnum.None);
                }
            };
            this.changeState = function (state) {
                _this.newsFeedService.changeReadState(_this.selectedElements, state).then(function (response) {
                    if (response) {
                        _this.selectedElements.forEach(function (element) {
                            element.read_state = state;
                        });
                    }
                });
            };
            this.groupSelection = function (selectionGroup) {
                _this.feedList.forEach(function (item) {
                    if (selectionGroup == App.GroupSelectionEnum.All) {
                        _this.eventManagerService.publish(_this.globals.events.selectedElementsEdit, item.id, App.SelectionOperationEnum.Add);
                        _this.isChecked = true;
                    }
                    else if (selectionGroup == App.GroupSelectionEnum.None) {
                        _this.eventManagerService.publish(_this.globals.events.selectedElementsEdit, item.id, App.SelectionOperationEnum.Remove);
                        _this.isChecked = false;
                    }
                    else if (selectionGroup == App.GroupSelectionEnum.Read) {
                        if (item.read_state) {
                            _this.eventManagerService.publish(_this.globals.events.selectedElementsEdit, item.id, App.SelectionOperationEnum.Add);
                        }
                        else {
                            _this.eventManagerService.publish(_this.globals.events.selectedElementsEdit, item.id, App.SelectionOperationEnum.Remove);
                        }
                    }
                    else if (selectionGroup == App.GroupSelectionEnum.Unread) {
                        if (!item.read_state) {
                            _this.eventManagerService.publish(_this.globals.events.selectedElementsEdit, item.id, App.SelectionOperationEnum.Add);
                        }
                        else {
                            _this.eventManagerService.publish(_this.globals.events.selectedElementsEdit, item.id, App.SelectionOperationEnum.Remove);
                        }
                    }
                });
                _this.eventManagerService.publish(_this.globals.events.elementComparison);
            };
            this.showDialog = function ($event, action) {
                if (_this.selectedElements.length == 0) {
                    App.Notifier.show("Նախ ընտրեք որևէ հոդված։", App.MessageType.warning);
                }
                else if (_.findIndex(_this.selectedElements, function (item) { return item.press_event; }) != -1 && action == App.FeedElementActionsEnum.PressEventJoin) {
                    App.Notifier.show("Ընտրված հոդված(ներ)ը արդեն ընդգրկված են ինֆորմացիոն իրադարձության մեջ։", App.MessageType.warning);
                }
                else if (action == App.FeedElementActionsEnum.RemoveElementFromSubjects) {
                    _this.newsFeedService.getSubjectsBySelectedArticles(_this.selectedElements).then(function (response) {
                        if (response.length == 0) {
                            App.Notifier.show("Ընտրված հոդված(ներ)ը պետք է ունենան ընդհանաուր թեմա։", App.MessageType.warning);
                            return;
                        }
                        else {
                            _this.showDialogContent($event, action);
                        }
                    });
                }
                else {
                    if (action == App.FeedElementActionsEnum.ArticleMerge) {
                        var lang = void 0;
                        for (var _i = 0, _a = _this.selectedElements; _i < _a.length; _i++) {
                            var element = _a[_i];
                            if (_this.selectedElements[0].selected_article.mass_media.id !== element.selected_article.mass_media.id) {
                                App.Notifier.show("Ընտրված հոդված(ներ)ը պետք է պատկանեն միևնույն լրատվամիջոցին։", App.MessageType.warning);
                                return;
                            }
                            if (lang == element.selected_article.language) {
                                App.Notifier.show("Ընտրված հոդված(ներ)ը պետք է լինեն տարբեր լեզուներով։", App.MessageType.warning);
                                return;
                            }
                            lang = element.selected_article.language;
                        }
                    }
                    _this.showDialogContent($event, action);
                }
            };
            this.addArticle = function ($event) {
                _this.$mdDialog.show({
                    template: '<add-article></add-article>',
                    parent: angular.element(document.body),
                    targetEvent: $event || false,
                    //locals: {options: options},
                    controller: ['$scope', function ($scope) {
                        }
                    ]
                }).then(function () {
                    _this.eventManagerService.publish(_this.globals.events.getNewsFeedList, 1);
                });
            };
        }
        NewsFeedHeaderComponentController.prototype.$onInit = function () {
            this.eventManagerService.subscribe(this.globals.events.allCheckUnSelect, this.allCheckUnSelect);
            this.countPerPage = +window.localStorage["countPerPage"] || this.countPerPage;
        };
        NewsFeedHeaderComponentController.prototype.showDialogContent = function ($event, action) {
            var options = {
                action: action,
                selectedElements: this.selectedElements
            };
            this.$mdDialog.show({
                template: '<news-feed-selected-elements options="options"></news-feed-selected-elements>',
                parent: angular.element(document.body),
                targetEvent: $event || false,
                locals: { options: options },
                controller: ['$scope', 'options', function ($scope, options) {
                        $scope.options = options;
                    }
                ]
            });
        };
        return NewsFeedHeaderComponentController;
    }());
    App.NewsFeedHeaderComponentController = NewsFeedHeaderComponentController;
    var NewsFeedHeaderComponent = (function () {
        function NewsFeedHeaderComponent() {
            this.templateUrl = "/components/news-feed-components/news-feed-header/news-feed-header.component.html";
            this.controllerAs = "vm";
            this.controller = ['$mdDialog', 'authenticationService', 'eventManagerService', 'globals', 'newsFeedService', 'mailingService', '$filter',
                NewsFeedHeaderComponentController
            ];
            this.bindings = {
                selectedElements: "<",
                countPerPage: "=",
                totalCount: "<",
                feedList: "<"
            };
        }
        NewsFeedHeaderComponent.id = "newsFeedHeader";
        return NewsFeedHeaderComponent;
    }());
    App.NewsFeedHeaderComponent = NewsFeedHeaderComponent;
    angular.module(App.Module)
        .component(NewsFeedHeaderComponent.id, new NewsFeedHeaderComponent());
})(App || (App = {}));

var App;
(function (App) {
    var NewsFeedSelectedElementsComponentController = (function () {
        function NewsFeedSelectedElementsComponentController($mdDialog, newsFeedService, eventManagerService, globals, mailingService) {
            var _this = this;
            this.$mdDialog = $mdDialog;
            this.newsFeedService = newsFeedService;
            this.eventManagerService = eventManagerService;
            this.globals = globals;
            this.mailingService = mailingService;
            this.FeedElementActions = App.FeedElementActionsEnum;
            this.NewsFeedType = App.NewsFeedTypeEnum;
            this.AttitudeGroups = App.AttitudeEnum;
            this.dataSource = [];
            this.analyzeList = [];
            this.mainGridOptions = {
                dataSource: new kendo.data.DataSource({
                    data: this.dataSource,
                    schema: {
                        model: {
                            fields: {
                                title: { type: "text" },
                                publish_datetime: { editable: false, type: "date" },
                                mass_media: { type: "text" },
                                subjects: { type: "text" }
                            }
                        }
                    }
                }),
                sortable: false,
                pageable: false,
                resizable: false,
                selectable: "row",
                filterable: false,
                columnMenu: false,
                reorderable: false,
                columns: [
                    {
                        field: "title",
                        title: "TITLE",
                        width: 300
                    },
                    {
                        field: "publish_datetime",
                        title: "PUBLISH_DATE",
                        type: "date",
                        format: "{0:yyyy-MM-dd}",
                        width: 100
                    },
                    {
                        field: "mass_media",
                        title: "MASS_MEDIA",
                        width: 100
                    },
                    {
                        field: "subjects",
                        title: "SUBJECTS",
                        width: 100
                    },
                    {
                        command: [
                            {
                                name: "Delete",
                                template: "<span class='icon icon-remove' ng-click='vm.removeElement($event)'></span>"
                            }
                        ],
                        attributes: {
                            "class": "text-center",
                        },
                        width: 60
                    }
                ]
            };
            this.removeElement = function ($event) {
                var sender = $event.currentTarget;
                var row = angular.element(sender).closest("tr");
                var dataItem = _this.mainGrid.dataItem(row);
                _this.mainGrid.dataSource.remove(dataItem);
                _this.mainGrid.dataSource.sync();
                _this.options.selectedElements = _.filter(_this.options.selectedElements, function (i) { return i["id"] !== dataItem["id"]; });
            };
            this.execute = function () {
                if (_this.options.action === _this.FeedElementActions.SendMailToPartner) {
                    _this.mailingService.sendMail(_this.options.selectedElements, _this.receiverEmail).then(function (response) {
                        if (response) {
                            App.Notifier.show("MAIL_SENT", App.MessageType.success);
                            _this.closeWindow();
                            _this.eventManagerService.publish(_this.globals.events.unSelectAllElements);
                        }
                        else {
                            App.Notifier.show("MAIL_NOT_SENT", App.MessageType.error);
                        }
                    });
                }
                else {
                    _this.newsFeedService.groupAction(_this.options.selectedElements, _this.options.action, _this.groupId).then(function (response) {
                        if (response) {
                            _this.closeWindow();
                            _this.eventManagerService.publish(_this.globals.events.unSelectAllElements);
                            _this.eventManagerService.publish(_this.globals.events.getNewsFeedList, 1);
                        }
                    });
                }
            };
            this.cancel = function () {
                _this.closeWindow();
            };
            this.closeWindow = function () {
                _this.$mdDialog.hide();
            };
        }
        NewsFeedSelectedElementsComponentController.prototype.$onInit = function () {
            var _this = this;
            this.options.selectedElements.forEach(function (item) {
                _this.dataSource.push({ id: item.id, title: item.selected_article.title, mass_media: item.selected_article.mass_media.name, publish_datetime: item.selected_article.publish_datetime, subjects: item.selected_article.subjects });
            });
            if (this.options.action == App.FeedElementActionsEnum.PressEventJoin) {
                this.PressEventOptions = {
                    dataTextField: "name",
                    dataValueField: "id",
                    valuePrimitive: true,
                    dataSource: new kendo.data.DataSource({
                        transport: {
                            read: {
                                url: "/request/get_press_events",
                                dataType: "json",
                                type: "POST"
                            },
                        },
                        schema: {
                            model: {
                                id: "id"
                            }
                        }
                    })
                };
            }
            else if (this.options.action == App.FeedElementActionsEnum.AnalyzeElement) {
                this.newsFeedService.getAnalyzeDetailsBySelectedArticle(this.dataSource, this.NewsFeedType.MassMedia).then(function (response) {
                    if (response) {
                        _this.analyzeList = response;
                    }
                });
            }
        };
        Object.defineProperty(NewsFeedSelectedElementsComponentController.prototype, "selectedIds", {
            get: function () {
                return this.options.selectedElements.map(function (_a) {
                    var id = _a.id;
                    return id;
                }).join();
            },
            enumerable: true,
            configurable: true
        });
        ;
        return NewsFeedSelectedElementsComponentController;
    }());
    App.NewsFeedSelectedElementsComponentController = NewsFeedSelectedElementsComponentController;
    var NewsFeedSelectedElementsComponent = (function () {
        function NewsFeedSelectedElementsComponent() {
            this.templateUrl = "/components/news-feed-components/news-feed-selected-elements/news-feed-selected-elements.component.html";
            this.controllerAs = "vm";
            this.controller = ['$mdDialog', 'newsFeedService', 'eventManagerService', 'globals', 'mailingService',
                NewsFeedSelectedElementsComponentController
            ];
            this.bindings = {
                options: "="
            };
        }
        NewsFeedSelectedElementsComponent.id = "newsFeedSelectedElements";
        return NewsFeedSelectedElementsComponent;
    }());
    App.NewsFeedSelectedElementsComponent = NewsFeedSelectedElementsComponent;
    angular.module(App.Module)
        .component(NewsFeedSelectedElementsComponent.id, new NewsFeedSelectedElementsComponent());
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    'use strict';
    var PressEventEditController = (function (_super) {
        __extends(PressEventEditController, _super);
        function PressEventEditController($timeout, $mdDialog, eventManagerService, globals) {
            _super.call(this);
            this.$timeout = $timeout;
            this.$mdDialog = $mdDialog;
            this.eventManagerService = eventManagerService;
            this.globals = globals;
        }
        PressEventEditController.prototype.$onInit = function () {
            var _this = this;
            var m;
            if (this.pressEventId) {
                m = new App.PressEvent(this.pressEventId).loadFromDB();
            }
            else {
                m = new App.PressEvent();
            }
            this.options = {
                title: this.pressEventId ? "EDIT_PRESS_EVENT" : "NEW_PRESS_EVENT",
                size: "small",
                buttons: {
                    acceptBtn: {
                        text: "SAVE",
                        btnClass: "btn-primary",
                        disableIfInvalid: true,
                        fnc: function () {
                            _this.options.item.saveToDB(function (err, res) {
                                if (!err) {
                                    _this.$mdDialog.hide();
                                    if (!_this.pressEventId) {
                                        _this.eventManagerService.publish(_this.globals.events.refreshPressEventList);
                                    }
                                }
                            });
                        }
                    },
                    cancelBtn: {
                        text: "CANCEL",
                        btnClass: "btn-default",
                        fnc: function () {
                            _this.$mdDialog.cancel();
                        }
                    }
                },
                item: m,
                item_options: {
                    fields: {
                        name: {
                            type: "text",
                            label: "NAME",
                            get: function (item) { return item; },
                            required: function () { return true; }
                        },
                        event_date: {
                            type: "date",
                            label: "EVENT_DATE",
                            get: function (item) { return item; },
                            required: function () { return true; }
                        }
                    },
                    rows: [
                        [{ field: "name", col_class: "col-md-12" }],
                        [{ field: "event_date", col_class: "col-md-7" }]
                    ]
                }
            };
        };
        PressEventEditController.id = "PressEventEditController";
        return PressEventEditController;
    }(App.BaseController));
    App.PressEventEditController = PressEventEditController;
    var PressEventEditComponent = (function () {
        function PressEventEditComponent() {
            this.templateUrl = "/components/press-event-components/press-event-edit-component/press-event-edit.component.html";
            this.controllerAs = "vm";
            this.controller = ['$timeout', '$mdDialog', 'eventManagerService', 'globals',
                PressEventEditController
            ];
            this.bindings = {
                pressEventId: "=?",
                options: "="
            };
        }
        PressEventEditComponent.id = "pressEventEdit";
        return PressEventEditComponent;
    }());
    App.PressEventEditComponent = PressEventEditComponent;
    angular.module(App.Module)
        .component(PressEventEditComponent.id, new PressEventEditComponent());
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    var PressEventListController = (function (_super) {
        __extends(PressEventListController, _super);
        function PressEventListController($timeout, $mdDialog, $http, authenticationService, eventManagerService, globals) {
            var _this = this;
            _super.call(this);
            this.$timeout = $timeout;
            this.$mdDialog = $mdDialog;
            this.$http = $http;
            this.authenticationService = authenticationService;
            this.eventManagerService = eventManagerService;
            this.globals = globals;
            this.refreshList = function (filter) {
                _this.filterData = filter;
                _this.mainGrid.dataSource.read();
                _this.mainGrid.refresh();
                _this.initGridOptions();
            };
            this.initGridOptions = function () {
                _this.mainGridOptions = {
                    dataSource: {
                        transport: {
                            read: function (e) {
                                _this.isLoaded = false;
                                _this.$http.post("/request/get_press_event_data/", { filter: _this.filterData || null })
                                    .success(function (res) {
                                    if (res) {
                                        e.success(res);
                                        _this.isLoaded = true;
                                    }
                                })
                                    .error(function (err) {
                                    e.error(err);
                                    _this.isLoaded = true;
                                    App.Notifier.show("ERROR_OCCURRED", App.MessageType.error);
                                });
                            }
                        },
                        schema: {
                            model: {
                                fields: {
                                    name: { type: "string" },
                                    count: { type: "number" },
                                    event_date: { type: "date" }
                                }
                            }
                        },
                        pageSize: 20,
                    },
                    hierarchical: true,
                    sortable: true,
                    pageable: true,
                    resizable: true,
                    filterable: true,
                    columnMenu: true,
                    reorderable: true,
                    editable: false,
                    columns: [
                        { field: "name", title: "NAME" },
                        { field: "count", title: "COUNT", width: 250 },
                        { field: "event_date", title: "EVENT_DATE", type: "date", format: "{0:yyyy-MM-dd}", width: 250 },
                        { template: "<md-button ng-if=\"vm.controlVisibility('edit_press_event')\"  ng-click =\"vm.editPressEvent(dataItem.id)\" class=\"md-icon-button custom-cell-button\" aria-label=\"Edit\">\n                        <md-tooltip><span data-translate=\"EDIT\"></span></md-tooltip>\n                        <md-icon md-svg-icon=\"editor:mode_edit\" aria-label=\"...\"></md-icon></md-button>",
                            width: 80
                        }
                    ]
                };
            };
            this.editPressEvent = function (id) {
                _this.pressEventId = id;
                if (_this.pressEventId) {
                    _this.changeToView();
                }
            };
            this.getMassMediaGridOptions = function (pressEventId) {
                return {
                    dataSource: {
                        transport: {
                            read: {
                                url: "/request/get_articles_by_press_event_id",
                                dataType: "json",
                                type: "POST",
                                data: { id: pressEventId }
                            }
                        },
                        schema: {
                            model: {
                                fields: {
                                    mass_media: { type: "string" },
                                    total_likes: { type: "number" },
                                    total_shares: { type: "number" },
                                    total_comments: { type: "number" }
                                }
                            }
                        },
                        pageSize: 10,
                        aggregate: [
                            { field: "total_likes", aggregate: "sum" },
                            { field: "total_shares", aggregate: "sum" },
                            { field: "total_comments", aggregate: "sum" }
                        ]
                    },
                    hierarchical: true,
                    sortable: true,
                    pageable: true,
                    resizable: true,
                    filterable: true,
                    columnMenu: true,
                    columns: [
                        {
                            template: " <img class=\"title-img\" ng-src=\"imgs/massMediaLogos/svg/#=mass_media#.svg\" alt=\"Article Logo\" style=\"width:35px\"/>",
                            width: "50px"
                        },
                        {
                            field: "mass_media",
                            title: "{{'MASS_MEDIA' | translate}}"
                        },
                        {
                            field: "total_likes",
                            title: "{{'LIKES' | translate}}",
                            aggregates: ["sum"],
                            footerTemplate: "Ընդհամենը : #=kendo.toString(sum)#"
                        },
                        {
                            field: "total_shares",
                            title: "{{'SHARES' | translate}}",
                            aggregates: ["sum"],
                            footerTemplate: "Ընդհամենը : #=kendo.toString(sum)#"
                        },
                        {
                            field: "total_comments",
                            title: "{{'COMMENTS' | translate}}",
                            aggregates: ["sum"],
                            footerTemplate: "Ընդհամենը : #=kendo.toString(sum)#"
                        }
                    ]
                };
            };
            this.getArticlesGridOptions = function (pressEventId, massMediaId) {
                return {
                    dataSource: {
                        transport: {
                            read: {
                                url: "/request/get_press_event_articles_by_mass_media_id",
                                dataType: "json",
                                type: "POST",
                                data: { id: pressEventId, mass_media_id: massMediaId }
                            }
                        },
                        schema: {
                            model: {
                                fields: {
                                    title: { type: "string" },
                                    publish_datetime: { type: "date" }
                                }
                            }
                        },
                        pageSize: 10,
                    },
                    hierarchical: true,
                    sortable: true,
                    pageable: true,
                    resizable: true,
                    filterable: true,
                    columnMenu: true,
                    columns: [
                        { template: " <a href=\"#=url#\" target=\"_blank\">#=title#</a>", title: "{{'TITLE' | translate}}" },
                        { field: "publish_datetime", title: "{{'DATE' | translate}}", format: "{0:yyyy-MM-dd HH:mm}", width: "170px" },
                        { template: " <a ng-if=\"dataItem.fb_link\"  ng-href=\"#=fb_link#\" class=\"mm-icon icon icon-sm icon-mm-facebook\" target=\"_blank\" md-labeled-by-tooltip=\"md-tooltip\" style=\"margin-left:70px\"></a>",
                            title: "{{'FB_LINK' | translate}}",
                            width: "200px"
                        }
                    ]
                };
            };
            this.controlVisibility = function (controlName) {
                return _this.authenticationService.user.controlPermissions.indexOf(controlName) != -1;
            };
            this.initGridOptions();
        }
        PressEventListController.prototype.changeToView = function () {
            this.viewMode = App.ViewMode.View;
        };
        PressEventListController.prototype.$onInit = function () {
            this.eventManagerService.subscribe(this.globals.events.refreshPressEventList, this.refreshList);
        };
        return PressEventListController;
    }(App.BaseController));
    App.PressEventListController = PressEventListController;
    var PressEventListComponent = (function () {
        function PressEventListComponent() {
            this.templateUrl = "/components/press-event-components/press-event-list-component/press-event-list.component.html";
            this.controllerAs = "vm";
            this.controller = ['$timeout', '$mdDialog', '$http', 'authenticationService', 'eventManagerService', 'globals',
                PressEventListController
            ];
            this.bindings = {
                viewMode: "=",
                pressEventId: "=?"
            };
        }
        PressEventListComponent.id = "pressEventList";
        return PressEventListComponent;
    }());
    App.PressEventListComponent = PressEventListComponent;
    angular.module(App.Module)
        .component(PressEventListComponent.id, new PressEventListComponent());
})(App || (App = {}));

var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var App;
(function (App) {
    var PressEventViewController = (function (_super) {
        __extends(PressEventViewController, _super);
        function PressEventViewController($mdDialog) {
            var _this = this;
            _super.call(this);
            this.$mdDialog = $mdDialog;
            this.pressEvent = new App.PressEvent();
            this.header = {
                title: "PRESS_EVENT",
                icon: "action:event",
                buttons: {
                    btn_edit: {
                        tooltip: "EDIT_PRESS_EVENT",
                        permission_name: "edit_press_event",
                        icon: "editor:mode_edit",
                        fnc: function ($event) {
                            var options;
                            _this.$mdDialog.show({
                                template: '<press-event-edit options="options" press-event-id="pressEventId"></press-event-edit>',
                                parent: angular.element(document.body),
                                targetEvent: $event || false,
                                //clickOutsideToClose: options.clickOutsideToClose || true,
                                locals: { options: options },
                                controller: ['$scope', 'options', function ($scope, options) {
                                        $scope.options = options;
                                        $scope.pressEventId = _this.pressEventId;
                                    }]
                            }).then(function () {
                                _this.pressEvent.loadFromDB();
                            });
                        },
                    },
                }
            };
            this.press_event_form_options = {
                fields: {
                    name: {
                        type: "text",
                        readonly: "true",
                        label: "NAME",
                        get: function (item) { return item; }
                    },
                    event_date: {
                        type: "text",
                        readonly: "true",
                        label: "EVENT_DATE",
                        get: function (item) { return item; },
                        format: 'date'
                    }
                },
                rows: [
                    [{ field: "name", col_class: "col-md-8" }, { field: "event_date", col_class: "col-md-4" }]
                ]
            };
        }
        PressEventViewController.prototype.changeToList = function () {
            this.viewMode = App.ViewMode.List;
        };
        PressEventViewController.prototype.$onInit = function () {
            this.LoadPressEvent(this.pressEventId);
        };
        PressEventViewController.prototype.LoadPressEvent = function (id) {
            this.pressEvent = new App.PressEvent(id);
            this.pressEvent.loadFromDB(function (err) {
                if (err) {
                    console.error("Cannot get press event", err);
                }
            });
        };
        return PressEventViewController;
    }(App.BaseController));
    App.PressEventViewController = PressEventViewController;
    var PressEventViewComponent = (function () {
        function PressEventViewComponent() {
            this.templateUrl = "/components/press-event-components/press-event-view-component/press-event-view.component.html";
            this.controllerAs = "vm";
            this.controller = [
                '$mdDialog',
                PressEventViewController
            ];
            this.bindings = {
                viewMode: "=",
                pressEventId: "<"
            };
        }
        PressEventViewComponent.id = "pressEventView";
        return PressEventViewComponent;
    }());
    App.PressEventViewComponent = PressEventViewComponent;
    angular.module(App.Module)
        .component(PressEventViewComponent.id, new PressEventViewComponent());
})(App || (App = {}));

var App;
(function (App) {
    var FbEmojiesComponentController = (function () {
        function FbEmojiesComponentController($mdDialog, eventManagerService, socialMediaService, globals) {
            var _this = this;
            this.$mdDialog = $mdDialog;
            this.eventManagerService = eventManagerService;
            this.socialMediaService = socialMediaService;
            this.globals = globals;
            this.Mode = App.ViewMode;
            this.save = function () {
                _this.socialMediaService.saveEmojies(_this.options).then(function (res) {
                    if (res) {
                        App.Notifier.show("Պահպանված է։", App.MessageType.success);
                        _this.closeWindow();
                        _this.eventManagerService.publish(_this.globals.events.getPostList, 1);
                    }
                });
            };
            this.closeWindow = function () {
                _this.$mdDialog.hide();
            };
        }
        FbEmojiesComponentController.prototype.$onInit = function () { };
        return FbEmojiesComponentController;
    }());
    App.FbEmojiesComponentController = FbEmojiesComponentController;
    var FbEmojiesComponent = (function () {
        function FbEmojiesComponent() {
            this.templateUrl = "/components/social-media-components/fb-emojies/fb-emojies.component.html";
            this.controllerAs = "vm";
            this.controller = ['$mdDialog', 'eventManagerService', 'socialMediaService', 'globals',
                FbEmojiesComponentController
            ];
            this.bindings = {
                options: "<"
            };
        }
        FbEmojiesComponent.id = "fbEmojies";
        return FbEmojiesComponent;
    }());
    App.FbEmojiesComponent = FbEmojiesComponent;
    angular.module(App.Module)
        .component(FbEmojiesComponent.id, new FbEmojiesComponent());
})(App || (App = {}));

var App;
(function (App) {
    var SocialMediaComponentController = (function () {
        function SocialMediaComponentController($mdDialog, eventManagerService, socialMediaService, $anchorScroll, globals) {
            var _this = this;
            this.$mdDialog = $mdDialog;
            this.eventManagerService = eventManagerService;
            this.socialMediaService = socialMediaService;
            this.$anchorScroll = $anchorScroll;
            this.globals = globals;
            this.requestParameter = new App.RequestParameterModel();
            this.selectedPosts = [];
            this.loading = false;
            this.selectedPostsEdit = function (id, operation) {
                var idx = _.findIndex(_this.selectedPosts, function (item) {
                    return item.id == id;
                });
                if (!operation) {
                    if (idx == -1) {
                        _this.selectedPosts.push(_.find(_this.postList, function (item) {
                            return item.id == id;
                        }));
                    }
                    else {
                        _this.selectedPosts = _.filter(_this.selectedPosts, function (item) {
                            return item.id != id;
                        });
                    }
                }
                else {
                    if (operation == App.SelectionOperationEnum.Add) {
                        if (idx == -1) {
                            _this.selectedPosts.push(_.find(_this.postList, function (item) {
                                return item.id == id;
                            }));
                        }
                    }
                    else {
                        _this.selectedPosts = _.filter(_this.selectedPosts, function (item) {
                            return item.id != id;
                        });
                    }
                }
                _this.postComparison();
            };
            this.postComparison = function () {
                var _loop_1 = function(i) {
                    _this.postList[i].selected = (_.findIndex(_this.selectedPosts, function (item) {
                        return item.id == _this.postList[i].id;
                    }) != -1);
                };
                for (var i = 0; i < _this.postList.length; i++) {
                    _loop_1(i);
                }
                var res = _this.postList.filter(function (item1) { return _this.selectedPosts.some(function (item2) { return item1.id === item2.id; }); }).length;
                _this.eventManagerService.publish(_this.globals.events.allPostCheckUnSelect, res);
            };
            this.unSelectAllSocialMediaPosts = function () {
                _this.selectedPosts.length = 0;
            };
            this.getPostList = function (currentPage) {
                _this.requestParameter.filter = angular.copy(_this.filter);
                _this.requestParameter.filter.subject = _this.requestParameter.filter.subject && _this.requestParameter.filter.subject.join();
                _this.requestParameter.filter.social_media_accounts = _this.requestParameter.filter.social_media_accounts && _this.requestParameter.filter.social_media_accounts.join();
                _this.requestParameter.current_page = currentPage;
                _this.loading = true;
                _this.socialMediaService.getPostList(_this.requestParameter).then(function (response) {
                    if (response) {
                        _this.totalCount = response.total_count;
                        _this.postList = response.elements;
                        _this.postComparison();
                        _this.eventManagerService.publish(_this.globals.events.setPaginationCurrentPage, currentPage || 1);
                        _this.searchPattern = _this.requestParameter.filter.pattern;
                    }
                }).finally(function () {
                    _this.loading = false;
                    _this.$anchorScroll();
                });
            };
        }
        SocialMediaComponentController.prototype.$onInit = function () {
            this.eventManagerService.subscribe(this.globals.events.getPostList, this.getPostList);
            this.eventManagerService.subscribe(this.globals.events.selectedPostsEdit, this.selectedPostsEdit);
            this.eventManagerService.subscribe(this.globals.events.postComparison, this.postComparison);
            this.eventManagerService.subscribe(this.globals.events.unSelectAllSocialMediaPosts, this.unSelectAllSocialMediaPosts);
            this.requestParameter.count_per_page = +window.localStorage["countPerPage"] || this.globals.defaultValues.countPerPage;
            this.getPostList(1);
            if (!this.requestParameter.filter.start_date) {
                this.requestParameter.filter.start_date = new Date();
                this.requestParameter.filter.start_date.setHours(0, 0, 0, 0);
                this.requestParameter.filter.start_date.setDate(this.requestParameter.filter.start_date.getDate() - 5);
            }
        };
        return SocialMediaComponentController;
    }());
    App.SocialMediaComponentController = SocialMediaComponentController;
    var SocialMediaComponent = (function () {
        function SocialMediaComponent() {
            this.templateUrl = "/components/social-media-components/social-media/social-media.component.html";
            this.controllerAs = "vm";
            this.controller = ['$mdDialog', 'eventManagerService', 'socialMediaService', '$anchorScroll', 'globals',
                SocialMediaComponentController
            ];
            this.bindings = {
                filter: "<?"
            };
        }
        SocialMediaComponent.id = "socialMedia";
        return SocialMediaComponent;
    }());
    App.SocialMediaComponent = SocialMediaComponent;
    angular.module(App.Module)
        .component(SocialMediaComponent.id, new SocialMediaComponent());
})(App || (App = {}));

var App;
(function (App) {
    var SocialMediaAddComponentController = (function () {
        function SocialMediaAddComponentController($mdDialog, eventManagerService, socialMediaService, globals) {
            var _this = this;
            this.$mdDialog = $mdDialog;
            this.eventManagerService = eventManagerService;
            this.socialMediaService = socialMediaService;
            this.globals = globals;
            this.save = function () {
                _this.socialMediaService.savePost(_this.post).then(function (res) {
                    if (res) {
                        App.Notifier.show("Պահպանված է։", App.MessageType.success);
                        _this.eventManagerService.publish(_this.globals.events.getPostList, 1);
                        _this.closeWindow();
                    }
                });
            };
            this.closeWindow = function () {
                _this.$mdDialog.hide();
            };
        }
        SocialMediaAddComponentController.prototype.$onInit = function () {
            var _this = this;
            if (this.options) {
                this.socialMediaService.getSocialMediaPostBYId(this.options.id).then(function (res) {
                    if (res) {
                        _this.post = res[0];
                    }
                });
            }
            else {
                this.post = new App.Post();
            }
        };
        return SocialMediaAddComponentController;
    }());
    App.SocialMediaAddComponentController = SocialMediaAddComponentController;
    var SocialMediaAddComponent = (function () {
        function SocialMediaAddComponent() {
            this.templateUrl = "/components/social-media-components/social-media-add/social-media-add.component.html";
            this.controllerAs = "vm";
            this.controller = ['$mdDialog', 'eventManagerService', 'socialMediaService', 'globals',
                SocialMediaAddComponentController
            ];
            this.bindings = {
                options: "<?",
            };
        }
        SocialMediaAddComponent.id = "socialMediaAdd";
        return SocialMediaAddComponent;
    }());
    App.SocialMediaAddComponent = SocialMediaAddComponent;
    angular.module(App.Module)
        .component(SocialMediaAddComponent.id, new SocialMediaAddComponent());
})(App || (App = {}));

var App;
(function (App) {
    var SocialMediaCardComponentController = (function () {
        function SocialMediaCardComponentController($mdDialog, authenticationService, eventManagerService, socialMediaService, globals) {
            var _this = this;
            this.$mdDialog = $mdDialog;
            this.authenticationService = authenticationService;
            this.eventManagerService = eventManagerService;
            this.socialMediaService = socialMediaService;
            this.globals = globals;
            this.ViewMode = App.ViewMode;
            this.changeSelected = function () {
                _this.eventManagerService.publish(_this.globals.events.selectedPostsEdit, _this.cardElement.id);
            };
            this.showDialog = function (mode, $event) {
                _this.$mdDialog.show({
                    template: (mode == _this.ViewMode.Edit) ? '<social-media-add options="options"></social-media-add>' : '<fb-emojies options="options"></fb-emojies>',
                    parent: angular.element(document.body),
                    targetEvent: $event || false,
                    locals: { options: _this.cardElement },
                    controller: ['$scope', 'options', function ($scope, options) {
                            $scope.options = options;
                        }]
                });
            };
            this.analyzePost = function ($event) {
                var options = { elementId: _this.cardElement.id, post: true };
                _this.$mdDialog.show({
                    template: '<news-feed-element-analyze options="options"></news-feed-element-analyze>',
                    parent: angular.element(document.body),
                    targetEvent: $event || false,
                    locals: { options: options },
                    controller: ['$scope', 'options', function ($scope, options) {
                            $scope.options = options;
                        }]
                });
            };
            this.changeReadUnreadState = function (state) {
                _this.socialMediaService.changeReadState([_this.cardElement], state).then(function (res) {
                    if (res) {
                        _this.cardElement.read_state = state;
                    }
                });
            };
        }
        SocialMediaCardComponentController.prototype.$onInit = function () {
        };
        return SocialMediaCardComponentController;
    }());
    App.SocialMediaCardComponentController = SocialMediaCardComponentController;
    var SocialMediaCardComponent = (function () {
        function SocialMediaCardComponent() {
            this.templateUrl = "/components/social-media-components/social-media-card/social-media-card.component.html";
            this.controllerAs = "vm";
            this.controller = ['$mdDialog', 'authenticationService', 'eventManagerService', 'socialMediaService', 'globals',
                SocialMediaCardComponentController
            ];
            this.bindings = {
                cardElement: "<",
                searchPattern: "<?"
            };
        }
        SocialMediaCardComponent.id = "socialMediaCard";
        return SocialMediaCardComponent;
    }());
    App.SocialMediaCardComponent = SocialMediaCardComponent;
    angular.module(App.Module)
        .component(SocialMediaCardComponent.id, new SocialMediaCardComponent());
})(App || (App = {}));

var App;
(function (App) {
    var SocialMediaFilterComponentController = (function () {
        function SocialMediaFilterComponentController($mdDialog, eventManagerService, socialMediaService, globals) {
            var _this = this;
            this.$mdDialog = $mdDialog;
            this.eventManagerService = eventManagerService;
            this.socialMediaService = socialMediaService;
            this.globals = globals;
            this.filter = new App.PostFilter();
            this.filterUsingPageEnum = App.FilterUsingPageEnum;
            this.savedFilterChange = function () {
                if (_this.savedFilter) {
                    _this.socialMediaService.getPostFilterById(_this.savedFilter).then(function (res) {
                        if (res) {
                            _this.filter = res;
                        }
                    });
                }
                else {
                    _this.filter = null;
                }
            };
            this.saveFilter = function () {
                var options = {
                    title: "SAVE",
                    size: "small",
                    buttons: {
                        acceptBtn: {
                            text: "SAVE",
                            btnClass: "btn-primary",
                            disableIfInvalid: true,
                            fnc: function () {
                                _this.socialMediaService.savePostFilter(_this.filter).then(function (res) {
                                    if (res) {
                                        _this.getFilteredData();
                                        _this.eventManagerService.publish('SAVED_FILTERS');
                                        _this.$mdDialog.hide();
                                    }
                                }).catch(function (err) {
                                    App.Notifier.show(err.data.message, App.MessageType.error);
                                });
                            }
                        },
                        cancelBtn: {
                            text: "CANCEL",
                            btnClass: "btn-default",
                            fnc: function () {
                                _this.$mdDialog.hide();
                            }
                        }
                    },
                    item: _this.filter,
                    item_options: {
                        fields: {
                            name: { type: "text", label: "NAME", get: function (item) { return item; }, required: function () { return true; } }
                        },
                        rows: [
                            [{ field: "name", col_class: "col-md-12" }]
                        ]
                    }
                };
                _this.$mdDialog.show({
                    template: '<dialog-box options="options"></dialog-box>',
                    parent: angular.element(document.body),
                    targetEvent: false,
                    locals: { options: options },
                    controller: ['$scope', 'options', function ($scope, options) { return $scope.options = options; }]
                });
            };
            this.rated_source = [{ id: 1, description: "Չգնահատված" }, { id: 2, description: "Գնահատված" }];
            if (localStorage.hasOwnProperty('filter')) {
                this.filter = JSON.parse(localStorage.getItem('filter'));
                setTimeout(function () {
                    _this.filter = JSON.parse(localStorage.getItem('filter'));
                    localStorage.removeItem('filter');
                }, 10);
            }
        }
        SocialMediaFilterComponentController.prototype.$onInit = function () {
            this.usingPage = this.usingPage ? this.usingPage : App.FilterUsingPageEnum.NewsFeed;
        };
        SocialMediaFilterComponentController.prototype.getStartDateByProject = function (project) {
            var _this = this;
            App.Utilities.getStartDateByProject(project.id).then(function (response) {
                _this.min_date = response.start_date;
            });
        };
        return SocialMediaFilterComponentController;
    }());
    App.SocialMediaFilterComponentController = SocialMediaFilterComponentController;
    var SocialMediaFilterComponent = (function () {
        function SocialMediaFilterComponent() {
            this.templateUrl = "/components/social-media-components/social-media-filter/social-media-filter.component.html";
            this.controllerAs = "vm";
            this.controller = ['$mdDialog', 'eventManagerService', 'socialMediaService', 'globals',
                SocialMediaFilterComponentController
            ];
            this.bindings = {
                filter: "=",
                getFilteredData: "&?",
                clearFilters: "&",
                usingPage: "<?"
            };
        }
        SocialMediaFilterComponent.id = "socialMediaFilter";
        return SocialMediaFilterComponent;
    }());
    App.SocialMediaFilterComponent = SocialMediaFilterComponent;
    angular.module(App.Module)
        .component(SocialMediaFilterComponent.id, new SocialMediaFilterComponent());
})(App || (App = {}));

var App;
(function (App) {
    var SocialMediaHeaderComponentController = (function () {
        function SocialMediaHeaderComponentController($mdDialog, authenticationService, eventManagerService, socialMediaService, globals, $filter) {
            var _this = this;
            this.$mdDialog = $mdDialog;
            this.authenticationService = authenticationService;
            this.eventManagerService = eventManagerService;
            this.socialMediaService = socialMediaService;
            this.globals = globals;
            this.$filter = $filter;
            this.FeedElementActions = App.FeedElementActionsEnum;
            this.GroupSelection = App.GroupSelectionEnum;
            this.isChecked = false;
            this.pageOptions = {
                dataSource: this.globals.dataSources.countPerPageDataSource,
                dataTextField: "value",
                dataValueField: "id",
                valueTemplate: '<span class="val-txt">{{dataItem.value}}</span>',
                template: '<span class="mm-k-dropdown-val-txt">{{dataItem.value}}</span>'
            };
            this.allPostCheckUnSelect = function (res) {
                if (_this.totalCount) {
                    if (_this.totalCount < _this.countPerPage) {
                        _this.isChecked = (_this.totalCount - res) == 0 ? true : false;
                    }
                    else {
                        if (_this.currentPage <= (_this.totalCount / _this.countPerPage)) {
                            _this.isChecked = (res < _this.countPerPage) ? false : true;
                        }
                        else {
                            _this.isChecked = (res == (_this.totalCount % _this.countPerPage)) ? true : false;
                        }
                    }
                }
            };
            this.changeState = function (state) {
                if (_this.selectedPosts.length == 0) {
                    App.Notifier.show("Նախ ընտրեք որևէ հոդված։", App.MessageType.warning);
                }
                else {
                    _this.socialMediaService.changeReadState(_this.selectedPosts, state).then(function (res) {
                        if (res) {
                            _this.selectedPosts.forEach(function (post) {
                                post.read_state = state;
                            });
                        }
                    });
                }
            };
            this.checkAll = function (isChecked) {
                isChecked ? _this.groupSelection(App.GroupSelectionEnum.All) : _this.groupSelection(App.GroupSelectionEnum.None);
            };
            this.changeCountPerPage = function () {
                window.localStorage["countPerPage"] = _this.countPerPage;
            };
            this.groupSelection = function (selectionGroup) {
                _this.postList.forEach(function (item) {
                    if (selectionGroup == App.GroupSelectionEnum.All) {
                        _this.eventManagerService.publish(_this.globals.events.selectedPostsEdit, item.id, App.SelectionOperationEnum.Add);
                        _this.isChecked = true;
                    }
                    else if (selectionGroup == App.GroupSelectionEnum.None) {
                        _this.eventManagerService.publish(_this.globals.events.selectedPostsEdit, item.id, App.SelectionOperationEnum.Remove);
                        _this.isChecked = false;
                    }
                    else if (selectionGroup == App.GroupSelectionEnum.Read) {
                        if (item.read_state) {
                            _this.eventManagerService.publish(_this.globals.events.selectedPostsEdit, item.id, App.SelectionOperationEnum.Add);
                        }
                        else {
                            _this.eventManagerService.publish(_this.globals.events.selectedPostsEdit, item.id, App.SelectionOperationEnum.Remove);
                        }
                    }
                    else if (selectionGroup == App.GroupSelectionEnum.Unread) {
                        if (!item.read_state) {
                            _this.eventManagerService.publish(_this.globals.events.selectedPostsEdit, item.id, App.SelectionOperationEnum.Add);
                        }
                        else {
                            _this.eventManagerService.publish(_this.globals.events.selectedPostsEdit, item.id, App.SelectionOperationEnum.Remove);
                        }
                    }
                });
                _this.eventManagerService.publish(_this.globals.events.postComparison);
            };
            this.addPost = function ($event) {
                _this.$mdDialog.show({
                    template: '<social-media-add></social-media-add>',
                    parent: angular.element(document.body),
                    targetEvent: $event || false,
                    controller: ['$scope', function ($scope) { }]
                });
            };
            this.generatePdf = function () {
                if (_this.selectedPosts.length > 0) {
                    var win = window.open();
                    var doc = win.document.open();
                    doc.write(App.Utilities.generateHtmlFromSelectedPosts(_this.selectedPosts, _this.$filter));
                    doc.close();
                    win.print();
                }
                else {
                    App.Notifier.show("Նախ ընտրեք որևէ հոդված։", App.MessageType.warning);
                }
            };
            this.showDialog = function ($event, action) {
                if (_this.selectedPosts.length == 0) {
                    App.Notifier.show("Նախ ընտրեք որևէ հոդված։", App.MessageType.warning);
                }
                else if (action == App.FeedElementActionsEnum.RemoveElementFromSubjects) {
                    _this.socialMediaService.getSubjectsBySelectedPosts(_this.selectedPosts).then(function (res) {
                        if (res.length == 0) {
                            App.Notifier.show("Ընտրված հոդված(ներ)ը պետք է ունենան ընդհանաուր թեմա։", App.MessageType.warning);
                            return;
                        }
                        else {
                            _this.showDialogContent($event, action);
                        }
                    });
                }
                else {
                    _this.showDialogContent($event, action);
                }
            };
        }
        SocialMediaHeaderComponentController.prototype.$onInit = function () {
            this.eventManagerService.subscribe(this.globals.events.allPostCheckUnSelect, this.allPostCheckUnSelect);
            this.countPerPage = +window.localStorage["countPerPage"] || this.countPerPage;
        };
        SocialMediaHeaderComponentController.prototype.showDialogContent = function ($event, action) {
            var options = {
                action: action,
                selectedElements: this.selectedPosts
            };
            this.$mdDialog.show({
                template: '<social-media-selected-elements options="options"></social-media-selected-elements>',
                parent: angular.element(document.body),
                targetEvent: $event || false,
                locals: { options: options },
                controller: ['$scope', 'options', function ($scope, options) {
                        $scope.options = options;
                    }
                ]
            });
        };
        return SocialMediaHeaderComponentController;
    }());
    App.SocialMediaHeaderComponentController = SocialMediaHeaderComponentController;
    var SocialMediaHeaderComponent = (function () {
        function SocialMediaHeaderComponent() {
            this.templateUrl = "/components/social-media-components/social-media-header/social-media-header.component.html";
            this.controllerAs = "vm";
            this.controller = ['$mdDialog', 'authenticationService', 'eventManagerService', 'socialMediaService', 'globals', '$filter',
                SocialMediaHeaderComponentController
            ];
            this.bindings = {
                selectedPosts: "<",
                countPerPage: "=",
                totalCount: "<",
                postList: "<",
                currentPage: "<?"
            };
        }
        SocialMediaHeaderComponent.id = "socialMediaHeader";
        return SocialMediaHeaderComponent;
    }());
    App.SocialMediaHeaderComponent = SocialMediaHeaderComponent;
    angular.module(App.Module)
        .component(SocialMediaHeaderComponent.id, new SocialMediaHeaderComponent());
})(App || (App = {}));

var App;
(function (App) {
    var SocialMediaSelectedElementsComponentController = (function () {
        function SocialMediaSelectedElementsComponentController($mdDialog, eventManagerService, socialMediaService, newsFeedService, globals) {
            var _this = this;
            this.$mdDialog = $mdDialog;
            this.eventManagerService = eventManagerService;
            this.socialMediaService = socialMediaService;
            this.newsFeedService = newsFeedService;
            this.globals = globals;
            this.FeedElementActions = App.FeedElementActionsEnum;
            this.NewsFeedType = App.NewsFeedTypeEnum;
            this.dataSource = [];
            this.analyzeList = [];
            this.AttitudeGroups = App.AttitudeEnum;
            this.mainGridOptions = {
                dataSource: new kendo.data.DataSource({
                    data: this.dataSource,
                    schema: {
                        model: {
                            fields: {
                                social_media_account_type_id: { type: "text" },
                                social_media_account_name: { type: "text" },
                                content: { type: "text" },
                                publish_datetime: { editable: false, type: "date" },
                                subjects: { type: "text" }
                            }
                        }
                    }
                }),
                sortable: false,
                pageable: false,
                resizable: false,
                selectable: "row",
                filterable: false,
                columnMenu: false,
                reorderable: false,
                columns: [
                    {
                        field: "social_media_account_type_id",
                        title: "SOCIAL_MEDIA_ACCOUNT_TYPE",
                        width: 100
                    },
                    {
                        field: "social_media_account_name",
                        title: "SOCIAL_MEDIA_ACCOUNT_NAME",
                        width: 200
                    },
                    {
                        field: "content",
                        title: "CONTENT",
                        width: 300
                    },
                    {
                        field: "publish_datetime",
                        title: "PUBLISH_DATE",
                        type: "date",
                        format: "{0:yyyy-MM-dd}",
                        width: 100
                    },
                    {
                        field: "subjects",
                        title: "SUBJECTS",
                        width: 300
                    },
                    {
                        command: [
                            {
                                name: "Delete",
                                template: "<span class='icon icon-remove' ng-click='vm.removeElement($event)'></span>"
                            }
                        ],
                        attributes: {
                            "class": "text-center",
                        },
                        width: 40
                    }
                ]
            };
            this.removeElement = function ($event) {
                var sender = $event.currentTarget;
                var row = angular.element(sender).closest("tr");
                var dataItem = _this.mainGrid.dataItem(row);
                _this.mainGrid.dataSource.remove(dataItem);
                _this.mainGrid.dataSource.sync();
                _this.options.selectedElements = _.filter(_this.options.selectedElements, function (i) { return i["id"] !== dataItem["id"]; });
                _this.dataSource = _.filter(_this.dataSource, function (i) { return i["id"] !== dataItem["id"]; });
            };
            this.execute = function () {
                _this.socialMediaService.removePosts(_this.options.selectedElements, _this.options.action, _this.groupId).then(function (response) {
                    if (response) {
                        _this.closeWindow();
                        _this.eventManagerService.publish(_this.globals.events.getPostsList, 1);
                    }
                });
            };
            this.closeWindow = function () {
                _this.$mdDialog.hide();
            };
        }
        SocialMediaSelectedElementsComponentController.prototype.$onInit = function () {
            var _this = this;
            this.options.selectedElements.forEach(function (item) {
                _this.dataSource.push({ id: item.id,
                    social_media_account_type_id: item.social_media_account_type_id == 1 ? 'Պրոֆիլ' : 'Էջ',
                    social_media_account_name: item.social_media_account_name,
                    content: item.content.length > 30 ? item.content.slice(0, 30) + '...' : item.content,
                    publish_datetime: item.publish_datetime,
                    subjects: item.subjects
                });
            });
            if (this.options.action == App.FeedElementActionsEnum.AnalyzeElement) {
                this.newsFeedService.getAnalyzeDetailsBySelectedArticle(this.dataSource, this.NewsFeedType.SocialMedia).then(function (response) {
                    if (response) {
                        _this.analyzeList = response;
                    }
                });
            }
        };
        Object.defineProperty(SocialMediaSelectedElementsComponentController.prototype, "selectedIds", {
            get: function () {
                return this.options.selectedElements.map(function (_a) {
                    var id = _a.id;
                    return id;
                }).join();
            },
            enumerable: true,
            configurable: true
        });
        ;
        return SocialMediaSelectedElementsComponentController;
    }());
    App.SocialMediaSelectedElementsComponentController = SocialMediaSelectedElementsComponentController;
    var SocialMediaSelectedElementsComponent = (function () {
        function SocialMediaSelectedElementsComponent() {
            this.templateUrl = "/components/social-media-components/social-media-selected-elements/social-media-selected-elements.component.html";
            this.controllerAs = "vm";
            this.controller = ['$mdDialog', 'eventManagerService', 'socialMediaService', 'newsFeedService', 'globals',
                SocialMediaSelectedElementsComponentController
            ];
            this.bindings = {
                options: "="
            };
        }
        SocialMediaSelectedElementsComponent.id = "socialMediaSelectedElements";
        return SocialMediaSelectedElementsComponent;
    }());
    App.SocialMediaSelectedElementsComponent = SocialMediaSelectedElementsComponent;
    angular.module(App.Module)
        .component(SocialMediaSelectedElementsComponent.id, new SocialMediaSelectedElementsComponent());
})(App || (App = {}));
