module App {
    export class PressEventListController extends BaseController {
        public changeToView() {
            this.viewMode = ViewMode.View;
        }
        public mainGridOptions: any;
        public filterData: INewsFilter;
        public isLoaded: any;

        constructor(private $timeout: ng.ITimeoutService,
                    private $mdDialog: any,
                    private $http: ng.IHttpService,
                    private authenticationService: Authentication,
                    public eventManagerService: EventManagerService,
                    public globals: any) {
            super();
            this.initGridOptions();
        }

        $onInit() {
            this.eventManagerService.subscribe(this.globals.events.refreshPressEventList, this.refreshList);
        }

        public refreshList = (filter?: INewsFilter): void => {
            this.filterData = filter;
            this.mainGrid.dataSource.read();
            this.mainGrid.refresh();
            this.initGridOptions();
        };

        public mainGrid: kendo.ui.Grid;
        public pressEventId: number;

        public initGridOptions = () => {
            this.mainGridOptions = {
                dataSource: {
                    transport: {
                        read: (e) => {
                            this.isLoaded = false;
                            this.$http.post("/request/get_press_event_data/",{filter: this.filterData || null})
                                .success((res)=>{
                                    if(res){
                                        e.success(res);
                                        this.isLoaded = true;

                                    }
                                })
                                .error((err) => {
                                    e.error(err);
                                    this.isLoaded = true;
                                    Notifier.show("ERROR_OCCURRED", MessageType.error)
                                })
                        }
                    },
                    schema: {
                        model: {
                            fields: {
                                name: {type: "string"},
                                count: {type: "number"},
                                event_date: {type: "date"}
                            }
                        }
                    },
                    pageSize: 20,
                },
                hierarchical: true,
                sortable: true,
                pageable: true,
                resizable: true,
                filterable: true,
                columnMenu: true,
                reorderable: true,
                editable: false,
                columns: [
                    { field: "name", title: "NAME" },
                    { field: "count", title: "COUNT", width: 250 },
                    { field: "event_date", title: "EVENT_DATE", type: "date", format: "{0:yyyy-MM-dd}", width: 250 },
                    { template: `<md-button ng-if="vm.controlVisibility(\'edit_press_event\')"  ng-click ="vm.editPressEvent(dataItem.id)" class="md-icon-button custom-cell-button" aria-label="Edit">
                        <md-tooltip><span data-translate="EDIT"></span></md-tooltip>
                        <md-icon md-svg-icon="editor:mode_edit" aria-label="..."></md-icon></md-button>`,
                        width: 80
                    }
                ]
            }
        };

        public editPressEvent = (id: number) => {
            this.pressEventId = id
            if(this.pressEventId){
                this.changeToView();
            }
        }

        public getMassMediaGridOptions = (pressEventId: number): any => {
            return {
                dataSource: {
                    transport: {
                        read: {
                            url: "/request/get_articles_by_press_event_id",
                            dataType: "json",
                            type: "POST",
                            data: {id: pressEventId}
                        }
                    },
                    schema: {
                        model: {
                            fields: {
                                mass_media: {type: "string"},
                                total_likes: {type: "number"},
                                total_shares: {type: "number"},
                                total_comments: {type: "number"}
                            }
                        }
                    },
                    pageSize: 10,
                    aggregate: [ 
                        { field: "total_likes", aggregate: "sum" }, 
                        { field: "total_shares", aggregate: "sum" },
                        { field: "total_comments", aggregate: "sum" }
                    ]
                },
                hierarchical: true,
                sortable: true,
                pageable: true,
                resizable: true,
                filterable: true,
                columnMenu: true,
                columns: [
                    { 
                        template: ` <img class="title-img" ng-src="imgs/massMediaLogos/svg/#=mass_media#.svg" alt="Article Logo" style="width:35px"/>`,
                        width: "50px" 
                    },
                    { 
                        field: "mass_media", 
                        title: "{{'MASS_MEDIA' | translate}}"
                    },
                    { 
                        field: "total_likes", 
                        title: "{{'LIKES' | translate}}",
                        aggregates: ["sum"],
                        footerTemplate: "Ընդհամենը : #=kendo.toString(sum)#"
                    },
                    { 
                        field: "total_shares", 
                        title: "{{'SHARES' | translate}}" ,
                        aggregates: ["sum"],
                        footerTemplate: "Ընդհամենը : #=kendo.toString(sum)#"
                    },
                    { 
                        field: "total_comments", 
                        title: "{{'COMMENTS' | translate}}" ,
                        aggregates: ["sum"],
                        footerTemplate: "Ընդհամենը : #=kendo.toString(sum)#"
                    }
                ]
            };
        }

        public getArticlesGridOptions = (pressEventId: number, massMediaId: number): any => {
            return {
                dataSource: {
                    transport: {
                        read: {
                            url: "/request/get_press_event_articles_by_mass_media_id",
                            dataType: "json",
                            type: "POST",
                            data: {id: pressEventId, mass_media_id: massMediaId}
                        }
                    },
                    schema: {
                        model: {
                            fields: {
                                title: {type: "string"},
                                publish_datetime:{type: "date"}
                            }
                        }
                    },
                    pageSize: 10,
                },
                hierarchical: true,
                sortable: true,
                pageable: true,
                resizable: true,
                filterable: true,
                columnMenu: true,
                columns: [
                    { template: ` <a href="#=url#" target="_blank">#=title#</a>`, title: "{{'TITLE' | translate}}"},
                    { field: "publish_datetime", title: "{{'DATE' | translate}}", format: "{0:yyyy-MM-dd HH:mm}",  width: "170px"  },
                    { template: ` <a ng-if="dataItem.fb_link"  ng-href="#=fb_link#" class="mm-icon icon icon-sm icon-mm-facebook" target="_blank" md-labeled-by-tooltip="md-tooltip" style="margin-left:70px"></a>`,
                      title: "{{'FB_LINK' | translate}}",
                      width:"200px"
                    }
                ]
            };
        }

        public controlVisibility = (controlName: string): boolean => {
            return this.authenticationService.user.controlPermissions.indexOf(controlName) != -1;
        }
    }

    export class PressEventListComponent implements ng.IComponentOptions {
        public static id = "pressEventList";
        templateUrl = "/components/press-event-components/press-event-list-component/press-event-list.component.html";
        controllerAs = "vm";
        bindings: any;

        constructor() {
            this.bindings = {
                viewMode: "=",
                pressEventId: "=?"
            };
        }

        controller = ['$timeout', '$mdDialog', '$http', 'authenticationService', 'eventManagerService', 'globals',
            PressEventListController
        ];
    }

    angular.module(Module)
        .component(PressEventListComponent.id, new PressEventListComponent());
}
