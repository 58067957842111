///<reference path='db-loader.ts' />
module App {
    export interface IBaseFilter {
        id?: number;
        name?: string;
        pattern?: string;
        project?: Dictionary;
        subject?: number[];
        start_date?: Date;
        end_date?: Date;
        rated?: number;
    };
    export interface INewsFilter extends IBaseFilter{
        mass_media_country?: number;
        mass_media_type?: MassMediaTypeEnum;
        mass_media?: number[];
        press_event?: number;
        attitude?: number;
        mass_media_rating?: number;
        reference_type?: number;
    }; 

    export interface IPostFilter extends IBaseFilter{
        social_media_account_type?: number;
        social_media_accounts?: number[];
    };

    export class NewsFilter extends DBLoader implements INewsFilter {

        public id: number;
        public name: string;
        public pattern: string;
        public project: Dictionary;
        public subject: number[];
        public start_date: Date;
        public end_date: Date;
        public mass_media_country: number;
        public mass_media_type: MassMediaTypeEnum;
        public mass_media: number[];
        public press_event: number;
        public rated:number;
        public attitude: number;
        public mass_media_rating: number;
        public reference_type: number;

        constructor(){
            super();
            if (!this.start_date) {
                this.start_date = new Date();
                this.start_date.setHours(0,0,0,0);
                this.start_date.setDate(this.start_date.getDate() - 5);
            }
        }
    };

    export class PostFilter extends DBLoader implements INewsFilter {

        public id: number;
        public name: string;
        public pattern: string;
        public project: Dictionary;
        public subject: number[];
        public start_date: Date;
        public end_date: Date;
        public social_media_account_type: number;
        public social_media_accounts: number[];
        public rated:number;

        constructor(){
            super();
            if (!this.start_date) {
                this.start_date = new Date();
                this.start_date.setHours(0,0,0,0);
                this.start_date.setDate(this.start_date.getDate() - 5);
            }
        }
    }
}



