module App {
    export class SprootChartDirective implements ng.IDirective {
        public static id = "sprootChart";
        public template = `<div style="height: 400px; max-width: 100%; margin: 0 auto;"></div>`;
        public restrict = 'E';
        public replace: boolean = true;
        public scope = {
            options: "<",
            data: '<',
            filter: '<?',
            additionalProperty: '<?',
            newsFeedType: '<?'
        };

        /*ngInject*/
        constructor(private $filter: ng.IFilterService,
                    private $state: ng.ui.IStateService) {}

        private returnSubtitle = (start_date?: string, end_date?: string): string => {
            let str: string = this.$filter('translate')('PERIOD_BEGIN') + '  ' + this.$filter('date')(start_date, 'dd/MM/yyyy') + "   ";
            str += this.$filter('translate')('PERIOD_END') + '  ' + this.$filter('date')(end_date ? end_date : new Date(), 'dd/MM/yyyy');
            return str;
        };

        public link = ($scope: any, element: JQuery, attributes: ng.IAttributes, ctrl: ng.IControllerService[]) => {
            var chartOptions: any;
            var commonOptions: any = {
                chart: {
                    type: $scope.options.type
                },
                title: {
                    text: $scope.filter.project.name
                },
                subtitle: {
                    text: this.returnSubtitle($scope.filter.start_date, $scope.filter.end_date)
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>: {point.y} ({point.percentage:.1f} %)'
                        }
                    },
                    column: {
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                if (this.y != 0) {
                                    return this.y
                                } else {
                                    return null;
                                }
                            }
                        },
                        stacking: $scope.options.stacking
                    },
                    line: {
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                if (this.y != 0) {
                                    return this.y
                                } else {
                                    return null;
                                }
                            }
                        },
                        stacking: $scope.options.stacking
                    },
                    series: {
                        cursor: 'pointer',
                        point: {
                            events: {
                                click: (event) => {
                                    localStorage.removeItem('filter');
                                    let subject_id: number;
                                    var copyFilter: INewsFilter = angular.copy($scope.filter);
                                    copyFilter.subject  = [];
                                    if($scope.options.type === "pie"){
                                        subject_id  = event.point.subject_id;
                                    } else if($scope.options.type === "line"){
                                        let period = $scope.data.response.find(item => item.x == event.point.category);
                                        copyFilter.start_date = period.period_start;
                                        copyFilter.end_date = period.period_end;
                                        subject_id = event.point.series.userOptions.id;
                                    } else{
                                        copyFilter[$scope.additionalProperty] =  event.point.series.userOptions.id;
                                        subject_id = $scope.data.response.find(item => item.x == event.point.category).subject_id;
                                    }
                                    copyFilter.subject.push(subject_id);
                                    localStorage.setItem('filter', JSON.stringify(copyFilter));
                                    if($scope.options.type === "column" && copyFilter.undefined){
                                        return;
                                    }
                                    let path = $scope.newsFeedType === NewsFeedTypeEnum.MassMedia ? 'layout.newsFeed' : 'layout.social_media';
                                    window.open(this.$state.href(path));
                                }
                            }
                        }
                    }
                },
            };
            switch ($scope.options.type) {
                case "pie":
                    chartOptions = {
                        tooltip: {
                            pointFormat: "<b>{point.y} ({point.percentage:.1f}%)<\/b>"
                        },
                        series: [{
                            data: $scope.data,
                            showInLegend: true
                        }]
                    };
                    break;
                case "column":
                    chartOptions = {
                        xAxis: {categories: $scope.data.xAxis},
                        yAxis: {
                            title: {
                                text: null
                            }
                        },
                        series: $scope.data.series
                    };
                    break;
                case "line":
                    chartOptions = {
                        xAxis: {
                            categories: $scope.data.xAxis,
                            min: 0.5,
                            max: $scope.data.xAxis.length-1.5,
                            tickmarkPlacement: 'on',
                        },
                        yAxis: {
                            title: {
                                text: null
                            }
                        },
                        colors: $scope.data.colors,
                        series: $scope.data.series,
                    };
                    break;
            }
            angular.extend(chartOptions, commonOptions);
            Highcharts.chart(element[0], chartOptions);
        }
    }

    angular.module(Module)
        .directive(SprootChartDirective.id, ['$filter', '$state', ($filter, $state) => new SprootChartDirective($filter, $state)]);
}
