module App {
    export class NewsFeedSelectedElementsComponentController  {
        public FeedElementActions = FeedElementActionsEnum;
        public NewsFeedType = NewsFeedTypeEnum;
        public AttitudeGroups = AttitudeEnum;
        public options: any;
        public mainGrid: kendo.ui.Grid;
        private dataSource: Array<any> = [];
        private groupId: number;
        private receiverEmail: string;
        public PressEventOptions: kendo.ui.DropDownListOptions;
        private analyzeList: Array<IAnalyzeDetail> = [];

        constructor(private $mdDialog: any,
                    public newsFeedService: INewsFeedService,
                    public eventManagerService: EventManagerService,
                    public globals: any,
                    public mailingService: MailingService) {
        }
        $onInit(){
            
            this.options.selectedElements.forEach((item) => {
                this.dataSource.push({id: item.id, title: item.selected_article.title, mass_media: item.selected_article.mass_media.name, publish_datetime: item.selected_article.publish_datetime, subjects: item.selected_article.subjects})
            });
            if(this.options.action == FeedElementActionsEnum.PressEventJoin) {
                this.PressEventOptions = {
                    dataTextField: "name",
                    dataValueField: "id",
                    valuePrimitive: true,
                    dataSource: new kendo.data.DataSource({
                        transport: {
                            read: {
                                url: "/request/get_press_events",
                                dataType: "json",
                                type: "POST"
                            },
                        },
                        schema: {
                            model: {
                                id: "id"
                            }
                        }
                    })
                };
            } else if(this.options.action == FeedElementActionsEnum.AnalyzeElement){
                this.newsFeedService.getAnalyzeDetailsBySelectedArticle(this.dataSource, this.NewsFeedType.MassMedia).then((response: Array<IAnalyzeDetail>) => {
                    if (response) {
                        this.analyzeList = response;
                    }
                });
            } 
        }

        public mainGridOptions = {
            dataSource: new kendo.data.DataSource({
                data: this.dataSource,
                schema: {
                    model: {
                        fields: {
                            title: { type: "text" },
                            publish_datetime: { editable: false, type: "date" },
                            mass_media: { type: "text" },
                            subjects: {  type: "text" }
                        }
                    }
                }
            }),
            sortable: false,
            pageable: false,
            resizable: false,
            selectable: "row",
            filterable: false,
            columnMenu: false,
            reorderable: false,
            columns: [
                {
                    field: "title",
                    title: "TITLE",
                    width: 300
                },
                {
                    field: "publish_datetime",
                    title: "PUBLISH_DATE",
                    type: "date",
                    format: "{0:yyyy-MM-dd}",
                    width: 100
                },
                {
                    field: "mass_media",
                    title: "MASS_MEDIA",
                    width: 100
                },
                {
                    field: "subjects",
                    title: "SUBJECTS",
                    width: 100
                },
                {
                    command: [
                        {
                          name: "Delete",
                          template: "<span class='icon icon-remove' ng-click='vm.removeElement($event)'></span>"
                        }
                    ],
                    attributes: {
                        "class": "text-center",
                    },
                    width: 60
                }
            ]
        };

        public get selectedIds(): string {
            return  this.options.selectedElements.map(({ id }) => {return id }).join()
        };

        public removeElement = ($event): void => {
            let sender = $event.currentTarget;
            let row = angular.element(sender).closest("tr");
            let dataItem = this.mainGrid.dataItem(row);
            this.mainGrid.dataSource.remove(dataItem);
            this.mainGrid.dataSource.sync();
            this.options.selectedElements = _.filter(this.options.selectedElements, (i) => { return i["id"] !== dataItem["id"]; });
           
        };

        public execute = (): void => {
            if(this.options.action === this.FeedElementActions.SendMailToPartner){
              this.mailingService.sendMail(this.options.selectedElements, this.receiverEmail).then((response: boolean) => {
                  if (response) {
                      Notifier.show("MAIL_SENT", MessageType.success);
                      this.closeWindow();
                      this.eventManagerService.publish(this.globals.events.unSelectAllElements);
                  } else {
                      Notifier.show("MAIL_NOT_SENT", MessageType.error);
                  }
              })
            } else {
              this.newsFeedService.groupAction(this.options.selectedElements, this.options.action, this.groupId).then((response) => {
                  if (response) {
                      this.closeWindow();
                      this.eventManagerService.publish(this.globals.events.unSelectAllElements);
                      this.eventManagerService.publish(this.globals.events.getNewsFeedList,1);
                  }
              });
            }
        };

        public cancel = (): void => {
            this.closeWindow();
        };

        private closeWindow = (): void =>{
            this.$mdDialog.hide();
        }


    }

    export class NewsFeedSelectedElementsComponent implements ng.IComponentOptions {
        public static id = "newsFeedSelectedElements";
        templateUrl = "/components/news-feed-components/news-feed-selected-elements/news-feed-selected-elements.component.html";
        controllerAs = "vm";
        bindings: any;
        constructor() {
            this.bindings = {
                options: "="
            };
        }
        controller = ['$mdDialog', 'newsFeedService', 'eventManagerService', 'globals','mailingService',
            NewsFeedSelectedElementsComponentController
        ];
    }
    angular.module(Module)
        .component(NewsFeedSelectedElementsComponent.id, new NewsFeedSelectedElementsComponent());
}
