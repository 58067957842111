module App {
	'use strict';

	export class SocialMediaAnalysisController extends BaseController {
        static id = "SocialMediaAnalysisController";
        
        public newsFeedTypeEnum = NewsFeedTypeEnum;

		public filter: IPostFilter = {};
    
		/*@ngInject*/
		constructor() {
			super();
		}
	}

	angular.module(Module)
		.controller(SocialMediaAnalysisController.id, SocialMediaAnalysisController);
}