module App {

    export interface IAnalysisService {
        getAnalysisByUser(newsFeedType: NewsFeedTypeEnum): ng.IPromise<AnalysisList[]>
        analysisByDistributionOfMediaCoverage(analysis: AnalysisList): ng.IPromise<any>;
        analysisByAttitudes(analysis: AnalysisList): ng.IPromise<any>;
        analysisByMassMediaRatings(analysis: AnalysisList): ng.IPromise<any>;
        analysisByReferenceTypes(analysis: AnalysisList): ng.IPromise<any>;
        analysisByCategory(analysis: AnalysisList): ng.IPromise<any>;
        analysisScore(analysis: AnalysisList): ng.IPromise<any>;
        analysisBySphere(analysis: AnalysisList): ng.IPromise<any>;
        analysisFB(analysis: AnalysisList): ng.IPromise<any>;
        analysisByPressEvents(analysis: AnalysisList): ng.IPromise<any>;
        analysisByTags(analysis: AnalysisList): ng.IPromise<any>;
        analysisCompareForPeriod(analysis: AnalysisList): ng.IPromise<any>;
        analysisWeeklyBySubject(analysis: AnalysisList): ng.IPromise<any>;
        fbAnalysisByDistributionOfMediaCoverage(analysis: AnalysisList): ng.IPromise<any>;
        fbAnalysisByAttitudes(analysis: AnalysisList): ng.IPromise<any>;
        fbAnalysisByCategory(analysis: AnalysisList): ng.IPromise<any>;
        fbAnalysisBySphere(analysis: AnalysisList): ng.IPromise<any>;
    }

    export interface IFiltersVisibility {
        id: number,
        filters: INewsFilter
    }

    export class AnalysisService implements IAnalysisService {
        static id = "analysisService";

        /*@ngInject*/
        constructor(private $q: ng.IQService){}

        private Attitudes: any[] = [
            {id: AttitudeEnum.Positive, name: 'Positive', color: '#73d41e', data: [] },
            {id: AttitudeEnum.Neutral, name: 'Neutral', color: '#e6c544', data: [] },
            {id: AttitudeEnum.Negative, name: 'Negative', color: '#da1b1b', data: [] }
        ];

        /*private FB: any[] = [
            {id: 1, name: 'Positive Likes', color: '#73d41e', data: [] },
            {id: 2, name: 'Neutral Likes', color: '#e6c544', data: [] },
            {id: 3, name: 'Negative Likes', color: '#d4172f', data: [] },
            {id: 4, name: 'Positive Shares', color: '#5b3ce6', data: [] },
            {id: 5, name: 'Neutral Shares', color: '#af8ae6', data: [] },
            {id: 6, name: 'Negative Shares', color: '#da2e94', data: [] },
            {id: 7, name: 'Comment', color: '#2fe6dd', data: [] }
        ];*/

        private FB: any[] = [
            {id: 1, name: 'Positive Likes', color: '#73d41e', data: [] },
            {id: 2, name: 'Negative Likes', color: '#d4172f', data: [] },
            {id: 3, name: 'Positive Shares', color: '#5b3ce6', data: [] },
            {id: 4, name: 'Negative Shares', color: '#da2e94', data: [] },
            {id: 5, name: 'Comment', color: '#2fe6dd', data: [] }
        ];

        public getAnalysisByUser = (newsFeedType: NewsFeedTypeEnum = NewsFeedTypeEnum.MassMedia): ng.IPromise<AnalysisList[]> => {
            return HttpRequester.post<AnalysisList[]>("/request/get_analysis_by_user", { user_id: null, feed_type: newsFeedType })
                .then((response: AnalysisList[]) => {
                    return response;
                });
        };

        public analysisByDistributionOfMediaCoverage = (analysis: AnalysisList): ng.IPromise<any> => {
            let validFilter = this.generateValidFilter(analysis.filters);
            return HttpRequester.post<any>("/request/analysis_by_distribution_of_media_coverage", {project: validFilter.project.id, subject: validFilter.subject, mass_media_type: validFilter.mass_media_type, start_date: validFilter.start_date, end_date: validFilter.end_date})
                .then((response: any) => {
                    return response;
                });
        };

        public analysisByAttitudes = (analysis: AnalysisList): ng.IPromise<any> => {
            let validFilter = this.generateValidFilter(analysis.filters);
            return HttpRequester.post<any>("/request/analysis_by_attitudes", {project: validFilter.project.id, subject: validFilter.subject, mass_media_type: validFilter.mass_media_type, start_date: validFilter.start_date, end_date: validFilter.end_date})
                .then((response: any) => {
                    return this.generateColumnChartData(response, this.Attitudes);
                });
        };

        public analysisByMassMediaRatings = (analysis: AnalysisList): ng.IPromise<any> => {
            let validFilter = this.generateValidFilter(analysis.filters);
            return HttpRequester.post<any>("/request/analysis_by_mass_media_ratings", {project: validFilter.project.id, subject: validFilter.subject, mass_media_type: validFilter.mass_media_type, start_date: validFilter.start_date, end_date: validFilter.end_date})
                .then((response: any) => {
                    return this.generateColumnChartData(response.data, response.mass_media_ratings);
                });
        };

        public analysisByReferenceTypes = (analysis: AnalysisList): ng.IPromise<any> => {
            let validFilter = this.generateValidFilter(analysis.filters);
            return HttpRequester.post<any>("/request/analysis_by_reference_types", {project: validFilter.project.id, subject: validFilter.subject, mass_media_type: validFilter.mass_media_type, start_date: validFilter.start_date, end_date: validFilter.end_date})
                .then((response: any) => {
                    return this.generateColumnChartData(response.data, response.reference_types);
                });
        };

        public analysisByCategory = (analysis: AnalysisList): ng.IPromise<any> => {
            let validFilter = this.generateValidFilter(analysis.filters);
            return HttpRequester.post<any>("/request/analysis_by_category", {project: validFilter.project.id, subject: validFilter.subject, mass_media_type: validFilter.mass_media_type, start_date: validFilter.start_date, end_date: validFilter.end_date})
                .then((response: any) => {
                    return this.generateColumnChartData(response.data, response.category_types);
                });
        };

        public analysisScore = (analysis: AnalysisList): ng.IPromise<any> => {
            let validFilter = this.generateValidFilter(analysis.filters);
            return HttpRequester.post<any>("/request/analysis_score", {project: validFilter.project.id, subject: validFilter.subject, mass_media_type: validFilter.mass_media_type, start_date: validFilter.start_date, end_date: validFilter.end_date})
                .then((response: any) => {
                    return this.generateColumnChartData(response.data, response.mass_media_ratings);
                });
        };

        public analysisBySphere = (analysis: AnalysisList): ng.IPromise<any> => {
            let validFilter = this.generateValidFilter(analysis.filters);
            return HttpRequester.post<any>("/request/analysis_by_sphere", {project: validFilter.project.id, subject: validFilter.subject, mass_media_type: validFilter.mass_media_type, start_date: validFilter.start_date, end_date: validFilter.end_date})
                .then((response: any) => {
                    return this.generateColumnChartData(response.data, response.sphere_types);
                });
        };

        public analysisFB = (analysis: AnalysisList): ng.IPromise<any> => {
            let validFilter = this.generateValidFilter(analysis.filters);
            return HttpRequester.post<any>("/request/analysis_fb", {project: validFilter.project.id, subject: validFilter.subject, mass_media_type: validFilter.mass_media_type, start_date: validFilter.start_date, end_date: validFilter.end_date})
                .then((response: any) => {
                    return this.generateColumnChartData(response, this.FB);
                });
        };

        public analysisByPressEvents = (analysis: AnalysisList): ng.IPromise<any> => {
            let validFilter = this.generateValidFilter(analysis.filters);
            return HttpRequester.post<any>("/request/analysis_by_press_events", {project: validFilter.project.id, subject: validFilter.subject, mass_media_type: validFilter.mass_media_type, start_date: validFilter.start_date, end_date: validFilter.end_date})
                .then((response: any) => {
                    return response;
                });
        };

        public analysisByTags = (analysis: AnalysisList): ng.IPromise<any> => {
            let validFilter = this.generateValidFilter(analysis.filters);
            return HttpRequester.post<any>("/request/analysis_by_tags", {project: validFilter.project.id, subject: validFilter.subject, mass_media_type: validFilter.mass_media_type, start_date: validFilter.start_date, end_date: validFilter.end_date})
                .then((response: any) => {
                    return this.generateColumnChartData(response.data, response.tag_types);
                });
        };

        public analysisWeeklyBySubject = (analysis: AnalysisList): ng.IPromise<any> => {
            let validFilter = this.generateValidFilter(analysis.filters);
            return HttpRequester.post<any>("/request/analysis_weekly_by_subject", {project: validFilter.project.id, subject: +validFilter.subject, mass_media_type: validFilter.mass_media_type, start_date: validFilter.start_date, end_date: validFilter.end_date})
                .then((response: any) => {
                    return this.generateColumnChartData(response, this.Attitudes)
                });

        }

        public analysisCompareForPeriod = (analysis: AnalysisList): ng.IPromise<any>  => {
            let validFilter = this.generateValidFilter(analysis.filters);
            return HttpRequester.post<any>("/request/analysis_compare_for_period", {project: validFilter.project.id, subject: validFilter.subject, mass_media_type: validFilter.mass_media_type, start_date: validFilter.start_date, end_date: validFilter.end_date})
                .then((response: any) => {
                    return this.generateLineChartData(response.data, response.subjects)
                });
        };

        private generateChartData  = (response: any[], keyArray: any[]): any[] => {
            let generatedData: any = [];
            for (let key of keyArray) {
                generatedData[key.id - 1] = {key: key.description, color: key.color, values: []};
                generatedData[key.id - 1].values = _.where(response, {key_property: key.id});
            }
            return generatedData;
        };

        private generateColumnChartData = (response: any[], keyArray: any[]) => {
            let localKeyArray = angular.copy(keyArray);
            var xAxis: any[] = [];
            for(var i: number = 0; i < response.length; ++i) {
                if(_.findIndex(xAxis, (item) => { return item == response[i].x}) == -1) {
                    xAxis.push(response[i].x);
                }
                var obj = _.find(localKeyArray, (item) => { return item.id == response[i].key_property});
                if (obj) {
                    if (!obj.hasOwnProperty('data')) {
                        obj.data = [];
                    }
                    obj.data.push(response[i].y);
                }
            }
            return {xAxis: xAxis, series: localKeyArray, response: response};
        };

        private generateLineChartData = (response: any[], keyArray: any[]) => {
            let localKeyArray = angular.copy(keyArray);
            var xAxis: any[] = [];
            var colors: any[] = [];
            for(var i: number = 0; i < response.length; ++i) {
                if(_.findIndex(xAxis, (item) => { return item == response[i].x}) == -1) {
                    xAxis.push(response[i].x);
                }
                var obj = _.find(localKeyArray, (item) => { return item.id == response[i].key_property});
                if (obj) {
                    if (!obj.hasOwnProperty('data')) {
                        obj.data = [];
                    }
                    obj.data.push(response[i].y);
                }
             }
            for(var i: number = 0; i < keyArray.length; ++i) {
                if(_.findIndex(colors, (item) => { return item == keyArray[i].subject_color}) == -1) {
                    colors.push(keyArray[i].subject_color);
                }
            }
             return {xAxis: xAxis, series: localKeyArray, colors: colors, response: response};
        };

        private generateValidFilter = (filter: INewsFilter): any => {
            if(!filter.start_date){
                Utilities.getStartDateByProject(filter.project.id).then((response:INewsFilter) => {
                    filter.start_date = response.start_date;
                });
            }
            let validFilter: any;
            validFilter = angular.copy(filter);
            if (filter.subject) {
                validFilter.subject = validFilter.subject.join();
            }
            return validFilter
        }

        public fbAnalysisByDistributionOfMediaCoverage = (analysis: AnalysisList): ng.IPromise<any> => {
            let validFilter = this.generateValidFilter(analysis.filters);
            return HttpRequester.post<any>("/request/fb_analysis_by_distribution_of_media_coverage", {project: validFilter.project.id, subject: validFilter.subject, mass_media_type: validFilter.mass_media_type, start_date: validFilter.start_date, end_date: validFilter.end_date})
                .then((response: any) => {
                    return response;
                });
        }

        public fbAnalysisByAttitudes = (analysis: AnalysisList): ng.IPromise<any> => {
            let validFilter = this.generateValidFilter(analysis.filters);
            return HttpRequester.post<any>("/request/fb_analysis_by_attitudes", {project: validFilter.project.id, subject: validFilter.subject, start_date: validFilter.start_date, end_date: validFilter.end_date})
                .then((response: any) => {
                    return this.generateColumnChartData(response, this.Attitudes);
                });
        }

        public fbAnalysisByCategory = (analysis: AnalysisList): ng.IPromise<any> => {
            let validFilter = this.generateValidFilter(analysis.filters);
            return HttpRequester.post<any>("/request/fb_analysis_by_category", {project: validFilter.project.id, subject: validFilter.subject, start_date: validFilter.start_date, end_date: validFilter.end_date})
                .then((response: any) => {
                    return this.generateColumnChartData(response.data, response.category_types);
                });
        }

        public fbAnalysisBySphere = (analysis: AnalysisList): ng.IPromise<any> => {
            let validFilter = this.generateValidFilter(analysis.filters);
            return HttpRequester.post<any>("/request/fb_analysis_by_sphere", {project: validFilter.project.id, subject: validFilter.subject, start_date: validFilter.start_date, end_date: validFilter.end_date})
                .then((response: any) => {
                    return this.generateColumnChartData(response.data, response.sphere_types);
                });
        }
    }

    angular.module(App.Module)
        .service(AnalysisService.id, AnalysisService);
}
