'use strict';

module App {

    export class UsersController {
        static id = "usersController";
        public user: User;
        public usersGrid: kendo.ui.Grid;
        public newsFeedTypeEnum = NewsFeedTypeEnum;
        public sourceDescriptions: any;

        /*@ngInject*/
        constructor(
            public authenticationService: Authentication,
            public $mdDialog: any,
            public $http: ng.IHttpService,
            private $translate: ng.translate.ITranslateService
        ) {
            this.user = new User();
        }

        $onInit() {
        }

        public controlVisibility(controlName: string): boolean {
            return this.authenticationService.user.controlPermissions.indexOf(controlName) != -1;
        }

        public mainGridOptions = {
            dataSource: {
                transport: {
                    read: {
                        url: "/request/get_users",
                        dataType: "json",
                        type: "POST",
                        cache: false
                    }
                },
                pageSize: 20
            },
            hierarchical: true,
            sortable: true,
            pageable: true,
            resizable: true,
            selectable: "row",
            filterable: true,
            columnMenu: true,
            reorderable: true,
            editable: false,
            change: (e: kendo.ui.GridChangeEvent) => {
                var grid = e.sender;
                var data = grid.dataItem(grid.select());
                this.user.id = data["id"];
            },
            dblClick: () => {
                if (this.controlVisibility('edit_user')) {
                    this.saveUser(null, 'edit');
                }
            },
            columns: [
                { field: "name", title: "NAME" },
                { field: "username", title: "USERNAME" },
                { field: "email", title: "EMAIL" },
                { field: "group", title: "GROUP" },
                { field: "status", title: "STATUS" },
                { field: "customer", title: "CUSTOMER" },
                { field: "last_login_date", title: "LOGIN_DATE", type: "date", format: "{0:yyyy/MM/dd HH:mm:ss}" }
            ]
        };

        public getUserReports = (userId: number) => {
            return {
                toolbar: [
                    {
                        name: "EDIT",
                        template: `<span flex></span><md-button ng-if="vm.controlVisibility('add_project_to_mass_media')"  ng-click="vm.editUserReportsList($event, dataItem.id)" class="md-icon-button custom-cell-button"  aria-label="Edit"><md-tooltip><span data-translate="EDIT"></span></md-tooltip><md-icon md-svg-icon="editor:mode_edit" aria-label="..." class="edit-mass"></md-icon></md-button>`
                    }
                ],
                dataSource: {
                    transport: {
                        read: {
                            url: "/request/get_reports_by_user",
                            dataType: "json",
                            type: "POST",
                            data: { userId: userId }
                        }
                    },
                    pageSize: 10,
                },
                sortable: true,
                pageable: true,
                resizable: true,
                filterable: true,
                columnMenu: true,
                columns: [
                    { field: "description", title: "{{'NAME' | translate}}" },
                    { field: "report_type_description", title: "{{'TYPE' | translate}}" }]
            }
        };

        public editUserReportsList = ($event, userId: number) => {
            this.$http.post('request/get_reports/', { id: userId }).success((res: any) => {
                for (var p in res) {
                    res[p].assigned = res[p].assigned == 0 ? false : true;
                }
                this.$mdDialog.show({
                    template: $('#ReportListPopupTemplate').html(),
                    parent: angular.element(document.body),
                    locals: { options: { reportList: res } },
                    controller: ['$scope', 'options', ($scope, options) => {
                        $scope.isChaked = false;
                        $scope.reportList = options.reportList;
                        $scope.cancel = () => {
                            this.$mdDialog.hide();
                        };
                        $scope.checkAll = (isChecked: boolean) => {
                            for (var p in $scope.reportList) {
                                $scope.reportList[p].assigned = isChecked;
                            }
                        };
                        $scope.saveReportList = () => {
                            var arr: number[] = [];
                            for (var i in $scope.reportList) {
                                $scope.reportList[i].assigned && arr.push($scope.reportList[i].id);
                            }
                            this.$http.post('request/save_user_reports', { user_id: userId, report_ids: arr.join() }).success(() => {
                                Notifier.show("CHANGES_SAVED", MessageType.success);
                                var grid: kendo.ui.Grid = $("#reportGrid" + userId).data("kendoGrid");
                                grid.dataSource.read();
                                grid.refresh();
                                this.$mdDialog.cancel();
                            }).error((err) => {
                                Notifier.show(err.message, MessageType.error);
                            })
                        }
                    }]
                })

            }).error((err) => {
                Notifier.show(err.message, MessageType.error)
            })
        };

        public getUserAnalysis = (userId: number) => {
            return {
                toolbar: [
                    {
                        name: "EDIT",
                        template: `<span flex></span><md-button ng-if="vm.controlVisibility('add_project_to_mass_media')"  ng-click="vm.editUserAnalysisList($event, dataItem.id)" class="md-icon-button custom-cell-button"  aria-label="Edit"><md-tooltip><span data-translate="EDIT"></span></md-tooltip><md-icon md-svg-icon="editor:mode_edit" aria-label="..." class="edit-mass"></md-icon></md-button>`
                    }
                ],
                dataSource: {
                    transport: {
                        read: {
                            url: "/request/get_analysis_by_user",
                            dataType: "json",
                            type: "POST",
                            data: { user_id: userId }
                        }
                    },
                    pageSize: 10,
                },
                sortable: true,
                pageable: true,
                resizable: true,
                filterable: true,
                columnMenu: true,
                columns: [
                    { field: "name", title: "{{'NAME' | translate}}", template: "{{dataItem.name | translate}}" },
                    { field: "name", title: "{{'TYPE' | translate}}", template: "{{dataItem.feed_type === vm.newsFeedTypeEnum.MassMedia ? ('MASS_MEDIA' | translate): ('SOCIAL_MEDIA' | translate)}}" }
                ]
            }
        };

        public editUserAnalysisList = ($event, userId: number) => {
            this.$http.post('request/get_analysis_list/', { id: userId, feed_type: null }).success((res: any) => {
                for (var p in res) {
                    res[p].assigned = res[p].assigned == 0 ? false : true;
                }
                this.$mdDialog.show({
                    template: $('#AnalysisListPopupTemplate').html(),
                    parent: angular.element(document.body),
                    locals: { options: { analysisList: res } },
                    controller: ['$scope', 'options', ($scope, options) => {
                        $scope.sourceDescriptions = {
                            mass_media: this.$translate.instant('MASS_MEDIA'),
                            social_media: this.$translate.instant('SOCIAL_MEDIA')
                        }
                        $scope.isChaked = false;
                        $scope.analysisList = options.analysisList;
    
                        $scope.selectedFeedType = "";
                        $scope.cancel = () => {
                            this.$mdDialog.hide();
                        };
                        $scope.checkAll = (isChecked: boolean) => {
                            for (var p in $scope.analysisList) {
                                $scope.analysisList[p].assigned = isChecked;
                            }
                        };
                        $scope.saveAnalysisList = () => {
                            var arr: number[] = [];
                            for (var i in $scope.analysisList) {
                                $scope.analysisList[i].assigned && arr.push($scope.analysisList[i].id);
                            }
                            this.$http.post('request/save_user_analysis', { user_id: userId, analysis_ids: arr.join() }).success(() => {
                                Notifier.show("CHANGES_SAVED", MessageType.success);
                                var grid: kendo.ui.Grid = $("#analysisGrid" + userId).data("kendoGrid");
                                grid.dataSource.read();
                                grid.refresh();
                                this.$mdDialog.cancel();
                            }).error((err) => {
                                Notifier.show(err.message, MessageType.error);
                            })
                        }

                        $scope.filterAnalysisList = (type) => {
                            return $scope.selectedFeedType === "" || type.feed_type == $scope.selectedFeedType
                        }
                    }]
                });
            }).error((err) => {
                Notifier.show(err.message, MessageType.error)
            });
        };

        public getUserLogs = (userId: number) => {
            return {
                dataSource: {
                    transport: {
                        read: {
                            url: "/request/get_user_logs",
                            dataType: "json",
                            type: "POST",
                            data: { userId }
                        }
                    },
                    pageSize: 10,
                },
                sortable: true,
                pageable: true,
                resizable: true,
                filterable: true,
                columnMenu: true,
                columns: [{ field: "date", title: "{{'LOG_DATE' | translate}}", type: "date", format: "{0:yyyy/MM/dd HH:mm:ss}" }]
            }
        }

        public saveUser($event, mode: string) {
            if (mode == 'new') {
                this.user = new User();
            }
            var options = {
                title: (this.user.id ? "EDIT_USER" : "NEW_USER"),
                event: $event,
                buttons: {
                    acceptBtn: {
                        text: "SAVE",
                        btnClass: "btn-primary",
                        disableIfInvalid: true,
                        fnc: () => {
                            options.item.saveToDB((err, res?) => {
                                if (!err) {
                                    this.user = new User();
                                    this.$mdDialog.cancel();
                                    this.usersGrid.dataSource.read();
                                    this.usersGrid.refresh();
                                }
                            });
                        }
                    },
                    cancelBtn: {
                        text: "CANCEL",
                        btnClass: "mm-btn-link clr-default",
                        fnc: () => {
                            this.user = new User();
                            this.$mdDialog.cancel()
                        }
                    }
                },
                item: (this.user.id ? (new User(this.user.id)).loadFromDB() : new User()),
                item_options: {
                    fields: {
                        firstName: { type: "text", label: "FIRST_NAME", get: (item) => item, required: () => true },
                        lastName: { type: "text", label: "LAST_NAME", get: (item) => item, required: () => true },
                        email: { type: "email", label: "EMAIL", get: (item) => item },
                        login: { type: "text", label: "USERNAME", get: (item) => item, required: () => true },
                        group: { type: "combo", label: "GROUP", source_url: "/request/get_user_groups", get: (item) => item, required: () => true },
                        status: { type: "combo", label: "STATUS", source_url: "/request/get_user_statuses", get: (item) => item, required: () => true },
                        customer: { type: "combo", label: "CUSTOMER", source_url: "/request/get_customers", get: (item) => item, description: 'name' },
                        feedRefreshInterval: { type: "number", label: "FEED_REFRESH_INTERVAL", get: (item) => item },
                    },
                    rows: [
                        [{ field: "firstName", col_class: "col-md-6" }, { field: "lastName", col_class: "col-md-6" }],
                        [{ field: "login", col_class: "col-md-6" }, { field: "email", col_class: "col-md-6" }],
                        [{ field: "group", col_class: "col-md-6" }, { field: "status", col_class: "col-md-6" }],
                        [{ field: "customer", col_class: "col-md-6" }, { field: "feedRefreshInterval", col_class: "col-md-6" }]
                    ]
                },
                size: "medium"
            };


            this.$mdDialog.show({
                template: '<dialog-box options="options"></dialog-box>',
                parent: angular.element(document.body),
                targetEvent: options.event || false,
                locals: { options: options },
                controller: ['$scope', 'options', ($scope, options) => $scope.options = options]
            })
        }
    }

    angular.module(Module)
        .controller(UsersController.id, UsersController);
}
