module App {
    'use strict';

    export class HomeController {
        static id = "homeController";
        public username: string;
        public password: string;
        public err_message: string;
        public navItems = [
            {
                title: "HOME",
                href: "home",
                id: "first"
            },
            {
                title: "ABOUT",
                href: "about",
                id: ""

            },
            {
                title: "SERVICES",
                href: "services",
                id: ""
            },
            {
                title: "CONTACT",
                href: "contact",
                id: ""
            },
        ];
        public services = [
            {
                title: "SERVICE_2",
                contents: ["SERVICE_2_2", "SERVICE_2_3"]
            },
            {
                title: "SERVICE_1",
                contents: ["SERVICE_1_2", "SERVICE_1_3", "SERVICE_1_4", "SERVICE_1_5", "SERVICE_1_6", "SERVICE_1_7"]
            },
            {
                title: "SERVICE_3",
                contents: ["SERVICE_3_2", "SERVICE_3_3"]
            }];

        /*@ngInject*/
        constructor(public $http: ng.IHttpService, private $state: ng.ui.IStateService, public authenticationService: Authentication) {
            this.username = "";
            this.password = "";
            this.err_message = "";

            $(window).scroll(function() {
                var windscroll = $(window).scrollTop();
                if (windscroll >= 100) {
                    $('#container').find('section').each(function(i) {
                        if ($(this).position().top <= windscroll + 80) {
                            $('#nav-menu ').find('a.active').removeClass('active');
                            $('#nav-menu').find('a').eq(i).addClass('active');
                        }
                    });

                } else {
                    $('#nav-menu').removeClass('fixed');
                    $('#nav-menu').find('a.active').removeClass('active');
                    $('#nav-menu').find('a:first').addClass('active');
                }

            }).scroll();​
        }

        public login() {
            if (this.username == "" || this.password == "") {
                this.err_message = "Fill all fields";
            }
            this.authenticationService.loginUser(this.username, this.password, (err: Error) => {
                if (this.authenticationService.isAuthorized()) {
                    this.$state.go("layout.newsFeed");
                } else if (err) {
                    this.err_message = err.message;
                }
            });
        }

        public scrollToSection(index) {
            var scrollAnchor = this.navItems[index].href,
                scrollPoint = $('section[data-anchor="' + scrollAnchor + '"]').offset().top - 50;
            $('body,html').animate({
                scrollTop: scrollPoint
            }, 500);

            return false;
        }
    }

    angular.module(Module)
        .controller(HomeController.id, HomeController);
}
