///<reference path='db-loader.ts' />
module App {
    export interface IProject {
        id: number;
        name: string;
        start_date: Date;
        subjects: Subject[];
        updatesAvailable: boolean
    }
    export class Project extends DBLoader implements IProject{
        public id: number;
        public name: string;
        public start_date: Date;
        public subjects: Subject[];
        public updatesAvailable: boolean
        constructor(id?: number) {
            super();
            this.id = id || null;
            this.name = null;
            this.start_date = null;
            this.subjects = [];
            this.updatesAvailable = null
        }
        
    }
}
