module App {

  export class Authentication{
    static id = "authenticationService";
    public user: User;
    private login_checked: boolean = false;

    public loginUser(login: string, password: string, callback: Function) {
      this.$http.post('/request/login/', {login: login, password: password}).success((res: any) => {
        this.login_checked = true;
        if (res.success) {
          this.user.init(res.user);
        } else {
          this.user.reset();
        }

        if (callback) {
          callback();
        }
      }).error((err: Error) => {
        this.user.reset();
        if (callback) {
          callback(err);
        }
      });
    }

    public checkLogin() {
      var d = this.$q.defer();
      if (this.login_checked) {
        this.$timeout(() => {
          d.resolve();
        })
      } else {
        this.loginUser("", "", (err) => {
          d.resolve()
        });
      }
      return d.promise;
    }

    public logoutUser(callback: Function) {
      this.$http.post('/request/logout/', '').success(() => {
        this.user.reset();
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (callback) {
          callback();
        }
      });
    }

    public isAuthorized = () => this.user.isAuthorized();

      public get isCustomerRole(): boolean {
          return this.user.role == UserRoleEnum.Customer;
      }

      public get isBackOfficeRole(): boolean {
          return this.user.role == UserRoleEnum.BackOffice;
      }


    /*@ngInject*/
    constructor(
      private $http: ng.IHttpService,
      private $q: ng.IQService,
      private $timeout: ng.ITimeoutService) {
      this.user = new User();
    }
  }

  angular.module(App.Module)
    .service(Authentication.id, Authentication);
}

