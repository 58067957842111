///<reference path='db-loader.ts' />
module App {

    export interface ISocialMediaFeedResponse {
        total_count: number;
        elements: ISocialMediaElement[];
    }

    export interface ISocialMediaElement {
        id: number;
        post: Post;
        read_state: boolean;
        rated: number;
        selected: boolean;
        logo: string;
    }

    export interface IPost {
        id: number;
        title: string;
        content: string;
        publish_datetime: Date;
        url: string;
        comments_url: string;
        image:string;
        social_media: SocialMedia;
        total_likes: number;
        emoji_like: number;
        emoji_love: number;
        emoji_haha: number;
        emoji_wow: number;
        emoji_sad: number;
        emoji_angry: number;
        shares: number;
        comments: number
        keywords: string;
        subjects: string;
    }


    export class SocialMediaFeedResponse extends DBLoader implements ISocialMediaFeedResponse {
        constructor() {
            super();
            this.total_count = 0;
            this.elements =[];
        }
        public total_count: number;
        public elements: SocialMediaElement[];
    }


    export class SocialMediaElement extends DBLoader implements ISocialMediaElement {
        constructor() {
            super();
            this.selected= false;
        }
        public id: number;
        public post: Post;
        public read_state: boolean;
        rated: number;
        public selected: boolean;
        logo: string;
    }



    export class Post extends DBLoader implements  IPost {

        public id: number;
        public title: string;
        public content: string;
        public publish_datetime: Date;
        public url: string;
        public comments_url: string;
        public image: string;
        public social_media: SocialMedia;
        public total_likes: number;
        public emoji_like: number;
        public emoji_love: number;
        public emoji_haha: number;
        public emoji_wow: number;
        public emoji_sad: number;
        public emoji_angry: number;
        public shares: number;
        public comments: number;
        public keywords: string;
        public subjects: string;
		public rated: number;

        constructor(vPost?: any) {
            super();
            if (vPost) {
                this.id = vPost.id;
                this.title = vPost.title;
                this.content = vPost.content;
                this.publish_datetime = vPost.publish_datetime;
                this.url = vPost.url;
                this.comments_url = vPost.comments_url;
                this.image = vPost.image;
                this.emoji_like = vPost.emoji_like;
                this.emoji_love = vPost.emoji_love;
                this.emoji_haha = vPost.emoji_haha;
                this.emoji_wow = vPost.emoji_wow;
                this.emoji_sad = vPost.emoji_sad;
                this.emoji_angry = vPost.emoji_angry;
                this.total_likes = vPost.emoji_like + vPost.emoji_love + vPost.emoji_haha + vPost.emoji_wow + vPost.emoji_sad + vPost.emoji_angry;
                this.shares = vPost.shares;
                this.comments = vPost.comments;

                this.social_media = new SocialMedia();
                this.social_media.id = vPost.social_media_id;
                this.social_media.name = vPost.social_media_name;
                this.social_media.type = {
                    id: vPost.social_media_type_id,
                    description: vPost.social_media_type_description
                };
                this.keywords = vPost.keywords;
                this.subjects = vPost.subjects;
				this.rated = vPost.rated;
            }

        }
    }
}
