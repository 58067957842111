module App {

    export class PaginationComponentController extends BaseController {

        public total: number;
        public currentPage: number;
        public countPerPage: number;
        public showPrevNext: boolean;
        public showFirstLast: boolean;
        public buttonsMaxCount: number = 5;
        public pageChangeEvent: string;

        constructor(
            private eventManagerService: EventManagerService,
            private $scope: ng.IScope,
            private $timeout: ng.ITimeoutService,
            private globals: any) {
            super();
            this.$scope.$watch('vm.countPerPage', (newValue: number, oldValue: number) => {
                if(newValue && newValue != oldValue) {
                    this.currentPage = 1;
                    this.goTo();
                }
            })

        }

        $onInit = () => {
			this.eventManagerService.subscribe(this.globals.events.setPaginationCurrentPage, this.setCurrentPage);
        }

        public goTo = (): void => {
            this.eventManagerService.publish(this.pageChangeEvent, this.currentPage);
        }

        private setCurrentPage = (pageNumber: number): void => {
            this.currentPage = pageNumber;
        }
    }

    export class PaginationComponent implements ng.IComponentOptions {
        public static id = "pagination";
        templateUrl = "/components/pagination/pagination.component.html";
        controllerAs = "vm";
        bindings: any;
        constructor() {
            this.bindings = {
                total: "<",
                countPerPage: "<",
                pageChangeEvent: "<",
                showPrevNext: "<?",
                showFirstLast: "<?"
            };
        }
        controller = ['eventManagerService', '$scope', '$timeout', 'globals',
            PaginationComponentController
        ];
    }
    angular.module(Module)
        .component(PaginationComponent.id, new PaginationComponent());
}
