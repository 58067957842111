module App {

  interface IDBLoaderClass {
    className: string
  }
  interface IDBLoaderProperty {
    type: string;
    field: string;
    optional?: string;
  }
  interface IDBLoaderObject {
    properties: IDBLoaderProperty[];
    extends?: string;
  }

  interface IDBLoader {
    id: number;
  }

  export class DBLoader implements IDBLoader {
    static objects: IDBLoaderObject[] = null;
    static classes: any;
    protected className = "";
    private property_types = {};
    public id: number;

    public static registerClass(n: string, c: any) {
      DBLoader[n] = c;
    }

    public static clone(obj) {
      var copy;

      // Handle the 3 simple types, and null or undefined
      if (null == obj || "object" != typeof obj) return obj;

      // Handle Date
      if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
      }

      // Handle Array
      if (obj instanceof Array) {
        copy = [];
        for (var i = 0, len = obj.length; i < len; i++) {
          copy[i] = DBLoader.clone(obj[i]);
        }
        return copy;
      }

      // Handle Object
      if (obj instanceof Object) {
        copy = {};
        if (obj.getClassName) {
          //if object class is described in objects.json
          for (var attr in obj.getProperties()) {
            copy[attr] = DBLoader.clone(obj[attr]);
          }
        } else {
          for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = DBLoader.clone(obj[attr]);
          }
        }
        return copy;
      }

      throw new Error("Unable to copy obj! Its type isn't supported.");
    }

    public getClassName() {
      return this.constructor["name"];
    }

    public getProperties() {
      var c, cn = this.getClassName(), a = {};
      while (cn) {
        if (! this.getClassName() || ! (c = DBLoader.objects[cn])) {
          console.error("There is no ", cn, " class description in objects.json");
        }
        for (var i in c.properties) {
          a[i] = c.properties[i];
        }
        cn = c.extends;
      }
      return a;
    }

    public saveToDB(cb){
      var className = this.getClassName();

      DBLoader.checkAndloadObjects();

      if (! className) {
        console.error("Called saveToDB on object which class can't be determined");
      }

      if (! DBLoader.objects[className]) {
        console.error("There is no ", className, " object in objects.json file.");
        return;
      }

      var s = DBLoader.objects[className]["set"];
      if (! s) {
        console.error("There is no set property in objects.json file for type ", className);
      }

      $.ajax({
        type: "POST",
        url: s["url"],
        //data: DBLoader.clone(this),
        data: {jsondata: JSON.stringify(DBLoader.clone(this))},
        success: (res) => {
          Notifier.show("CHANGES_SAVED", MessageType.success);
          cb && cb(null, res);
        },
        dataType: "json"
      }).fail(function(err) {
        var err_message;
        try {
          err_message = JSON.parse(err.responseText)["message"]
        } catch (ex) {
          console.error("JSON.parse ERROR")
        }
        Notifier.show(err_message, MessageType.error);
        console.error( err);
        cb && cb(err);
      });
    }

    public removeFromDB(cb){
      var className = this.getClassName();

      DBLoader.checkAndloadObjects();

      if (! className) {
        console.error("Called removeFromDB on object which class can't be determined");
      }

      if (! DBLoader.objects[className]) {
        console.error("There is no ", className, " object in objects.json file.");
        return;
      }

      var s = DBLoader.objects[className]["remove"];
      if (! s) {
        console.error("There is no remove property in objects.json file for type ", className);
      }

      $.ajax({
        type: "POST",
        url: s["url"],
        //data: DBLoader.clone(this),
        data: {jsondata: JSON.stringify(DBLoader.clone(this))},
        success: (res) => {
          Notifier.show("CHANGES_SAVED", MessageType.success);
          console.log( "success" );
          cb && cb(null, res);
        },
        dataType: "json"
      }).fail(function(err) {
        var err_message;
        try {
          err_message = JSON.parse(err.responseText)["message"]
        } catch (ex) {
          console.log("JSON.parse ERROR")
        }
        Notifier.show(err_message, MessageType.error);
        console.error( err);
        cb && cb(err);
      });
    }

    public loadFromDB(cb?: Function) {
      var className = this.getClassName();
      DBLoader.checkAndloadObjects();

      if (! className) {
        console.error("Called saveToDB on object which class can't be determined");
      }

      if (! DBLoader.objects[className]) {
        console.error("There is no ", className, " object in objects.json file.");
        return;
      }

      var g = DBLoader.objects[className]["get"];
      if (! g) {
        console.error("There is no get property in objects.json file for type ", className);
      }

      HttpRequester.post(g["url"], {id: this.id})
      .then(res => {
          this.loadFromDbObject(res);
          cb && cb(null);
        }, err => {
          console.error(err);
          cb && cb(err);
        }
      );
      return this;
    }


    public loadFromDbObject(obj: any, className?: any, fieldPrefix?: string) {
      loadFromDBObject.call(this, obj, className, fieldPrefix);
    }

    static setProperty(n: string, prop: IDBLoaderProperty, obj, fieldPrefix?: string) {
      fieldPrefix = fieldPrefix ? fieldPrefix + "_" : "";
      var type;
      if (! this.hasOwnProperty(n)) {
        //console.error("There is no ", n, " property on class ", this.className);
      }

      var f = fieldPrefix + prop.field;
      if (! prop || ! prop.type) {
        console.error("No type field on property ", prop);
      }
      if (! obj.hasOwnProperty(f)
        && prop.type.match(/((number)|(string)|(boolean)|(date))/)
        && ! (prop.optional && JSON.parse(prop.optional))) {
        console.warn("There is no ", f, " property on object ", obj);
      }

      type = prop.type;
      if (type.match(/((^.*\[\]$)|(number)|(string)|(boolean)|(date))/) &&
        (! obj.hasOwnProperty(f) || obj[f] == null)) {
        this[n] = obj[f];
        return;
      }
      if (type == "number") {
        this[n] = +obj[f];
      } else if (type == "string") {
        this[n] = obj[f];
      } else if (type == "boolean") {
        this[n] = !! +obj[f];
      } else if (type == "date") {
        this[n] = new Date(obj[f]);
      } else if (type.match(/^.*\[\]$/)) {
        if (Array.isArray(this[n])) {
          this[n].length = 0;
        } else {
          this[n] = [];
        }
        type = type.substr(0, type.length - 2);
        for (var i in obj[f]) {
          this[n].push(DBLoader.instantiateType(type, obj[f][i]));
          loadFromDBObject.call(this[n][this[n].length - 1], obj[f][i], this[n][this[n].length - 1].getClassName && this[n][this[n].length - 1].getClassName() || type);
        }
      } else {
        if (! this[n]) {
          this[n] = DBLoader.instantiateType(type, obj, f);
        }
        loadFromDBObject.call(this[n], obj, this[n].getClassName && this[n].getClassName() || type, f);
      }
    }

    public static instantiateType(type: string, obj: any, fieldPrefix?: string) {
      var t = DBLoader.objects[type];
      fieldPrefix = fieldPrefix ? fieldPrefix + "_" : "";
      if (t && t["classes"]) {
        if (! t["classDeterminerField"]) {
          console.error("No classDeterminerField on type ", type);
          return;
        }
        if (! obj.hasOwnProperty(fieldPrefix + t["classDeterminerField"])) {
          console.error("No classDeterminerField: ", fieldPrefix + t["classDeterminerField"], " value on object ", obj);
          return;
        }
        if (! t["classes"][obj[fieldPrefix + t["classDeterminerField"]]]) {
          console.error("No class specified for classDeterminerField value ", obj[t["classDeterminerField"]], " of ", t);
          return;
        }
        if (! App[t["classes"][obj[fieldPrefix + t["classDeterminerField"]]]]) {
          console.error("Class ", t["classes"][obj[t["classDeterminerField"]]], " doesn't exist");
          return;
        }
        return new App[t["classes"][obj[fieldPrefix + t["classDeterminerField"]]]]();
      }
      return new (App[type] || Object)();
    }

    static checkAndloadObjects = () => {
      if (DBLoader.objects) {
        return;
      }
      $.ajax({
        url: 'objects.json',
        async: false,
        dataType: 'json',
        success: function(response) {
          DBLoader.objects = response;
        }
      })
      .fail(function() {
          //console.error("Failed to load objects.json file");
      });
    }

    constructor() {
    }
  }

  function loadFromDBObject(obj: any, className?: any, fieldPrefix?: string) {
    className = className || (<DBLoader>this).getClassName();
    fieldPrefix = fieldPrefix || "";

    DBLoader.checkAndloadObjects();

    if (! DBLoader.objects[className]) {
      //console.error("There is no ", className, " object in objects.json file.");
      return;
    }

    var co = DBLoader.objects[className];

    for (var i in co.properties) {
      DBLoader.setProperty.call(this, i, co.properties[i], obj, fieldPrefix);
    }

    if (co.extends) {
      loadFromDBObject.call(this, obj, co.extends, fieldPrefix);
    }
  }
}
