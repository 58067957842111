module App {
  export class CustomerListController extends BaseController {
    public changeToView () {
      this.viewMode = ViewMode.View;
    }

    constructor(private $timeout: ng.ITimeoutService,
                private $mdDialog: any,
                private authenticationService: Authentication,
                public eventManagerService: EventManagerService,
                public globals: any){
      super();
    }

    $onInit(){
      this.eventManagerService.subscribe(this.globals.events.refreshCustomerList, this.refreshList);
    }

    public refreshList = (): void => {
      this.mainGrid.dataSource.read();
      this.mainGrid.refresh();
    };

    public mainGrid: kendo.ui.Grid;
    public customerId: number;
    public mainGridOptions = {
      dataSource: {
          transport: {
              read: {
                  url: "/request/get_customers",
                  dataType: "json",
                  type: "POST"
              }
          },
          schema: {
              model: {
                  fields: {
                      registration_date: {type: "date"}
                  }
              }
          },
          pageSize: 20,
      },
      sortable: true,
      pageable: true,
      resizable: true,
      selectable: "row",
      filterable: true,
      columnMenu: true,
      reorderable: true,
      editable: false,
      change: (e: kendo.ui.GridChangeEvent) => {
          var grid = e.sender;
          var data = grid.dataItem(grid.select());
          this.customerId = data["id"];
      },
      dblClick: () => {
          this.$timeout(() => {
              this.setViewMode();
          })
      },
      columns: [
          {
              field: "name",
              title: "NAME"
          },
          {
              field: "registration_date",
              title: "REGISTRATION_DATE",
              type: "date",
              format: "{0:yyyy-MM-dd}"
          }
      ]
    }

    showDialog($event) {
      var options: any;
      this.$mdDialog.show({
        template: '<customer-edit options="options"></customer-edit>',
        parent: angular.element(document.body),
        targetEvent: $event || false,
        locals: {options: options},
        controller: ['$scope', 'options', ($scope, options) => $scope.options = options]
      });
    }
    public controlVisibility(controlName: string): boolean {
      return this.authenticationService.user.controlPermissions.indexOf(controlName) != -1;
    }
  }

  export class CustomerListComponent implements ng.IComponentOptions {
    public static id = "customerList";
    templateUrl = "/components/customer-components/customer-list-component/customer-list.component.html";
    controllerAs = "vm";
    bindings: any;
    constructor(){
      this.bindings = {
          viewMode: "=",
          customerId: "=?"
      };
    }
    controller = ['$timeout', '$mdDialog', 'authenticationService', 'eventManagerService', 'globals',
      CustomerListController
    ];
  }

  angular.module(Module)
    .component(CustomerListComponent.id, new CustomerListComponent());
}
