module App {
    'use strict';

    export class BaseController  {
        static id = "baseController";

        /*@ngInject*/
        constructor() {
        }

        public viewMode: ViewMode;

        public get isListMode(): boolean {
            return this.viewMode == ViewMode.List;
        }

        public get isViewMode(): boolean {
            return this.viewMode == ViewMode.View;
        }

        public get isCreateMode(): boolean {
            return this.viewMode == ViewMode.Create;
        }

        public get isEditMode(): boolean {
            return this.viewMode == ViewMode.Edit;
        }

        public setListMode = (): void => {
            this.viewMode = ViewMode.List;
        };

        public setViewMode = (): void => {
            this.viewMode = ViewMode.View;
        };

        public setCreateMode = (): void => {
            this.viewMode = ViewMode.Create;
        };

        public setEditMode = (): void => {
            this.viewMode = ViewMode.Edit;
        };
    }
    angular.module(Module)
        .controller(BaseController.id, BaseController);
}