module App {

	angular.module(Module)
		.config([
		"$stateProvider",
		"$urlRouterProvider",
		(
			$stateProvider: angular.ui.IStateProvider,
			$urlRouterProvider: angular.ui.IUrlRouterProvider) => {
			'use strict';
			var defaultControllerAs = "vm";

        // $stateProvider.state("login", {
        //     url: "/login",
        //     templateUrl: "areas/login/login.html",
        //     controller: LoginController.id,
        //     controllerAs: defaultControllerAs,
        //     resolve: {
        //       user: ['authenticationService', function (authenticationService: Authentication) {
        //         return authenticationService.checkLogin();
        //       }]
        //     }
        // });

        $stateProvider.state("home", {
            url: "/home",
            templateUrl: "areas/home/home.html",
            controller: HomeController.id,
            controllerAs: defaultControllerAs,
			resolve: {
				user: ['authenticationService', function (authenticationService: Authentication) {
					return authenticationService.checkLogin();
				}]
			}
        });

        $stateProvider.state("layout", {
            url: "/",
            abstract: true,
            templateUrl: "areas/layout/layout.html",
            controller: LayoutController.id,
            controllerAs: defaultControllerAs,
            resolve: {
              user: ['authenticationService', function (authenticationService: Authentication) {
                return authenticationService.checkLogin();
              }]
            }
        });

        $stateProvider.state("layout.dashboard", {
            url: "dashboard",
            templateUrl: "areas/dashboard/dashboard.html",
            //controller: DashboardController.id,
            controllerAs: defaultControllerAs
        });

        $stateProvider.state("layout.customer", {
            url: "customer",
            templateUrl: "areas/customer/customer.html",
            controller: CustomerController.id,
            controllerAs: defaultControllerAs
        });

        $stateProvider.state("layout.mass_media", {
            url: "mass_media",
            templateUrl: "areas/mass-media/mass-media.html",
            controller: MassMediaController.id,
            controllerAs: defaultControllerAs
        });

        $stateProvider.state("layout.newsFeed", {
            url: "news-feed",
            templateUrl: "areas/news-feed/news-feed.html",
            controller: NewsFeedController.id,
            controllerAs: defaultControllerAs
        });

        $stateProvider.state("layout.social_media", {
            url: "social_media",
            templateUrl: "areas/social-media/social-media.html",
            controller: SocialMediaController.id,
            controllerAs: defaultControllerAs
        });
        
        $stateProvider.state("layout.social_media_account", {
            url: "social_media_account",
            templateUrl: "areas/social-media-accounts/social-media-accounts.html",
            controller: SocialMediaAccountsController.id,
            controllerAs: defaultControllerAs
        });
        
		$stateProvider.state("layout.analysis", {
            url: "analysis",
            templateUrl: "areas/analysis/analysis.html",
            controller: AnalysisController.id,
            controllerAs: defaultControllerAs
        });

        $stateProvider.state("layout.social_media_analysis", {
            url: "social_media_analysis",
            templateUrl: "areas/social-media-analysis/social-media-analysis.html",
            controller: SocialMediaAnalysisController.id,
            controllerAs: defaultControllerAs
        });

		$stateProvider.state("layout.press_event", {
            url: "press_event",
            templateUrl: "areas/press-event/press-event.html",
            controller: PressEventController.id,
            controllerAs: defaultControllerAs
        });

        $stateProvider.state("layout.project", {
            url: "project",
            templateUrl: "areas/project/project.html",
            controller: ProjectController.id,
            controllerAs: defaultControllerAs
        });

        $stateProvider.state("layout.reports", {
            url: "reports",
            templateUrl: "areas/reports/reports.html",
            controller: ReportsController.id,
            controllerAs: defaultControllerAs
        });

        $stateProvider.state("layout.reportView", {
            url: "report-view/:reportId",
            params: {
                "reportDescription": null
            },
            templateUrl: "areas/report-view/report-view.html",
            controller: ReportViewController.id,
            controllerAs: defaultControllerAs
        });

        $stateProvider.state("layout.catalog", {
             url: "catalog",
             templateUrl: "areas/catalog/catalog.html",
             controller: CatalogController.id,
             controllerAs: defaultControllerAs
        });

        $stateProvider.state("layout.users", {
            url: "users",
            templateUrl: "areas/users/users.html",
            controller: UsersController.id,
            controllerAs: defaultControllerAs
        });

        $stateProvider.state("layout.permissionGroups", {
            url: "permission-groups",
            templateUrl: "areas/permission-groups/permission-groups.html",
            controller: PermissionGroupsController.id,
            controllerAs: defaultControllerAs
        });

        $stateProvider.state("error", {
            url: "/error",
            templateUrl: "areas/error/error.html",
            controller: ErrorController.id,
            controllerAs: defaultControllerAs
        });

        $urlRouterProvider.otherwise("/home");

        }]);

}
