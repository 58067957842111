///<reference path='db-loader.ts' />
module App {

  export class User extends DBLoader {
    get id(): number { return this._id } set id(pId: number) { this._id = pId }
    private firstName: string;
    private lastName: string;
    private login: string;
    private password: string;
    private group: IDictionary;
    private status: IDictionary;
    public role: UserRoleEnum;
    public customerId: number;
    public sectionPermissions: Array<any>;
    public controlPermissions: Array<any>;
    public email: string;
    public feedRefreshInterval: number;


    public reset() {
      this.id = null;
      this.firstName = "";
      this.lastName = "";
      this.login = "";
      this.password = null;
      this.group = null;
      this.status = null;
      this.role = null;
      this.customerId = null;
      this.sectionPermissions = [];
      this.controlPermissions = [];
      this.email = "";
      this.feedRefreshInterval = 0;
    }

    public init(u: any) {
      this.id = u.id;
      this.firstName = u.firstName;
      this.lastName = u.lastName;
      this.login = u.login;
      this.role = u.role;
      this.customerId = u.customerId;
      this.sectionPermissions = u.sectionPermissions && u.sectionPermissions.slice() || [];
      this.controlPermissions = u.controlPermissions && u.controlPermissions.slice() || [];
      this.group = <IDictionary>{id: u.groupId};
      this.email = u.email;
      this.feedRefreshInterval = u.feedRefreshInterval;
    }

    public isAuthorized() {
      return this.id != null;
    }

    constructor(private _id?: number) {
      super();
      if (_id){
        this._id = _id;
      } else {
        this.reset();
      }
    }
  }
}

