module App {

    export class Utilities {
        static id = "utilitiesService";

        static base64toBlob(base64Data, contentType, sliceSize?) {
            contentType = contentType || '';
            sliceSize = sliceSize || 512;

            var byteCharacters = atob(base64Data);
            var byteArrays = [];

            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);

                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                var byteArray = new Uint8Array(byteNumbers);

                byteArrays.push(byteArray);
            }

            var blob = new Blob(byteArrays, {type: contentType});
            return blob;
        }

        static getFiltersDescription(filters: any, options: any) {
            var filtersDescriptions: string = "";
            var label: string;
            var value: string = "";
            var arr: Array<any> = [];
            var objIndex: number;

            for (var key in filters) {
                value = "";
                label = options.fields[key].label

                if (filters[key] != null) {
                    if (Array.isArray(filters[key])) {
                        var s = options.fields[key].source;
                        value = this.getDescriptionsFromArrayByIds(filters[key], s);
                    } else if (key.indexOf("date") != -1) {
                        var d = filters[key];
                        value = d.getDate().toString() + "/" + (d.getMonth() + 1).toString() + "/" + d.getFullYear().toString();
                    } else if (typeof filters[key] === 'object') {
                        value = filters[key].description;
                    } else {
                        value = filters[key].toString();
                    }
                }

                objIndex = this.getObjectIndexInArrayByProperty(label, "description", arr)
                if(objIndex == -1) {
                    var obj: any;
                    if (options.fields[key].label != "") {
                        obj = {description: label, firstValue: value};
                    } else {
                        obj = {description: label, firstValue: "", secondValue: value};
                    }
                    if (options.fields[key].range) {
                        obj.range = true;
                    }
                    arr.push(obj);
                } else {
                    if (options.fields[key].label != "") {
                        arr[objIndex].firstValue = value;
                    } else {
                        arr[objIndex].secondValue = value;
                    }
                }
            }

            for (var i: number = 0; i < arr.length; ++i) {
                if (arr[i].firstValue && arr[i].secondValue) {
                    filtersDescriptions += arr[i].description + ": " + (arr[i].range ? "(>=)" : "") + arr[i].firstValue + " - " + (arr[i].range ? "(<=)" : "") + arr[i].secondValue + ", ";
                } else if (arr[i].firstValue) {
                    filtersDescriptions += arr[i].description + ": " + (arr[i].range ? "(>=)" : "") + arr[i].firstValue + ", ";
                } else if (arr[i].secondValue) {
                    filtersDescriptions += arr[i].description + ": " + (arr[i].range ? "(<=)" : "") +  arr[i].secondValue + ", ";
                }
            }

            return filtersDescriptions.substr(0, filtersDescriptions.length - 2);
        }

        static getDescriptionsFromArrayByIds(ids: number[], source: Object[]): string {
            var descriptions: string = "";

            for (var i: number = 0; i < ids.length; ++i) {
                var result = $.grep(source, function(e){ return e["id"] == ids[i]; });
                descriptions += result[0]["description"] + (i == ids.length - 1 ? "" : ", ");
            }

            return descriptions;
        }

        static getObjectIndexInArrayByProperty(value: any, property: string, arr: Object[]): number {
            var index: number = -1;

            for (var i = 0; i < arr.length; ++i) {
                if (arr[i][property] == value) {
                    return i;
                }
            }

            return index;
        }

        static changeCreatedExcel(workbook, filtersDescription: string, reportDescription?: string) {
            var sheet = workbook.sheets[0];
            var alphabet: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

            sheet.freezePane.rowSplit = 0;
            for (var i: number = 0; i < 3; ++i) {
                sheet.rows.unshift({});
            }
            sheet.mergedCells = ["A1:" + alphabet.substr(sheet.columns.length - 1, 1) + "1", "A2:" + alphabet.substr(sheet.columns.length - 1, 1) + "2"];

            sheet.rows[0].cells = [{value: reportDescription, hAlign: "center"}];
            sheet.rows[1].cells = [{value: filtersDescription, hAlign: "center"}];
            sheet.columns[0].autoWidth = false;
            sheet.columns[0].width = 200;
        }

        static 	generateHtmlFromSelectedArticles = (selectedArticles: Array<IFeedCardElement>, filter: ng.IFilterService): string => {
			var html: string = `
                <style>
                    @media print { body { -webkit-print-color-adjust: exact; } }
                    .item{display:flex; }
                    img {width:30px; height:30px, margin-top: 15px; margin-right:10px}
                    p {margin: 0;}
                    .title{margin-right:10px; margin-bottom: 10px}
                    .fb_button{color:#fff;  border-radius: 50%; border:1px solid #47598D ; font-weight: bold; cursor:pointer; background-color:#47598D}
                    .fb_link{margin-left:15px; }
                </style>`;
			var arr: Array<IFeedCardElement> = angular.copy(selectedArticles);
			arr.sort(function(a, b) {
				var nameA = a.selected_article.mass_media.name.toUpperCase(); // ignore upper and lowercase
				var nameB = b.selected_article.mass_media.name.toUpperCase(); // ignore upper and lowercase
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}
				return 0;
			});
			var index: number = 0;
			for (var i: number = 0; i < arr.length; ++i) {
                index++;
                html += `<div class="item">`
                html += `<img src="imgs/massMediaLogos/svg/${arr[i].selected_article.mass_media.name}.svg"/>`;
                html += `<h4>${index}. ${arr[i].selected_article.mass_media.name} - ${filter("date")(arr[i].selected_article.publish_datetime, "dd/MM/yyyy HH:mm")}</h4>`;
                html += `</div>`
                for (var j: number = 0; j < arr[i].articles.length; ++ j) {
					var article = arr[i].articles[j];
                    html += `<a href="${article.url}" class="title">${article.title}</a>`;
					html += article.fb_link ?  `  |  <a  ng-if="article.fb_link" href="${article.fb_link}" class="fb_link"><button class="fb_button">f</button></a><br> ` : `<br>`;
					html = `<div class="title">` + html + `</div>`
                }
               
			}
			return html;
        }

        static 	generateHtmlFromSelectedPosts = (selectedPosts: Array<ISocialMediaElement>, filter: ng.IFilterService): string => {
			var html: string = `
                <style>
                	@media print { body { -webkit-print-color-adjust: exact; } }
                    .content{margin-right:10px; margin-bottom: 10px;  text-decoration: none;}
                </style>`;
            var arr: Array<any> = angular.copy(selectedPosts);
			var index: number = 0;
			for (var i: number = 0; i < arr.length; ++i) {
                index++;
                html += `<h4>${index}.${arr[i].social_media_account_type_id == 1 ? 'Պրոֆիլ ' : 'Էջ '} ${arr[i].social_media_account_name} - ${filter("date")(arr[i].publish_datetime, "dd/MM/yyyy HH:mm")}</h4>`;
                html += `<a href="${arr[i].url}" class="content">${arr[i].content.length > 50 ?  filter("limitTo")(arr[i].content, 50) + '...' : arr[i].content  }</a>`;
			}
			return html;
        }
        
        static getStartDateByProject = (id:number) => {
            return HttpRequester.post("/request/get_project_start_date", {id: id})
                .then((response: any) => {
                    return response;
                });
        }

        /*@ngInject*/
        constructor(
            private $http: ng.IHttpService) {
            HttpRequester.http = $http;
        }
    };

    angular.module(App.Module)
        .service(Utilities.id, Utilities);
}


