module App {
    export class SocialMediaComponentController  {

        private requestParameter: IRequestParameterModel = new RequestParameterModel();
        public selectedPosts: Array<ISocialMediaElement> = [];
        public postList: Array<ISocialMediaElement>;
        public filter: IPostFilter;
        public searchPattern: string;
        private totalCount: number;
        public loading: boolean = false;
        
        constructor(private $mdDialog: any,
            private eventManagerService: EventManagerService,
            private socialMediaService: SocialMediaService,
            private $anchorScroll: ng.IAnchorScrollService,
            private globals: any) {
                        
        }

        $onInit(){  
            this.eventManagerService.subscribe(this.globals.events.getPostList, this.getPostList);
            this.eventManagerService.subscribe(this.globals.events.selectedPostsEdit, this.selectedPostsEdit);
            this.eventManagerService.subscribe(this.globals.events.postComparison, this.postComparison);
            this.eventManagerService.subscribe(this.globals.events.unSelectAllSocialMediaPosts, this.unSelectAllSocialMediaPosts);
            this.requestParameter.count_per_page = +window.localStorage["countPerPage"] || this.globals.defaultValues.countPerPage;
            this.getPostList(1)
            if (!this.requestParameter.filter.start_date) {
                this.requestParameter.filter.start_date = new Date();
                this.requestParameter.filter.start_date.setHours(0,0,0,0);
                this.requestParameter.filter.start_date.setDate(this.requestParameter.filter.start_date.getDate() - 5);
            }
        }

        public selectedPostsEdit = (id: number, operation?: SelectionOperationEnum): void => {
            let idx: number = _.findIndex(this.selectedPosts, (item: ISocialMediaElement) => {
                return item.id == id;
            });
            if (!operation) {
                if (idx == -1) {
                    this.selectedPosts.push(_.find(this.postList, (item) => {
                        return item.id == id
                    }));
                } else {
                    this.selectedPosts = _.filter(this.selectedPosts, (item) => {
                        return item.id != id
                    });
                }
            } else {
                if (operation == SelectionOperationEnum.Add) {
                    if (idx == -1) {
                        this.selectedPosts.push(_.find(this.postList, (item) => {
                            return item.id == id
                        }));
                    }
                } else {
                    this.selectedPosts = _.filter(this.selectedPosts, (item) => {
                        return item.id != id
                    });
                }
            }
            this.postComparison()
        };

        public postComparison = (): void => {
            for (let i = 0; i < this.postList.length; i++) {
                this.postList[i].selected = (_.findIndex(this.selectedPosts, (item) => {
                    return item.id == this.postList[i].id
                }) != -1);
            }
            let res  = this.postList.filter(item1 => this.selectedPosts.some(item2 => item1.id === item2.id)).length;
            this.eventManagerService.publish(this.globals.events.allPostCheckUnSelect, res);
        };

        public unSelectAllSocialMediaPosts = (): void => {
            this.selectedPosts.length = 0;
        };

        public getPostList = (currentPage?: number): void => {
            this.requestParameter.filter = angular.copy(this.filter);
            this.requestParameter.filter.subject = this.requestParameter.filter.subject && <any>this.requestParameter.filter.subject.join();
            this.requestParameter.filter.social_media_accounts = this.requestParameter.filter.social_media_accounts && <any>this.requestParameter.filter.social_media_accounts.join();
            this.requestParameter.current_page = currentPage;
            this.loading = true;
            this.socialMediaService.getPostList(this.requestParameter).then((response: any) => {
                if (response) {
                    this.totalCount = response.total_count;
                    this.postList = response.elements;
                    this.postComparison();
                    this.eventManagerService.publish(this.globals.events.setPaginationCurrentPage, currentPage || 1);
                    this.searchPattern = this.requestParameter.filter.pattern;
                }
            }).finally(() => {
                this.loading = false;
                this.$anchorScroll();
            });
        };

    }

    export class SocialMediaComponent implements ng.IComponentOptions {
        public static id = "socialMedia";
        templateUrl = "/components/social-media-components/social-media/social-media.component.html";
        controllerAs = "vm";
        bindings: any;
        constructor() {
            this.bindings = {
                filter: "<?"
            };
        }
        controller = ['$mdDialog', 'eventManagerService', 'socialMediaService', '$anchorScroll', 'globals', 
            SocialMediaComponentController
        ];
    }
    angular.module(Module)
        .component(SocialMediaComponent.id, new SocialMediaComponent());
}
