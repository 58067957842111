module App
{
    export class SprootMultiSelectDirective implements ng.IDirective {
        public static id = "sprootMultiSelect";
        public template = `
            <md-input-container class="md-block" ng-class="{'md-input-invalid': !ngModelValue && form[fieldName].$touched}">
              <label ng-class="{'md-required': required}">{{label | translate}}</label>
              <select kendo-multi-select="multiSelect"
                      k-ng-model="value"
                      ng-model="ngModelValue"
                      k-options="options"
                      k-on-change="onChange()" 
                      name="{{fieldName}}"
                      ng-required="form && required"
                      aria-label="..."></select>
              <div class="md-errors-spacer"></div>
              <div ng-messages="form[fieldName].$error">
                <div ng-message="required" ng-class="{'multi-select-require': !ngModelValue && form[fieldName].$touched}">{{'THIS_IS_REQUIRED' | translate}}</div>
              </div>
            </md-input-container>`;
        public restrict = 'AE';
        public require = ['^?form'];
        public replace: boolean = true;
        public scope = {
            value: '=ngModel',
            url: "<?",
            source: "<?",
            label: "<?",
            onChange: "&?",
            valueField: "<?",
            textField: "<?",
            valuePrimitive: "<?",
            optionLabel: "<?",
            filterable: "<?",
            name: "<?",
            required: "<?",
            clearButton: "<?",
            filter: "<?"
        }

        public link = ($scope: any, element: JQuery, attributes: ng.IAttributes, ctrl: ng.IControllerService[]) => {
            $scope.watcher = $scope.$watch("value", (newValue, oldValue) => {
                if(newValue !== undefined) {
                    if (!newValue || newValue.length == 0) {
                        element.removeClass('md-input-has-value');
                        $scope.ngModelValue = null;
                    } else {
                        $scope.ngModelValue = newValue;
                        element.addClass('md-input-has-value');
                    }
                    element.find("input").val("");
                    //$scope.watcher();
                }
            });

			setTimeout(() => {
			    var input: any = element.find("input");
				input.on('focusin', () => {
					if (!$scope.ngModelValue && !element.hasClass('md-input-has-value')) {
						element.addClass('md-input-has-value');
					}
				});
				input.on('focusout', () => {
					if (!$scope.ngModelValue && element.hasClass('md-input-has-value')) {
						element.removeClass('md-input-has-value');
					}
				});
            }, 1000);

            $scope.$watch("filter", (newValue, oldValue) => {
                if(newValue != oldValue) {
                    let data = angular.isUndefined($scope.filter) ? {} : {id: $scope.filter};
                    $scope.multiSelect.dataSource.read(data).then(() => {
                        var view = $scope.multiSelect.dataSource.view();
                        $scope.value = _.filter($scope.value, (item: number) => {
                            var allIds: number[] = _.map(view, (obj: any) => {
                                return obj.id;
                            });
                            return allIds.indexOf(+item) != -1
                        })
                    });
                }
            });
            $scope["form"] = ctrl && ctrl[0];
            $scope["fieldName"] = $scope["name"] || $scope["label"];
            $scope["options"] = {
                animation: {
                    close: {
                        effects: "fadeOut zoom:out",
                        duration: 300
                    },
                    open: {
                        effects: "fadeIn zoom:in",
                        duration: 300
                    }
                },
                autoClose: false,
                valuePrimitive: angular.isUndefined($scope["valuePrimitive"]) ? true : $scope["primitive"],
                clearButton: angular.isUndefined($scope["valuePrimitive"]) ? false : $scope["primitive"],
                optionLabel: $scope["optionLabel"] ? " " : false,
                filter: angular.isUndefined($scope[$scope["filterable"]]) ? "contains" : $scope["filterable"],
                dataValueField: $scope["valueField"] || "id",
                dataTextField: $scope["textField"] || "description",
                dataSource: $scope["source"] || {
                    transport: {
                        read: {
                            dataType: "json",
                            type: "POST",
                            url: $scope["url"],
                            data: angular.isUndefined($scope.filter) ? {} : {id: $scope.filter}
                        }
                    }                    
                }
            };            
        }
    }

    angular.module(Module)
        .directive(SprootMultiSelectDirective.id, () => new SprootMultiSelectDirective());
}
