module App {
    export class SocialMediaSelectedElementsComponentController  {
        
        public FeedElementActions = FeedElementActionsEnum;
        public NewsFeedType = NewsFeedTypeEnum;
        private dataSource: Array<any> = [];
        public mainGrid: kendo.ui.Grid;
        private groupId: number;
        public options: any;
        private analyzeList: Array<IAnalyzeDetail> = [];
        public AttitudeGroups = AttitudeEnum;

        constructor(private $mdDialog: any,
            private eventManagerService: EventManagerService,
            private socialMediaService: SocialMediaService,
            private newsFeedService: INewsFeedService,
            private globals: any) {
        }
        $onInit(){
            this.options.selectedElements.forEach((item) => {
                this.dataSource.push({  id: item.id, 
                                        social_media_account_type_id: item.social_media_account_type_id == 1 ? 'Պրոֆիլ': 'Էջ', 
                                        social_media_account_name: item.social_media_account_name,
                                        content: item.content.length > 30 ? item.content.slice(0, 30) + '...' :  item.content, 
                                        publish_datetime: item.publish_datetime,
                                        subjects: item.subjects
                                    })
            });
            if(this.options.action == FeedElementActionsEnum.AnalyzeElement){
                this.newsFeedService.getAnalyzeDetailsBySelectedArticle(this.dataSource, this.NewsFeedType.SocialMedia).then((response: Array<IAnalyzeDetail>) => {
                    if (response) {
                        this.analyzeList = response;
                    }
                });
            } 
        }

        public mainGridOptions = {
            dataSource: new kendo.data.DataSource({
                data: this.dataSource,
                schema: {
                    model: {
                        fields: {
                            social_media_account_type_id:{ type: "text" },
                            social_media_account_name: { type: "text" },
                            content: { type: "text" },
                            publish_datetime: { editable: false, type: "date" },
                            subjects: {  type: "text" }
                        }
                    }
                }
            }),
            sortable: false,
            pageable: false,
            resizable: false,
            selectable: "row",
            filterable: false,
            columnMenu: false,
            reorderable: false,
            columns: [
                {
                    field: "social_media_account_type_id",
                    title: "SOCIAL_MEDIA_ACCOUNT_TYPE",
                    width: 100
                },
                {
                    field: "social_media_account_name",
                    title: "SOCIAL_MEDIA_ACCOUNT_NAME",
                    width: 200
                },
                {
                    field: "content",
                    title: "CONTENT",
                    width: 300
                },
                {
                    field: "publish_datetime",
                    title: "PUBLISH_DATE",
                    type: "date",
                    format: "{0:yyyy-MM-dd}",
                    width: 100
                },
                {
                    field: "subjects",
                    title: "SUBJECTS",
                    width: 300
                },
                {
                    command: [
                        {
                          name: "Delete",
                          template: "<span class='icon icon-remove' ng-click='vm.removeElement($event)'></span>"
                        }
                    ],
                    attributes: {
                        "class": "text-center",
                    },
                    width: 40
                }
            ]
        };

        public get selectedIds(): string {
            return  this.options.selectedElements.map(({ id }) => {return id }).join()
        };

        public removeElement = ($event): void => {
            let sender = $event.currentTarget;
            let row = angular.element(sender).closest("tr");
            let dataItem = this.mainGrid.dataItem(row);
            this.mainGrid.dataSource.remove(dataItem);
            this.mainGrid.dataSource.sync();
            this.options.selectedElements = _.filter(this.options.selectedElements, (i) => { return i["id"] !== dataItem["id"]; });
            this.dataSource = _.filter( this.dataSource, (i) => { return i["id"] !== dataItem["id"]; });
        };

        public execute = (): void => {
            this.socialMediaService.removePosts(this.options.selectedElements, this.options.action, this.groupId).then((response) => {
                if (response) {
                    this.closeWindow();
                    this.eventManagerService.publish(this.globals.events.getPostsList,1);
                }
            });
        };

        private closeWindow = (): void =>{
            this.$mdDialog.hide();
        }


    }

    export class SocialMediaSelectedElementsComponent implements ng.IComponentOptions {
        public static id = "socialMediaSelectedElements";
        templateUrl = "/components/social-media-components/social-media-selected-elements/social-media-selected-elements.component.html";
        controllerAs = "vm";
        bindings: any;
        constructor() {
            this.bindings = {
                options: "="
            };
        }
        controller = ['$mdDialog', 'eventManagerService', 'socialMediaService', 'newsFeedService', 'globals',
            SocialMediaSelectedElementsComponentController
        ];
    }
    angular.module(Module)
        .component(SocialMediaSelectedElementsComponent.id, new SocialMediaSelectedElementsComponent());
}
