///<reference path='db-loader.ts' />
module App {

    export interface INewsFeedResponse {
        total_count: number;
        feed_elements: IFeedCardElement[];
    }

    export interface IFeedCardElement {
        id: number;
        articles: Array<Article>;
        selected_article: Article;
        press_event: PressEvent;
        total_views_count: number;
        total_likes: number;
        total_comments: number;
        total_shares: number;
        read_state: boolean;
        rated: number;
        selected: boolean;
        logo: string;
    }

    export interface IAnalyzeDetail {
        element_id?: number;
        subject_id: number;
        subject_name: string;
        attitude: number;
        attitude_group: number;
        reference_type: number;
        category: number;
        sphere: number;
        tags: number[];
        post_id?:number;
    }

    export class NewsFeedResponse extends DBLoader implements INewsFeedResponse {
        constructor() {
            super();
            this.total_count = 0;
            this.feed_elements =[];
        }
        public total_count: number;
        public feed_elements: FeedCardElement[];
    }


    export class FeedCardElement extends DBLoader implements IFeedCardElement {
        constructor() {
            super();
            this.articles = [];
            this.selected= false;
        }
        public id: number;
        public articles: Article[];
        public selected_article: Article;
        public press_event: PressEvent;
        public total_views_count: number;
        public total_likes: number;
        public total_comments: number;
        public total_shares: number;
        public read_state: boolean;
        public rated: number;
        public selected: boolean;
        public logo: string;
    }


    export class AnalyzeDetail implements IAnalyzeDetail{
        constructor() {
            this.tags = [];
        }
        public element_id: number;
        public subject_id: number;
        public subject_name: string;
        public attitude: number;
        public attitude_group: number;
        public reference_type: number;
        public category: number;
        public sphere: number;
        public press_event: number;
        public tags: number[];
        public post_id: number;
    }


}
