module App
{
  class DialogBoxController {
    public options;

    public tittle;
    public item_options;
    public item;
    public acceptBtn;
    public cancelBtn;

    public cancel = () => {
      this.$mdDialog.hide();
    }

    /*@ngInject*/
    constructor(public  $mdDialog: any) {
      document.getElementsByTagName("md-dialog")[0].className += (' ' + this.options.size + '-dialog') || '';
      this.options.title = this.options.title || "";
      this.options.item_options = this.options.item_options || "";
      this.options.item = this.options.item || "";
      this.options.acceptBtn = this.options.acceptBtn || false;
      this.options.cancelBtn = this.options.cancelBtn || false;
    }
  }

  export class DialogBoxDirective implements ng.IDirective {
    public static id = "dialogBox";
    public restrict = 'AE';
    public templateUrl = './directives/dialog-box/dialog-box.html';
    public replace: boolean = true;
    public scope = {
      options: '='
    };
    public controller = DialogBoxController;
    public controllerAs: string = "vm";
    public bindToController: boolean = true;

    public link($scope: ng.IScope, element: JQuery, attributes: ng.IAttributes) {
    }
  }

  angular.module(Module)
    .directive(DialogBoxDirective.id, () => new DialogBoxDirective());
}

