module App {
    export enum MassMediaTypeEnum {
        online = 1,
        tv = 2,
        press = 3
    }

    export enum MassMediaRatingEnum {
        Top1 = 1,
        Top2,
        Top3 ,
        Top4
    }
}