module App {
  'use strict';

  export class CatalogController {
    static id = "catalogController";

    public catalog: any ={};
    public mainGrid: kendo.ui.Grid;
    private selected_catalog: {};

    public mainGridOptions = {
      toolbar: [
        {
          name: "ADD",
          template: '<span flex></span><md-button ng-if="app.controlVisibility(\'edit_item_in_catalog\')" class="md-icon-button custom-cell-button"  ng-click="vm.addDescription($event)"><md-tooltip><span data-translate="ADD"></span></md-tooltip><md-icon md-svg-icon="content:add_circle" aria-label="..." style="color:white"></md-icon></md-button>'
        }
      ],
      dataSource: {
        transport: {
          read: {
            url: "",
            dataType: "json",
            type: "POST"
          },
        },
        schema: {
          model: {
            fields: {
              id: { type: "number" },
              description: { validation: { required: true }, type: "string" },
              parent_id: { type: "number" }
            }
          }
        },
        pageSize: 20
      },
      sortable: true,
      pageable: true,
      resizable: true,
      reorderable: true,
      columns: [
        {
          field: "description",
          title: "DESCRIPTION",
          width: "120px"
        },
        {
          command: [
            {
              name: "edit",
              template: '<md-button ng-if="app.controlVisibility(\'edit_item_in_catalog\')" class="md-icon-button custom-cell-button" ng-click="vm.editDescription($event)" aria-label="Edit"><md-tooltip><span data-translate="EDIT"></span></md-tooltip><md-icon md-svg-icon="editor:mode_edit" aria-label="..."></md-icon></md-button>',
            }
          ],
          width: "10px"
        }
      ]
    };

    private mainDialogOptions(event: Event, item?: any) {
      var dialog_options = {
        title: "EDIT_CATALOG",
        size: "small",
        buttons: {
          acceptBtn: {
            text: "SAVE",
            btnClass: 'btn-primary',
            disableIfInvalid: true,
            fnc: () => {
              this.$http.post("/request/save_catalog", {id: dialog_options.item.id, description: dialog_options.item.description, parentId: dialog_options.item.parentObject && dialog_options.item.parentObject.id, catalogId: this.selected_catalog["id"]}).success(() => {
                this.mainGrid.dataSource.read();
                this.mainGrid.refresh();
                this.$mdDialog.hide();
                Notifier.show("CHANGES_SAVED", MessageType.success)
              }).error((err) => {
                if(err) {
                  Notifier.show(err.message, MessageType.error)
                }
              })
            }
          },
          cancelBtn: {
            text: "CANCEL",
            btnClass: 'btn-default',
            fnc: () => {
              this.$mdDialog.hide();
            }
          }
        },
        item: item,
        item_options: {
          fields: {
            id: {type: "number", label: "ID", readonly: true},
            description: {type: "text", label: "DESCRIPTION", required: () => true},
            parentObject: {type: "combo", label: "PARENT", source_url: this.selected_catalog["parent_url"], isNotVisible: (item) => !this.selected_catalog["parent_url"], get: (item) => item, required: () => true, description: "name"}
          },
          rows: [
            [{field: "description", col_class: "col-md-12"}],
            [{field: "parentObject", col_class: "col-md-6"}],
          ]
        }
      };

      this.$mdDialog.show({
        template: '<dialog-box options="options"></dialog-box>',
        parent: angular.element(document.body),
        targetEvent: event || false,
        locals: {options: dialog_options},
        controller: ['$scope', 'options', ($scope, options) => $scope.options = options]
      }).then(() => {
          this.mainGrid.dataSource.read();
          this.mainGrid.refresh();
      });
    }
    public addDescription($event) {
      if (!this.selected_catalog){
        Notifier.show("CHOOSE_CATALOG_TYPE", MessageType.info)
      } else {
        this.mainDialogOptions($event,{});
      }
    }

    public editDescription($event) {
      var sender = $event.currentTarget;
      var row = angular.element(sender).closest("tr");
      var dataItem = this.mainGrid.dataItem(row);

      angular.extend(dataItem, {parentObject: {id: dataItem["parent_id"], description: ""}});

      this.mainDialogOptions($event, angular.copy(dataItem));
    }

    private init(){
      this.$http.post("/request/get_catalog", '').success((res) => {
        this.catalog = res;
      }).error((err) => {
        if(err) {
          console.log(err.message);
        }
      })
    }

    public viewCatalog(selected_catalog: any) {
      this.selected_catalog = selected_catalog;
      if (selected_catalog.url) {
        this.mainGridOptions.dataSource.transport.read["url"] = selected_catalog.url;
        this.mainGrid.dataSource.read();
        this.mainGrid.refresh();
      }
    }

    /*@ngInject*/
    constructor(
      public $http: ng.IHttpService,
      public $mdDialog: any)
    {
      this.init();
    }
  }

  angular.module(Module)
    .controller(CatalogController.id, CatalogController);

}
