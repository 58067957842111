module App {
  export class MassMediaViewController extends BaseController {
    public massMediaId: number;
    public changeToList () {
      this.viewMode = ViewMode.List;
    }
    constructor(private $mdDialog: any){
      super();

    }

    $onInit(){
        this.LoadMassMedia(this.massMediaId);
    }

    public LoadMassMedia(id: number) {
      this.massMedia = new MassMedia(id);
      this.massMedia.loadFromDB((err) => {
        if (err) {
          console.error("Cannot get mass media ", err);
        }
      });
    }

    public massMedia: MassMedia = new MassMedia();

    public header = {
      title: "MASS_MEDIA",
      icon: "notification:live_tv",
      buttons: {
        btn_edit: {
          tooltip: "EDIT_MASS_MEDIA",
          permission_name: "edit_mass_media",
          icon: "editor:mode_edit",
          fnc: ($event) => {
            var options: any;

            this.$mdDialog.show({
              template: '<mass-media-edit options="options" mass-media-id="massMediaId"></mass-media-edit>',
              parent: angular.element(document.body),
              targetEvent: $event || false,
              //clickOutsideToClose: options.clickOutsideToClose || true,
              locals: {options: options},
              controller: ['$scope', 'options', ($scope, options) => {
                $scope.options = options;
                $scope.massMediaId = this.massMediaId} ]
            }).then(() => {
              this.massMedia.loadFromDB();
            });

          },
        },
      }
    };

    public mass_media_form_options = {
      fields: {
        name: {
          type: "text",
          readonly: "true",
          label: "NAME",
          get: (item) => item
        },
        type: {
          type: "text",
          readonly: "true",
          label: "TYPE",
          get: (item) => item.type,
          property_name: "description"
        },
        rating: {
          type: "text",
          readonly: "true",
          label: "RATING",
          get: (item) => item.rating,
          property_name: "description"
        },
        main_url: {
          type: "text",
          readonly: "true",
          label: "MAIN_URL",
          get: (item) => item,
          isNotVisible: (item) => (item.type && item.type.id != MassMediaTypeEnum.online) || !item.type
        },
        country: {
          type: "text",
          readonly: "true",
          label: "COUNTRY",
          get: (item) => item.country,
          property_name: "description"
        },
        fb_id: {
          type: "number",
          label: "FB_ID",
          readonly: "true",
          get: (item) => item
        },
        date_format: {
          type: "text",
          label:"DATE_FORMAT",
          readonly: "true",
          get: (item) => item,
          property_name: "description"
        }
      },
      rows: [
        [{field: "name", col_class: "col-md-6"}, {field: "main_url", col_class: "col-md-6"}],
        [{field: "type", col_class: "col-md-6"},{field: "rating", col_class: "col-md-6"}],
        [{field: "country", col_class: "col-md-6"}, {field: "fb_id", col_class: "col-md-6"}],
        [{field: "date_format", col_class: "col-md-6"}]
      ]
    };
  }

  export class MassMediaViewComponent implements ng.IComponentOptions {
    public static id = "massMediaView";
    templateUrl = "/components/mass-media-components/mass-media-view-component/mass-media-view.component.html";
    controllerAs = "vm";
    bindings: any;
    constructor(){
      this.bindings = {
          viewMode: "=",
          massMediaId: "<"
      };
    }
    controller = [
      '$mdDialog',
      MassMediaViewController
    ];
  }

  angular.module(Module)
    .component(MassMediaViewComponent.id, new MassMediaViewComponent());

}
