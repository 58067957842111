module App{
    export enum LanguageEnum {
        am = 1,
        ru = 2,
        en = 3
    }

    export enum FeedElementActionsEnum {
        ArticleMerge = 1,
        PressEventJoin,
        RemoveElementFromProject,
        SendMailToPartner,
        AnalyzeElement,
        RemoveElementFromSubjects
    }

    export enum AttitudeEnum {
        Positive = 1,
        Neutral,
        Negative
    }

    export enum SelectionOperationEnum {
      Add = 1,
      Remove
    }

    export enum GroupSelectionEnum {
      All = 1,
      None,
      Read,
      Unread
    }
}
