module App
{
    class UserMailingSubjectsComponentController extends BaseController {

        public customerId: number;
        public grid: kendo.ui.Grid;
		public gridOptions: any;
		public postDataObj = {customer_id: this.customerId};

        constructor(
        	private customerService: ICustomerService,
			private authenticationService: Authentication,
        	private $mdDialog: any) {
            super();

        }

        $onInit = () => {
			this.initGridOptions();
        }

		private initGridOptions = () => {
			this.gridOptions = {
				dataSource: {
					transport: {
						read: {
							url: "/request/get_customer_users",
							dataType: "json",
							type: "POST",
                            data: {customer_id: this.customerId}
						}
					},
					pageSize: 20,
				},
				sortable: true,
				pageable: true,
				resizable: true,
				filterable: true,
				columnMenu: true,
				columns: [
					{ field: "name", title: "NAME" }
				]
			}
		}

		public getMailingSubjectsGridOptions = (userId: number) => {
			return {
				dataSource: {
					transport: {
						read: {
							url: "/request/get_mailing_subjects_by_user",
							dataType: "json",
							type: "POST",
							data: {id: userId}
						}
					},
					pageSize: 10,
				},
				sortable: true,
				pageable: true,
				resizable: true,
				filterable: true,
				columnMenu: true,
				columns: [
					{ field: "name", title: "NAME"},
					{ template: `<md-button ng-if="vm.controlVisibility('remove_mailing_subject')" class="md-icon-button custom-cell-button" ng-click="vm.removeSubject(dataItem.id, dataItem.user_id)" aria-label="Remove"><md-tooltip><span data-translate="DELETE"></span></md-tooltip><md-icon md-svg-icon="action:delete" aria-label="..."></md-icon></md-button>`, width: 75}
				]
			}
		}

		public saveSubjectForMailing(user_id: number, $event) {
			this.$mdDialog.show({
				template: $('#mailingSubjectPopupTemplate').html(),
				parent: angular.element(document.body),
				targetEvent: $event || false,
				locals: {options: {user_id: user_id}},
				controller: ['$scope', 'options', ($scope, options) => {
					$scope.options = options;
					$scope.postDataObj = {customer_id: this.customerId};
					$scope.saveSubjectForMailing = () => {
						this.customerService.saveSubjectForMailing(options).then((b: any) => {
							if (!(b instanceof Error)) {
								var grid: kendo.ui.Grid = $("#subjectsForMailingGrid" + user_id).data("kendoGrid");
								grid.dataSource.read();
								grid.refresh();
								this.$mdDialog.hide();
							}
						})
					}
					$scope.cancel = () => {
						this.$mdDialog.hide();
					}

				}]
			})
		}

		public removeSubject = (id: number, user_id: number) => {
			if(confirm("Հեռացնե՞լ թեման։")) {
				this.customerService.removeSubjectForMailing(id).then((b: any) => {
					if (!(b instanceof Error)) {
						var grid: kendo.ui.Grid = $("#subjectsForMailingGrid" + user_id).data("kendoGrid");
						grid.dataSource.read();
						grid.refresh();
					}
				});
			}

		}

		public controlVisibility = (controlName: string): boolean => {
			return this.authenticationService.user.controlPermissions.indexOf(controlName) != -1;
		}

    }

    export class UserMailingSubjectsComponent implements ng.IComponentOptions {
        public static id = "userMailingSubjects";
        templateUrl = "/components/user-mailing-subjects/user-mailing-subjects.component.html";
        controllerAs = "vm";
        bindings: any;
        constructor(){
            this.bindings = {
                customerId: "<",
            };
        }
        controller = [
        	'customerService',
			'authenticationService',
        	'$mdDialog',
			UserMailingSubjectsComponentController
        ];
    }

    angular.module(Module)
        .component(UserMailingSubjectsComponent.id, new UserMailingSubjectsComponent());
}
