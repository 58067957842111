module App {
    export class SocialMediaHeaderComponentController {
		
		public FeedElementActions = FeedElementActionsEnum;
		public GroupSelection = GroupSelectionEnum;
		public selectedPosts: Array<ISocialMediaElement>;
		public postList: ISocialMediaElement[];
		public isChecked: boolean = false;
        public countPerPage: number;
		public totalCount: number;
		public currentPage: number;

        public pageOptions = {
			dataSource: this.globals.dataSources.countPerPageDataSource,
			dataTextField: "value",
			dataValueField: "id",
			valueTemplate: '<span class="val-txt">{{dataItem.value}}</span>',
			template: '<span class="mm-k-dropdown-val-txt">{{dataItem.value}}</span>'
        };
        
		constructor(private $mdDialog: any,
			private authenticationService: Authentication,
			private eventManagerService: EventManagerService,
			private socialMediaService: SocialMediaService,
			private globals: any,
			private $filter: ng.IFilterService) {
        }

        $onInit() {
            this.eventManagerService.subscribe(this.globals.events.allPostCheckUnSelect, this.allPostCheckUnSelect);
			this.countPerPage = +window.localStorage["countPerPage"] || this.countPerPage;
		}

		public allPostCheckUnSelect = (res: number): void => {
			if(this.totalCount){
				if(this.totalCount < this.countPerPage){
					this.isChecked = (this.totalCount - res) == 0 ? true: false;
				}else{
					if(this.currentPage <=  (this.totalCount / this.countPerPage) ){
						this.isChecked = (res <  this.countPerPage) ? false : true;
					}else{
						this.isChecked = (res ==  (this.totalCount % this.countPerPage)) ? true : false;
					}
				
				}
			}
								
		}
        
		public changeState = (state: boolean) => {
			if (this.selectedPosts.length == 0){
				Notifier.show("Նախ ընտրեք որևէ հոդված։", MessageType.warning);
			} else{
				this.socialMediaService.changeReadState(this.selectedPosts, state).then((res: boolean) => {
					if (res) {
						this.selectedPosts.forEach((post) => {
							post.read_state = state;
						})
					}
				});
			}
        };
    
        public checkAll = (isChecked: boolean) => {
            isChecked ?  this.groupSelection(GroupSelectionEnum.All): this.groupSelection(GroupSelectionEnum.None)
		}
		
		public changeCountPerPage = (): void =>{
			window.localStorage["countPerPage"] = this.countPerPage;
		};
        
        public groupSelection = (selectionGroup: GroupSelectionEnum) => {
			this.postList.forEach((item) => {
				if(selectionGroup == GroupSelectionEnum.All) {
					this.eventManagerService.publish(this.globals.events.selectedPostsEdit, item.id, SelectionOperationEnum.Add);
					this.isChecked = true;
				} else if(selectionGroup == GroupSelectionEnum.None){
					this.eventManagerService.publish(this.globals.events.selectedPostsEdit, item.id, SelectionOperationEnum.Remove);
					this.isChecked = false;
				} else if(selectionGroup == GroupSelectionEnum.Read) {
					if(item.read_state) {
						this.eventManagerService.publish(this.globals.events.selectedPostsEdit, item.id, SelectionOperationEnum.Add);
					} else {
						this.eventManagerService.publish(this.globals.events.selectedPostsEdit, item.id, SelectionOperationEnum.Remove);
					}
				} else if(selectionGroup == GroupSelectionEnum.Unread) {
					if(!item.read_state) {
						this.eventManagerService.publish(this.globals.events.selectedPostsEdit, item.id, SelectionOperationEnum.Add);
					} else {
						this.eventManagerService.publish(this.globals.events.selectedPostsEdit, item.id, SelectionOperationEnum.Remove);
					}
				}
			});
			this.eventManagerService.publish(this.globals.events.postComparison);
        };
        


        public addPost = ($event): void => {
            this.$mdDialog.show({
                template: '<social-media-add></social-media-add>',
                parent: angular.element(document.body),
                targetEvent: $event || false,
                controller: ['$scope', ($scope) => { }]
            })
        };

        public generatePdf = () => {
			if (this.selectedPosts.length > 0) {
				var win = window.open();
				var doc = win.document.open();
				doc.write(Utilities.generateHtmlFromSelectedPosts(this.selectedPosts, this.$filter));
				doc.close();
				win.print();
			} else {
				Notifier.show("Նախ ընտրեք որևէ հոդված։", MessageType.warning);
			}
        }
        
        public showDialog = ($event, action): void => {
			if (this.selectedPosts.length == 0){
				Notifier.show("Նախ ընտրեք որևէ հոդված։", MessageType.warning);
			} else if( action == FeedElementActionsEnum.RemoveElementFromSubjects){
				this.socialMediaService.getSubjectsBySelectedPosts(this.selectedPosts).then((res: Dictionary[]) => {
					if (res.length == 0) {
						Notifier.show("Ընտրված հոդված(ներ)ը պետք է ունենան ընդհանաուր թեմա։", MessageType.warning);	
						return
					}else{
						this.showDialogContent($event, action);
					}
				});
			}else {
                this.showDialogContent($event, action);
            } 
		};

		public showDialogContent($event, action): void{
			let options: any = {
				action: action,
				selectedElements: this.selectedPosts
			};
			this.$mdDialog.show({
				template: '<social-media-selected-elements options="options"></social-media-selected-elements>',
				parent: angular.element(document.body),
				targetEvent: $event || false,
				locals: {options: options},
				controller: ['$scope', 'options', ($scope, options) => {
					$scope.options = options; }
				]
			})
		}


    }

    export class SocialMediaHeaderComponent implements ng.IComponentOptions {
        public static id = "socialMediaHeader";
        templateUrl = "/components/social-media-components/social-media-header/social-media-header.component.html";
        controllerAs = "vm";
        bindings: any;
        constructor() {
            this.bindings = {
				selectedPosts: "<",
				countPerPage: "=",
				totalCount: "<",
				postList: "<",
				currentPage: "<?"
			};
        }
        controller = ['$mdDialog', 'authenticationService', 'eventManagerService', 'socialMediaService', 'globals',  '$filter',
            SocialMediaHeaderComponentController
        ];
    }
    angular.module(Module)
        .component(SocialMediaHeaderComponent.id, new SocialMediaHeaderComponent());
}
