module App {
    'use strict';

    export class PressEventController extends BaseController {
        static id = "pressEventController";
        public filter: INewsFilter;
        public filter_data:any;
        public changeToView() {
            this.viewMode = ViewMode.View;
        }

        /*@ngInject*/
        constructor(private $filter: ng.IFilterService,
                    private authenticationService: Authentication,
                    private $mdDialog: any,
                    public globals: any,
                    private $http: ng.IHttpService,
                    public eventManagerService: EventManagerService
        ) {
            super();
            if (!this.viewMode) {
                this.setListMode();
            }
        }
        public getFilteredData = () => {
            this.eventManagerService.publish(this.globals.events.refreshPressEventList, this.filter)
        };

        public showDialog = ($event): void => {
            var options: any;
            this.$mdDialog.show({
                template: '<press-event-edit options="options"></press-event-edit>',
                parent: angular.element(document.body),
                targetEvent: $event || false,
                locals: {options: options},
                controller: ['$scope', 'options', ($scope, options) => $scope.options = options]
            });
        };

        public clearFilters = () =>{
            this.filter = {};
            this.getFilteredData()
        }
    

        public controlVisibility = (controlName: string): boolean => {
            return this.authenticationService.user.controlPermissions.indexOf(controlName) != -1;
        }
    }

    angular.module(Module)
        .controller(PressEventController.id, PressEventController);
}
