module App
{
  export class MultiSelectDirective implements ng.IDirective {
    public static id = "multiSelect";
    public restrict = 'AE';
    public scope = {
      mOptions: '='
    }

    public link($scope: ng.IScope, element: JQuery, attributes: ng.IAttributes) {
      $.ajax({
        type: "POST",
        url: $scope["mOptions"].source_url,
        success: (res) => {
          if ($scope["mOptions"].description) {
            for(var i in res) {
              //if(res[i].hasOwnProperty($scope["mOptions"].description)) {
                res[i].description = res[i][$scope["mOptions"].description];
                delete res[i][$scope["mOptions"].description]
              //}
            }
          }

          $scope["mOptions"].source = res;
        }
      }).fail(function(err) {
        Notifier.show(err.message, MessageType.error);
      });
    }
  }

  angular.module(Module)
    .directive(MultiSelectDirective.id, () => new MultiSelectDirective());
}
