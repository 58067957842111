module App {
    'use strict';

    interface ISection {
        id: number
        name: string
        icon: string
        url: string
        visible?: boolean
    }

    export class LayoutController {
        static id = "layoutController";
        public user: User;

        get appTitle() {
            return this.config.name;
        }

        get appVersion() {
            return this.config.version;
        }

        public sections: ISection[] = [
            //{id: 100, name: "DASHBOARD", icon: "glyphicon glyphicon-home", url: "dashboard"},
            { id: 110, name: "NEWS_FEED", icon: "icon icon-mm-newsfeed", url: "news_feed" },
            { id: 120, name: "ANALYSIS", icon: "icon icon-mm-analysys", url: "analysis" },
			{ id: 280, name: "SOCIAL_MEDIA", icon: "icon icon-facebook", url: "social_media" },            
            { id: 300, name: "SOCIAL_MEDIA_ANALYSIS", icon: "icon icon-mm-facebook", url: "social_media_analysis" },
            { id: 230, name: "PROJECTS", icon: "icon icon-folder-open", url: "project" },
            { id: 220, name: "CUSTOMERS", icon: "icon icon-clent-meetup", url: "customer" },
            { id: 250, name: "MASS_MEDIA", icon: "icon icon-mm-mass-media", url: "mass_media" },
            { id: 270, name: "PRESS_EVENT", icon: "icon icon-mm-press-event", url: "press_event" },
            { id: 290, name: "SOCIAL_MEDIA_ACCOUNT", icon: "icon icon-user", url: "social_media_account" },
            { id: 260, name: "REPORTS", icon: "icon icon-mm-reports", url: "reports" },
            { id: 240, name: "CATALOG", icon: "icon icon-info-circle", url: "catalog" },
            { id: 200, name: "PERMISSION_GROUPS", icon: "icon icon-locked", url: "permission-groups" },
            { id: 210, name: "USERS", icon: "icon icon-user", url: "users" }
        ];

        public logOut() {
            this.authenticationService.logoutUser((err: Error) => {
                if (!this.authenticationService.isAuthorized()) {
                    this.$state.go("home");
                    clearInterval(this.globals.events.feedUpdateInterval);
                } else {
                    err && alert(err.message);
                }
            });
        }

        public changeUserPassword($event) {
            var options = {
                title: "CHANGE_PASSWORD",
                event: $event,
                buttons: {
                    acceptBtn: {
                        text: "CHANGE",
                        disableIfInvalid: true,
                        fnc: () => {
                            this.$http.post('request/change_user_password', options.item).success(() => {
                                Notifier.show("CHANGES_SAVED", MessageType.success);
                                this.$mdDialog.cancel();
                            }).error((err) => {
                                Notifier.show(err.message, MessageType.error);
                            })
                        }
                    },
                    cancelBtn: {
                        text: "CANCEL",
                        fnc: () => {
                            this.$mdDialog.cancel();
                        }
                    }
                },
                item: {},
                item_options: {
                    fields: {
                        currentPassword: { type: "password", label: "CURRENT_PASSWORD", get: (item) => item, required: () => true },
                        newPassword: { type: "password", label: "NEW_PASSWORD", get: (item) => item, required: () => true },
                        newPasswordConfirm: { type: "password", label: "CONFIRM_NEW_PASSWORD", get: (item) => item, required: () => true }
                    },
                    rows: [
                        [{ field: "currentPassword", col_class: "col-md-9" }],
                        [{ field: "newPassword", col_class: "col-md-9" }],
                        [{ field: "newPasswordConfirm", col_class: "col-md-9" }]
                    ]
                },
                size: "small"
            };


            this.$mdDialog.show({
                template: '<dialog-box options="options"></dialog-box>',
                parent: angular.element(document.body),
                targetEvent: options.event || false,
                locals: { options: options },
                controller: ['$scope', 'options', ($scope, options) => $scope.options = options]
            })
        }

        private setSectionsVisibility() {
            var section_permissions = this.authenticationService.user.sectionPermissions;
            for (var k in this.sections) {
                this.sections[k].visible = section_permissions.indexOf(this.sections[k].id) != -1;
            }
        }

        /*@ngInject*/
        constructor(
            private config: Config,
            private authenticationService: Authentication,
            private $state: ng.ui.IStateService,
            private $mdDialog: any,
            private $http: ng.IHttpService,
            private globals: any,
            public configurationService: ConfigurationService
        ) {
            this.user = this.authenticationService.user;
            this.setSectionsVisibility();
        }
    }

    angular.module(Module)
        .controller(LayoutController.id, LayoutController);

}
