module App {
    'use strict';

    export class MassMediaEditController extends BaseController {
        static id = "MassMediaEditController";
        public options: any;
        public massMediaId: number;

        constructor(
            private $timeout: ng.ITimeoutService,
            public $mdDialog: any,
            public eventManagerService: EventManagerService,
            public globals: any){
            super();
        }

        $onInit() {
        var m: IMassMedia;
        if (this.massMediaId) {
            m = new MassMedia(this.massMediaId).loadFromDB();
        } else {
            m = new MassMedia();
        }

        this.options = {
            title: this.massMediaId ? "EDIT_MASS_MEDIA" : "NEW_MASS_MEDIA",
            size: "small",
            buttons: {
                acceptBtn: {
                    text: "SAVE",
                    btnClass: "btn-primary",
                    disableIfInvalid: true,
                    fnc: () => {
                        this.options.item.language && (this.options.item.language = this.options.item.language.join());
                        this.options.item.saveToDB((err, res?) => {
                            if (!err) {
                                this.$mdDialog.hide();
                                if (!this.massMediaId) {
                                    this.eventManagerService.publish(this.globals.events.refreshMassMediaList);
                                }
                            }
                        });
                    }
                },
                cancelBtn: {
                    text: "CANCEL",
                    btnClass: "btn-default",
                    fnc:() => {
                        this.$mdDialog.cancel()
                    }
                }
            },
            item: m,
            item_options: {
                fields: {
                    name: {type: "text", label: "NAME", get: (item) => item, required: () => true},
                    type: {type: "combo", label: "TYPE", get: (item) => item, source_url: "/request/get_mass_media_types", required: () => true},
                    rating: {type: "combo", label: "RATING", get: (item) => item, source_url: "/request/get_mass_media_ratings", required: () => true},
                    main_url: {type: "text", label: "MAIN_URL", get: (item) => item, isNotVisible: (item) => (item.type && item.type.id != MassMediaTypeEnum.online) || !item.type, required: () => true},
                    logo: {type: "text", label: "LOGO", get: (item) => item},
                    country: {type: "combo", label: "COUNTRY", get: (item) => item, source_url: "/request/get_countries", required: () => true},
                    fb_id: {type: "text", label: "FB_ID", get: (item) => item, isNotVisible: (item) => (item.type && item.type.id != MassMediaTypeEnum.online) || !item.type},
                    language: { type: "multiselect", label: "LANGUAGE", get: (item) => item, source_url: "/request/get_languages", required: () => true},
                    date_format: {type:"combo", label:"DATE_FORMAT", get: (item) => item, source_url: "/request/get_date_formats",  required:() => true},
                    updatesAvailable: {type: "checkbox", label: "UPDATES_AVAILABLE"}

                },
                rows: [
                    [{field: "name", col_class: "col-md-6"}, {field: "country", col_class: "col-md-6"}],
                    [{field: "type", col_class: "col-md-6"}, {field: "rating", col_class: "col-md-6"}],
                    [{field: "main_url", col_class: "col-md-6"}, {field: "fb_id", col_class: "col-md-6"}],
                    this.massMediaId ? null :[{field: "language", col_class: "col-md-12"}],
                    [{field:"date_format", col_class:"col-md-8"}],
                    [{field:"updatesAvailable", col_class:"col-md-6"}]
                ]
            }
            }
        }
    }

    export class MassMediaEditComponent implements ng.IComponentOptions {
        static id = "massMediaEdit";
        templateUrl = "/components/mass-media-components/mass-media-edit-component/mass-media-edit.component.html";
        controllerAs = "vm";
        bindings: any;
        constructor(){
            this.bindings = {
                massMediaId: "=?",
                options: "="
            };
        }
        controller = ['$timeout', '$mdDialog', 'eventManagerService', 'globals',
            MassMediaEditController
        ];
    }

    angular.module(Module)
        .component(MassMediaEditComponent.id,  new MassMediaEditComponent());

}
