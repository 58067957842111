module App {
  'use strict';

  export class MassMediaController extends BaseController {
    static id = "massMediaController";
    public b:any;
    /*@ngInject*/
    constructor(private $filter: ng.IFilterService) {
      super();
      if(!this.viewMode) {
        this.setListMode();
      }
    }
  }

  angular.module(Module)
    .controller(MassMediaController.id, MassMediaController);
}
