module App {

	var app = angular.module(Module);

	export class Config {
		static id = "config";
		constructor() {

		}

		name = "Monitoring";
		version = "1.0.0-alpha";
		events = {
			myEvent: "example",
			uiRouter: {
				$stateChangeError: "$stateChangeError",
				$stateChangeStart: "$stateChangeStart",
				$stateChangeSuccess: "$stateChangeSuccess",
				$stateNotFound: "$stateNotFound"
			}
		}
		remoteUri = new RemoteUriConfig();
	}

	export class RemoteUriConfig {
		base = "/api/";
		account = {
			login: `${this.base}/login`
		}
	}

	app.constant(Config.id, new Config());

	app.config((
		$logProvider: ng.ILogProvider,
		$locationProvider: ng.ILocationProvider
		) => {

		// turn debugging off/on (no info or warn)
		if ($logProvider.debugEnabled) {
			$logProvider.debugEnabled(true);
		}

	});

    app.config(
        ($translateProvider: ng.translate.ITranslateProvider) => {
            $translateProvider.useStaticFilesLoader({
                prefix: 'translations/locale-',
                suffix: '.json'
            });
            $translateProvider.preferredLanguage('en');
    });

	app.config(function($mdIconProvider) {
	$mdIconProvider
	  .iconSet('navigation', 'svg/navigation-icons.svg', 24)
		.iconSet('notification', 'svg/notification-icons.svg', 24)
	  .iconSet('communication', 'svg/communication-icons.svg', 24)
	  .iconSet('social', 'svg/social-icons.svg', 24)
	  .iconSet('action', 'svg/action-icons.svg', 24)
	  .iconSet('editor', 'svg/editor-icons.svg', 24)
	  .iconSet('content', 'svg/content-icons.svg', 24)
	  .iconSet('hardware', 'svg/hardware-icons.svg', 24)
	  .iconSet('map', 'svg/maps-icons.svg', 24)
	  .iconSet('av', 'svg/av-icons.svg', 24)
	  .iconSet('file', 'svg/file-icons.svg', 24)
	  .iconSet('image', 'svg/image-icons.svg', 24)
	  .iconSet('custom', 'svg/custom-icons.svg', 24)
	});

	app.config(['growlProvider', function(growlProvider) {
	growlProvider.onlyUniqueMessages(false);
	growlProvider.globalTimeToLive({success: 3000, error: 10000, warning: 3000, info: 3000});
	growlProvider.globalDisableCountDown(true);
	}]);

    app.constant('globals', {
        events: {
      		getNewsFeedList: 'getNewsFeedList',
			selectedElementsEdit: 'selectedElementsEdit',
			elementComparison: 'elementComparison',
			pageChangeEvent: 'pageChangeEvent',
			generateAnalysisChart: 'generateAnalysisChart',
			removeChartFromList: 'removeChartFromList',
			refreshCustomerList: 'refreshCustomerList',
			refreshMassMediaList: 'refreshMassMediaList',
			refreshPressEventList: 'refreshPressEventList',
			setPaginationCurrentPage: 'setPaginationCurrentPage',
			unSelectAllElements: 'unSelectAllElements',
			allCheckUnSelect: 'allCheckUnSelect',
			feedUpdateInterval:'feedUpdateInterval',
			getPostList: 'getPostList',
			selectedPostsEdit: 'selectedPostsEdit',
			postComparison:'postComparison',
			allPostCheckUnSelect: 'allPostCheckUnSelect',
			unSelectAllSocialMediaPosts: 'unSelectAllSocialMediaPosts'
		},
		defaultValues: {
			countPerPage: 10
		},
		dataSources: {
			countPerPageDataSource: [{id: 5, value: 5},{id: 10, value: 10},{id: 20, value: 20}, {id: 50, value: 50}]
		}
    });

}
