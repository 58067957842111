module App {
	'use strict';

	export class SocialMediaController extends BaseController {
		static id = "socialMediaController";

		public filter: IPostFilter = {};
    
		/*@ngInject*/
		constructor(private authenticationService: Authentication,
					public eventManagerService: EventManagerService,
					public globals: any) {
			super();
			if (!this.viewMode) {
				this.setListMode();
			}
		}
		
        public filterFunction = () => {
            this.eventManagerService.publish(this.globals.events.getPostList, 1);
        };

        public clearFilters = () => {
            this.filter = new PostFilter();
            this.filterFunction();
        };
	}

	angular.module(Module)
		.controller(SocialMediaController.id, SocialMediaController);
}
