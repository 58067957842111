///<reference path='db-loader.ts' />
module App {
    export interface ISubject {
        id: number;
        name: string;
        keywords: Keyword[];
    }
    export class Subject extends DBLoader implements ISubject {
        constructor() {
            super();
        }
        public id: number;
        public name: string;
        public keywords: Keyword[];
    }
}
