module App
{
    export function highlight($sce) {
        return function(text, phrase, searchPattern) {
            text = text || "";
            if (phrase) {
                text = text.replace(new RegExp("(" + phrase + ")", "gmi"),"<span class='keyword'>$1</span>");
            }

            if (searchPattern) {
                text = text.replace(new RegExp("(" + searchPattern + ")", "gmi"),"<span class='searchPattern'>$1</span>");
            }
            return $sce.trustAsHtml(text);
        }
    }
    highlight.$inject = ["$sce"];

    angular.module(Module)
        .filter("highlight", highlight);
}