///<reference path='db-loader.ts' />
module App {
    export interface IKeyword {
        id: number;
        name: string;
        since: Date;
    }
    export class Keyword extends DBLoader implements IKeyword {
        constructor() {
            super();
        }
        public id: number;
        public name: string;
        public since: Date;
    }
}
