module App
{
    export class ComboBoxDirective implements ng.IDirective {
        public static id = "kendoComboBox";
        public restrict = 'AE';
        public require = "ngModel";
        public scope = {
            kOptions: '=?',
            options: '='
        }

        public link($scope: ng.IScope, element: JQuery, attributes: ng.IAttributes, ctrls: ng.INgModelController) {
            $scope["kOptions"] = $scope["kOptions"] || [];
            angular.extend($scope["kOptions"], {
                animation: {
                    close: {
                        effects: "fadeOut zoom:out",
                        duration: 300
                    },
                    open: {
                        effects: "fadeIn zoom:in",
                        duration: 300
                    }
                },
                dataValueField: $scope["options"].id || "id",
                dataTextField: $scope["options"].description || "description",
                cascadeFrom: $scope["options"].cascade || "",
                autoBind: $scope["options"].autoBind || true ,
                filter: "contains"
            });

            if ($scope["options"].source_url) {
                angular.extend($scope["kOptions"], {
                    dataSource: {
                        transport: {
                            read: {
                                dataType: "json",
                                url: $scope["options"].source_url,
                                type: "POST",
                                data: $scope["options"].data || {}
                            }
                        },
                        requestEnd: (e) => {
                            if ($scope["options"].firstable && e.response && e.response.length) {
                                $scope["options"].kendoComboValue = e.response[0].id
                            }                            
                        }
                    }
                });
            } else {
                angular.extend($scope["kOptions"], {
					dataSource: {
						transport: {
							read: (options) => {
							    setTimeout(() => {
									return options.success($scope["options"].source);
                                })
							}
						}
					}
                });
            }
        }
    }

    angular.module(Module)
        .directive(ComboBoxDirective.id, () => new ComboBoxDirective());
}
