module App {

    var app = angular.module(Module, [
        "ngAnimate",
        "ngCookies",
        "ngSanitize",
        "ui.router",
        "ui.bootstrap",
        "ngMaterial",
        "pascalprecht.translate",
        "kendo.directives",
        "ngMessages",
        'angular-growl'
    ]);

    app.run(($rootScope: ng.IRootScopeService,
             $location: ng.ILocationService,
             config: Config,
             loggerFactory: ILoggerFactory,
             authenticationService: Authentication,
             notifierService: Notifier,
             httpRequesterService: HttpRequester,             
             $state: angular.ui.IStateService) => {

        var logger = loggerFactory("app.startup");
        logger.info("run", "app launched!");

        $rootScope.$on(config.events.uiRouter.$stateChangeError, (event: ng.IAngularEvent, toState: angular.ui.IState, toParams: any, fromState: angular.ui.IState, fromParams: any, error: Error) => {
            logger.error("$stateChangeError", "an error occured while loading state.", error);
            $state.go("error");
        });


        $rootScope.$on('$locationChangeStart', (event, next, current) => {
            // redirect to login page if not logged in and trying to access a restricted page
            var restrictedPage = $.inArray($location.path(), ['/home', '/login']) === -1;
            authenticationService.checkLogin().then(() => {
                if (restrictedPage) {
                    if (!authenticationService.isAuthorized()) {
                        $state.go("home");
                    }
                    //$location.path('/login');
                } else if (authenticationService.isAuthorized()) {
                    $state.go("layout.newsFeed");
                }
            })
        });
    });
}
