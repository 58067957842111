module App {
    export class Response<T> extends BaseResponse {
        public Result: T;

        public constructor(data: any) {
            super(data);

            var self = this;
            self.load(data);
        }

        public load(data: any): void {
            var self = this;

            if (data) {
                self.Result = data.Result;
            }
        };
    }
}