///<reference path='db-loader.ts' />
module App {

    export interface IPressEvent {
        id: number;
        name: string;
        event_date: Date;
        projects: Project[];
    }
    export class PressEvent extends DBLoader implements IPressEvent {
        constructor(id?: number) {
            super();
            this.id = id || null;
            this.name = null;
            this.event_date = null;
            this.projects = [];
        }

        public id: number;
        public name: string;
        public event_date: Date;
        public projects: Project[];
    }
}
