module App
{
  class relatedProjectsController extends BaseController {
    public mode: string;
    public projects: Project[];
    public selected_project: any;
    public parentObject: any;
    public project_form_options: any;
    private className: string;

    constructor(private authenticationService: Authentication,
                public projectService: IProjectService) {
      super();
      this.initProjectProperties();
      this.project_form_options = {
        fields: {
          project: {type: "combo", label: "PROJECT", source_url: "/request/get_projects", get: (item) => item, description: "name", required: () => true}
        },
        rows: [
          [{field: "project", col_class: "col-md-10"}]
        ],
        label_small_size: true
      }
    }

    $onInit(){
        this.className =  this.className || (<DBLoader>this.parentObject).getClassName();
    }

    private initProjectProperties() {
        this.selected_project = null;
        this.mode = "view";
    }

    public addNewProject() {
        this.selected_project = {};
        this.mode = "new";
    }

    public removeProjectFromParentObject(item: any) {
        if(confirm("Հեռացնե՞լ նախագիծը ։")) {
            this.projectService.removeProjectFromParentObject(item.id, this.className).then((response: boolean) => {
                if (response) {
                    this.parentObject.loadFromDB();
                    this.initProjectProperties();
                    Notifier.show("PROJECT_SUCCESSFULLY_REMOVED", MessageType.info)
                } else {
                    Notifier.show("ERROR_OCCURRED", MessageType.error)
                }
            });
        }
    }

    public  controlVisibility(controlName: string): boolean {
        return this.authenticationService.user.controlPermissions.indexOf(controlName) != -1;
    }

    public setProjectToParentObject(item: any) {
        this.projectService.setProjectToParentObject(item.project.id, this.parentObject.id, this.className).then((response: boolean) => {
            if (response) {
                this.parentObject.loadFromDB();
                this.initProjectProperties();
                Notifier.show("CHANGES_SAVED", MessageType.info)
            } else {
                Notifier.show("ERROR_OCCURRED", MessageType.error)
            }
        });
    }

    public cancelEditing() {
      this.initProjectProperties();
    }
  }

  export class relatedProjectsComponent implements ng.IComponentOptions {
      public static id = "relatedProjects";
      templateUrl = "/components/related-projects-components/related-projects.component.html";
      controllerAs = "vm";
      bindings: any;
      constructor(){
          this.bindings = {
              parentObject: "=",
          };
      }
      controller = ['authenticationService', 'projectService',
          relatedProjectsController
      ];
  }

  angular.module(Module)
    .component(relatedProjectsComponent.id, new relatedProjectsComponent());
}
