module App {

  export enum MessageType {
    info = 1,
    success,
    warning,
    error
  }

  export class Notifier {
    static id = "notifierService";

    static growl;
    static show: Function;

    public show(message: string, messageTypeIndex?: number) {
      if (angular.isUndefined(messageTypeIndex)) {
        messageTypeIndex = 1;
      }

      if (angular.isUndefined(message) && messageTypeIndex == 4) {
        message = "Error Occurred";
      }

      Notifier.growl[MessageType[messageTypeIndex]](message)
    }

    /*@ngInject*/
    constructor(private growl) {
      Notifier.growl = growl;
      Notifier.show = this.show;
    }
  }

  angular.module(App.Module)
    .service(Notifier.id, Notifier);
}

