module App {
    export class NewsFeedFilterComponentController extends BaseController {
        public filter: INewsFilter = new NewsFilter();
        public savedFilter: number;
        public refreshSavedFilterSource: Function;
        public rated_source: any;
        public min_date: Date;
        public rated = "";
        public countries: any;
        private getFilteredData: Function;
        private usingPage: FilterUsingPageEnum;
        public FilterUsingPageEnum = FilterUsingPageEnum;
        public NewsFeedSelectionModeEnum = NewsFeedSelectionModeEnum;

        constructor(private newsFeedService: INewsFeedService,
                    private authenticationService: Authentication,
                    private $mdDialog: any,
                    private eventManagerService: EventManagerService,
                    public configurationService: IConfigurationService) {
            super();
            this.rated_source = [{id:1, description:"չգնահատված"}, {id:2, description: "գնահատված"}];
            if(localStorage.hasOwnProperty('filter')){
                this.filter = JSON.parse(localStorage.getItem('filter'));
                setTimeout(()=> {
                    this.filter = JSON.parse(localStorage.getItem('filter'));
                    localStorage.removeItem('filter');
                }, 10);

            }
        }

        $onInit() {
            this.usingPage = this.usingPage || FilterUsingPageEnum.NewsFeed;
            this.newsFeedService.getCountriesDatasourse().then((response: any) => {
                this.countries = response;
            })
        }

        private saveFilter = (): void => {
            let options = {
                title: "SAVE",
                size: "small",
                buttons: {
                    acceptBtn: {
                        text: "SAVE",
                        btnClass: "btn-primary",
                        disableIfInvalid: true,
                        fnc: () => {
                            this.newsFeedService.saveFilter(this.filter).then((response: boolean) => {
                                if (response) {
                                    if (this.usingPage != FilterUsingPageEnum.Analysis) {
                                        this.getFilteredData();
                                    }
                                    this.eventManagerService.publish('SAVED_FILTERS');
                                    this.$mdDialog.hide();
                                }
                            }).catch((err) => {
                                Notifier.show(err.data.message, MessageType.error);
                            });
                        }
                    },
                    cancelBtn: {
                        text: "CANCEL",
                        btnClass: "btn-default",
                        fnc: () => {
                            this.$mdDialog.hide();
                        }
                    }
                },
                item: this.filter,
                item_options: {
                    fields: {
                        name: {type: "text", label: "NAME", get: (item) => item, required: () => true}
                    },
                    rows: [
                        [{field: "name", col_class: "col-md-12"}]
                    ]
                }
            };
            this.$mdDialog.show({
                template: '<dialog-box options="options"></dialog-box>',
                parent: angular.element(document.body),
                targetEvent: false,
                locals: {options: options},
                controller: ['$scope', 'options', ($scope, options) => $scope.options = options]
            });
        };

        public savedFilterChange = () => {
            if (this.savedFilter) {
                this.newsFeedService.getFilterById(this.savedFilter).then((response: INewsFilter) => {
                    this.filter = response;
                })
            } else {
                this.filter = null;
            }
        }

        public getStartDateByProject(project: Dictionary){
            Utilities.getStartDateByProject(project.id).then((response:INewsFilter) => {
                this.min_date = response.start_date;
            });
        }
    }
    export class NewsFeedFilterComponent implements ng.IComponentOptions{
        static id = "newsFeedFilter";
        templateUrl = "/components/news-feed-components/news-feed-filter/news-feed-filter.component.html";
        controllerAs = "vm";
        bindings: any;
        constructor() {
            this.bindings = {
                filter: "=",
                getFilteredData: "&?",
                clearFilters: "&",
                usingPage: "<?"
            };
        }
        controller = ['newsFeedService','authenticationService','$mdDialog', 'eventManagerService', 'configurationService',
            NewsFeedFilterComponentController
        ];
    }

    angular.module(Module)
        .component(NewsFeedFilterComponent.id, new NewsFeedFilterComponent());
}
