module App {
    export class NewsFeedComponentController {
        public feedList: Array<IFeedCardElement>;
        public filter: INewsFilter;
        private totalCount: number;
        private requestParameter: IRequestParameterModel = new RequestParameterModel();
        public selectedElements: Array<IFeedCardElement> = [];
        public loading: boolean = false;
        public searchPattern: string;

        constructor(public newsFeedService: INewsFeedService,
                    public eventManagerService: EventManagerService,
                    public globals: any,
                    private authenticationService: Authentication,
                    private $anchorScroll: ng.IAnchorScrollService) {
        }

        $onInit() {
            this.eventManagerService.subscribe(this.globals.events.getNewsFeedList, this.getNewsFeedList);
            this.eventManagerService.subscribe(this.globals.events.selectedElementsEdit, this.selectedElementsEdit);
            this.eventManagerService.subscribe(this.globals.events.unSelectAllElements, this.unSelectAllElements);
            this.eventManagerService.subscribe(this.globals.events.elementComparison, this.elementComparison);
            this.requestParameter.count_per_page = +window.localStorage["countPerPage"] || this.globals.defaultValues.countPerPage;
            this.getNewsFeedList(1);
            if(this.authenticationService.user && this.authenticationService.user.feedRefreshInterval > 0 ){
                this.globals.events.feedUpdateInterval =  setInterval(() => {
                    this.getNewsFeedList(1);
                }, 1000 * 60 * this.authenticationService.user.feedRefreshInterval);
            }
        }

        $onDestroy() {
            if(this.authenticationService.user && this.authenticationService.user.feedRefreshInterval > 0 ){
                clearInterval(this.globals.events.feedUpdateInterval);
            }
        }


        public selectedElementsEdit = (id: number, operation?: SelectionOperationEnum): void => {
            let idx: number = _.findIndex(this.selectedElements, (item: IFeedCardElement) => {
                return item.id == id;
            });

            if (!operation) {
                if (idx == -1) {
                    this.selectedElements.push(_.find(this.feedList, (item) => {
                        return item.id == id
                    }));
                } else {
                    this.selectedElements = _.filter(this.selectedElements, (item) => {
                        return item.id != id
                    });
                }
            } else {
                if (operation == SelectionOperationEnum.Add) {
                    if (idx == -1) {
                        this.selectedElements.push(_.find(this.feedList, (item) => {
                            return item.id == id
                        }));
                    }
                } else {
                    this.selectedElements = _.filter(this.selectedElements, (item) => {
                        return item.id != id
                    });
                }
            }
            this.elementComparison()
        };

        public unSelectAllElements = (): void => {
            this.selectedElements.length = 0;
        };

        public getNewsFeedList = (currentPage?: number): void => {
            this.requestParameter.filter = angular.copy(this.filter);
            if((!this.requestParameter.filter.subject || this.requestParameter.filter.subject.length != 1) && this.requestParameter.filter.attitude){
                Notifier.show("Երանգավորման ֆիլտրը կիրառելի է միայն և միայն մեկ թեմա ընտրելու դեպքում։", MessageType.warning);
                return;
            }
            if((!this.requestParameter.filter.subject || this.requestParameter.filter.subject.length != 1) && this.requestParameter.filter.reference_type){
                Notifier.show("Հիշատակման տեսակի ֆիլտրը կիրառելի է միայն և միայն մեկ թեմա ընտրելու դեպքում։", MessageType.warning);
                return;
            }
            this.requestParameter.filter.subject = this.requestParameter.filter.subject && <any>this.requestParameter.filter.subject.join();
            this.requestParameter.filter.mass_media = this.requestParameter.filter.mass_media && <any>this.requestParameter.filter.mass_media.join();
            this.requestParameter.current_page = currentPage;
            this.loading = true;
            this.newsFeedService.getNewsFeedList(this.requestParameter).then((response: INewsFeedResponse) => {
                if (response) {
                    this.totalCount = response.total_count;
                    this.feedList = response.feed_elements;
                    this.elementComparison();
                    this.eventManagerService.publish(this.globals.events.setPaginationCurrentPage, currentPage || 1);
                    this.searchPattern = this.requestParameter.filter.pattern;
                }
            }).finally(() => {
                this.loading = false;
                this.$anchorScroll();
            });
        };

        public elementComparison = (): void => {
            for (let i = 0; i < this.feedList.length; i++) {
                this.feedList[i].selected = (_.findIndex(this.selectedElements, (item) => {
                    return item.id == this.feedList[i].id
                }) != -1);
            }
            let res  = this.feedList.filter(item1 => this.selectedElements.some(item2 => item1.id === item2.id)).length;
            this.eventManagerService.publish(this.globals.events.allCheckUnSelect, res);
        };
    }

    export class NewsFeedComponent implements ng.IComponentOptions {
        public static id = "newsFeed";
        templateUrl = "/components/news-feed-components/news-feed/news-feed.component.html";
        controllerAs = "vm";
        bindings: any;

        constructor() {
            this.bindings = {
                filter: "<?"
            };
        }

        controller = ['newsFeedService', 'eventManagerService', 'globals', 'authenticationService', '$anchorScroll',
            NewsFeedComponentController
        ];
    }
    angular.module(Module)
        .component(NewsFeedComponent.id, new NewsFeedComponent());
}
