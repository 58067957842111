module App {
    export enum SocialMediaTypeEnum {
        Facebook = 1,
        Twitter = 2
    }
    export enum ResponseStatuses {
        NotSet = -3,
        Success = 0,
        PartialSuccess = -2,
        Failure = -1,
        Unknown = 1,
        UserFriendlyMessage = 2
    }
    export enum ViewMode {
      List = 1,
      View,
      Create,
      Edit
    }
    export enum FilterUsingPageEnum {
      NewsFeed = 1,
      Analysis
    }
    export enum NewsFeedSelectionModeEnum {
        Keywords = 1,
        Sections
    }
    export enum NewsFeedTypeEnum {
        MassMedia = 1,
        SocialMedia
    }
}
