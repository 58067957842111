
module App{
  'use strict';

  export class ReportsController{
    static id = "reportsController";

    public mainGridOptions = {
      viewReport: (id, description) => {
        this.$state.go("layout.reportView", { reportId: id, reportDescription: description });
      },
      dataSource: {
        transport: {
          read: {
            url: "/request/get_report_types",
            dataType: "json",
            type: "POST"
          }
        },
        pageSize: 100,
      },
      hierarchical: true,
      sortable: true,
      pageable: true,
      editable: false,
      detailInit: this.detailInit,
      columns: [{ field: "description", title: "DESCRIPTION" }]
    };

    private detailInit(e) {
      $("<div id='childGrid'></div>'").appendTo(e.detailCell).kendoGrid({
        dataSource: {
          transport: {
            read: {
              url: "/request/get_reports_by_user",
              dataType: "json",
              type: "POST",
              data:{ userId: null}
            }
          },
          schema: {
            model: {
              fields: {
                id: { type: "number" },
                description: {  type: "string" },
                report_type: {  type: "number" }
              }
            }
          },
          filter: { field: "report_type", operator: "eq", value: e.data.id }
        },
        change: (e: kendo.ui.GridChangeEvent) => {
          var grid = e.sender;
          var data = grid.dataItem(grid.select());
          this["options"]["viewReport"](data["id"], data["description"]);
        },
        selectable: "row",
        columns: [
          { field: "number", title: "NUMBER", width: "100px" },
          { field: "description", title: "DESCRIPTION" }
        ]
      });
    }

    /*@ngInject*/
    constructor(
      public $state: ng.ui.IStateService,
      public $mdDialog: any){

    }
  }

  angular.module(Module)
    .controller(ReportsController.id, ReportsController);
}
