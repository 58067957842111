
module App{
    'use strict';

    export class PermissionGroupsController{
        static id = "permissionGroupsController";
        public permissionGroups: any ={};
        public selectedGroup: any;
        public permissions: any;

        /*@ngInject*/
        constructor(private $http: ng.IHttpService, private $mdDialog: any, private authenticationService: Authentication){
            this.init();
        }

        public controlVisibility(controlName: string): boolean {
            if (this.authenticationService.user.controlPermissions.indexOf(controlName) != -1) {
                return true
            }
            return false;
        }

        private init() {
            this.$http.post('request/get_user_groups', null).success((res) => {
                this.permissionGroups = res;
            }).error((err) => {
                Notifier.show(err.message, MessageType.error)
            });
        }

        public showGroupPermissions(id: number) {
            this.$http.post('request/get_permissions_by_group/', {id: id}).success((res) => {
                for(var p in res) {
                    res[p].status = res[p].status == 0 ? false : true;
                }
                this.permissions = res;
            }).error((err) => {
                Notifier.show(err.message, MessageType.error)
            })
        }

        public savePermissions() {
            var arr: number[] = [];
            for (var i in this.permissions) {
                this.permissions[i].status && arr.push(this.permissions[i].id);
            }
            this.$http.post('request/save_user_group_permissions', {group_id: this.selectedGroup, permissions_ids: arr.join()}).success(() => {
                Notifier.show("Changes Saved", MessageType.success);
            }).error((err) => {
                Notifier.show(err.message, MessageType.error);
            })
        }

        public addPermissionGroup($event) {
            var options = {
                title: "NEW_PERMISSION_GROUP",
                event: $event,
                buttons: {
                    acceptBtn: {
                        text: "SAVE",
                        btnClass: 'btn-primary',
                        disableIfInvalid: true,
                        fnc: () => {
                            this.$http.post("/request/add_permission_group", {groupName: options.item["groupName"]}).success(() => {
                                this.init();
                                Notifier.show("Changes Saved", MessageType.success);
                                this.$mdDialog.cancel();
                            }).error((err) => {
                                Notifier.show(err.message, MessageType.error);
                            })
                        }
                    },
                    cancelBtn: {
                        text: "CANCEL",
                        btnClass: 'mm-btn-link clr-default',
                        fnc:() => {
                            this.$mdDialog.cancel();
                        }
                    }
                },
                item: {},
                item_options: {
                    fields: {
                        groupName: {
                          type: "text",
                          label: "GROUP_NAME",
                          get: (item) => item,
                          required: () => true
                        },
                        permission_group_role: {
                          type: "combo",
                          label: "PERMISSION_GROUP_ROLE",
                          get: (item) => item,
                          source_url: "/request/get_permission_group_roles",
                          description: "description",
                        }
                    },
                    rows: [
                        [{field: "groupName", col_class: "col-md-9"}],
                        [{field: "permission_group_role", col_class: "col-md-9"}]
                    ]
                },
                size: "small"
            };


            this.$mdDialog.show({
                template: '<dialog-box options="options"></dialog-box>',
                parent: angular.element(document.body),
                targetEvent: options.event || false,
                locals: {options: options},
                controller: ['$scope', 'options', ($scope, options) => $scope.options = options]
            })
        }
    }

    angular.module(Module)
        .controller(PermissionGroupsController.id, PermissionGroupsController);
}
