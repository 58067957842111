module App
{
    class massMediaSectionsController extends BaseController {
        public customerId: number;
        public grid: kendo.ui.Grid;
        public gridOptions: any;

        constructor(private authenticationService: Authentication,
                    private customerService: CustomerService,
                    public  $http: ng.IHttpService,
                    private $mdDialog: any) {
            super();
        }

        $onInit = () => {
            this.initGridOptions();
        }

        private initGridOptions = () => {
            this.gridOptions = {
                dataSource: {
                    transport: {
                        read: {
                            url: "/request/get_mass_media_sections_for_customer",
                            dataType: "json",
                            type: "POST",
                            data: {id: this.customerId}
                        }
                    },
                    pageSize: 20,
                },
                sortable: true,
                pageable: true,
                resizable: true,
                filterable: true,
                columnMenu: true,
                columns: [
                    { field: "mass_media", title: "MASS_MEDIA_LIST", width: 200},
                    { field: "sections", title: "SECTIONS" },
                    { template: `<md-button ng-if="vm.controlVisibility(\'edit_mass_media\')" class="md-icon-button custom-cell-button" ng-click="vm.saveMassMediaSections($event, dataItem.id)" aria-label="Edit"><md-tooltip><span data-translate="EDIT"></span></md-tooltip><md-icon md-svg-icon="editor:mode_edit" aria-label="..."></md-icon></md-button>
								<md-button ng-if="vm.controlVisibility('edit_mass_media')" class="md-icon-button custom-cell-button" ng-click="vm.removeMassMediaSections($event, dataItem.id)" aria-label="Remove"><md-tooltip><span data-translate="DELETE"></span></md-tooltip><md-icon md-svg-icon="action:delete" aria-label="..."></md-icon></md-button>`, width: 140}
                ]
            }
        }

        public saveMassMediaSections($event, massMediaId?: any ) {
            this.$mdDialog.show({
                template: $('#MassMediaSectionTemplate').html(),
                parent: angular.element(document.body),
                targetEvent: $event || false,
                locals: {options: {customer_id: this.customerId}},
                controller: ['$scope', 'options', ($scope, options) => {
                    $scope.options = options;
                    $scope.title = massMediaId? "EDIT" : "ADD_MASS_MEDIA_SECTION";
                    if(massMediaId){
                        $scope.disabledMassMedia = true;
                        this.customerService.getMassMediaSectionsByCustomerId(this.customerId, massMediaId)
                            .then((res) => {
                                if(res){
                                    $scope.options = res[0];
                                }else {
                                    Notifier.show("ERROR_OCCURRED", MessageType.error)
                                }
                            })
                    }
                    $scope.save = () => {
                        this.customerService.saveMassMediaSectionToCustomer(this.customerId, $scope.options.mass_media_id, $scope.options.sections.join())
                            .then((res) => {
                                if (res) {
                                    Notifier.show("CHANGES_SAVED", MessageType.success);
                                    this.refreshGrid();
                                    this.$mdDialog.cancel();
                                } else {
                                    Notifier.show("ERROR_OCCURRED", MessageType.error)
                                }
                            })
                    };
                    $scope.cancel = () => {
                        this.$mdDialog.hide();
                    }
                }]
            })
        }

        public  controlVisibility(controlName: string): boolean {
            return this.authenticationService.user.controlPermissions.indexOf(controlName) != -1;
        }

        public refreshGrid(){
            var grid: kendo.ui.Grid = $("#sectionGrid" + this.customerId).data("kendoGrid");
            grid.dataSource.read();
            grid.refresh();
        }

        public removeMassMediaSections($event, massMediaId){
            if(confirm("Հեռացնե՞լ նախագիծը ։")) {
                this.customerService.removeMassMediaSections(this.customerId, massMediaId)
                    .then((res) => {
                        if(res){
                            Notifier.show("SUCCESSFULLY_REMOVED", MessageType.info);
                            this.refreshGrid();
                        }else {
                            Notifier.show("ERROR_OCCURRED", MessageType.error)
                        }
                    })
            }
        }
    }

    export class massMediaSectionsComponent implements ng.IComponentOptions {
        public static id = "massMediaSections";
        templateUrl = "/components/mass-media-sections/mass-media-sections.component.html";
        controllerAs = "vm";
        bindings: any;
        constructor(){
            this.bindings = {
                customerId: "=",
            };
        }
        controller = [
            'authenticationService',
            'customerService',
            '$http',
            '$mdDialog',
            massMediaSectionsController
        ];
    }

    angular.module(Module)
        .component(massMediaSectionsComponent.id, new massMediaSectionsComponent());
}
