///<reference path='db-loader.ts' />
module App {

    export interface IMassMedia {
        id: number;
        name: string;
        type: Dictionary;
        rating: Dictionary;
        main_url: string;
        logo: string;
        country: Dictionary;
        fb_id: number;
        language: string;
        date_format:string;
        updatesAvailable: boolean;
    }

    export class MassMedia extends DBLoader implements IMassMedia {
        public id: number;
        public name: string;
        public type: Dictionary;
        public rating: Dictionary;
        public main_url: string;
        public logo: string;
        public country: Dictionary;
        public fb_id: number;
        public language: string;
        public date_format: string;
        public _updatesAvailable: boolean;
        get updatesAvailable(): boolean { return this._updatesAvailable } 
        set updatesAvailable(up: boolean) { this._updatesAvailable = up ? true : false }
        constructor(id?: number) {
            super();
            this.id = id || null;
            this.name = null;
            this.type = null;
            this.rating = null;
            this.main_url = null;
            this.logo = null;
            this.country = null;
            this.fb_id = null;
            this.date_format = null;
            this.language = null;            
        }
    }
}