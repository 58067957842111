module App {

    export interface  IConfigurationService{
        newsFeedSelectionMode: NewsFeedSelectionModeEnum;
    }

    export class ConfigurationService implements IConfigurationService {
        static id = "configurationService";

        /*@ngInject*/
        constructor(public eventManagerService: EventManagerService,
                    public globals: any){
            this.newsFeedSelectionMode = this.newsFeedSelectionMode || NewsFeedSelectionModeEnum.Keywords;
        }

        private _newsFeedSelectionMode: NewsFeedSelectionModeEnum;
        public get newsFeedSelectionMode(){
            return this._newsFeedSelectionMode;
        }
        public set newsFeedSelectionMode(value: number){
            this._newsFeedSelectionMode = value;
            this.eventManagerService.publish(this.globals.events.getNewsFeedList, 1)
        }
    }

    angular.module(App.Module)
        .service(ConfigurationService.id, ConfigurationService);
}
