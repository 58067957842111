module App {

    export interface IMailingService {
        sendMail(articles: Array<IFeedCardElement>, receiver: string): ng.IPromise<boolean>
    }

    export class MailingService implements IMailingService {
        static id = "mailingService";

        /*@ngInject*/
        constructor(
            private $q: ng.IQService,
            private $filter: ng.IFilterService){
        }

        public sendMail = (articles: Array<IFeedCardElement>, receiver: string): ng.IPromise<boolean> => {
            var html: string = Utilities.generateHtmlFromSelectedArticles(articles, this.$filter);
            return HttpRequester.post("/request/send_email", {html: html, subject: "Articles", receiver: receiver })
                .then((response: boolean) => {
                    return response;
                });
        };

    }

    angular.module(App.Module)
        .service(MailingService.id, MailingService);
}
