
module App {

  export class HttpRequester {
    static id = "httpRequesterService";
    static http: ng.IHttpService;

    static post = <TEntity>(url: string, data: any): ng.IPromise<TEntity | Error> =>{
      return HttpRequester.http.post(url, data)
          .then((response) => {
              return response.data;
          }).catch((err) => {
              Notifier.show(err.data.message || "ERROR_OCCURRED", MessageType.error);
              return new Error();
          });
    };

    /*static post = <TEntity>(url: string, entity: any): ng.IPromise<TEntity> => {
        return HttpRequester.http.post<Response<TEntity>>(url, entity)
            .then((response) => {
                return response.data.Result;
            });
    };*/

    static get = <TEntity>(url: string): ng.IPromise<TEntity> => {
        return HttpRequester.http.get<Response<TEntity>>(url)
            .then((response) => {
                return response.data.Result;
            })
    };

    /*
    .catch(reason => {
                throw reason;
            });
    */

/*    private showUserFriendlyMessage = <T>(response: Response<T>): void => {
        if (response.HasError && response.ResponseStatus == ResponseStatuses.UserFriendlyMessage) {
            Notifier.show("Oops...", response.Errors[0].Message, "error");
        }
    };*/

    /*@ngInject*/
    constructor(private $http: ng.IHttpService) {
      HttpRequester.http = $http;
    }
  }

  angular.module(App.Module)
    .service(HttpRequester.id, HttpRequester);
}

