module App {

    export class AnalysisFiltersController extends BaseController {

        public filter: INewsFilter;

        constructor(){
            super();
        }

        $onInit = () => {
        };

    }

    export class AnalysisFiltersComponent implements ng.IComponentOptions {
        public static id = "analysisFilters";
        templateUrl = "/components/analysis-components/analysis-filters/analysis-filters.component.html";
        controllerAs = "vm";
        bindings: any;
        constructor() {
            this.bindings = {
                filter: "<"
            };
        }
        controller = [
            AnalysisFiltersController
        ];
    }

    angular.module(Module)
        .component(AnalysisFiltersComponent.id, new AnalysisFiltersComponent());

}
