module App
{
  export class Filters {
    public static id = "filters";
    public restrict = 'A';
    public scope = {
      filters: "=",
      filterFormOptions: "=",
      showFilters: "&",
      getFilteredData: "&",
      clearFilters: "&"
    };

    public link: Function = ($scope: ng.IScope, element: JQuery, attributes: ng.IAttributes) => {
      this.$templateRequest("directives/filters/filters.html").then((html) => {
        var template = angular.element(html);
        element.append(template);
        this.$compile(template)($scope);
      });
    }

    /*@ngInject*/
    constructor(
        public $templateRequest: ng.ITemplateRequestService,
        public $compile: ng.ICompileService
    ) {
    }
  }

  angular.module(Module)
      .directive(Filters.id, ['$templateRequest', '$compile', ($templateRequest, $compile) => new Filters($templateRequest, $compile)]);
}

