module App
{
    export class SprootComboBoxDirective implements ng.IDirective {
        public static id = "sprootComboBox";
        public template = `
            <md-input-container class="md-block">
              <label>{{label | translate}}</label>
              <input kendo-combo-box
                     k-ng-model="value"
                     ng-model="ngModelValue"
                     k-options="options"
                     k-on-change="onChange()" 
                     name="{{fieldName}}"
                     ng-required="form && required"
                     aria-label="..." />
              <div ng-messages="form[fieldName].$error">
                <div ng-message="required">{{'THIS_IS_REQUIRED' | translate}}</div>
              </div>
            </md-input-container>`;
        public restrict = 'AE';
        public require = ['^?form'];
        public replace: boolean = true;
        public scope = {
            value: '=ngModel',
            url: "<?",
            source: "<?",
            label: "<?",
            onChange: "&?",
            valueField: "<?",
            textField: "<?",
            valuePrimitive: "<?",
            filterable: "<?",
            name: "<?",
            required: "<?",
            filter: "<?"
        }

        public link = ($scope: any, element: JQuery, attributes: ng.IAttributes, ctrl: ng.IControllerService[]) => {
            $scope.watcher = $scope.$watch("value", (newValue, oldValue) => {
                if(newValue != oldValue) {
                    if (!newValue) {
                        $scope.ngModelValue = null;
                    } else if(typeof newValue === "string" || typeof newValue === "number") {
                        $scope.ngModelValue = newValue;
                    } else if (typeof newValue === "object") {
                        var idField: string = $scope.valueField || "id";
                        $scope.ngModelValue = newValue[idField];
                    }
                    //$scope.watcher();
                }

            });
            $scope.$watch("filter", (newValue, oldValue) => {
                if(newValue != oldValue) {
                    $scope.dropDownList.setDataSource(new kendo.data.DataSource($scope.generateDataSource()));
                    $scope.value = null;
                }
            });
            $scope["form"] = ctrl && ctrl[0];
            $scope["fieldName"] = $scope["name"] || $scope["label"];
            $scope["options"] = {
                animation: {
                    close: {
                        effects: "fadeOut zoom:out",
                        duration: 300
                    },
                    open: {
                        effects: "fadeIn zoom:in",
                        duration: 300
                    }
                },
                valuePrimitive: angular.isUndefined($scope["valuePrimitive"]) ? true : $scope["primitive"],
                filter: $scope["filterable"] ? "contains" : false,
                dataValueField: $scope["valueField"] || "id",
                dataTextField: $scope["textField"] || "description",
                dataSource:$scope["source"] || {
                    transport: {
                        read: {
                            dataType: "json",
                            type: "POST",
                            url: $scope["url"],
                            data: angular.isUndefined($scope.filter) ? {} : {id: $scope.filter}
                        }
                    }
                }
            };
            $scope.generateDataSource = () => {
                return $scope["source"] || {
                    transport: {
                        read: {
                            dataType: "json",
                            type: "POST",
                            url: $scope["url"],
                            data: angular.isUndefined($scope.filter) ? {} : {id: $scope.filter}
                        }
                    }
                }
            }
        }

    }

    angular.module(Module)
        .directive(SprootComboBoxDirective.id, () => new SprootComboBoxDirective());
}
