module App {
  "use strict"

  angular.module(Module)
    .directive("highlightActive", function () {
      function e(e, t, a, o) {
        var n, r, i;
        r = t.find("a");
        i = function () {
          return o.path()
        };
        n = function (e, t) {
          return t = "#" + t, angular.forEach(e, function (e) {
            var a, o, n;
            return o = angular.element(e), a = o.parent("li"), n = o.attr("href"), a.hasClass("active") && a.removeClass("active"), 0 === t.indexOf(n) ? a.addClass("active") : void 0
          })
        };

        n(r, o.path());
        e.$watch(i, function (e, t) {
          return e !== t ? n(r, o.path()) : void 0
        })
      }

      var t = {
        restrict: "A",
        controller: ["$scope", "$element", "$attrs", "$location", e]
      };
      return t
    })
    .directive("collapseNav", [
      function () {
        return {
          restrict: "A",
          link: function (scope, ele) {
            var $a, $aRest, $app, $lists, $listsRest, $nav, $window, Timer, prevWidth, updateClass;
            $window = $(window);
            $lists = ele.find("ul").parent("li");
            $lists.append('<i class="glyphicon glyphicon-chevron-down icon-has-ul-h"></i>');
            $a = $lists.children("a");
            $a.append('<i class="glyphicon glyphicon-chevron-down icon-has-ul"></i>');
            $listsRest = ele.children("li").not($lists);
            $aRest = $listsRest.children("a");
            $app = $("#app");
            $nav = $("#nav-container");

            $a.on("click", function (event) {
              var $parent, $this;
              if (!$app.hasClass("nav-collapsed-min") && !($nav.hasClass("nav-horizontal") && $window.width() >= 768)) {
                $this = $(this);
                $parent = $this.parent("li");
                $lists.not($parent).removeClass("open").find("ul").slideUp();
                $parent.toggleClass("open").find("ul").stop().slideToggle();
                event.preventDefault();
              }
            });
            $aRest.on("click", function () {
              return $lists.removeClass("open").find("ul").slideUp()
            });
            scope.$on("nav:reset", function () {
              return $lists.removeClass("open").find("ul").slideUp()
            });
            Timer = void 0;
            prevWidth = $window.width();

            updateClass = function () {
              var currentWidth = $window.width();
              if (768 > currentWidth) {
                $app.removeClass("nav-collapsed-min");
              } else if (768 > prevWidth && $nav.hasClass("nav-horizontal")) {
                $lists.removeClass("open").find("ul").slideUp();
              }
              prevWidth = currentWidth;
            };

            $window.resize(function () {
              var t;
              clearTimeout(t);
              t = setTimeout(updateClass, 300);
            })
          }
        }
      }
    ])
    .directive("toggleOffCanvas", [
      function () {
        return {
          restrict: "A",
          link: function (scope, ele) {
            return ele.on("click", function () {
              return $("#app").toggleClass("on-canvas")
            })
          }
        }
      }
    ])
    .directive("toggleNavCollapsedMin", ["$rootScope",
      function ($rootScope) {
        return {
          restrict: "A",
          link: function (scope, ele) {
            var app = $("#app");
            ele.bind("click", () => {
                if (app.hasClass("nav-collapsed-min")) {
                    $("#logo").attr("src","imgs/sproot_logo_white.png");
                    app.removeClass("nav-collapsed-min");
                } else {
                    $("#logo").attr("src","imgs/sproot_logo_white_short.png");
                    app.addClass("nav-collapsed-min");
                    $rootScope.$broadcast("nav:reset");
                }
            })
          }
        }
      }
    ])
    .directive('ngEnter',
    function () {
      return {
        restrict: "A",
        link: function (scope, element, attrs) {
          element.bind("keydown keypress", function (event) {
            if (event.which === 13) {
              scope.$apply(function () {
                scope.$eval(attrs["ngEnter"]);
              });

              event.preventDefault();
            }
          });
        }
      }
    })
    .directive('dynAttr', [
      function () {
        return {
          scope: {list: '=dynAttr'},
          link: function (scope, elem) {
            //for(attr in scope.list){
            elem.attr(scope.list, '');
            //}
            //console.log(scope.list);
          }
        };
      }
    ])
    .directive('fileModel', ['$parse', function ($parse) {
      return {
        restrict: 'A',
        link: function(scope, element, attrs) {
          var model = $parse(attrs.fileModel);
          var modelSetter = model.assign;

          element.bind('change', function(){
            scope.$apply(function(){
              modelSetter(scope, element[0].files[0]);
            });
          });
        }
      };
    }])
    .directive('treeModel', ['$compile', function( $compile ) {
      return {
        restrict: 'A',
        link: function ( scope, element, attrs ) {
          //tree id
          var treeId = attrs.treeId;

          //tree model
          var treeModel = attrs.treeModel;

          //node id
          var nodeId = attrs.nodeId || 'id';

          //node label
          var nodeLabel = attrs.nodeLabel || 'label';

          //children
          var nodeChildren = attrs.nodeChildren || 'children';

          //add children
          var addChildren = attrs.addChildren || 'vm.addChildren(node, $event)';

          //add button visibility
          var addButtonVisibility = attrs.addButtonVisibility || 'vm.addButtonVisibility(node)';

          //tree template
          var template =
            '<ul>' +
            '<li data-ng-repeat="node in ' + treeModel + '">' +
            '<md-icon data-ng-class="node.selected" class="node-head" md-svg-icon="custom:right_arrow" aria-label="..." data-ng-show="node.' + nodeChildren + '.length && node.collapsed" data-ng-click="' + treeId + '.selectNodeHead(node)"></md-icon>' +
            '<md-icon data-ng-class="node.selected" class="node-head" md-svg-icon="custom:down_arrow" aria-label="..." data-ng-show="node.' + nodeChildren + '.length && !node.collapsed" data-ng-click="' + treeId + '.selectNodeHead(node)"></md-icon>' +
            '<md-icon data-ng-class="node.selected" class="node-head" md-svg-icon="" aria-label="..." data-ng-hide="node.' + nodeChildren + '.length"></md-icon>' +
            '<span data-ng-class="node.selected" data-ng-click="' + treeId + '.selectNodeLabel(node)">{{node.' + nodeLabel + '}}</span>' +
            '<md-icon data-ng-class="node.selected" md-svg-icon="content:add_circle_outline" data-ng-show="node.selected && ' + addButtonVisibility + '" data-ng-click="' + addChildren + '"></md-icon>' +
            '<div data-ng-hide="node.collapsed" data-tree-id="' + treeId + '" data-tree-model="node.' + nodeChildren + '" data-node-id=' + nodeId + ' data-node-label=' + nodeLabel + ' data-node-children=' + nodeChildren + '></div>' +
            '</li>' +
            '</ul>';


          //check tree id, tree model
          if( treeId && treeModel ) {

            //root node
            if( attrs.angularTreeview ) {

              //create tree object if not exists
              scope[treeId] = scope[treeId] || {};

              //if node head clicks,
              scope[treeId].selectNodeHead = scope[treeId].selectNodeHead || function( selectedNode ){
                //Collapse or Expand
                selectedNode.collapsed = !selectedNode.collapsed;
              };

              //if node label clicks,
              scope[treeId].selectNodeLabel = scope[treeId].selectNodeLabel || function( selectedNode ){
                //remove highlight from previous node
                if( scope[treeId].currentNode && scope[treeId].currentNode.selected ) {
                  scope[treeId].currentNode.selected = undefined;
                }

                //set highlight to selected node
                selectedNode.selected = 'selected';

                //set currentNode
                scope[treeId].currentNode = selectedNode;
              };
            }

            //Rendering template.
            element.html('').append( $compile(template)( scope ) );
          }
        }
      };
    }])
    .directive('format', ['$filter', function ($filter) {
      return {
        require: '?ngModel',
        link: function (scope, elem, attrs, ctrl) {
          if (!ctrl) return;

          ctrl.$parsers.push(function(data) {
            //View -> Model
            return data;
          });
          ctrl.$formatters.push(function(data) {
            //Model -> View
            if (attrs.format == 'date') {
              return $filter(attrs.format)(data, "dd/MM/yyyy");
            } else if (attrs.format == 'currency') {
              return $filter(attrs.format)(data);
            } else {
              return data;
            }
          });
        }
      };
    }])
    .directive('includeReplace', ['$templateRequest', '$compile', function ($templateRequest, $compile) {
      return {
        restrict: 'A',
        replace: true,
        link: function (scope, element, attrs) {
          $templateRequest(attrs.includeReplace).then((html) => {
            var template = angular.element(html);
            element.parent().append(template);
            element.children().unwrap();
            //element.remove();
            $compile(template)(scope);
          });
        }
      };
    }])
    .directive("bindHtmlCompile", ['$compile', function ($compile) {
      return {
          restrict: "A",
          link: (scope, element, attrs) => {
              scope.$watch(() => {
                  return scope.$eval(attrs.bindHtmlCompile);
              }, (value) => {
                  element.html(value);
                  $compile(element.contents())(scope);
              });
          }
      }
    }])
    .directive('accordion', () => {
        return {
            restrict: "A",
            link: (scope, element, attrs) => {
                element.bind('click', function() {
                    var panel = this.nextElementSibling;
                    var li = element.parent().parent();
                    var ul = li.parent();
                    var nodes: any[] = <any>ul.children();

                    if(li.hasClass("active")) {
                        li.removeClass("active");
                        panel.style.maxHeight = null;
                    } else {
                        li.addClass("active");
                        panel.style.maxHeight = panel.scrollHeight + "px";
                        for(var i: number = 0; i < nodes.length; ++i) {
                            if(nodes[i].hasAttribute("class", "active") && nodes[i].id != li[0].id) {
                                nodes[i].removeAttribute("class", "active");
                                nodes[i].children[1].style.maxHeight = null;
                            }
                        }
                    }
                })
            },
        }
    })
    .directive('togglePanel', () => {
        return {
            restrict: "A",
            link: (scope, element, attrs) => {
                element.bind('click', function() {
                    var ibox = $(this).closest('div.ibox');
                    var button = $(this).find('i');
                    var content = ibox.children('.ibox-content');
                    content.slideToggle(200);
                    button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
                    ibox.toggleClass('').toggleClass('border-bottom');
                    setTimeout(function () {
                        ibox.resize();
                        ibox.find('[id^=map-]').resize();
                    }, 50);
                });
            }
        }
    })

    /* Slide toggle
    *  Default values - mm-open, mm-closed in toggle element and toggle content
    *  Parameters     - @slideDuration (default state: 200)
    *                   @groupSel      - this attribute specifies selector of toggle elements that should work as a group (accordion effect)
    *                                    For ex. for adding group accordion effect in news feed, you need to do the following:
    *                                    a) Specify attribute group-sel=".card-toggle-group" for each group element
    *                                    b) Add class  card-toggle-group for toggle element and toggle content for each element
    *                   @closeOnContentClick - specifies if content should be closed on click
    *  Note           - Keep all classes with mm in toggle element, toggle content and in inside icon if you use icon
    *  Usage Examples - News feed card article name toggle, News feed card filters
    * */
    .directive('mmSlideToggle', () => {
        return {
            restrict: "A",
            link: (scope, element, attrs) => {
                /* Variables */
                var duration = (attrs["slideDuration"]!=null) ? attrs["slideDuration"] : "200";
                var toggleContentSel = attrs["mmSlideToggle"];
                var groupSel = attrs["groupSel"];
                var closeOnContentClick = attrs["closeOnContentClick"];
                var toggleElClass = "mm-slide-toggle";
                var toggleElContentClass = "mm-slide-toggle-content";
                var openClass = "mm-open";
                var closedClass = "mm-closed";
                var animateClass = "mm-animate";

                /* Functions */
                /**
                 * Closes other elements when clicking on group element
                 */
                var groupFunc = function() {
                    $("." + toggleElContentClass + groupSel + "." + openClass).not(toggleContentSel).stop(true, true).slideToggle(duration, function(){
                        var groupToggleSel = "." + toggleElContentClass + groupSel + "." + openClass;
                        $(this).toggleClass(openClass);
                        $(groupToggleSel).addClass(animateClass);
                        setTimeout(function () {
                            $(groupToggleSel).not(element).toggleClass(openClass);
                            $(groupToggleSel).removeClass(animateClass);
                        }, 5);
                    });
                }
                /**
                 * Toggles element
                 */
                var elToggleFunc = function(){

                    $(toggleContentSel).stop(true, true).slideToggle(duration, function(){
                        element.toggleClass(openClass).toggleClass(closedClass);
                        element.addClass(animateClass);
                        setTimeout(function () {
                            $(toggleContentSel).toggleClass(openClass).toggleClass(closedClass);
                            element.removeClass(animateClass);
                        }, 200);
                    });
                }

                /* Click Events */
                // Element click event
                element.on('click', function(e){
                    e.preventDefault();
                    // If group option is on
                    if(groupSel!=null) {
                        groupFunc();
                    }
                    elToggleFunc();
                })
                // Content click event
                if(closeOnContentClick!=null) {
                    $('body').off('click', toggleContentSel);
                    $('body').on('click', toggleContentSel, function(e){
                        e.preventDefault();
                        elToggleFunc();
                    });
                }

            }
        }
    })
 /* Dropdown toggle
  * Parameters      - @slideDuration (default state: 200)
 */
  .directive('mmDropdownToggle', () => {
      return {
          restrict: "A",
          link: (scope, element, attrs) => {
              var duration = (attrs["toggleDuration"]!=null) ? attrs["toggleDuration"] : "200";
              var dropdownToggleSel = '.mm-dropdown-toggle';
              var toggleContentInnerSel = '.mm-toggle-content-inner';
              var selectedClass = 'mm-open';
              var toggleContentEl = element.find('.mm-toggle-content');
              var toggleEl = element.find('.mm-toggle-el');

              toggleEl.on('click', function(e) {
                  e.stopPropagation();
                  $(dropdownToggleSel).not(element).removeClass(selectedClass);
                  toggleContentEl.show();
                  toggleContentEl.find(toggleContentInnerSel).show();
                  toggleContentEl.height(toggleContentEl.find(toggleContentInnerSel).outerHeight());
                  toggleContentEl.width(toggleContentEl.find(toggleContentInnerSel).outerWidth());
                  setTimeout(function () {
                      toggleContentEl.closest(dropdownToggleSel).toggleClass(selectedClass);
                      if (!toggleContentEl.closest(dropdownToggleSel).hasClass(selectedClass))
                          setTimeout(function () {
                              toggleContentEl.hide();
                          }, duration);
                      toggleContentEl.height(toggleContentEl.find(toggleContentInnerSel).outerHeight());
                      toggleContentEl.width(toggleContentEl.find(toggleContentInnerSel).outerWidth());
                  }, 5);
              });

              $('body').on('click', function(e){
                  $(dropdownToggleSel).removeClass(selectedClass);
              });
              // toggleContentEl.closest(dropdownToggleSel).on('click', function(e){
              //     e.stopPropagation();
              // });
          }
      }
  })
  /* Menu Active on Click
   * Parameters      - @slideDuration (default state: 200)
   *                   @groupSel      - this attribute specifies selector of elements that should work as a group,
   *                                    which means making active one element will remove active state from the others
   */
  .directive('mmMenuActiveOnClick', ['$timeout', function ($timeout) {
      return {
          restrict: "A",
          link: (scope, element, attrs) => {
              var groupSel = attrs["groupSel"];
              var activeClass = "active";
              var defaultMenuHref = '#/news_feed';

              element.on('click', function(e) {
                  if(groupSel!=null)
                    $(groupSel).not(this).removeClass(activeClass);
                  $(this).addClass(activeClass);
              });

              // Set default active menu
              $(groupSel).find('a[href="' + defaultMenuHref +'"]').parent(groupSel).addClass('active');

              // Use this code if you change place of news feed menu item and fix menu item's rendering based on permission
              // if (scope.$last === true) {
              //     $timeout(function () {
              //         scope.$emit('ngRepeatFinished');
              //         $(groupSel).find('a[href="#/news_feed"]').parent(groupSel).addClass('active');
              //     });
              // }

          }
      }
  }])
  /* Sidebar Show Hide Toggle Functionality
 */
  .directive('mmSidebarShowHide', () => {
      return {
          restrict: "A",
          link: (scope, element, attrs) => {
              /* Variables */
              var contentColSel = '.mm-content-col';
              var sidebarColSel = '.mm-sidebar-col';
              var parentElSel = '.mm-sidebar-show-hide > .row';
              var sidebarToggleSel = '.mm-sidebar-toggle';
              var sidebarHiddenCl = 'mm-sidebar-hidden';
              var hideAnimateCl = 'mm-hide-animate';
              var hiddenCl = 'mm-hidden';


              /* Functions */
              function hideSidebar(curEl, callback) {
                  var wToShrink = $(parentElSel).width();
                  $(contentColSel).css('width',wToShrink);
                  $(sidebarColSel).addClass(hideAnimateCl);
                  setTimeout(function () {
                      $(sidebarColSel).addClass(hiddenCl);
                      curEl.addClass(sidebarHiddenCl);
                      callback();
                  }, 100);
              }

              function showSidebar(curEl, callback) {
                  $(contentColSel).removeAttr('style');
                  setTimeout(function () {
                      $(sidebarColSel).removeClass(hiddenCl);
                      callback();
                  }, 295);
                  setTimeout(function () {
                      $(sidebarColSel).removeClass(hideAnimateCl);
                      curEl.removeClass(sidebarHiddenCl);
                  }, 310);
              }

              function refreshCharts() {
                  var wToShrink = $('.mm-chart-list .chart-list-item').width();
                  $('.mm-chart-list .chart-el-wrapper > div').each(function(){
                      $(this).highcharts().setSize(wToShrink, 400);
                  })
              }
              function refreshChartsWithDelay() {
                  setTimeout(function() {
                      refreshCharts();
                  }, 300);
              }

              function refreshLayout() {
                  $(parentElSel).css('width','auto');
                  $(contentColSel).css('width', 'auto');
                  setTimeout(function () {
                      if(!$(sidebarToggleSel).hasClass(sidebarHiddenCl))
                          showSidebar($(sidebarToggleSel), refreshCharts);
                      else
                          hideSidebar($(sidebarToggleSel), refreshCharts);
                  }, 300);
              }

              /* Events */
              element.on('click', function(e) {
                  e.stopPropagation();
                  if(!$(this).hasClass(sidebarHiddenCl))
                      hideSidebar($(this), refreshChartsWithDelay);
                  else
                      showSidebar($(this), refreshCharts);
              });

              $('body').on('click',' .admin-options md-radio-button, .top-nav .toggle-min', function(){
                  refreshLayout();
              });

              $(window).on('resize', function(){
                  refreshLayout();
              })

          }
      }
  })
}
