module App
{
    export class SprootDropDownDirective implements ng.IDirective {
        public static id = "sprootDropDown";
        public template = `
            <md-input-container class="md-block" >
              <label>{{label | translate}}</label>
              <input kendo-drop-down-list="dropDownList"
                     k-ng-model="value"
                     ng-model="ngModelValue"
                     k-options="options"
                     k-on-change="onChange()" 
                     name="{{fieldName}}"
                     ng-required="form && required"
                     aria-label="..." 
                     ng-disabled="disabled"/>
              <div ng-messages="form[fieldName].$error">
                <div ng-message="required">{{'THIS_IS_REQUIRED' | translate}}</div>
              </div>
            </md-input-container>`;
        public restrict = 'AE';
        public require = ['^?form'];
        public replace: boolean = true;
        public scope = {
            value: '=ngModel',
            url: "<?",
            source: "<?",
            label: "<?",
            onChange: "&?",
            valueField: "<?",
            textField: "<?",
            valuePrimitive: "<?",
            optionLabel: "<?",
            filterable: "<?",
            name: "<?",
            required: "<?",
            filter: "<?",
            postData: "=?",
            disabled:"<?"
        }

        public link = ($scope: any, element: JQuery, attributes: ng.IAttributes, ctrl: ng.IControllerService[]) => {
            $scope.watcher = $scope.$watch("value", (newValue, oldValue) => {
                if(newValue !== oldValue) {
                    if (angular.isUndefined(newValue) || newValue == "" || newValue == null) {
                        $scope.ngModelValue = null;
                    } else if(typeof newValue === "string" || typeof newValue === "number") {
                        $scope.ngModelValue = newValue;
                    } else if (typeof newValue === "object") {
                        var idField: string = $scope.valueField || "id";
                        $scope.ngModelValue = newValue[idField];
                    }
                    //$scope.watcher();
                }

            });
            $scope.$watch("filter", (newValue, oldValue) => {
                if(newValue != oldValue) {
                    $scope.refreshSource();
                }
            });
            $scope["form"] = ctrl && ctrl[0];
            $scope["fieldName"] = $scope["name"] || $scope["label"];

			$scope.getPostData = () => {
				var postData: any = $scope.postData || {};
				if (!angular.isUndefined($scope.filter)) {
					angular.extend(postData, {id: $scope.filter})
				}
				return postData;
			}

            $scope["options"] = {
                animation: {
                    close: {
                        effects: "fadeOut zoom:out",
                        duration: 300
                    },
                    open: {
                        effects: "fadeIn zoom:in",
                        duration: 300
                    }
                },
                valuePrimitive: angular.isUndefined($scope["valuePrimitive"]) ? true : $scope["primitive"],
                optionLabel: angular.isUndefined($scope["optionLabel"]) ? " " : false,
                filter: angular.isUndefined($scope["filterable"]) ? "contains" : $scope["filterable"],
                disabled: angular.isUndefined($scope["disabled"]) ? false : $scope["disabled"],
                dataValueField: $scope["valueField"] || "id",
                dataTextField: $scope["textField"] || "description",
                dataSource: $scope["source"] || {
                    transport: {
                        read: {
                            dataType: "json",
                            type: "POST",
                            url: $scope["url"],
                            data: $scope.getPostData()
                        }
                    }
                }
            };

            $scope.generateDataSource = () => {
                return $scope["source"] || {
                    transport: {
                        read: {
                            dataType: "json",
                            type: "POST",
                            url: $scope["url"],
                            data: $scope.getPostData()
                        }
                    },
                    requestEnd: (e) => {
                        var response = e.response;
                        var allIds: number[] = _.map(response, (obj: any) => {
                            return obj.id;
                        });
                        if (allIds.indexOf(+$scope.value) == -1) {
                            $scope.value = null;
                        }
                    }
                }
            }
            $scope.refreshSource = () => {
                $scope.dropDownList.setDataSource(new kendo.data.DataSource($scope.generateDataSource()));
            }
            this.eventManagerService.subscribe($scope["label"], $scope.refreshSource)
        }

        constructor(
            public eventManagerService: EventManagerService) {
        }
    }

    angular.module(Module)
        .directive(SprootDropDownDirective.id, ['eventManagerService', (eventManagerService) => new SprootDropDownDirective(eventManagerService)]);
}
