module App {
    export class SocialMediaFilterComponentController  {
        
        public filter: IPostFilter = new PostFilter();
        public usingPage: FilterUsingPageEnum;
        public refreshSavedFilterSource: Function;
        private getFilteredData: Function;
        private clearFilters: Function;
        public savedFilter: number;
        public min_date: Date;
        public rated_source: any;
        public filterUsingPageEnum = FilterUsingPageEnum;
    
        constructor(private $mdDialog: any,
            private eventManagerService: EventManagerService,
            private socialMediaService: SocialMediaService,
            private globals: any) {
            this.rated_source = [{id:1, description:"Չգնահատված"}, {id:2, description: "Գնահատված"}];
            if(localStorage.hasOwnProperty('filter')){
                this.filter = JSON.parse(localStorage.getItem('filter'));
                setTimeout(()=> {
                    this.filter = JSON.parse(localStorage.getItem('filter'));
                    localStorage.removeItem('filter');
                }, 10);
            }
        }

        $onInit(){
            this.usingPage = this.usingPage ? this.usingPage : FilterUsingPageEnum.NewsFeed;
        }

        public savedFilterChange = () => {
            if (this.savedFilter) {
                this.socialMediaService.getPostFilterById(this.savedFilter).then((res: IPostFilter) => {
                    if(res){
                        this.filter = res;
                    }
                })
            } else {
                this.filter = null;
            }
        }

        public getStartDateByProject(project: Dictionary){
            Utilities.getStartDateByProject(project.id).then((response:INewsFilter) => {
                this.min_date = response.start_date;
            });
        }


        private saveFilter = (): void => {
            let options = {
                title: "SAVE",
                size: "small",
                buttons: {
                    acceptBtn: {
                        text: "SAVE",
                        btnClass: "btn-primary",
                        disableIfInvalid: true,
                        fnc: () => {
                            this.socialMediaService.savePostFilter(this.filter).then((res: boolean) => {
                                if (res) {
                                    this.getFilteredData();
                                    this.eventManagerService.publish('SAVED_FILTERS');
                                    this.$mdDialog.hide();
                                }
                            }).catch((err) => {
                                Notifier.show(err.data.message, MessageType.error);
                            });
                        }
                    },
                    cancelBtn: {
                        text: "CANCEL",
                        btnClass: "btn-default",
                        fnc: () => {
                            this.$mdDialog.hide();
                        }
                    }
                },
                item: this.filter,
                item_options: {
                    fields: {
                        name: {type: "text", label: "NAME", get: (item) => item, required: () => true}
                    },
                    rows: [
                        [{field: "name", col_class: "col-md-12"}]
                    ]
                }
            };
            this.$mdDialog.show({
                template: '<dialog-box options="options"></dialog-box>',
                parent: angular.element(document.body),
                targetEvent: false,
                locals: {options: options},
                controller: ['$scope', 'options', ($scope, options) => $scope.options = options]
            });
        };

    }

    export class SocialMediaFilterComponent implements ng.IComponentOptions {
        public static id = "socialMediaFilter";
        templateUrl = "/components/social-media-components/social-media-filter/social-media-filter.component.html";
        controllerAs = "vm";
        bindings: any;
        constructor() {
            this.bindings = {
                filter: "=",
                getFilteredData: "&?",
                clearFilters: "&",
                usingPage: "<?"
            };
        }
        controller = ['$mdDialog', 'eventManagerService', 'socialMediaService', 'globals',
            SocialMediaFilterComponentController
        ];
    }
    angular.module(Module)
        .component(SocialMediaFilterComponent.id, new SocialMediaFilterComponent());
}
