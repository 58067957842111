module App {
    export class SocialMediaCardComponentController {

        private cardElement: ISocialMediaElement;
        public searchPattern: string;
        public ViewMode = ViewMode;

        constructor(private $mdDialog: any,
            private authenticationService: Authentication,
            private eventManagerService: EventManagerService,
            private socialMediaService: SocialMediaService,
            private globals: any) {

        }

        $onInit() {
        }

        public changeSelected = () => {
            this.eventManagerService.publish(this.globals.events.selectedPostsEdit, this.cardElement.id);
        };

        public showDialog = (mode: ViewMode,  $event): void => {
            this.$mdDialog.show({
                template: (mode == this.ViewMode.Edit) ? '<social-media-add options="options"></social-media-add>' : '<fb-emojies options="options"></fb-emojies>',
                parent: angular.element(document.body),
                targetEvent: $event || false,
                locals: { options:  this.cardElement },
                controller: ['$scope', 'options', ($scope, options) => {
                    $scope.options = options
                }]
            })
        };
        
        public analyzePost = ($event) => {
            let options: any = { elementId: this.cardElement.id, post: true  };
            this.$mdDialog.show({
                template: '<news-feed-element-analyze options="options"></news-feed-element-analyze>' ,
                parent: angular.element(document.body),
                targetEvent: $event || false,
                locals: {options: options},
                controller: ['$scope', 'options', ($scope, options) => {
                    $scope.options = options;
                }]
            })
        }

        public changeReadUnreadState = (state: boolean): void => {
            this.socialMediaService.changeReadState([this.cardElement], state).then((res: boolean) => {
                if (res) {
                    this.cardElement.read_state = state;
                }
            });
        }

    }

    export class SocialMediaCardComponent implements ng.IComponentOptions {
        public static id = "socialMediaCard";
        templateUrl = "/components/social-media-components/social-media-card/social-media-card.component.html";
        controllerAs = "vm";
        bindings: any;
        constructor() {
            this.bindings = {
                cardElement: "<",
                searchPattern: "<?"
            };
        }
        controller = ['$mdDialog', 'authenticationService', 'eventManagerService', 'socialMediaService', 'globals',
            SocialMediaCardComponentController
        ];
    }
    angular.module(Module)
        .component(SocialMediaCardComponent.id, new SocialMediaCardComponent());
}
