module App {
	export class ProjectController extends BaseController {
		static id = "projectController";

		public grid: kendo.ui.Grid;
		public gridOptions: any;
		private statusDataSource = [{id: 1, description: "Ակտիվ"}, {id: 2, description: "Պասիվ"}];
		/*@ngInject*/
		constructor(private projectService: IProjectService,
					private authenticationService: Authentication,
					private $mdDialog: any,
					private $http: ng.IHttpService)
		{
			super();
			this.setListMode();
			this.initGridOptions();
		}

		private initGridOptions = () => {
			this.gridOptions = {
				dataSource: {
					transport: {
						read: {
							url: "/request/get_projects",
							dataType: "json",
							type: "POST"
						}
					},
					pageSize: 20,
				},
				sortable: true,
				pageable: true,
				resizable: true,
				filterable: true,
				columnMenu: true,
				columns: [
					{ field: "name", title: "NAME" },
					{ field: "status", title: "STATUS" },
					{ template: `<md-button ng-if="vm.controlVisibility(\'edit_project\')" class="md-icon-button custom-cell-button" ng-click="vm.saveProject(dataItem, $event)" aria-label="Edit"><md-tooltip><span data-translate="EDIT"></span></md-tooltip><md-icon md-svg-icon="editor:mode_edit" aria-label="..."></md-icon></md-button>
								<md-button ng-if="vm.controlVisibility('edit_project')" class="md-icon-button custom-cell-button" ng-click="vm.removeProject(dataItem)" aria-label="Remove"><md-tooltip><span data-translate="DELETE"></span></md-tooltip><md-icon md-svg-icon="action:delete" aria-label="..."></md-icon></md-button>`, width: 150}
				]
			}
		}

		public saveProject(project: any, $event) {
			var projectCopy: any;
			if (project) {
				projectCopy = angular.copy(project);
				projectCopy.status = {id: projectCopy.status_id, name: projectCopy.status};
				projectCopy.updatesAvailable  = project.updatesAvailable ? true : false
				
			} else {
				projectCopy = new Project();
			}
			var dialog_options = {
				title: project ? "EDIT_PROJECT" : "ADD_PROJECT",
				size: "small",
				buttons: {
					acceptBtn: {
						text: "SAVE",
						disableIfInvalid: true,
						fnc: () => {
							
							this.projectService.saveProject(dialog_options.item).then((b: any) => {
								if (!(b instanceof Error)) {
									this.$mdDialog.hide();
									this.grid.dataSource.read();
									this.grid.refresh();
								}
							})
						}
					},
					cancelBtn: {
						text: "CANCEL",
						fnc: () => {
							this.$mdDialog.hide();
						}
					}
				},
				item: projectCopy,
				item_options: {
					fields: {
						name: {type: "text", label: "NAME", required: () => true},
						status: {type: "combo", label: "STATUS", get: (item) => item, source: this.statusDataSource},
						updatesAvailable: {type: "checkbox", label: "UPDATES_AVAILABLE"}
					},
					rows: [
						[{field: "name", col_class: "col-xs-10"}],
						[{field: "status", col_class: "col-xs-10"}],
						[{field: "updatesAvailable", col_class: "col-xs-10"}]
					]
				}
			};

			this.$mdDialog.show({
				template: '<dialog-box options="options"></dialog-box>',
				parent: angular.element(document.body),
				targetEvent: $event || false,
				locals: {options: dialog_options},
				controller: ['$scope', 'options', ($scope, options) => $scope.options = options]
			})
		}

		public getSubjectGridOptions = (projectId: number) => {
			return {
				dataSource: {
					transport: {
						read: {
							url: "/request/get_subjects",
							dataType: "json",
							type: "POST",
							data: {id: projectId}
						}
					},
					pageSize: 10,
				},
				sortable: true,
				pageable: true,
				resizable: true,
				filterable: false,
				columnMenu: false,
				columns: [
					{ field: "name", title: "{{'NAME' | translate}}"},
					{ field: "status", title: "{{'STATUS' | translate}}" },
					{ field: "rateable", title: "{{'RATEABLE' | translate}}", template: "<div class='full-width text-center' ng-init='dataItem.rateable=(dataItem.rateable ? true : false)'><input ng-model='dataItem.rateable' type='checkbox' disabled></input></div>", width: 200},
					{ template: `<md-button ng-if="vm.controlVisibility('edit_subject')" class="md-icon-button custom-cell-button" ng-click="vm.saveSubject(dataItem, null, $event)" aria-label="Edit"><md-tooltip><span data-translate="EDIT"></span></md-tooltip><md-icon md-svg-icon="editor:mode_edit" aria-label="..."></md-icon></md-button>
								<md-button ng-if="vm.controlVisibility('edit_subject')" class="md-icon-button custom-cell-button" ng-click="vm.removeSubject(dataItem)" aria-label="Remove"><md-tooltip><span data-translate="DELETE"></span></md-tooltip><md-icon md-svg-icon="action:delete" aria-label="..."></md-icon></md-button>`, width: 150}
				]
			}
		}

		public saveSubject(subject: any, projectId: number, $event) {
			var subjectCopy: any;
			if (subject) {
				subjectCopy = angular.copy(subject);
				subjectCopy.status = {id: subjectCopy.status_id, name: subjectCopy.status};
			} else {
				subjectCopy = {project_id: projectId};
			}
			var dialog_options = {
				title: subject ? "EDIT_SUBJECT" : "ADD_SUBJECT",
				size: "small",
				buttons: {
					acceptBtn: {
						text: "SAVE",
						disableIfInvalid: true,
						fnc: () => {
							this.saveSubjectToDB(angular.copy(dialog_options.item));
						}
					},
					cancelBtn: {
						text: "CANCEL",
						fnc: () => {
							this.$mdDialog.hide();
						}
					}
				},
				item: subjectCopy,
				item_options: {
					fields: {
						chooseFromExisting: {type: "checkbox", label: "CHOOSE_FROM_EXISTING", isNotVisible: (item) => { return item.id }},
						existingSubject: {type: "combo", label: "SUBJECTS", source_url: "/request/get_subjects", description: "name", required: () => true, isNotVisible: (item) => { return !item.chooseFromExisting }},
						name: {type: "text", label: "NAME", required: () => true, isNotVisible: (item) => { return item.chooseFromExisting }},
						status: {type: "combo", label: "STATUS", get: (item) => item, source: this.statusDataSource, isNotVisible: (item) => { return item.chooseFromExisting }},
						rateable: {type: "checkbox", label: "RATEABLE"}
					},
					rows: [
						[{field: "chooseFromExisting", col_class: "col-xs-10"}],
						[{field: "existingSubject", col_class: "col-xs-10"}],
						[{field: "name", col_class: "col-xs-10"}],
						[{field: "status", col_class: "col-xs-10"}],
						[{field: "rateable", col_class: "col-xs-10"}]
					]
				}
			};

			this.$mdDialog.show({
				template: '<dialog-box options="options"></dialog-box>',
				parent: angular.element(document.body),
				targetEvent: $event || false,
				locals: {options: dialog_options},
				controller: ['$scope', 'options', ($scope, options) => $scope.options = options]
			})
		}

		public saveSubjectToDB = (subjectObj) => {
			if(subjectObj.existingSubject) {
				subjectObj.id = subjectObj.existingSubject.id;
			}
			this.projectService.saveSubject(subjectObj).then((b: any) => {
				if (!(b instanceof Error)) {
					var grid: kendo.ui.Grid = $("#subjectGrid" + subjectObj.project_id).data("kendoGrid");
					grid.dataSource.read();
					grid.refresh();
					this.$mdDialog.hide();
				}
			})
		}

		public getKeywordGridOptions = (subjectId: number): any => {
			return {
				dataSource: {
					transport: {
						read: {
							url: "/request/get_keywords",
							dataType: "json",
							type: "POST",
							data: {subject_id: subjectId}
						}
					},
					pageSize: 20,
				},
				sortable: true,
				pageable: true,
				resizable: true,
				filterable: true,
				columnMenu: true,
				columns: [
					{ field: "name", title: "{{'NAME' | translate}}" },
					{ field: "language_description", title: "{{'LANGUAGE' | translate}}" },
					{ field: "status", title: "{{'STATUS' | translate}}" },
					{ template: `<md-button ng-if="vm.controlVisibility('edit_keyword')" class="md-icon-button custom-cell-button" ng-click="vm.saveKeyword(dataItem, null, $event)" aria-label="Edit"><md-tooltip><span data-translate="EDIT"></span></md-tooltip><md-icon md-svg-icon="editor:mode_edit" aria-label="..."></md-icon></md-button>
								<md-button ng-if="vm.controlVisibility('edit_keyword')" class="md-icon-button custom-cell-button" ng-click="vm.removeKeyword(dataItem)" aria-label="Remove"><md-tooltip><span data-translate="DELETE"></span></md-tooltip><md-icon md-svg-icon="action:delete" aria-label="..."></md-icon></md-button>`, width: 150},
					//{ field: "expression", title: "EXPRESSION" },
				]
			};
		}

		public saveKeyword(keyword: any, subjectId: number, $event) {
			var keyWordCopy: any;
			if (!keyword) {
				keyWordCopy = {subject_id: subjectId};
			} else {
				keyWordCopy = angular.copy(keyword);
				keyWordCopy.language = {id: keyWordCopy.language, name: keyWordCopy.language_description};
				keyWordCopy.status = {id: keyWordCopy.status_id, name: keyWordCopy.status};
			}
			var dialog_options = {
				title: keyword ? "EDIT_KEYWORD" : "ADD_KEYWORD",
				size: "small",
				buttons: {
					acceptBtn: {
						text: "SAVE",
						disableIfInvalid: true,
						fnc: () => {
							this.projectService.saveKeyword(dialog_options.item).then((b: any) => {
								if (!(b instanceof Error)) {
									var grid: kendo.ui.Grid = $("#keywordGrid" + dialog_options.item.subject_id).data("kendoGrid");
									grid.dataSource.read();
									grid.refresh();
									this.$mdDialog.hide();
								}
							})
						}
					},
					cancelBtn: {
						text: "CANCEL",
						fnc: () => {
							this.$mdDialog.hide();
						}
					}
				},
				item: keyWordCopy,
				item_options: {
					fields: {
						name: { type: "text", label: "NAME", required: () => true },
						language: { type: "combo", label: "LANGUAGE", get: (item) => item,	source_url: "/request/get_languages", required: () => true },
						status: { type: "combo", label: "STATUS", get: (item) => item, source: this.statusDataSource }
					},
					rows: [
						[{field: "name", col_class: "col-xs-10"}],
						[{field: "language", col_class: "col-xs-10"}],
						[{field: "status", col_class: "col-xs-10"}]
					]
				}
			};

			this.$mdDialog.show({
				template: '<dialog-box options="options"></dialog-box>',
				parent: angular.element(document.body),
				targetEvent: $event || false,
				locals: {options: dialog_options},
				controller: ['$scope', 'options', ($scope, options) => $scope.options = options]
			})
		}

		public removeProject = (project) => {
			if(confirm("Հեռացնե՞լ նախագիծը։")) {
				project.status = {id: 3};
				this.projectService.saveProject(project).then((b: any) => {
					if (!(b instanceof Error)) {
						this.$mdDialog.hide();
						this.grid.dataSource.read();
						this.grid.refresh();
					}
				})
			}
		}

		public removeSubject = (subject) => {
			if(confirm("Հեռացնե՞լ թեման։")) {
				subject.status = {id: 3};
				this.projectService.saveSubject(subject).then((b: any) => {
					if (!(b instanceof Error)) {
						var grid: kendo.ui.Grid = $("#subjectGrid" + subject.project_id).data("kendoGrid");
						grid.dataSource.read();
						grid.refresh();
					}
				})
			}
		}

		public removeKeyword = (keyword) => {
			if(confirm("Հեռացնե՞լ բանալի բառը։")) {
				keyword.status = {id: 3};
				keyword.language = {id: keyword.language}
				this.projectService.saveKeyword(keyword).then((b: any) => {
					if (!(b instanceof Error)) {
						var grid: kendo.ui.Grid = $("#keywordGrid" + keyword.subject_id).data("kendoGrid");
						grid.dataSource.read();
						grid.refresh();
					}
				})
			}
		}

		public getMassMediaGridOptions = (projectId: number) => {
			return {
				toolbar: [
					{
						name: "EDIT",
						template: '<span flex></span><md-button ng-if="app.controlVisibility(\'edit_mass_media_list\')" class="md-icon-button custom-cell-button middle-icon"  ng-click="vm.editMassMediaList(dataItem.id, $event)"><md-tooltip><span data-translate="EDIT"></span></md-tooltip><md-icon md-svg-icon="editor:mode_edit" aria-label="..." class="edit-mass"></md-icon></md-button> '
					}
				],
				dataSource: {
					transport: {
						read: {
							url: "/request/get_mass_media_by_project",
							dataType: "json",
							type: "POST",
							data: {id: projectId}
						}
					},
					pageSize: 10,
				},
				sortable: true,
				pageable: true,
				resizable: true,
				filterable: true,
				columnMenu: true,
				columns: [
					{ field: "name", title: "{{'NAME' | translate}}"},
					{ field: "country", title: "{{'COUNTRY' | translate}}"}]
			}
		}

		public editMassMediaList = (projectId, $event) => {
			this.$http.post('request/get_mass_media_full_list_for_project/', {id: projectId}).success((res: any) => {
				for(var p in res) {
					res[p].assigned = res[p].assigned == 0 ? false : true;
				}
				this.$mdDialog.show({
					template: $('#massMediaListPopupTemplate').html(),
					parent: angular.element(document.body),
					locals: {options: {massMediaList: res}},
					controller: ['$scope', 'options', ($scope, options) => {
						$scope.isChaked = false;
						$scope.selectedCountry = "";
						$scope.massMediaList = options.massMediaList;
						$scope.cancel = () => {
							this.$mdDialog.hide();
						}
						$scope.checkAll = (isChecked: boolean) => {
							for( var p in $scope.massMediaList) {
								if ($scope.selectedCountry && $scope.selectedCountry == $scope.massMediaList[p].country_id) {
									$scope.massMediaList[p].assigned = isChecked;
								} else if (!$scope.selectedCountry) {
									$scope.massMediaList[p].assigned = isChecked;
								}
							}
						}

						$scope.saveMassMediaList = () => {
							var arr: number[] = [];
							for (var i in $scope.massMediaList) {
								$scope.massMediaList[i].assigned && arr.push($scope.massMediaList[i].id);
							}
							this.$http.post('request/save_project_mass_media', {project_id: projectId, mass_media_ids: arr.join()}).success(() => {
								Notifier.show("CHANGES_SAVED", MessageType.success);
								var grid: kendo.ui.Grid = $("#massMediaGrid" + projectId).data("kendoGrid");
								grid.dataSource.read();
								grid.refresh();
								this.$mdDialog.cancel();
							}).error((err) => {
								Notifier.show(err.message, MessageType.error);
							})
						}
						$scope.filterMassMediaList = (massMedia) =>{
							return $scope.selectedCountry == "" || massMedia.country_id == $scope.selectedCountry;
						}
					}]
				})
			}).error((err) => {
				Notifier.show(err.message, MessageType.error)
			})
		}

		public getSocialMediaAccountGridOptions = (projectId: number) => {
			return {
				toolbar: [
					{
						name: "EDIT",
						template: '<span flex></span><md-button ng-if="app.controlVisibility(\'add_social_media_account\')" class="md-icon-button custom-cell-button middle-icon"  ng-click="vm.editSocialMediaAccountList(dataItem.id, $event)"><md-tooltip><span data-translate="EDIT"></span></md-tooltip><md-icon md-svg-icon="editor:mode_edit" aria-label="..." class="edit-mass"></md-icon></md-button> '
					}
				],
				dataSource: {
					transport: {
						read: {
							url: "/request/get_social_media_by_project",
							dataType: "json",
							type: "POST",
							data: {id: projectId}
						}
					},
					pageSize: 10,
				},
				sortable: true,
				pageable: true,
				resizable: true,
				filterable: true,
				columnMenu: true,
				columns: [
					{ field: "name", title: "{{'SOCIAL_MEDIA_ACCOUNT_NAME' | translate}}"},
					{ field: "type", title: "{{'SOCIAL_MEDIA_ACCOUNT_TYPE' | translate}}"}]
			}
		}

		public editSocialMediaAccountList = (projectId, $event) => {
			this.$http.post('request/get_social_media_full_list_for_project/', {id: projectId}).success((res: any) => {
				for(var sm in res) {
					res[sm].assigned = res[sm].assigned == 0 ? false : true;
				}
				this.$mdDialog.show({
					template: $('#socialMediaAccountListPopupTemplate').html(),
					parent: angular.element(document.body),
					locals: {options: {socialMediaAccountList: res}},
					controller: ['$scope', 'options', ($scope, options) => {
						$scope.isChaked = false;
						$scope.selectedType = "";
						$scope.socialMediaAccountList = options.socialMediaAccountList;
						$scope.cancel = () => {
							this.$mdDialog.hide();
						}
						$scope.checkAll = (isChecked: boolean) => {
							for( var sm in $scope.socialMediaAccountList) {
								if ($scope.selectedType && $scope.selectedType == $scope.socialMediaAccountList[sm].type_id) {
									$scope.socialMediaAccountList[sm].assigned = isChecked;
								} else if (!$scope.selectedType) {
									$scope.socialMediaAccountList[sm].assigned = isChecked;
								}
							}
						}

						$scope.saveSocialMediaAccountList = () => {
							var arr: number[] = [];
							for (var i in $scope.socialMediaAccountList) {
								$scope.socialMediaAccountList[i].assigned && arr.push($scope.socialMediaAccountList[i].id);
							}
							this.$http.post('request/save_project_social_media_account', {project_id: projectId, social_media_account_ids: arr.join()}).success(() => {
								Notifier.show("CHANGES_SAVED", MessageType.success);
								var grid: kendo.ui.Grid = $("#socialMediaAccountGrid" + projectId).data("kendoGrid");
								grid.dataSource.read();
								grid.refresh();
								this.$mdDialog.cancel();
							}).error((err) => {
								Notifier.show(err.message, MessageType.error);
							})
						}
						$scope.filterSocialMediaAccountList = (socialMediaAccount) =>{
							return $scope.selectedType == "" || socialMediaAccount.type_id == $scope.selectedType;
						}
					}]
				})
			}).error((err) => {
				Notifier.show(err.message, MessageType.error)
			})
		}


		public controlVisibility = (controlName: string): boolean => {
			return this.authenticationService.user.controlPermissions.indexOf(controlName) != -1;
		}
	}

	angular.module(Module)
		.controller(ProjectController.id, ProjectController);
}

