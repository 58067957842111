module App {
    export class AnalyzeDetailsComponentController extends BaseController {
        private analyzeData: AnalyzeDetail;
        private analyzeList: any[];
        //private AttitudeSource = [{id: AttitudeEnum.Positive, value: AttitudeEnum[AttitudeEnum.Positive]}, {id: AttitudeEnum.Neutral, value:AttitudeEnum[AttitudeEnum.Neutral]},{id: AttitudeEnum.Negative, value:AttitudeEnum[AttitudeEnum.Negative]}];
        private AttitudeSource: Array<any> = [];
        private AttitudeGroupSource: Array<any> = [];
        public feedType: NewsFeedTypeEnum;

        constructor(private newsFeedService: INewsFeedService) {
            super();
        }

        $onInit() {
            this.analyzeData = this.analyzeData || new AnalyzeDetail();
            this.newsFeedService.getAttitudeDatasourse().then((response: Array<any>) => {
                if (response) {
                    this.AttitudeSource = response;
                    this.getAttitudeGroups();
                }
            });
        }

        public getAttitudeGroups = () => {
            for (var i: number = 0; i <= this.AttitudeSource.length; i++) {
                if(this.AttitudeSource[i]!=null)
                {
                    var index = this.AttitudeSource[i].group_id - 1;
                    if (this.AttitudeGroupSource[index] == undefined ) {
                        this.AttitudeGroupSource[index] = [];
                    }
                    this.AttitudeGroupSource[index].push(this.AttitudeSource[i]);
                }
            }
        };

        public execute = (): void => {
            if(this.analyzeList){
                this.newsFeedService.analyzeFeedElementBySelectedArticles(this.analyzeData, this.analyzeList, this.feedType).then((response: boolean) => {
                    if (response) {
                        Notifier.show("Կատարված է։", MessageType.success);
                    }
                });
            }else {
                this.newsFeedService.analyzeFeedElementBySubject(this.analyzeData).then((response: boolean) => {
                    if (response) {
                        Notifier.show("Կատարված է։", MessageType.success);
                    }
                });
            }
        };
    }
    export class AnalyzeDetailsComponent implements ng.IComponentOptions{
        static id = "analyzeDetails";
        templateUrl = "/components/news-feed-components/news-feed-element-analyze-details/news-feed-element-analyze-details.component.html";
        controllerAs = "vm";
        bindings: any;
        constructor() {
            this.bindings = {
                analyzeData: "<",
                analyzeList:"<?",
                feedType: "<?"
            };
        }
        controller = ['newsFeedService',
            AnalyzeDetailsComponentController
        ];
    }

    angular.module(Module)
        .component(AnalyzeDetailsComponent.id, new AnalyzeDetailsComponent());
}
