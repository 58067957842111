module App
{
  class projectsController extends BaseController {
    public mode: string;
    public projects: Project[];
    public selected_project: Project;
    public object: any;
    public project_form_options: any;
    public className: string;

    constructor(public $http: ng.IHttpService, public $mdDialog: any,
                private authenticationService: Authentication) {
      super();
      this.initProjectProperties();
      this.project_form_options = {
        fields: {
          project: {type: "combo", label: "PROJECT", source_url: "/request/get_projects", get: (item) => item, description: "name"}
        },
        rows: [
          [{field: "project", col_class: "col-md-4"}]
        ],
        label_small_size: true
      }
    }

    private initProjectProperties() {
      this.selected_project = null;
      this.mode = "view";
    }

    public addNewProject() {
    this.selected_project = new Project();
    this.mode = "new";
  }

  public removeProject(project: Project) {
    if(confirm("Հեռացնե՞լ նախագիծը։")) {
      let className =  this.className || (<DBLoader>this.object).getClassName();
      //project.object = <IObject> {id: this.object['id'], type: {description: className}};
      project.removeFromDB((err) => {
        if (!err) {
          this.object["loadFromDB"]();
          this.initProjectProperties();
        }
      });
    }
  }

      public  controlVisibility(controlName: string): boolean {
        return this.authenticationService.user.controlPermissions.indexOf(controlName) != -1;
      }

    public saveProject(project: Project) {
      let className =  this.className || (<DBLoader>this.object).getClassName();
      //project.object = <IObject> {id: this.object['id'], type: {description: className}};
      project.saveToDB((err) => {
        if (!err) {
          this.object["loadFromDB"]();
          this.initProjectProperties();
        }
      });
    }

    public cancelEditing() {
      this.initProjectProperties();
    }
  }

  export class projectsComponent implements ng.IComponentOptions {
      public static id = "projects";
      templateUrl = "/components/project-components/projects.component.html";
      controllerAs = "vm";
      bindings: any;
      constructor(){
          this.bindings = {
              object: "=",
              className: "=?"
          };
      }
      controller = ['$http',
          '$mdDialog',
          'authenticationService',
          projectsController
      ];
  }

  angular.module(Module)
    .component(projectsComponent.id, new projectsComponent());
}
