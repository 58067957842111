///<reference path='db-loader.ts' />
module App {
    export class Notification extends DBLoader {
        message: string;
        type: NotificationStatusEnum;
    }
}



