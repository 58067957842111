module App{
    export interface IError {
        StackTrace: string;
        Message: string;
        IDField: string;
    }

    export interface IBaseResponse {
        Errors: IError[];
        HasError: boolean;
        HttpStatusCode: HttpStatusCode;
        ResponseStatus: ResponseStatuses;
    }

    export class BaseResponse implements IBaseResponse {

        //#region properties

        public Errors: IError[];
        public HasError: boolean;
        public HttpStatusCode: HttpStatusCode;
        public ResponseStatus: ResponseStatuses;



        public constructor(data: IBaseResponse) {
            var self = this;
            self.load(data);
        }



        public load(data: IBaseResponse): void {
            var self = this;

            if (data) {
                self.Errors = data.Errors;
                self.HasError = data.HasError;
                self.HttpStatusCode = data.HttpStatusCode;
                self.ResponseStatus = data.ResponseStatus;
            }
        };


    };

}
