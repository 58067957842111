module App {
  'use strict';

  export class PressEventEditController extends BaseController {
    static id = "PressEventEditController";
    public options: any;
    public pressEventId: number;

      constructor(
          private $timeout: ng.ITimeoutService,
          public $mdDialog: any,
          public eventManagerService: EventManagerService,
          public globals: any){
          super();
      }

    $onInit() {
      let m: PressEvent;
      if (this.pressEventId) {
        m = new PressEvent(this.pressEventId).loadFromDB()
      } else {
        m = new PressEvent();
      }

      this.options = {
          title: this.pressEventId ? "EDIT_PRESS_EVENT" : "NEW_PRESS_EVENT",
          size: "small",
          buttons: {
              acceptBtn: {
                  text: "SAVE",
                  btnClass: "btn-primary",
                  disableIfInvalid: true,
                  fnc: () => {
                      this.options.item.saveToDB((err, res?) => {
                          if (!err) {
                              this.$mdDialog.hide();
                              if (!this.pressEventId) {
                                this.eventManagerService.publish(this.globals.events.refreshPressEventList);
                              }
                          }
                      });
                  }
              },
              cancelBtn: {
                  text: "CANCEL",
                  btnClass: "btn-default",
                  fnc:() => {
                      this.$mdDialog.cancel()
                  }
              }
          },
          item: m,
          item_options: {
              fields: {
                  name: {
                    type: "text",
                    label: "NAME",
                    get: (item) => item,
                    required: () => true
                  },
                  event_date: {
                    type: "date",
                    label: "EVENT_DATE",
                    get: (item) => item,
                    required: () => true
                  }
                },
              rows: [
                  [{field: "name", col_class: "col-md-12"}],
                  [{field: "event_date", col_class: "col-md-7"}]
              ]
          }
      }
      }
  }

  export class PressEventEditComponent implements ng.IComponentOptions {
    static id = "pressEventEdit";
    templateUrl = "/components/press-event-components/press-event-edit-component/press-event-edit.component.html";
    controllerAs = "vm";
    bindings: any;
    constructor(){
        this.bindings = {
            pressEventId: "=?",
            options: "="
        };
    }
    controller = ['$timeout', '$mdDialog', 'eventManagerService', 'globals',
        PressEventEditController
    ];
  }

  angular.module(Module)
    .component(PressEventEditComponent.id,  new PressEventEditComponent());

}
