module App{
    export class ProjectStructureController{
        static id = "projectStructureController";

        public project_structure_id: number;
        public selected_element: any;
        public element_form_options: any;
        public element_form_options_edit: any;
        private fields_array: string[];
        public treeViewData;
        public collapseBtnName: string;
        public b: boolean;
        public isLoaded: boolean;

/*
        public showProject(i: number) {
            this.isLoaded = false;
            HttpRequester.post("/request/get_project_structure", null)
                .then((response: IProject[]) => {
                    this.projects = response;
                    this.treeViewData = [d];
                    this.isLoaded = true;
                });
            var d = new ProjectStructureElement(i);

            d.loadFromDB(() => {
                d.loadChildrenFromStructureData();
                this.treeViewData = [d];
                this.isLoaded = true;
            });
        }

        public collapseBtnClick() {
            this.b = !this.b;
            this.collapseBtnName = this.b ? "EXPAND_ALL" : "COLLAPSE_ALL";
            this.collapseOrExpandAll(this.treeViewData)
        }

        public collapseOrExpandAll(obj) {
            for(var i in obj) {
                obj[i].collapsed = this.b;
                if (obj[i].structureData) {
                    this.collapseOrExpandAll(obj[i].structureData);
                }
            }
        }

        public departmentSelected(d) {
            this.selected_element = new ProjectStructureElement(d.id);
            this.initDepartmentFormOptions();
            this.selected_element.loadFromDB((err) => {
                if (err) {
                    console.error("Cannot get department properties ", err);
                    return;
                }
                this.header.title = this.selected_element.title;
            });
        }

        public header = {
            title: "",
            icon: "action:view_quilt",
            buttons: {
                btn_edit: {
                    tooltip: "EDIT_DEPARTMENT",
                    icon: "editor:mode_edit",
                    fnc: ($event) => {
                        if(!this.selected_department) {
                            Notifier.show("SELECT_DEPARTMENT", MessageType.warning);
                            return;
                        }
                        var newItem = new Department(this.selected_department.id).loadFromDB();
                        var dialog_options = {
                            title: "EDIT_DEPARTMENT",
                            size: "medium",
                            buttons: {
                                acceptBtn: {
                                    text: "SAVE",
                                    fnc: () => {
                                        dialog_options.item.saveToDB((err) => {
                                            if (!err) {
                                                this.$mdDialog.hide();
                                                this.showDepartment(this.project_structure_id);
                                            }
                                        });
                                    }
                                },
                                cancelBtn: {
                                    text: "CANCEL",
                                    fnc: () => {
                                        this.$mdDialog.hide();
                                    }
                                }
                            },
                            item: newItem,
                            item_options: this.department_form_options_edit //getOptions(this.department_form_options)
                        };

                        function getOptions(o: any) {
                            var obj = angular.copy(o)
                            for(var i in obj.fields) {
                                delete obj["fields"][i]["readonly"];
                                if (obj["fields"][i]["format"] == "date") {
                                    delete obj["fields"][i]["format"];
                                    obj["fields"][i]["type"] = "date";
                                }
                                if (obj["fields"][i]["source_url"]) {
                                    obj["fields"][i]["type"] = "combo";
                                    if (obj["fields"][i]["property_name"]) {
                                        delete obj["fields"][i]["property_name"];
                                    }
                                    if (obj["fields"][i]["get"]) {
                                        delete obj["fields"][i]["get"];
                                        angular.extend(obj["fields"][i], {get: (item) => item})
                                    }
                                }
                            }

                            return obj;
                        }

                        this.$mdDialog.show({
                            template: '<dialog-box options="options"></dialog-box>',
                            parent: angular.element(document.body),
                            targetEvent: $event || false,
                            locals: {options: dialog_options},
                            controller: ['$scope', 'options', ($scope, options) => $scope.options = options]
                        }).then(() => {
                            this.selected_department.loadFromDB((err) => {
                                if (!err) {
                                    this.header.title = this.selected_department.title;
                                }
                            });
                        });
                    }
                },
                btn_remove: {
                    tooltip: "REMOVE_DEPARTMENT",
                    icon: "action:delete",
                    fnc: ($event) => {
                        if(confirm('Հեռացնե՞լ ընտրված ստորաբաժանումը')) {
                            this.$http.post('request/remove_department', {id: this.selected_department.id}).success(() => {
                                Notifier.show("DEPARTMENT_REMOVED", MessageType.info);
                                this.showDepartment(this.project_structure_id);
                            }).error((err) => {
                                Notifier.show(err.message, MessageType.error)
                            })
                        }
                    }
                }
            }
        };

        private initDepartmentFormOptions() {
            this.department_form_options = {
                fields: {
                    status: {type: "text", readonly: "true", label: "STATUS", get: (item) => item.status, property_name: "description"},
                    head: {type: "text", readonly: "true", label: "HEAD", get: (item) => item.head, property_name: "description"},
                    description: {type: "textarea", readonly: "true", label: "DESCRIPTION"}
                },
                rows: [
                    [{field: "status", col_class: "col-md-6"}],
                    [{field: "head", col_class: "col-md-6"}],
                    [{field: "description", col_class: "col-md-6"}]
                ]
            };

            this.department_form_options_edit = {
                fields: {
                    title: {type: "text", label: "TITLE"},
                    status: {type: "combo", label: "STATUS", source_url: "/request/get_department_statuses", get: (item) => item},
                    description: {type: "textarea", label: "DESCRIPTION"},
                    head: {type: "combo", label: "HEAD", source_url: "/request/get_employees_for_combo", get: (item) => item},
                },
                rows: [
                    [{field: "title", col_class: "col-md-6"}],
                    [{field: "status", col_class: "col-md-6"}],
                    [{field: "description", col_class: "col-md-6"}],
                    [{field: "head", col_class: "col-md-6"}]
                ]
            }
        }

        public addButtonVisibility(node): boolean {
            var b: boolean = true;
            b = node.canHaveChild;
            return b;
        }

        public addChildren(node) {
            var options: any = {
                title: node.title,
                //event: $event,
                size: "small",
                buttons: {
                    acceptBtn: {
                        text: "ADD",
                        fnc: () => {
                            this.$http.post('request/add_departments/', options.item).success(() => {
                                this.showDepartment(this.project_structure_id);
                                this.$mdDialog.cancel();
                            }).error((err) => {
                                Notifier.show(err.message, MessageType.error);
                            })
                        }
                    },
                    cancelBtn: {
                        text: "CANCEL",
                        fnc:() => {
                            this.$mdDialog.cancel()
                        }
                    }
                },
                item: {"parent_id": node.id, children_count: 1},
                item_options: {
                    fields: {
                        children_type: {type: "combo", label: "CHILDREN", source_url: "/request/get_department_possible_children/" + node.type.id.toString(), "id": "department_type_id", "description": "department_type_description"},
                        children_count: {type: "text", label: "COUNT"}
                    },
                    rows: [
                        [{field: "children_type", col_class: "col-md-6"}],
                        [{field: "children_count", col_class: "col-md-6"}]
                    ]
                }
            };

            this.$mdDialog.show({
                template: '<dialog-box options="options"></dialog-box>',
                parent: angular.element(document.body),
                targetEvent: false,
                locals: {options: options},
                controller: ['$scope', 'options', ($scope, options) => $scope.options = options]
            });
        }
*/

        /*@ngInject*/
        constructor(
            public $state: ng.ui.IStateService,
            public $scope: ng.IScope,
            private $mdDialog: any,
            private $http: ng.IHttpService,
            private authenticationService: Authentication) {

            this.project_structure_id = $state.params["projectId"];
            this.element_form_options = {fields: {}, rows: {}};
            this.fields_array = [];
            this.collapseBtnName = "COLLAPSE_ALL";
            this.b = false;
            this.isLoaded = false;
            //this.showDepartment(this.project_structure_id);

            $scope.$watch("project_structure_tree.currentNode", (value) => {
                //value && this.departmentSelected(value);
            })
        }
    }
    angular.module(Module)
        .controller(ProjectStructureController.id, ProjectStructureController);
}
