module App {
    export class SocialMediaAddComponentController {

        public post: IPost;
        public options: ISocialMediaElement;
        constructor(private $mdDialog: any,
            private eventManagerService: EventManagerService,
            private socialMediaService: SocialMediaService,
            private globals: any) {
        }

        $onInit() {
            if(this.options){
               this.socialMediaService.getSocialMediaPostBYId(this.options.id).then((res) => {
                   if(res){
                    this.post = res[0]
                   }
               })
            }else {     
                this.post = new Post();    
            }
            
        }

        public save = () => {
            this.socialMediaService.savePost(this.post).then((res)=> {
                if (res) {
                    Notifier.show("Պահպանված է։", MessageType.success);
                    this.eventManagerService.publish(this.globals.events.getPostList,1);
                    this.closeWindow();
                }
            })
        }

        public closeWindow = (): void => {
            this.$mdDialog.hide();
        };

    }

    export class SocialMediaAddComponent implements ng.IComponentOptions {
        public static id = "socialMediaAdd";
        templateUrl = "/components/social-media-components/social-media-add/social-media-add.component.html";
        controllerAs = "vm";
        bindings: any;
        constructor() {
            this.bindings = {
                options: "<?",
            }
        }
        controller = ['$mdDialog', 'eventManagerService', 'socialMediaService', 'globals',
            SocialMediaAddComponentController
        ];
    }
    angular.module(Module)
        .component(SocialMediaAddComponent.id, new SocialMediaAddComponent());
}
