module App
{
  class usersController extends BaseController {
    public users: User[];
    public object: any;

    constructor() {
      super();
    }
  }

  export class usersComponent implements ng.IComponentOptions {
      public static id = "users";
      templateUrl = "/components/user-components/users.component.html";
      controllerAs = "vm";
      bindings: any;
      constructor(){
          this.bindings = {
              object: "=",
              className: "=?"
          };
      }
      controller = [
          usersController
      ];
  }

  angular.module(Module)
    .component(usersComponent.id, new usersComponent());
}
