module App
{
  export class KendoGridDirective implements ng.IDirective {
    public static id = "kendoGrid";
    public restrict = 'A';
    //public replace = true;

    public scope = {
      options: '=',
      kendoGrid: '='
    }

    public link: Function = ($scope: ng.IScope, element: JQuery, attributes: ng.IAttributes) => {

      $("#grid").delegate("tbody>tr", "dblclick", function(){$scope["options"].dblClick && $scope["options"].dblClick()});

      if ($scope["options"] && (angular.isUndefined($scope["options"].translateColumnTitles) || $scope["options"].translateColumnTitles)) {
        for (var i in $scope["options"].columns) {
          if ($scope["options"].columns[i].title) {
            $scope["options"].columns[i].title = "{{'" + $scope["options"].columns[i].title + "' | translate}}" //this.$translate.instant($scope["options"].columns[i].title); //
          }
        }
      }

      if ($scope["options"] && angular.isDefined($scope["options"].savedSettings)) {
        var s: Object = {
          settings: "Настройки",
          saveSettings: "Сохранить настройки",
          restoreToDefaults: "Восстановить по умолчанию",
          settingsSaved: "Настройки сохранены.",
          settingsRestored: "Настройки восстановлены по умолчанию. Обновите страницу."
        };

        var options = window.localStorage[$scope["options"].savedSettings];

        if(options) {
          var saved_columns_options = (JSON.parse(options)).columns;
          for(var i in $scope["options"].columns) {
            var index = this.getObjectIndexInArrayByProperty(saved_columns_options, 'field', $scope["options"].columns[i].field);
            $scope["options"].columns[i].hidden = (index != -1 ? saved_columns_options[index].hidden || false : false);
            if (saved_columns_options[i].width) {
              $scope["options"].columns[i].width = saved_columns_options[i].width;
            }
          }
        }

        $scope["options"].columnMenuInit = (e) => {
          var menu = e.container.find(".k-menu").data("kendoMenu");

          menu.append([
            {
              text: s["settings"],
              items: [{text: s["saveSettings"]},{text: s["restoreToDefaults"]}]
            }
          ]);

          menu.bind("select", function (e) {
            var menuText = $(e.item).text();
            if (menuText == s["saveSettings"]) {
              e.preventDefault();
              window.localStorage[$scope["options"].savedSettings] = kendo.stringify($scope["kendoGrid"].getOptions());
              Notifier.show(s["settingsSaved"], MessageType.success);
            } else if (menuText == s["restoreToDefaults"]) {
              e.preventDefault();
              window.localStorage.removeItem($scope["options"].savedSettings);
              Notifier.show(s["settingsRestored"], MessageType.success);
            }
          });
        }
      }
    }

    public getObjectIndexInArrayByProperty(array: Array<any>, propertyName: string, value: any): number {
      for(var i: number = 0; i < array.length; ++i) {
        if(array[i][propertyName] === value) {
          return i;
        }
      }
      return -1;
    }

    /*@ngInject*/
    constructor () {

    }
  }

  angular.module(Module)
    .directive(KendoGridDirective.id, () => new KendoGridDirective());
}
