module App {
    export class Map<Value> {

        get = (key: number | string | any): Value => {
            return <Value>this.map[key];
        }

        set = (key: number | string | any, value: Value) => {
            this.map[key] = value;
        }

        public keys = (): any[] => {

            var keys = [];

            for (var key in this.map) {
                if (this.map.hasOwnProperty(key)) {
                    keys.push(key);
                }
            }
            return keys;
        }

        remove = (key: number | string | any): boolean => {
            if (this.map.hasOwnProperty(key)) {
                delete this.map[key];
                return true;
            }

            return false;
        }

        hasKey = (key: number | string | any): boolean => {
            return this.map.hasOwnProperty(key);
        }

        hasKeys = (...keys: any[]): boolean => {

            for (var key in keys) {
                if (!this.hasKey(key)) {
                    return false;
                }
            }
            return true;
        }

        values = (): Value[] => {

            var values: Value[] = [];

            var keys = this.keys();
            keys.forEach(key => values.push(this.map[key]));
            return values;
        }

        updateFrom = (externalMap: Map<Value>) => {
            for (var key in externalMap.keys) {
                this.set(key, externalMap.get(key));
            }
        }

        private map = new Object();
    }
}