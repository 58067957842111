module App {
	'use strict';

	export class SocialMediaAccountsController extends BaseController {
		static id = "socialMediaAccountsController";

		public acceptedFileTypes: string = "image/png, image/jpeg, image/jpg";		
		public grid: kendo.ui.Grid;
		public gridOptions: any;
		public bace64: string = '';
		public b: any;
		/*@ngInject*/
		constructor(private $filter: ng.IFilterService,
			private authenticationService: Authentication,
			private socialMediaService: SocialMediaService,
			private $mdDialog: any) {
			super();
			this.setListMode();
			this.initGridOptions();

		}

		private initGridOptions = () => {
			this.gridOptions = {
				dataSource: {
					transport: {
						read: {
							url: "/request/get_social_media_accounts",
							dataType: "json",
							type: "POST",
							data: { id: null }
						}
					},
					pageSize: 20,
				},
				sortable: true,
				pageable: true,
				resizable: true,
				filterable: true,
				columnMenu: true,
				columns: [
					{ field :"image", title:"IMAGE", template:`<img class="img-circle img-grid" src="{{dataItem.image.length > 0 ? dataItem.image : 'imgs/user.png'}}" />`, width: 100},
					{ field: "name",  template: `{{dataItem.name.split('(')[0]}}`, title: "NAME" , width:350},
					{ field: "type", title: "TYPE" , width:250},
					{ field: "status", title: "STATUS", width:250 },
					{ field: "main_url", template: `<a href="{{dataItem.main_url}}" target="_blank">{{dataItem.main_url.length > 80 ? (dataItem.main_url | limitTo: 80) + '... ': dataItem.main_url}}</a>`, title: "URL" },
					{ template: `<md-button ng-if="vm.controlVisibility(\'edit_social_media_account\')" class="md-icon-button custom-cell-button" ng-click="vm.saveSocialMediaAccount(dataItem, $event)" aria-label="Edit"><md-tooltip><span data-translate="EDIT"></span></md-tooltip><md-icon md-svg-icon="editor:mode_edit" aria-label="..."></md-icon></md-button>`, width: 70 }
				]
			}
		}

		public saveSocialMediaAccount(social_account: ISocialMedia, $event) {
			var socialAccountCopy: any;
			if (social_account) {
				socialAccountCopy = angular.copy(social_account);
				socialAccountCopy.status = { id: socialAccountCopy.status_id, name: socialAccountCopy.status };
				socialAccountCopy.type = { id: socialAccountCopy.type_id, name: socialAccountCopy.type };
				socialAccountCopy.name = socialAccountCopy.name.split('(')[0]
			} else {
				socialAccountCopy = new SocialMedia();
			}
			var dialog_options = {
				title: social_account ? "EDIT_SOCIAL_ACCOUNT" : "ADD_SOCIAL_ACCOUNT",
				size: "medium",
				buttons: {
					acceptBtn: {
						text: "SAVE",
						disableIfInvalid: true,
						fnc: () => {
							this.save(socialAccountCopy);
						}
					},
					cancelBtn: {
						text: "CANCEL",
						fnc: () => {
							this.$mdDialog.hide();
						}
					}
				},
				item: socialAccountCopy,
				item_options: {
					fields: {
						image: { type: "file", 
							addEvent: () => this.addEvent(event), 
							src:  this.getSrc(socialAccountCopy.image), 
							acceptedFileTypes: this.acceptedFileTypes, 
							get: (item) => item 
						},
						name: { type: "text", label: "NAME", required: () => true },
						type: { type: "combo", label: "SOCIAL_MEDIA_ACOOUNT_TYPE", source_url: "/request/get_social_media_types", description: "name", required: () => true },
						status: { type: "combo", label: "STATUS", get: (item) => item, source_url: "/request/get_statuses" },
						main_url: { type: "text", label: "URL", required: () => true }
					},
					rows: [
						[{ field: "image", col_class: "col-xs-12"}],
						[{ field: "name", col_class: "col-xs-6" }, { field: "type", col_class: "col-xs-6" }],
						[{ field: "main_url", col_class: "col-xs-6" }, { field: "status", col_class: "col-xs-6" }]
					]
				}
			};
			this.$mdDialog.show({
				template: '<dialog-box options="options"></dialog-box>',
				parent: angular.element(document.body),
				targetEvent: $event || false,
				locals: { options: dialog_options },
				controller: ['$scope', 'options', ($scope, options) => $scope.options = options]
			})
		};

		public getSrc = (img):string => {
			return img ? img : 'imgs/user.png'
		}

		public addEvent = (e) => {
			var reader = new FileReader();
			reader.onload = (e) => {
				if(reader.result){
					this.bace64 = <string>reader.result;
					document.getElementById('#social')['src'] = reader.result 
				}
			}
			if(e.target && e.target.files){
				reader.readAsDataURL(e.target.files[0]);
			}
		}

	

		public save = (item) => {
			if(item.image && item.image.size / 1024 > 100 ){
				Notifier.show("Ընտրված նկարի չափը գերազանցում է 100KB -ն․", MessageType.error);
				return
			} else {
				item.image = this.bace64;
				this.socialMediaService.saveSocialmediaAccount(item).then((b: any) => {
					if (!(b instanceof Error)) {
						Notifier.show("Պահպանված է։", MessageType.success);
						this.$mdDialog.hide();
						this.grid.dataSource.read();
						this.grid.refresh();
					}
				})
			}
		}

		public controlVisibility = (controlName: string): boolean => {
			return this.authenticationService.user.controlPermissions.indexOf(controlName) != -1;
		}
	}


	angular.module(Module)
		.controller(SocialMediaAccountsController.id, SocialMediaAccountsController);
}
