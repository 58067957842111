module App {
  'use strict';

  export class LoginController {
    static id = "loginController";

    public username: string;
    public password: string;
    public err_message: string;

    /*@ngInject*/
    constructor(public $http: ng.IHttpService, private $state: ng.ui.IStateService, public authenticationService: Authentication) {
      this.username = "";
      this.password = "";
      this.err_message = ""
    }

    public login() {
      if (this.username == "" || this.password == "") {
        this.err_message = "Fill all fields";
      }
      this.authenticationService.loginUser(this.username, this.password, (err: Error) => {
        if (this.authenticationService.isAuthorized()) {
          this.$state.go("layout.newsFeed");
        } else if (err) {
          this.err_message = err.message;
        }
      });
    }

  }

  angular.module(Module)
    .controller(LoginController.id, LoginController);

}
