module App
{
  export class ItemViewForm {
    public static id = "itemViewForm";
    public restrict = 'AE';
    public scope = {
      options: '=',
      item: '=',
      header: '='
    };

    public link: Function = ($scope: ng.IScope, element: JQuery, attributes: ng.IAttributes) => {
      if (! $scope["options"]) {
        console.error("No options attribute on item-view-form");
      }
      $scope["getFieldParent"] = (field, item) => {
          return field.get && field.get(item) || item;
      }
      for (var i in $scope["options"]["fields"]) {
        if ($scope["item"]
          && angular.isUndefined($scope["item"][i])
          && angular.isDefined($scope["options"]["fields"][i].default_value)) {
            //$scope["item"][i] = $scope["options"]["fields"][i].default_value;
        }
        if ($scope["options"]["fields"][i].type == "combo") {
          $scope["options"]["fields"][i].kendoComboWatcher = $scope.$watch("getFieldParent('" + i + "', item)['" + ($scope["options"]["fields"].property_name || i) + "']",
            (function(field) {
              return function (value) {
                if (value && field && field.options) {
                  field.kendoComboValue = value[field.options.id || "id"];
                  field.kendoComboWatcher();
                }
              }
            })($scope["options"]["fields"][i])
          );
        }
        if ($scope["options"]["fields"][i].type == "file") {
          $scope["options"]["fields"][i].fileWatcher = $scope.$watch("getFieldParent('" + i + "', item)['" + ($scope["options"]["fields"].property_name || i) + "']",
            (function(field) {  
              return function (value) {
                if(value){
                  field.addEvent();
                }
              }
            })($scope["options"]["fields"][i])
          );
        }
        if ($scope["options"]["fields"][i].type == "multiselect") {
          $scope["options"]["fields"][i].kendoMultiSelectWatcher = $scope.$watch("getFieldParent('" + i + "', item)['" + ($scope["options"]["fields"].property_name || i) + "']",
              (function(field) {
                return function (value) {
                  if (value && field && field.options && field.options.id) {
                    field.kendoMultiSelectValue = value[field.options.id || "id"];
                    field.kendoMultiSelectWatcher();
                  }
                }
              })($scope["options"]["fields"][i])
          );
        }

      }

      $scope["controlVisibility"] = (controlName: string) => {
        if (this.authenticationService.user.controlPermissions.indexOf(controlName) != -1) {
          return true
        }
        return false;
      }


      this.$templateRequest("directives/item-view-form/item-view-form.html").then((html) => {
        var template = angular.element(html);
        element.append(template);
        this.$compile(template)($scope);
      });
    }

    /*@ngInject*/
    constructor(
      public $templateRequest: ng.ITemplateRequestService,
      public $compile: ng.ICompileService,
      private authenticationService: Authentication
    ) {
    }
  }

  angular.module(Module)
    .directive(ItemViewForm.id, ['$templateRequest', '$compile', 'authenticationService', ($templateRequest, $compile, authenticationService) => new ItemViewForm($templateRequest, $compile, authenticationService)]);
}
