module App {
	export class NewsFeedHeaderComponentController  {
		public selectedElements: Array<IFeedCardElement>;
		public FeedElementActions = FeedElementActionsEnum;
		public GroupSelection = GroupSelectionEnum;
		public feedList: IFeedCardElement[];
		public isChecked: boolean;
		public countPerPage: number;

		public pageOptions = {
			dataSource: this.globals.dataSources.countPerPageDataSource,
			dataTextField: "value",
			dataValueField: "id",
			// headerTemplate: '<div class="dropdown-header k-widget k-header">' +
			// '<span></span>' +
			// '</div>',
			valueTemplate: '<span class="val-txt">{{dataItem.value}}</span>',
			template: '<span class="mm-k-dropdown-val-txt">{{dataItem.value}}</span>'
		};

		public listItems: Array<{ text: string, value: number }> = [
			{ text: "Small", value: 1 },
			{ text: "Medium", value: 2 },
			{ text: "Large", value: 3 }
		];
		public selectedValue: { text: string, value: number } = { text: "Foo", value: 1 };

		constructor(
			private $mdDialog: any,
			private authenticationService: Authentication,
			private eventManagerService: EventManagerService,
			private globals: any,
			private newsFeedService: INewsFeedService,
			private mailingService: IMailingService,
			private $filter: ng.IFilterService) {

		}
		$onInit(){
			this.eventManagerService.subscribe(this.globals.events.allCheckUnSelect, this.allCheckUnSelect);
			this.countPerPage = +window.localStorage["countPerPage"] || this.countPerPage;
		}
		public allCheckUnSelect = (res: number): void => {
			res < this.countPerPage ? this.isChecked = false : this.isChecked = true;
		}

		public generatePdf = () => {
			if (this.selectedElements.length > 0) {
				var win = window.open();
				var doc = win.document.open();
				doc.write(Utilities.generateHtmlFromSelectedArticles(this.selectedElements, this.$filter));
				doc.close();
				win.print();
			} else {
				Notifier.show("Please select articles.", MessageType.warning);
			}
		};

		public changeCountPerPage = (): void =>{
			window.localStorage["countPerPage"] = this.countPerPage;
		};

		public checkAll = (isChecked: boolean) => {
			if(isChecked) {
				this.groupSelection(GroupSelectionEnum.All);
			} else {
				this.groupSelection(GroupSelectionEnum.None);
			}
		};

		public changeState = (state: boolean) => {
			this.newsFeedService.changeReadState(this.selectedElements, state).then((response: boolean) => {
				if (response) {
					this.selectedElements.forEach((element) => {
						element.read_state = state;
					})
				}
			});
		};

		public groupSelection = (selectionGroup: GroupSelectionEnum) => {
			this.feedList.forEach((item) => {
				if(selectionGroup == GroupSelectionEnum.All) {
					this.eventManagerService.publish(this.globals.events.selectedElementsEdit, item.id, SelectionOperationEnum.Add);
					this.isChecked = true;
				} else if(selectionGroup == GroupSelectionEnum.None){
					this.eventManagerService.publish(this.globals.events.selectedElementsEdit, item.id, SelectionOperationEnum.Remove);
					this.isChecked = false;
				} else if(selectionGroup == GroupSelectionEnum.Read) {
					if(item.read_state) {
						this.eventManagerService.publish(this.globals.events.selectedElementsEdit, item.id, SelectionOperationEnum.Add);
					} else {
						this.eventManagerService.publish(this.globals.events.selectedElementsEdit, item.id, SelectionOperationEnum.Remove);
					}
				} else if(selectionGroup == GroupSelectionEnum.Unread) {
					if(!item.read_state) {
						this.eventManagerService.publish(this.globals.events.selectedElementsEdit, item.id, SelectionOperationEnum.Add);
					} else {
						this.eventManagerService.publish(this.globals.events.selectedElementsEdit, item.id, SelectionOperationEnum.Remove);
					}
				}
			});
			this.eventManagerService.publish(this.globals.events.elementComparison);
		};

		public showDialog = ($event, action): void => {
			if (this.selectedElements.length == 0){
				Notifier.show("Նախ ընտրեք որևէ հոդված։", MessageType.warning);
			} else if(_.findIndex(this.selectedElements, (item) => { return item.press_event }) != -1 && action == FeedElementActionsEnum.PressEventJoin) {
				Notifier.show("Ընտրված հոդված(ներ)ը արդեն ընդգրկված են ինֆորմացիոն իրադարձության մեջ։", MessageType.warning);
			} else if( action == FeedElementActionsEnum.RemoveElementFromSubjects){
				this.newsFeedService.getSubjectsBySelectedArticles(this.selectedElements).then((response: Dictionary[]) => {
					if (response.length == 0) {
						Notifier.show("Ընտրված հոդված(ներ)ը պետք է ունենան ընդհանաուր թեմա։", MessageType.warning);	
						return
					}else{
						this.showDialogContent($event, action);
					}
				});
			} else {
				if(action == FeedElementActionsEnum.ArticleMerge) {					
					let lang: LanguageEnum;
					for (let element of this.selectedElements) {
						if (this.selectedElements[0].selected_article.mass_media.id !== element.selected_article.mass_media.id) {
							Notifier.show("Ընտրված հոդված(ներ)ը պետք է պատկանեն միևնույն լրատվամիջոցին։", MessageType.warning);
							return;
						}
						if (lang == element.selected_article.language) {
							Notifier.show("Ընտրված հոդված(ներ)ը պետք է լինեն տարբեր լեզուներով։", MessageType.warning);
							return;
						}
						lang = element.selected_article.language;
					}	
				}
				this.showDialogContent($event, action);
			}
		};

		public showDialogContent($event, action): void{
			let options: any = {
				action: action,
				selectedElements: this.selectedElements
			};
			this.$mdDialog.show({
				template: '<news-feed-selected-elements options="options"></news-feed-selected-elements>',
				parent: angular.element(document.body),
				targetEvent: $event || false,
				locals: {options: options},
				controller: ['$scope', 'options', ($scope, options) => {
					$scope.options = options; }
				]
			})
		}

		public addArticle = ($event): void => {
			this.$mdDialog.show({
				template: '<add-article></add-article>',
				parent: angular.element(document.body),
				targetEvent: $event || false,
				//locals: {options: options},
				controller: ['$scope', ($scope) => {
				}
				]
			}).then(() => {
				this.eventManagerService.publish(this.globals.events.getNewsFeedList,1);
			});
		};
	}

	export class NewsFeedHeaderComponent implements ng.IComponentOptions {
		public static id = "newsFeedHeader";
		templateUrl = "/components/news-feed-components/news-feed-header/news-feed-header.component.html";
		controllerAs = "vm";
		bindings: any;
		constructor() {
			this.bindings = {
				selectedElements: "<",
				countPerPage: "=",
				totalCount: "<",
				feedList: "<"
			};
		}
		controller = ['$mdDialog','authenticationService', 'eventManagerService', 'globals', 'newsFeedService', 'mailingService', '$filter',
			NewsFeedHeaderComponentController
		];
	}
	angular.module(Module)
		.component(NewsFeedHeaderComponent.id, new NewsFeedHeaderComponent());
}
