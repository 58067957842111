module App {
    'use strict';

    export class NewsFeedController extends BaseController {
        static id = "newsFeedController";

        public filter: any = {};
        public filter_form_options: any;
        public filtersDescriptionForExcel: string;


        /*@ngInject*/
        constructor(private authenticationService: Authentication,
                    public eventManagerService: EventManagerService,
                    public globals: any) {
            super();
        };

        public filterFunction = () => {
            this.eventManagerService.publish(this.globals.events.unSelectAllElements);
            this.eventManagerService.publish(this.globals.events.getNewsFeedList, 1);
        };

        public clearFilters = () => {
            this.filter = new NewsFilter();
            this.filterFunction();
        };
    }

    angular.module(Module)
        .controller(NewsFeedController.id, NewsFeedController);
}
