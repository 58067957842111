module App {
  'use strict';

  export class CustomerController extends BaseController {
    static id = "customerController";
    public b:any;
    /*@ngInject*/
    constructor(private $filter: ng.IFilterService) {
      super();
      if(!this.viewMode) {
        this.setListMode();
      }
    }
  }

  angular.module(Module)
    .controller(CustomerController.id, CustomerController);
}
