///<reference path='db-loader.ts' />
module App {

    export interface ICustomer {
        id: number;
        name: string;
        registration_date: Date;
        projects?: Project[];
        users?: User[];
    }

    export class Customer extends DBLoader {
        constructor(id?: number) {
            super();
            this.id = id || null;
            this.name = null;
            this.registration_date = null;
        }
        public id: number;
        public name: string;
        public registration_date: Date;
        public projects: Project[];
        public users: User[];
    }
}
