module App
{
/*    export class BindHtmlCompile implements ng.IDirective {
    static id = "bindHtmlCompile";
    public restrict = 'A';
    public link = (scope, element, attrs) => {
      scope.$watch(() => {
        return scope.$eval(attrs.bindHtmlCompile);
      }, (value) => {
        element.html(value);
        this.$compile(element.contents())(scope);
      });
    }

    constructor(public $compile: ng.ICompileService) {
    }
  }*/

  export class TabPanel implements ng.IDirective {
    public static id = "tabPanel";
    public restrict = 'AE';
    public templateUrl = 'directives/tab-panel/tab-panel.html';

    public link($scope: ng.IScope, element: JQuery, attrs: ng.IAttributes) {
      $scope["tabs"] = $scope.$eval(attrs["tabs"]);

      $scope["selected_tab"] = 0;
      $scope["onTabSelected"] = (i) => {
        $scope["selected_tab"] = $scope["tabs"][i];
        $scope["selected_tab"].is_loaded = true;
      }
    }
  }

  angular.module(Module)
    .directive(TabPanel.id, () => new TabPanel())
    //.directive(BindHtmlCompile.id, ['$compile', ($compile) => new BindHtmlCompile($compile)]);

}

