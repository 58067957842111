///<reference path='db-loader.ts' />
module App{
    export interface IDictionary {
        id: number;
        description: string;
    }

    export class Dictionary implements  IDictionary{
        id: number;
        description: string;
    }

    export interface IRequestParameterModel {
        count_per_page: number
        current_page: number;
        filter: any;
    }

    export class RequestParameterModel {
        public count_per_page: number;
        public current_page: number;
        public filter: any;
    }

}


