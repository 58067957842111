module App {
    export class NewsFeedElementAnalyzeComponentController  {
        private analyzeList: Array<IAnalyzeDetail> = [];
        public options: any;
        private AttitudeGroups = AttitudeEnum;

        constructor(private $mdDialog: any,
                    public newsFeedService: INewsFeedService,
                    public eventManagerService: EventManagerService,
                    public globals: any) {
        }
        $onInit(){
            this.newsFeedService.getAnalyzeDetailsBySubject(this.options).then((response: Array<IAnalyzeDetail>) => {
                if (response) {
                    this.analyzeList = response;
                }
            });
        }

        public cancel = (): void => {
            this.closeWindow();
        };

        private closeWindow = (): void =>{
            this.$mdDialog.hide();
        }
    }

    export class NewsFeedElementAnalyzeComponent implements ng.IComponentOptions {
        public static id = "newsFeedElementAnalyze";
        templateUrl = "/components/news-feed-components/news-feed-element-analyze/news-feed-element-analyze.component.html";
        controllerAs = "vm";
        bindings: any;
        constructor() {
            this.bindings = {
                options: "="
            };
        }
        controller = ['$mdDialog', 'newsFeedService', 'eventManagerService', 'globals',
            NewsFeedElementAnalyzeComponentController
        ];
    }
    angular.module(Module)
        .component(NewsFeedElementAnalyzeComponent.id, new NewsFeedElementAnalyzeComponent());
}
